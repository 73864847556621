import React, { useEffect, useState } from "react";
import BlockRenderFn from "./Exports";
import { useSelector } from "react-redux";
import _ from "underscore";

export default function Template1() {    
    const data = useSelector((state) => state.dataValue)
    // const [data, setdataVal] = useState({})
    // function getFetchData() {
    //     fetch("http://localhost:3000/db").then(res => res.json()).then(result => {
    //         let iframeBody = document.getElementById("customizer")
    //         // if (iframeBody.document) {
    //         //     iframeBody = iframeBody.document;
    //         // }
    //         console.log("iframeBody: ", iframeBody)
    //         // iframeBody.body.style.backgroundColor = "red";
    //         setdataVal(result)
    //     })
    // }
    // useEffect(() => {
    //     getFetchData()
    // }, [])
    console.log("dataVal------:", data)
    return (
        <React.Fragment>
            {!_.isEmpty(data) && data.active.length > 0 && data.active.map((opt, index) => {
                let activeData = !_.isEmpty(data) && data.components[opt]
                return (
                    <div className="layout_card_inner" key={index.toString()}>
                        <BlockRenderFn
                            type={opt}
                            data={activeData}
                        />
                    </div>
                )
            })}
        </React.Fragment>
    )
}