import React from 'react'
import { Box, IconButton, TextField, InputAdornment, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function Quantity({ quantity, incriment, decrement, handleQtyChange, endorement }) {

    return (
        <Box sx={{
            display: 'inline-flex',
        }}>
            <IconButton aria-label="view" color="primary" size="small" fontSize="12px" onClick={decrement}><RemoveIcon fontSize='inherit' /></IconButton>
            <TextField
                sx={{ width: "60px", textAlign: "center", mx: .4, }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', }}
                value={quantity}
                size="small"
                onChange={handleQtyChange}

                InputProps={{
                    endAdornment: endorement !== "" ? <InputAdornment position="end">
                        <Typography variant="caption" > 
                        {endorement}
                        </Typography>
                    </InputAdornment > : null
                }}
            />
            <IconButton aria-label="view" color= "primary" size="small" fontSize="12px" onClick={incriment}> <AddIcon fontSize='inherit' /></IconButton >
        </Box >
    )
}
