import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player"
import * as heading from "./Typography/Exports"

export default function Video() {
    const dataVal = useSelector((state) => state.dataValue.components.video)
    console.log("vide dataVal: ", dataVal)
    let height = ""
    if(dataVal && dataVal.video_height === "small"){
        height = "400px"
    } else if(dataVal.video_height === "medium"){
        height = "500px"
    } else{
        height = "600px"
    }
    return (
        <Box id="video" sx={{ position: 'relative' }}>
            {dataVal && dataVal.show_overlay &&
                <heading.Overlay />
            }
            {dataVal && dataVal.style === "background_video" ?
                <ReactPlayer
                    url={dataVal && dataVal.link}
                    controls={false}
                    muted={true}
                    loop={true}
                    volume={false}
                    width={"100%"}
                    height={height}
                    playing={true}
                    playsinline={true}
                    playbackRate={0}
                    light={false}
                /> :
                <ReactPlayer
                    url={dataVal && dataVal.link}
                    controls={true}
                    muted={false}
                    loop={false}
                    volume={false}
                    width={"100%"}
                    height={height}
                    playing={false}
                    playsinline={true}
                    playbackRate={0}
                    light={true}
                />
            }
        </Box>
    )
}