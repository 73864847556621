import React from "react";
import BrandList from "./Components/BrandList";
import ContentBlock from "./Components/ContentBlock";
import Gallery from "./Components/Gallery";
import Header from "./Components/Header/Header";
import HeadingText from "./Components/HeadingText";
import Heroslider from "./Components/Heroslider";
import ProductList from "./Components/ProductList";
import TextColumnWithImage from "./Components/TextColumnWithImage";
import TextOverImage from "./Components/TextOverImage";
import MapBlock from "./Components/MapBlock";
import HeadingBlock from "./Components/HeadingBlock";
import Testimonials from "./Components/Testimonials";
import BlogPost from "./Components/BlogPost";
import Newsletter from "./Components/Newsletter";
import Video from "./Components/Video";

// export const blokImport = (arr) => {
//     return (
//         <React.Fragment>
//             {arr.map((opt, index) => {
//                 return (
//                     <Box key={index.toString()}>
//                         <BlockRenderFn data={opt} />
//                     </Box>
//                 )
//             })}
//         </React.Fragment>
//     )
// }

export default function BlockRenderFn({ type, data }) {
    return (
        <>
            {type === "header" &&
                <Header />
            }
            {type === "hero_slider" &&
                <Heroslider
                    type="hero"
                />
            }
            {type === "content_block" &&
                <ContentBlock />
            }
            {type === "heading_text" &&
                <HeadingText
                    data={data}
                />
            }
            {type === "text_over_image" &&
                <TextOverImage />
            }
            {type === "product_list" &&
                <ProductList />
            }
            {type === "text_column_with_image" &&
                <TextColumnWithImage />
            }
            {type === "gallery" &&
                <Gallery />
            }
            {type === "brands_list" &&
                <BrandList />
            }
            {type === "map" &&
                <MapBlock />
            }
            {type === "heading_block" &&
                <HeadingBlock />
            }
            {type === "testimonials" &&
                <Testimonials />
            }
            {type === "blog_post" &&
                <BlogPost />
            }
            {type === "newsletter" &&
                <Newsletter />
            }
            {type === "video" &&
                <Video />
            }
        </>
    )
}