import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, IconButton, Container, FormControl, Select, ListItemIcon, Pagination, LinearProgress, Skeleton
} from '@mui/material'
import { styled } from '@mui/material/styles';
import ReactPlayer from "react-player"

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import noImage from '../../../../Assets/images/no_image.jpg';
import ClearIcon from '@mui/icons-material/Clear';
import Check from '@mui/icons-material/Check';

import { DataGrid } from '@mui/x-data-grid';

import Quantity from '../../../common/Quantity/Quantity';
import _ from "underscore"
import * as Api from "../../../../Container/config/Apis"
import config from '../../../../Container/config';
import { useSnackbar } from 'notistack';

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: "0 !important",
    // width: '200px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <Box sx={{ mt: 15 }}>
                <Typography variant="h5" component="div">No result found!</Typography>
            </Box>
        </StyledGridOverlay>
    );
}

export default function Inventory() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [showDialog, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingData, setloadingData] = React.useState(true);
    const [loadingBar, setloadingBar] = React.useState(true);
    const [count, setCount] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [saveBtn, setSaveBtn] = useState(false)
    const [limit, setLimit] = useState(50)
    const [params, setParams] = useState({
        search: "",
        sort_key: "",
        sort_value: "desc",
        page: 1
    })
    const [options, setOptions] = useState([
        { name: "Product title A-Z", key: "title", value: "asc", active: false },
        { name: "Product title Z-A", key: "title", value: "desc", active: false },
        { name: "Created (oldest first)", key: "sku", value: "asc", active: false },
        { name: "Created (newest first)", key: "sku", value: "desc", active: false },
    ])

    const [rows, setrows] = useState([
        { id: 1, image: noImage, product: "Product name", sku: "No SKU", out_of_stock: true, inventory_quantity: 2, action: false },
    ])
    const columns = [
        {
            field: 'image_src', headerName: 'Image', width: "80",
            renderCell: (params) => loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                Api.getId(params.value) === null || Api.getId(params.value) === undefined ?
                <img src={params.value} width="50" style={{ border: "1px solid #eee", borderRadius: "3px", maxHeight: "50px", cursor: "pointer" }} />
                    :
                    <ReactPlayer
                        url={params.value}
                        controls={false}
                        config={{
                            youtube: {
                                playerVars: { showinfo: 0 }
                            }
                        }}
                        onClick={() => navigate(`/products/${params.row.id}`)}
                        className="video_preview_product"
                        width={"100%"}
                        height={"100%"}
                        playsinline={true}
                        playbackRate={0}
                        light={true}
                    />, sortable: false,
        },
        {
            field: 'product_name', headerName: 'Product', renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <Box>
                        <Button component={Link} to={`/products/${params.row.product_id}`} color="primary" variant="text" className='noHover' sx={{ justifyContent: 'flex-start', display: 'block' }}>
                            <Typography variant="p" component="strong">{params.value || "-"}</Typography>
                            <Typography color="#999" variant="p" component="div">{params.row.title}</Typography>
                        </Button>
                    </Box>,
            flex: 1, sortable: false,
        },

        {
            field: 'sku', headerName: 'SKU', sortable: false, width: "250", renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> : null
        },

        {
            field: 'out_of_stock', headerName: 'When sold out', sortable: false, width: "200",
            renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <Select
                        id="whenSoldSelect"
                        value={params.value || false}
                        name="out_of_stock"
                        onChange={(e) => handleOutStockChange(e, params)}
                        size="small">
                        <MenuItem value={false}>Stop selling</MenuItem>
                        <MenuItem value={true}>Continue selling</MenuItem>
                    </Select>
        },
        {
            field: 'inventory_quantity', headerName: 'Available quantity', sortable: false, flex: 1,
            renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <>
                        <Quantity
                            quantity={params.value || 0}
                            handleQtyChange={(e) => handleQtyChange(e, params)}
                            incriment={() => updateQty(params, params.value + 1)}
                            decrement={() => updateQty(params, params.value - 1)}
                        />
                    </>
        },
        {
            field: 'action', headerName: '', sortable: false,
            renderCell: (params) =>
                <>
                    {params.value ?
                        <Button variant="contained" onClick={() => saveQty(params)}>Save</Button> : null
                    }
                </>
        },
    ]
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const closeDialog = () => {
        setOpen(false);
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const editProduct = () => {
        navigate("/products/edit-product")
    };
    // dropdown btn

    const handleOutStockChange = (e, data) => {
        let ind = _.findLastIndex(rows, { 'id': data.id })
        rows[ind][e.target.name] = e.target.value
        setrows([...rows])
        let body = {
            id: data.row.id,
            inventory_item_id: data.row.inventory_item_id,
            product_id: data.row.product_id,
            [e.target.name]: data.row[e.target.name],
        }
        updateVariantFn(Api.PutApi, `${config.PRODUCT_VARIANTS}/${data.row.id}`, body)
    };
    const updateQty = (e, val) => {
        let ind = _.findLastIndex(rows, { 'id': e.id })
        if (rows[ind].inventory_quantity != val) {
            rows[ind].action = true
        } else {
            rows[ind].action = false
        }
        rows[ind].inventory_quantity = val
        setrows([...rows])
    }

    const handleQtyChange = (e, data) => {
        let ind = _.findLastIndex(rows, { 'id': data.id })
        if (rows[ind].inventory_quantity != e.target.value) {
            rows[ind].action = true
        } else {
            rows[ind].action = false
        }
        rows[ind].inventory_quantity = e.target.value
        setrows([...rows])
    }
    function hideSaveBtn(id) {
        let ind = _.findLastIndex(rows, { 'id': id })
        rows[ind].action = false
        setrows([...rows])
    }

    const saveQty = (data) => {
        console.log("data: ", data)
        let body = {
            id: data.row.id,
            inventory_item_id: data.row.inventory_item_id,
            product_id: data.row.product_id,
            inventory_quantity: data.row.inventory_quantity,
        }
        updateVariantFn(Api.PutApi, `${config.PRODUCT_VARIANTS}/${data.row.id}`, body)
        hideSaveBtn(data.row.id)
    }

    //Update variant image
    const updateVariantFn = async (api, url, data) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                // getVariantList(location.search)
            }
        })
    }

    //Get variant count
    const getVariantCount = () => {
        Api.GetApi(config.PRODUCT_VARIANTS_COUNT).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let newCount = Math.ceil(parseInt(res.data.data) / limit)
                if (newCount !== 0) {
                    setCount(newCount)
                } else {
                    setCount(1)
                }
            }
        })
    }
    //Get variant list
    const getVariantList = (param) => {
        Api.GetApi(`${config.PRODUCT_VARIANTS_LIST}${param}`).then(res => {
            setTimeout(() => {
                setloadingData(false)
                setloadingBar(false)
            }, 500);
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let arr = []
                    res.data.data.map((opt) => {
                        let option = ""
                        if (opt.option1 !== null && opt.option2 !== null && opt.option3 !== null) {
                            option = `${opt.option1}/${opt.option2}/${opt.option3}`
                        }
                        arr.push({
                            ...opt,
                            'product_name': !_.isNull(opt.Product) ? opt.Product.title : "",
                            'image_src': !_.isNull(opt.Image) ? opt.Image.src : noImage,
                            'option': option
                        })
                    })
                    setrows(arr)
                }

            }
        })
    }


    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    //Search change
    const [search, setSearch] = useState('')
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }
    const handleSearchBlur = (e) => {
        setloadingData(true)
        if (search !== "") {
            setParams({ ...params, 'search': search })
            setparam("search", search)
        } else {
            setParams({ ...params, 'search': "" })
            deleteparam("search")
        }
    }

    //Sort by on change
    const handleSortChange = (val) => {
        setloadingData(true)
        let newArr = []
        options.map((opt) => {
            if (opt.name === val.name) {
                newArr.push({ ...opt, 'active': true })
                setParams({ ...params, "sort_key": val.key, "sort_value": val.value })
                setparam("sort_key", val.key)
                setparam("sort_value", val.value)
            } else {
                newArr.push({ ...opt, 'active': false })
            }
        })
        setOptions(newArr)
        handleClose()
    }

    //Clear sort by filter
    const clearSortBy = () => {
        setloadingData(true)
        let newArr = []
        setParams({ ...params, "sort_key": "", "sort_value": "" })
        deleteparam("sort_key")
        deleteparam("sort_value")
        options.map((opt) => {
            newArr.push({ ...opt, 'active': false })
        })
        setOptions(newArr)
    }
    const handleChangePage = (_event, newPage) => {
        setloadingData(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };

    const onPageSizeChange = (e) => {
        setLimit(e)
    }

    useEffect(() => {
        let newArr = []

        searchParams.set("page", params.page)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getVariantList(location.search)
        //Set SortBy active value
        if (searchParams.get("sort_key") || searchParams.get("sort_value")) {
            options.map((obj) => {
                if (obj.key === searchParams.get("sort_key") && obj.value === searchParams.get("sort_value")) {
                    newArr.push({ ...obj, 'active': true })
                } else {
                    newArr.push({ ...obj, 'active': false })
                }
            })
            setOptions(newArr)
        }
    }, [limit, params])

    useEffect(() => {
        getVariantCount()
        if (searchParams.get("search")) {
            setSearch(searchParams.get("search"))
        }
    }, [])

    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Inventory</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Button component={Link} to="/products/list" variant="contained">View products</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: 2, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={6}>
                                <Box sx={{ display: "flex" }}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon color="grey" />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search', value: search }}
                                            onBlur={handleSearchBlur}
                                            onChange={handleSearchChange}
                                        />
                                    </Search>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: "Right" }}>
                                    {searchParams.get("sort_key") || searchParams.get("sort_value") ?
                                        <Button variant='text' color="error" size="small" sx={{ mr: 2 }} endIcon={<ClearIcon />} onClick={clearSortBy}>Clear</Button>
                                        : null
                                    }
                                    <Button
                                        id="sortButton"
                                        aria-controls="sortButtonArea"
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="text"
                                        disableElevation
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDownIcon />}>
                                        Sort by
                                    </Button>
                                    <StyledMenu
                                        id="sortButtonArea"
                                        MenuListProps={{ 'aria-labelledby': 'sortButton', }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}>
                                        {options.map((opt) => {
                                            return (
                                                <MenuItem key={opt.name} onClick={() => handleSortChange(opt)} disableRipple className={`sort_list ${opt.active ? "active" : ""}`} sx={{ fontSize: "12px" }}>
                                                    {opt.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </StyledMenu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            sx={{ border: 0 }}
                            rows={rows}
                            columns={columns}
                            pageSize={limit}
                            onPageSizeChange={(e) => onPageSizeChange(e)}
                            rowsPerPageOptions={[]}
                            checkboxSelection
                            disableColumnMenu
                            disableSelectionOnClick={true}
                            autoHeight={true}
                            density={"standard"}
                            rowHeight={60}
                            hideFooterPagination={true}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loadingData}
                        />
                        <Pagination count={count} showFirstButton showLastButton sx={{ p: 2, float: "right" }} onChange={handleChangePage} />
                    </Box>
                </Card>
            </Container>
        </React.Fragment>
    )
}

