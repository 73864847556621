import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent, CardActionArea, CardMedia,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, Radio, FormLabel, RadioGroup, FormHelperText, Typography,
    FormGroup, Select, TextField, InputBase, Container, InputLabel,
    Avatar, ListItemAvatar, ListItemText, ListItemButton, ListItem, List, IconButton, OutlinedInput, Chip,
} from '@mui/material';

import CurrencyFormat from 'react-currency-format';

import { alpha } from '@mui/material/styles';
import { Link, useNavigate, useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import noImage from '../../../../Assets/images/no_image.jpg';
// icons 
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

//  Date time
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

// common components
import PopupModal from '../../../common/PopupModal/PopupModal'
import CheckBoxList from '../../../common/CheckBoxList/CheckBoxList'

// image
import bundleprod from '../../../../Assets/images/bundleprod.jpg'
import world from '../../../../Assets/images/world.svg'
import India from '../../../../Assets/images/India.svg'

import config from "../../../../Container/config";
import * as Api from "../../../../Container/config/Apis"
import _ from "underscore";
import moment from "moment";
import { LoadingButton } from '@mui/lab';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        // borderRadius:"10px",
        // background: "#f7f8fa",
        margin: 0,
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    '&.Mui-error': {
        border: "1px solid #d32f2f",
    },
}));

// file drag drop  

const AddDiscount = () => { 
    const param = useParams();
    const navigate = useNavigate()
    let initError = {
        title: "",
        product: "",
        category: "",
        requirement: "",
        customers: "",
        usage_limit: "",
        ends_at: "",
    }
    const [errors, setError] = useState(initError);
    const [discountCode, setDiscountCode] = useState("");
    const [body, setBody] = useState({
        title: "",
        value_type: "percentage",
        value: "",
        customer_selection: "all",
        target_selection: "all",
        starts_at: moment(new Date()),
        ends_at: moment(new Date()).add(24, 'hours'),
        once_per_customer: false,
        prerequisite_quantity_range: null,
        prerequisite_subtotal_range: null,
        usage_limit: null,
        entitled_collection_ids: [],
        entitled_variant_ids: [],
        entitled_product_ids: [],
        prerequisite_customer_ids: []
    });

    const [hideShow, setHideShow] = useState({
        activeType: "percentage",
        activeApplies: "all",
        countriesApplies: "all",
        shippingRates: false,
        minimRequirements: "none",
        customerEligibility: "all",
        customerSpendsbuy: "quantity",
        customerSpendsbuySelect: "spcProduct",
        customerGetsSelect: "spcProduct",
        customerGetsDiscount: "percentage",
    })

    const [checkBoxes, setCheckBoxes] = useState({
        shippingRates: false,
        setmaximumuser: false,
        usageLimitTimes: false,
        usageLimitUse: false,
        setEndDate: false,
    })

    const [modalData, setmodalData] = useState({
        searchProduct: [],
        searchCategory: [],
        // spcificCustSeg: [],
        customers: [],
        countryselect: [],
    })
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const [selectcountries, setSelectCountries] = useState([
        { label: "India", value: "all", img: India, },
        { label: "Rest of world", value: "selected", img: world, },
    ])


    const searchInput = useRef()
    const [editDiscount, seteditDiscount] = useState(false);
    const [loading, setloading] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })
    const [params, setParams] = useState({
        search: "",
        page: 1
    })
    const [categoryList, setCategoryList] = useState([])
    const [productList, setproductList] = useState([])
    const [userList, setuserList] = useState([]);
    const [discountData, setdiscountData] = useState({});
    const [greater_than_or_equal_to, setRequirementValue] = useState("")

    const ModalOpen = () => setOpenModal(true);
    const [search, setSearch] = useState('')
    const ModalClose = () => {
        setOpenModal(false)
        setParams({
            search: "",
            page: 1
        })
    };

    const discountTypes = [
        { label: "Percentage", value: "percentage" },
        { label: "Fixed amount", value: "fixed_amount" },
        // { label: "Free shipping", value: "free" },
        // { label: "Buy X get Y", value: "buyxy" },
    ]
    const discountApplies = [
        { label: "All products", value: "all" },
        { label: "Specific category", value: "category" },
        { label: "Specific products", value: "product" },
    ]
    const countries = [
        { label: "All countries", value: "all", },
        { label: "Selected countries", value: "selected", },
    ]
    const minimRequirements = [
        { label: "None", value: "none" },
        { label: `Minimum purchase amount (${config.CURRENCY})`, value: "minimumPurchase" },
        { label: "Minimum quantity of items", value: "minimumQuantity" },
    ]
    const customerEligibility = [
        { label: "All", value: "all" },
        // { label: "Specific customer segments", value: "segments" },
        { label: "Specific customers", value: "customers" },
    ]
    const customerSpendsbuy = [
        { label: "Minimum quantity of items", value: "quantity" },
        { label: "Minimum purchase amount", value: "amount" },
    ]
    const customerSpendsbuySelect = [
        { label: "Specific products", value: "spcProduct" },
        { label: "Specific category", value: "spcCollection" },
    ]
    const customerGetsSelect = [
        { label: "Specific products", value: "spcProduct" },
        { label: "Specific category", value: "spcCollection" },
    ]
    const customerGetsDiscount = [
        { label: "Percentage", value: "percentage" },
        { label: "Free", value: "free" },
    ]

    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
        setError({ ...errors, [e.target.name]: "" })
    }
    const handleRadioChange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const togglehideShow = (e) => {
        setHideShow({ ...hideShow, [e.target.name]: e.target.value })
    }
    const toggleCheckBoxes = (e) => {
        setCheckBoxes({ ...checkBoxes, [e.target.name]: e.target.checked })
    }
    const checkboxChange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.checked })
    }
    const handleRequirementchange = (e) => {
        setRequirementValue(e.target.value)
        setError({ ...errors, 'requirement': "" })
    }
    const handleDateChange = (newValue, type) => {
        if (type === "start") {
            setBody({ ...body, 'starts_at': newValue });
        } else {
            setBody({ ...body, 'ends_at': newValue });
        }
    };
    const generateDiscountCode = () => {
        var randomstring = Math.random().toString(36).slice(-10).toUpperCase();
        setDiscountCode(randomstring)
        setBody({ ...body, 'title': randomstring })
        setError({ ...errors, 'title': "" })
    }
    const updateDiscountCode = (e) => {
        if (e.target.value.length <= 20) {
            setDiscountCode(e.target.value)
            setBody({ ...body, 'title': e.target.value })
            setError({ ...errors, 'title': "" })
        }
    }

    const triggerModal = useCallback((e, title, search) => {
        console.log("e-------", e.target.name)
        setOpenModal(true);
        let title_html = ""
        if (search === true) {
            title_html = <Box>
                <Typography variant="h6" component="div" gutterBottom>{title}</Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon color="grey" />
                    </SearchIconWrapper>
                    <StyledInputBase
                        sx={{ width: '100%' }}
                        placeholder="Search…"
                        name={e.target.name}
                        inputProps={{ 'aria-label': 'search' }}
                        inputRef={searchInput}
                        onChange={(e) => handleSearchChange(e, "input")}
                    />
                </Search>
            </Box>
        } else {
            title_html = title
        }
        setModalContent({ "modalShowing": e.target.name, "modalTitle": title_html })
    }, [searchInput])

    const handleSearchChange = useCallback((e, type) => {
        if (type === "browse") {
            triggerModal(e, e.target.name === "searchCategory" ? "Search category" : "Search products", true)
            setTimeout(() => {
                searchInput.current.focus()
            }, 1);
        }
        setSearch(e.target.value)
        setParams({ ...params, 'search': e.target.value })
        if (e.target.name === "searchCategory") {
            if (e.target.value !== "") {
                getCategoryList(`?page=${params.page}&search=${e.target.value}`)
            } else {
                getCategoryList(`?page=${params.page}`)
            }
        } else if (e.target.name === "searchProduct") {
            if (e.target.value !== "") {
                getproductList(`?page=${params.page}&search=${e.target.value}`)
            } else {
                getproductList(`?page=${params.page}`)
            }
        } else if (e.target.name === "customers") {
            if (e.target.value !== "") {
                getUserList(`?page=${params.page}&search=${e.target.value}`)
            } else {
                getUserList(`?page=${params.page}`)
            }
        }
    }, [search, params])

    const savebtnFunct = () => {
        let updateDate = []
        if (modalContent.modalShowing === "searchCategory") {
            let arr = []
            categoryList.filter((data) => data.active).map((data, indx) => {
                arr.push(data.id)
                updateDate.push({ ...data });
            })
            setBody({ ...body, entitled_collection_ids: arr })
            setmodalData({ ...modalData, 'searchCategory': updateDate })
        } else if (modalContent.modalShowing === "searchProduct") {
            let arr = []
            let variantArray = []
            productList.filter((data) => data.active).map((data, indx) => {
                arr.push(data.id)
                data.variants.filter((opt) => opt.active).map((opt, indx) => {
                    variantArray.push(opt.id)
                })
                updateDate.push({ ...data });
            })

            setBody({ ...body, entitled_product_ids: arr, entitled_variant_ids: variantArray.length > 0 ? variantArray : [] })
            setmodalData({ ...modalData, 'searchProduct': updateDate })
        } else if (modalContent.modalShowing === "customers") {
            let arr = []
            userList.filter((data) => data.active).map((data, indx) => {
                arr.push(data.id)
                updateDate.push({ ...data });
            })
            setBody({ ...body, prerequisite_customer_ids: arr })
            setmodalData({ ...modalData, 'customers': updateDate })
        }

        ModalClose()
    }

    const deletSelectedlist = (data, type) => {
        let currentIndex = _.findLastIndex(modalData[type], { 'id': data.id })
        let array = []
        let arraySet = ""
        if (type === "searchCategory") {
            array = categoryList
            arraySet = setCategoryList
        }
        if (type === "searchProduct") {
            array = productList
            arraySet = setproductList
        }
        if (type === "customers") {
            array = userList
            arraySet = setuserList
        }
        let i = _.findLastIndex(array, { 'id': data.id })
        array[i].active = false
        if (type === "searchProduct") {
            array[i].variants.map((y) => {
                return y.active = false
            })
        }
        arraySet(array)
        if (currentIndex !== -1) {
            modalData[type].splice(currentIndex, 1)
            setmodalData({ ...modalData })
        }
    }

    //Get category list
    const getCategoryList = (param) => {
        Api.GetApi(`${config.CATEGORY_LIST}${param}`).then(res => {
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                if (res.data.data) {
                    res.data.data.map((opt) => {
                        arr.push({ ...opt, active: false })
                    })

                    if (modalData.searchCategory.length > 0) {
                        arr.map((data) => {
                            modalData.searchCategory.map((opt) => {
                                if (data.id === opt.id) {
                                    return data.active = true
                                } else {
                                    return data.active = false
                                }
                            })
                        })
                    }
                    setCategoryList(arr)
                }
            }
        })
    }
    //Get product list
    const getproductList = (param) => {
        Api.GetApi(`${config.PRODUCTS_LIST}${param}`).then(res => {
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                let variantArr = []
                if (res.data.data) {
                    res.data.data.map((opt) => {
                        opt.variants.map((x) => {
                            return x.active = false
                        })
                        arr.push({ ...opt, active: false })
                    })
                    if (modalData.searchProduct.length > 0) {
                        arr.map((data) => {
                            modalData.searchProduct.map((opt) => {
                                if (data.id === opt.id) {
                                    data.active = true
                                    data.variants = opt.variants
                                } else {
                                    return data.active = false
                                }
                            })
                        })
                    }
                    setproductList(arr)
                }
            }
        })
    }
    //Get category list
    const getUserList = (param) => {
        Api.GetApi(`${config.USER_LIST}${param}`).then(res => {
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                if (res.data.data) {
                    res.data.data.map((opt) => {
                        arr.push({ ...opt, active: false })
                    })
                    if (modalData.customers.length > 0) {
                        arr.map((data) => {
                            modalData.customers.map((opt) => {
                                if (data.id === opt.id) {
                                    data.active = true
                                } else {
                                    return data.active = false
                                }
                            })
                        })
                    }
                    setuserList(arr)
                }
            }
        })
    }
    console.log("productList: ", productList)
    console.log("categoryList: ", categoryList)
    console.log("userList: ", userList)
    console.log("modalData: ", modalData)

    const handleChange = useCallback((e, data, type) => {

        let updateCheck = [];
        console.log("productList: ", productList)
        if (type === "searchProduct") {
            productList.map((dta) => {
                updateCheck.push({ ...dta });
            });
        } else if (type === "searchCategory") {
            categoryList.map((dta) => {
                updateCheck.push({ ...dta });
            });
        }
        // else if (type === "spcificCustSeg") {
        //     spcfcCustSeg.map((dta) => {
        //         return updateCheck.push({ ...dta });
        //     });
        // } 
        else if (type === "customers") {
            userList.map((dta) => {
                updateCheck.push({ ...dta });
            });
        } else if (type === "countryselect") {
            selectcountries.map((dta) => {
                updateCheck.push({ ...dta });
            });
        }

        console.log("type", type)
        console.log("updateCheck", updateCheck)
        let index = _.findLastIndex(updateCheck, { 'id': data.id })
        console.log("index", index)

        if (updateCheck.length > 0 && index !== -1) {
            updateCheck[index].active = e.target.checked;
            // updateCheck[index].variants && updateCheck[index].variants.length > 0 && updateCheck[index].variants.map((upd) => {
            //     updateCheck[index].variants.push({...upd, 'active': e.target.checked})
            // });
            updateCheck[index].variants && updateCheck[index].variants.length > 0 && updateCheck[index].variants.map((upd) => {
                (upd.active = e.target.checked);
            });

            if (type === "searchProduct") {
                setproductList(updateCheck);
            } else if (type === "searchCategory") {
                setCategoryList(updateCheck);
            }
            // else if (type === "spcificCustSeg") {
            //     setSpcfcCustSeg(updateCheck);
            // } 
            else if (type === "customers") {
                setuserList(updateCheck);
            } else if (type === "countryselect") {
                setSelectCountries(updateCheck);
            }
        }
    }, [productList, categoryList, userList])

    const handleinnerChange = useCallback((e, data, indx) => {
        let updateInnerCheck = [];

        productList.map((dta) => {
            return updateInnerCheck.push({ ...dta });
        });
        let index = _.findLastIndex(updateInnerCheck, { 'id': data.id })

        console.log("e.target.checked ", e.target.checked);
        updateInnerCheck[index].active = true;
        updateInnerCheck[index].variants[indx].active = e.target.checked;
        let length = updateInnerCheck[index].variants.filter((opt) => opt.active).length;

        if (length < 1) {
            updateInnerCheck[index].active = false;
        }
        setproductList(updateInnerCheck);
    }, [productList])

    const saveDiscount = () => {
        let bodyData = {}
        if (discountCode === "") {
            setError({ ...errors, 'title': "Discount code can't be blank" })
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else if (body.value === "") {
            setError({ ...errors, 'value': "Value can't be blank" })
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else if (
            (body.target_selection === "product" && body.entitled_product_ids.length === 0) ||
            (body.target_selection === "category" && body.entitled_collection_ids.length === 0)) {
            setError({ ...errors, [body.target_selection]: `Atleast 1 ${body.target_selection} must be added` })
            window.scrollTo({
                top: 200,
                behavior: 'smooth',
            });
        } else if (hideShow.minimRequirements !== "none" && greater_than_or_equal_to === "") {
            setError({ ...errors, 'requirement': "Value can't be blank" })
        } else if (hideShow.customerEligibility !== "all" && body.prerequisite_customer_ids.length === 0) {
            setError({ ...errors, 'customers': `Atleast 1 user must be added` })
        } else if (checkBoxes.usageLimitTimes && body.usage_limit === "") {
            setError({ ...errors, 'usage_limit': "Value can't be blank" })
        } else if (moment(body.starts_at).diff(body.ends_at) >= 0) {
            setError({ ...errors, 'ends_at': "End time must be greater than start time" })
        } else {
            setError(initError)
            setloading(true)
            if (body.target_selection === "product" && body.entitled_collection_ids.length > 0) {
                bodyData['entitled_collection_ids'] = []
                bodyData['entitled_product_ids'] = body.entitled_product_ids
                bodyData['entitled_variant_ids'] = body.entitled_variant_ids
            } else if (body.target_selection === "category" && body.entitled_product_ids.length > 0) {
                bodyData['entitled_collection_ids'] = body.entitled_collection_ids
                bodyData['entitled_product_ids'] = []
                bodyData['entitled_variant_ids'] = []
            } else if (body.target_selection === "all") {
                bodyData['entitled_collection_ids'] = []
                bodyData['entitled_product_ids'] = []
                bodyData['entitled_variant_ids'] = []
            }
            let value = `-${parseFloat(body.value).toFixed(2)}`
            bodyData['entitled_collection_ids'] = body.entitled_collection_ids
            bodyData['entitled_product_ids'] = body.entitled_product_ids
            bodyData['entitled_variant_ids'] = body.entitled_variant_ids
            bodyData['prerequisite_customer_ids'] = body.prerequisite_customer_ids

            bodyData['title'] = discountCode
            bodyData['value'] = value.toString()
            bodyData['value_type'] = body.value_type
            bodyData['once_per_customer'] = body.once_per_customer
            bodyData['usage_limit'] = checkBoxes.usageLimitTimes ? body.usage_limit : null
            bodyData['target_selection'] = body.target_selection !== 'all' ? 'entitled' : body.target_selection
            bodyData['customer_selection'] = hideShow.customerEligibility !== 'all' ? 'prerequisite' : 'all'
            bodyData['prerequisite_subtotal_range'] = hideShow.minimRequirements === 'minimumPurchase' ? { less_than_or_equal_to: greater_than_or_equal_to } : null
            bodyData['prerequisite_quantity_range'] = hideShow.minimRequirements === 'minimumQuantity' ? { greater_than_or_equal_to } : null
            bodyData['starts_at'] = moment(body.starts_at).format()
            bodyData['ends_at'] = checkBoxes.setEndDate ? moment(body.ends_at).format() : null

            console.log("bodyData: ", bodyData)

            if (editDiscount) {
                discountFn(Api.PutApi, `${config.DISCOUNT_RULE}/${body.id}`, bodyData, "edit")
            } else {
                discountFn(Api.PostApi, config.DISCOUNT_RULE, bodyData, "add")
            }
        }
    }


    const discountFn = async (api, url, data, type) => {
        await api(url, data).then(res => {
            if (res.error === true) {
                setloading(false)
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success')
                setTimeout(() => {
                    navigate('/discount')
                }, 1000);
            }
        })
    }


    console.log("checkBoxes-------------------", checkBoxes)
    console.log("errors-------------------", errors)

    //GET Discount BY ID
    const getDiscountByID = useCallback(async (id) => {
        Api.GetApi(`${config.DISCOUNT_RULE}/${id}`).then(async res => {
            if (res.error === true) {
                if (!_.isUndefined(res.response.data.error)) {
                    snack(res.response.data.error, 'error');
                } else {
                    snack(res.res.data.error, 'error');
                }
            } else {
                if (res.data.data) {
                    let resData = res.data.data
                    setdiscountData(res.data.data)
                    resData['value'] = (resData.value).toString().split("-")[1]
                    let updateHideShow = { ...hideShow }
                    if (resData.discount_rule_ent_collections.length > 0) {
                        let arr = []
                        resData['target_selection'] = "category"
                        resData.discount_rule_ent_collections.map((opt) => {
                            arr.push(opt.collection_id)
                        })
                        resData['entitled_collection_ids'] = arr
                        resData['entitled_product_ids'] = []
                        resData['entitled_variant_ids'] = []
                    } else if (resData.discount_rule_ent__variants.length > 0) {
                        let arr = []
                        let arr2 = []
                        resData.discount_rule_ent__variants.map((obj) => {
                            arr2.push(obj.product_variant.product_id)
                            arr.push(obj.variant_id)
                        })
                        resData['entitled_variant_ids'] = arr
                        resData['entitled_product_ids'] = _.union(arr2)
                        resData['target_selection'] = "product"
                        resData['entitled_collection_ids'] = []
                    } else {
                        resData['target_selection'] = "all"
                        resData['entitled_collection_ids'] = []
                        resData['entitled_product_ids'] = []
                        resData['entitled_variant_ids'] = []
                    }

                    let array = []
                    if (resData.discount_rule_prereq_customers.length > 0) {
                        updateHideShow.customerEligibility = "customers"
                        resData.discount_rule_prereq_customers.map((opt) => {
                            array.push(opt.customer_id)
                        })
                    }
                    resData['prerequisite_customer_ids'] = array

                    if (resData.prerequisite_subtotal_range !== null && resData.prerequisite_subtotal_range.less_than_or_equal_to) {
                        setRequirementValue(resData.prerequisite_subtotal_range.less_than_or_equal_to)
                        updateHideShow.minimRequirements = "minimumPurchase"
                    }
                    if (resData.prerequisite_quantity_range !== null && resData.prerequisite_quantity_range.greater_than_or_equal_to) {
                        setRequirementValue(resData.prerequisite_quantity_range.greater_than_or_equal_to)
                        updateHideShow.minimRequirements = "minimumQuantity"
                    }
                    let checkboxData = { ...checkBoxes }
                    if (resData.usage_limit !== null && resData.usage_limit !== "") {
                        checkboxData.usageLimitTimes = true
                    }
                    if (resData.ends_at !== null && resData.ends_at !== "") {
                        checkboxData.setEndDate = true
                    }
                    setCheckBoxes({ ...checkboxData })
                    setHideShow({ ...updateHideShow })

                    await setBody(resData)
                    await setDiscountCode(resData.title)
                } else {
                    snack("No record found. Please check the URL.", 'error');
                }
            }
        })
    }, [])
    console.log("discountData: ", discountData)


    useEffect(() => {
        if (discountData !== {}) {
            let modal_data = { ...modalData }
            if (discountData.discount_rule_ent_collections && discountData.discount_rule_ent_collections.length > 0 && categoryList.length > 0) {
                let arr = []
                categoryList.map((data) => {
                    discountData.discount_rule_ent_collections.map((opt) => {
                        if (opt.collection_id === data.id) {
                            data.active = true
                            arr.push(data)
                        }
                    })
                })
                modal_data.searchCategory = arr
            } else if (discountData.discount_rule_ent__variants && discountData.discount_rule_ent__variants.length > 0) {
                if (productList.length > 0) {
                    let arr = []
                    productList.map((data) => {
                        discountData.discount_rule_ent__variants.map((obj) => {
                            if (data.id === obj.product_variant.product_id) {
                                data.active = true
                                data.variants.map((x) => {
                                    if (x.id === obj.variant_id) {
                                        x.active = true
                                    }
                                })
                                arr.push(data)
                            }
                        })
                    })
                    modal_data.searchProduct = _.union(arr)
                }
            }

            if (discountData.discount_rule_prereq_customers && discountData.discount_rule_prereq_customers.length > 0 && userList.length > 0) {
                let arr = []
                userList.map((data) => {
                    discountData.discount_rule_prereq_customers.map((opt) => {
                        if (opt.customer_id === data.id) {
                            data.active = true
                            arr.push(data)
                        }
                    })
                })
                modal_data.customers = _.union(arr)
            }
            setmodalData({ ...modal_data })
        }
    }, [discountData, categoryList, productList]);

    useEffect(() => {
        getCategoryList(`?page=${params.page}`)
        getproductList(`?page=${params.page}`)
        getUserList(`?page=${params.page}`)
        if (param.id) {
            getDiscountByID(param.id)
            seteditDiscount(true)
        }
    }, []);

    console.log("hideShow", hideShow)
    console.log("body", body)

    return (
        <Container maxWidth="lg">
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={6}>
                        <Button component={Link} variant="text" to="/discount" startIcon={<ArrowBackIosIcon />}>
                            {param.id ? discountData && discountData.title : "Create discount code"}</Button>
                    </Grid>
                    <Grid item md={6} sx={{ textAlign: "Right" }}></Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={8}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Grid container spacing={2} columns={12}>
                                    <Grid item md={6}>
                                        <Typography variant="h6" component="div" gutterBottom>Discount code</Typography>
                                    </Grid>
                                    <Grid item md={6} sx={{ textAlign: "Right" }}>
                                        <Button variant="text" onClick={generateDiscountCode}> Generate code </Button>
                                    </Grid>
                                </Grid>
                                <FormControl fullWidth  >
                                    <TextField
                                        id="title"
                                        name="title"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        variant="outlined"
                                        value={discountCode}
                                        maxLength="20"
                                        onChange={updateDiscountCode}
                                        error={errors.title !== "" ? true : false}
                                        helperText={errors.title !== "" ? errors.title : ""}
                                    />
                                    {/* <FormHelperText>Customers will enter this discount code at checkout.</FormHelperText> */}
                                </FormControl>
                            </CardContent>
                        </Card>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>Types</Typography>
                                <FormGroup>
                                    <RadioGroup
                                        row
                                        aria-labelledby="discount Types selection"
                                        defaultValue="Percentage"
                                        name="value_type">
                                        {discountTypes.map((data, index) => {
                                            return (
                                                <FormControlLabel key={index} value={data.value}
                                                    control={
                                                        <Radio checked={data.value === body.value_type ? true : false} onChange={handleRadioChange} />
                                                    } label={data.label} />
                                            )
                                        })
                                        }
                                    </RadioGroup>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        {body.value_type === "percentage" || body.value_type === "fixed_amount" ?
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Discount value</Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <FormControl sx={{ m: 0 }} >
                                            <TextField
                                                label=""
                                                type="number"
                                                id="value"
                                                name="value"
                                                onChange={handleOnchange}
                                                value={body.value ? body.value : ""}
                                                size="small"
                                                sx={{ m: 0, width: '150px' }}
                                                error={errors.value !== "" ? true : false}
                                                helperText={errors.value !== "" ? errors.value : ""}
                                                InputProps={
                                                    {
                                                        endAdornment: body.value_type === "percentage" ? <InputAdornment position="end">%</InputAdornment> : null,
                                                        startAdornment: body.value_type === "fixed_amount" ? <InputAdornment position="start">{config.CURRENCY}</InputAdornment> : null
                                                    }
                                                }
                                                placeholder={body.value_type === "fixed_amount" ? "0.00" : ""}
                                            />
                                        </FormControl>
                                    </Box>


                                    <Divider sx={{ my: 2 }}></Divider>
                                    <FormGroup>
                                        <FormLabel id="target_selection">Applies to</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="target_selection"
                                            defaultValue="all"
                                            name="target_selection">
                                            {discountApplies.map((data, index) => {
                                                return <FormControlLabel key={index} value={data.value}
                                                    control={
                                                        <Radio checked={data.value === body.target_selection ? true : false} onChange={handleRadioChange} />
                                                    } label={data.label} />
                                            })}
                                        </RadioGroup>
                                    </FormGroup>
                                    {body.target_selection === "category" ?
                                        <>
                                            <Box sx={{ display: 'flex', mt: 2 }}>
                                                <Search>
                                                    <SearchIconWrapper>
                                                        <SearchIcon color="grey" />
                                                    </SearchIconWrapper>
                                                    <StyledInputBase
                                                        placeholder="Search category"
                                                        inputProps={{ 'aria-label': 'search', value: params.search }}
                                                        name="searchCategory"
                                                        onChange={(e) => handleSearchChange(e, "browse")}
                                                    />
                                                </Search>
                                                <Button variant="outlined" color="primary"
                                                    name="searchCategory" onClick={(e) => triggerModal(e, "Search category", true)} size="large" sx={{ ml: 1 }} >Browse</Button>
                                            </Box>
                                            {modalData.searchCategory.length > 0 ?
                                                modalData.searchCategory.map((data, indx) => {
                                                    return (
                                                        <Chip variant='outlined' label={data.title} key={indx} onDelete={() => deletSelectedlist(data, "searchCategory")} sx={{ mr: 1, mt: 2 }} />
                                                    )
                                                })
                                                :
                                                <FormHelperText error={errors.category !== "" ? true : false}>
                                                    {errors.category !== "" ? errors.category : ""}
                                                </FormHelperText>
                                            }
                                        </> : null
                                    }
                                    {body.target_selection === "product" ?
                                        <>
                                            <Box sx={{ display: 'flex', mt: 2 }}>
                                                <Search>
                                                    <SearchIconWrapper>
                                                        <SearchIcon color="grey" />
                                                    </SearchIconWrapper>
                                                    <StyledInputBase
                                                        placeholder="Search products"
                                                        inputProps={{ 'aria-label': 'search', value: params.search }}
                                                        name="searchProduct"
                                                        onChange={(e) => handleSearchChange(e, "browse")}
                                                    />
                                                </Search>
                                                <Button variant="outlined" color="primary" name="searchProduct" onClick={(e) => triggerModal(e, "Search products", true)} size="large" sx={{ ml: 1 }} >Browse</Button>
                                            </Box>
                                            {modalData.searchProduct.length > 0 ?
                                                <List sx={{ width: '100%' }} dense={true}>
                                                    {modalData.searchProduct.map((data, indx) => {
                                                        console.log("data: ", data)
                                                        return (
                                                            <ListItem
                                                                alignItems="flex-start"
                                                                key={indx}
                                                                secondaryAction={
                                                                    <IconButton edge="end" aria-label="delete" onClick={() => deletSelectedlist(data, "searchProduct")}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                }>
                                                                <ListItemAvatar>
                                                                    <Avatar variant='square' alt="Remy Sharp" src={data.images.length > 0 && data.images[0].src ? data.images[0].src : noImage || noImage} />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={data.title || "Untitled"}
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography
                                                                                sx={{ display: 'inline' }}
                                                                                component="span"
                                                                                variant="body2"
                                                                                color="#999"
                                                                            >
                                                                                ({data.variants.filter((x) => x.active).length} of {data.variants.length} variants selected)
                                                                            </Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        )
                                                    })}
                                                </List>
                                                :
                                                <FormHelperText error={errors.product !== "" ? true : false}>
                                                    {errors.product !== "" ? errors.product : ""}
                                                </FormHelperText>
                                            }
                                        </> : null
                                    }


                                </CardContent>
                            </Card> : null}

                        {hideShow.activeType === "percentage" || hideShow.activeType === "fixed_amount" || hideShow.activeType === "free" ?
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Minimum requirements</Typography>
                                    <FormGroup>
                                        <RadioGroup
                                            row
                                            aria-labelledby="Minimum requirements"
                                            defaultValue="none"
                                            name="minimRequirements">
                                            {minimRequirements.map((data, index) => {
                                                return <FormControlLabel key={index} value={data.value} control={
                                                    <Radio checked={data.value === hideShow.minimRequirements ? true : false} name="minimRequirements" onChange={togglehideShow} />
                                                } label={data.label} />

                                            })}
                                        </RadioGroup>
                                    </FormGroup>
                                    {hideShow.minimRequirements !== "none" &&
                                        <FormControl sx={{ m: 0, mt: 2 }}  >
                                            <TextField
                                                label=""
                                                type="number"
                                                id="greater_than_or_equal_to"
                                                name="greater_than_or_equal_to"
                                                onChange={handleRequirementchange}
                                                value={greater_than_or_equal_to}
                                                size="small"
                                                sx={{ m: 0, width: '150px' }}
                                                error={errors.requirement !== "" ? true : false}
                                                helperText={errors.requirement !== "" ? errors.requirement : ""}
                                                InputProps={
                                                    {
                                                        startAdornment: hideShow.minimRequirements === "minimumPurchase" ? <InputAdornment position="start">{config.CURRENCY}</InputAdornment> : null
                                                    }
                                                }
                                                placeholder={hideShow.minimRequirements === "minimumPurchase" ? "0.00" : ""}
                                            />
                                        </FormControl>
                                    }
                                </CardContent>
                            </Card> : null}

                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>Customer eligibility</Typography>
                                <FormGroup>
                                    <RadioGroup
                                        row
                                        aria-labelledby="Customer eligibility"
                                        defaultValue="everyone"
                                        name="CustomerEligibility">
                                        {customerEligibility.map((data, index) => {
                                            return <FormControlLabel key={index} value={data.value} control={
                                                <Radio checked={data.value === hideShow.customerEligibility ? true : false} name="customerEligibility" onChange={togglehideShow} />
                                            } label={data.label} />
                                        })}
                                    </RadioGroup>
                                </FormGroup>
                                {/* {hideShow.customerEligibility === "segments" ?
                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon color="grey" />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search customer segments"
                                                inputProps={{ 'aria-label': 'search' }}
                                                name="spcificCustSeg"
                                                onChange={(e) => triggerModal(e, "Add customer segments")}
                                            />
                                        </Search>
                                        <Button variant="outlined" color="primary" name="spcificCustSeg" onClick={(e) => triggerModal(e, "Add customer segments")} size="large" sx={{ ml: 1 }} >Browse</Button>
                                    </Box> : null} */}
                                {hideShow.customerEligibility === "customers" ?
                                    <>
                                        <Box sx={{ display: 'flex', mt: 2 }}>
                                            <Search>
                                                <SearchIconWrapper>
                                                    <SearchIcon color="grey" />
                                                </SearchIconWrapper>
                                                <StyledInputBase
                                                    placeholder="Search user"
                                                    inputProps={{ 'aria-label': 'search' }}
                                                    name="customers"
                                                    onChange={(e) => triggerModal(e, "Search users", true)}
                                                />
                                            </Search>
                                            <Button variant="outlined" color="primary" name="customers" onClick={(e) => triggerModal(e, "Add customers", true)} size="large" sx={{ ml: 1 }} >Browse</Button>
                                        </Box>

                                        {modalData.customers.length > 0 ?
                                            <List sx={{ width: '100%' }} dense={true}>
                                                {modalData.customers.map((data, indx) => {
                                                    console.log("data: ", data)
                                                    return (
                                                        <ListItem
                                                            alignItems="flex-start"
                                                            key={indx}
                                                            secondaryAction={
                                                                <IconButton edge="end" aria-label="delete" onClick={() => deletSelectedlist(data, "customers")}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }>
                                                            <ListItemText
                                                                primary={data.name || "Untitled"}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            sx={{ display: 'inline' }}
                                                                            component="span"
                                                                            variant="body2"
                                                                            color="#999"
                                                                        >{data.email && data.email}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                            :
                                            <FormHelperText error={errors.customers !== "" ? true : false}>
                                                {errors.customers !== "" ? errors.customers : ""}
                                            </FormHelperText>
                                        }
                                    </> : null}
                            </CardContent>
                        </Card>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>Usage limits</Typography>

                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={checkBoxes.usageLimitTimes} onChange={toggleCheckBoxes} name="usageLimitTimes" />} label="Limit number of times this discount can be used in total" />
                                    {checkBoxes.usageLimitTimes ?
                                        <Box sx={{ mb: 2 }}>
                                            <FormControl sx={{ m: 0 }} >
                                                <TextField
                                                    type="number"
                                                    id="usage_limit"
                                                    name="usage_limit"
                                                    value={body.usage_limit}
                                                    size="small"
                                                    sx={{ m: 0, }}
                                                    onChange={handleOnchange}
                                                    error={errors.usage_limit !== "" ? true : false}
                                                    helperText={errors.usage_limit !== "" ? errors.usage_limit : ""}
                                                />
                                            </FormControl>
                                        </Box> : null}
                                    <FormControlLabel control={<Checkbox onChange={checkboxChange} checked={body.once_per_customer} name="once_per_customer" />} label="Limit to one use per customer" />
                                </FormGroup>

                            </CardContent>
                        </Card>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>Active dates</Typography>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox onChange={toggleCheckBoxes} checked={checkBoxes.setEndDate} name="setEndDate" />} label="Set end date" />
                                </FormGroup>

                                <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
                                    <Grid item md={6}>
                                        <LocalizationProvider dateAdapter={DateAdapter} >
                                            <DateTimePicker
                                                label="Start date"
                                                value={body.starts_at}
                                                onChange={(e) => handleDateChange(e, "start")}
                                                minDateTime={moment(new Date(), "MM/DD/YYYY HH:mm A")}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                disablePast

                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item md={6}>
                                        {checkBoxes.setEndDate ?
                                            <LocalizationProvider dateAdapter={DateAdapter} >
                                                <DateTimePicker
                                                    label="End date"
                                                    value={body.ends_at}
                                                    onChange={(e) => handleDateChange(e, "end")}
                                                    minDateTime={moment(new Date(), "MM/DD/YYYY HH:mm A")}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                    disablePast
                                                />
                                            </LocalizationProvider> : null}
                                    </Grid>
                                </Grid>
                                <FormHelperText error={errors.ends_at !== "" ? true : false}>
                                    {errors.ends_at !== "" ? errors.ends_at : ""}
                                </FormHelperText>



                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="h6" gutterBottom >Summary</Typography>
                                <Typography variant="subtitle1" component="div" gutterBottom >{discountCode}</Typography>
                                <ul className="contenList" >
                                    {body.value !== "" && body.value != 0 ?
                                        <li>
                                            {body.value_type === "percentage" ?
                                                `${body.value}%` :
                                                <CurrencyFormat value={parseFloat(body.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                            }
                                            {" "}off on
                                            {body.target_selection === 'category' && modalData.searchCategory.length > 0 ?
                                                ` ${modalData.searchCategory.length} ${modalData.searchCategory.length > 1 ? 'categories' : 'category'}` :
                                                body.target_selection === 'product' && modalData.searchProduct.length > 0 ?
                                                    ` ${modalData.searchProduct.length} ${modalData.searchProduct.length > 1 ? 'products' : 'product'}` :
                                                    " all products"}
                                        </li> :
                                        <li>No information entered yet.</li>
                                    }
                                    {body.value !== "" && body.value != 0 && hideShow.minimRequirements !== "none" && greater_than_or_equal_to != 0 && greater_than_or_equal_to !== "" &&
                                        <li>
                                            Minimum purchase of {" "}
                                            {hideShow.minimRequirements === "minimumPurchase" && greater_than_or_equal_to !== "" ?
                                                <CurrencyFormat value={parseFloat(greater_than_or_equal_to).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} /> :
                                                `${greater_than_or_equal_to} items`
                                            }
                                        </li>
                                    }
                                    {body.value !== "" && body.value != 0 && hideShow.customerEligibility === "all" ?
                                        <li>For all users</li> :
                                        modalData.customers.length > 0 &&
                                        <li>For {modalData.customers.length} {modalData.customers.length > 1 ? "users" : "user"}</li>
                                    }
                                    {body.value !== "" && body.value != 0 && checkBoxes.usageLimitTimes && body.usage_limit !== "" && body.usage_limit != 0 &&
                                        <li>Limit of {body.usage_limit} uses{body.once_per_customer && `, one per user`}</li>
                                    }
                                    {body.value !== "" && body.value != 0 && body.starts_at &&
                                        <li>Available from {moment(body.starts_at).format("D MMM, YY")} {checkBoxes.setEndDate && `to ${moment(body.ends_at).format("D MMM")}`}</li>
                                    }
                                </ul>

                                {/* <Divider sx={{ my: 2 }} />
                                <Typography variant="h6" component="div" gutterBottom >Performance</Typography>
                                <Typography variant="subtitle2" component="div" gutterBottom >Discount is not active yet.</Typography> */}

                            </CardContent>
                        </Card>
                        {/* <Button variant="contained" color="primary" className='float_btn' size="large" sx={{ ml: 1 }} onClick={saveDiscount}>Save</Button> */}

                        <LoadingButton
                            sx={{ ml: 1, whiteSpace: 'nowrap' }}
                            color="primary"
                            className='float_btn'
                            size="large"
                            onClick={saveDiscount}
                            loading={loading}
                            loadingIndicator="Please wait..."
                            variant="contained"
                        >
                            {!editDiscount ? "Save" : "Update"}
                        </LoadingButton>
                    </Grid>
                    <Grid item md={12}>
                        <Divider sx={{ my: "15px" }}></Divider>
                        <Box sx={{ textAlign: "left" }}>
                            <Button variant="contained" color="error" size="large" onClick={() => navigate("/discount")}>Cancel</Button>
                            {/* <Button variant="contained" color="primary" onClick={saveDiscount} size="large" sx={{ ml: 1 }}>Save</Button> */}

                            <LoadingButton
                                sx={{ ml: 1, whiteSpace: 'nowrap' }}
                                color="primary"
                                size="large"
                                onClick={saveDiscount}
                                loading={loading}
                                loadingIndicator="Please wait..."
                                variant="contained"
                            >
                                {!editDiscount ? "Save" : "Update"}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt="Add"
                savebtnFunct={savebtnFunct} >
                {/* <Search>
                    <SearchIconWrapper>
                        <SearchIcon color="grey" />
                    </SearchIconWrapper>
                    <StyledInputBase
                        sx={{ width: '100%' }}
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}

                    />
                </Search> */}
                <Box className='search_modal_outer'>
                    {modalContent.modalShowing === "searchCategory" ?
                        <>
                            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                {categoryList.length > 0 ?
                                    <Box>
                                        {categoryList.map((value, index) => {
                                            const labelId = `category-${value.id}`;
                                            return (
                                                <Box key={index} >
                                                    <ListItem sx={{ flexDirection: "column", alignItems: "center", }} disablePadding>
                                                        <CheckBoxList
                                                            key={index}
                                                            value={value.id}
                                                            handleToggle={(e) => handleChange(e, value, "searchCategory")}
                                                            labelId={labelId}
                                                            checked={value.active}
                                                            name={value.title}
                                                            image={value.path || noImage}
                                                            avatar={true}
                                                            child={false}
                                                        />
                                                    </ListItem>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    :
                                    <h5>No result found</h5>
                                }
                            </List>
                        </> : null}

                    {modalContent.modalShowing === "searchProduct" ?
                        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                            {productList.length > 0 && productList.map((value, index) => {
                                const labelId = `Product-${value.id}`;

                                return (
                                    <Box key={index} >
                                        <ListItem sx={{ flexDirection: "column", alignItems: "center", }} disablePadding>
                                            <CheckBoxList
                                                key={index}
                                                value={value.id}
                                                handleToggle={(e) => handleChange(e, value, "searchProduct")}
                                                labelId={labelId}
                                                checked={value.active}
                                                name={value.title}
                                                image={value.images.length > 0 ? value.images[0].src : noImage || noImage}
                                                avatar={true}
                                                child={false}
                                            />
                                            <List sx={{ width: "100%" }} component="div" disablePadding>
                                                {value.variants && value.variants.length > 0 && value.variants.map((data, indx) => {
                                                    const labelIdInnr = `checkbox-list-label-${data.id}`;
                                                    return (
                                                        <CheckBoxList
                                                            key={indx}
                                                            value={data.id}
                                                            handleToggle={(e) => handleinnerChange(e, value, indx)}
                                                            labelId={labelIdInnr}
                                                            checked={data.active}
                                                            name={data.title || "Untitled"}
                                                            avatar={false}
                                                            child={true}
                                                        />
                                                    );
                                                })}
                                            </List>
                                        </ListItem>
                                    </Box>
                                );
                            })}
                        </List> : null}

                    {/* {modalContent.modalShowing === "spcificCustSeg" ?
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                        {spcfcCustSeg.map((value, index) => {
                            const labelId = `category-${value.id}`;
                            return (

                                <Box key={index} >
                                    <ListItem sx={{ flexDirection: "column", alignItems: "center", }} disablePadding>
                                        <CheckBoxList
                                            key={index}
                                            value={value.id}
                                            handleToggle={(e) => handleChange(e, index)}
                                            labelId={labelId}
                                            checked={value.active}
                                            name={value.name}
                                            avatar={false}
                                            child={false}
                                        />
                                    </ListItem>
                                </Box>
                            )
                        })}

                    </List> : null} */}

                    {modalContent.modalShowing === "customers" ?
                        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                            {userList.map((value, index) => {
                                const labelId = `category-${value.id}`;
                                return (

                                    <Box key={index} >
                                        <ListItem sx={{ flexDirection: "column", alignItems: "center", }} disablePadding>
                                            <CheckBoxList
                                                key={index}
                                                value={value.id}
                                                handleToggle={(e) => handleChange(e, value, "customers")}
                                                labelId={labelId}
                                                checked={value.active}
                                                name={`${value.email}  (${value.name})`}
                                                avatar={false}
                                                child={false}
                                            />
                                        </ListItem>
                                    </Box>
                                )
                            })}


                        </List>
                        : null}
                    {/* countries  setCountries */}
                    {modalContent.modalShowing === "countryselect" ?
                        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                            {selectcountries.map((value, index) => {
                                const labelId = `category-${value.id}`;
                                return (
                                    <Box key={index} >
                                        <ListItem sx={{ flexDirection: "column", alignItems: "center", }} disablePadding>
                                            <CheckBoxList
                                                key={index}
                                                value={value.id}
                                                // handleToggle={(e) => handleChange(e, index)}
                                                labelId={labelId}
                                                checked={value.active}
                                                name={value.label}
                                                avatar={true}
                                                image={value.img}
                                                child={false}
                                                icon={true}
                                            />
                                        </ListItem>
                                    </Box>
                                )

                            })}

                        </List>
                        : null}
                </Box>
            </PopupModal>
        </Container >
    );
}

export default AddDiscount