export default [
    {
        type: "text",
        name: "heading",
        id: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "editor",
        name: "subheading",
        id: "subheading",
        label: "Subheading",
        value: "<p></p>",
    },
    {
        type: "text",
        name: "button_label",
        label: "Button label",
        value: "",
        placeholder: ""
    },
]