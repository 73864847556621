import React, { useEffect, useState, useRef } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, Typography, Select, TextField, Container,
    Avatar, Badge, Alert, AlertTitle,
} from '@mui/material';
import { Link } from "react-router-dom";
// icons  
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
// common components
import bundleprod from '../../../../../Assets/images/bundleprod.jpg';
import Quantity from '../../../../common/Quantity/Quantity';

const Refund = () => {
    const [location, setlocation] = React.useState(1);
    const products = [
        {
            id: 1,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "Unicorn Fruit Whipped Body ButterUnicorn Fruit Whipped Body ButterUnicorn Fruit Whipped Body Butter",
            price: 20.00,
            totalItems: 2,
            quantity: 0,
            SKU: "testBundle1",
            desc: "6 product set"

        },
        {
            id: 2,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 20.00,
            totalItems: 2,
            quantity: 0,
            SKU: "testBundle1",
            desc: "6 product set"

        },
        {
            id: 3,
            tag: "fulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 139.00,
            totalItems: 1,
            quantity: 0,
            sku: "testBundle1",
            desc: "6 product set"

        },
    ];
    const handleQtyChange = (e) => {
        console.log("handleQtyChange", e)
    }
    const updateQty = (e, val) => {}
    const handleLocationChange = (event) => {
        setlocation(event.target.value);
    };
    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={6}>
                            <Button component={Link} variant="text" to="/orders/details" startIcon={<ArrowBackIosIcon />}> Refund</Button>
                        </Grid>
                        <Grid item md={6} sx={{ textAlign: "Right" }}></Grid>
                    </Grid>
                </Box>
                <Alert variant="outlined" severity="warning" sx={{ mb: 2 }}>
                    <AlertTitle>Return items before issuing refunds</AlertTitle>
                    If you want to track shipping and automatically restock returned items, return items before issuing a refund. You can’t return an item if it’s already been refunded.
                    <Box sx={{ pt: 2 }}>
                        <Button variant="outlined" color="warning">Return items</Button>
                        <Button variant="text" color="warning" className="noHover" sx={{ ml: 1 }}>Learn more</Button>
                    </Box>
                </Alert>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                                        <CircleTwoToneIcon color="warning" sx={{ mr: 1 }} /> Unfulfilled
                                    </Typography>

                                    {products.filter((cntnt) => cntnt.tag === "Unfulfilled").map((data, index) => {
                                        return (
                                            <Box key={index} className="orderProductBox" sx={{ display: "flex", }}>
                                                <Box className="orderProductBox__img">
                                                    <Badge overlap="circular">
                                                        <Avatar alt="Sample user" src={data.img} variant="square" />
                                                    </Badge>
                                                </Box>
                                                <Box className="orderProductBoxCntnt" sx={{ display: "flex" }}>
                                                    <Box className="orderProductBoxCntnt__link">
                                                        <Typography variant="subtitle2" component="div">{data.name}</Typography>

                                                        <Typography variant="body2" component="div">₹{(data.price).toFixed(2)} </Typography>
                                                    </Box>
                                                    <Box className="orderProductBoxCntnt__qntty">
                                                        <Quantity
                                                            quantity={data.quantity || 0}
                                                            handleQtyChange={(e) => handleQtyChange(e, data.quantity)}
                                                            incriment={() => updateQty(data.quantity, data.quantity + 1)}
                                                            decrement={() => updateQty(data.quantity, data.quantity - 1)}
                                                            endorement={`/${data.totalItems}`}
                                                        />
                                                        {/* totalItems */}
                                                    </Box>
                                                    <Box className="orderProductBoxCntnt__price" sx={{ pt: 1.2 }}><Typography variant="body2" component="div">₹{(data.price).toFixed(2)} </Typography> </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                    <Typography variant="caption" component="div">Refunded items will be removed from the order.</Typography>
                                </CardContent>
                                <Divider />
                                <Box sx={{ p: 2 }}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Restock item" />
                                </Box>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                                        <CheckCircleTwoToneIcon color="primary" sx={{ mr: 1 }} /> Fulfilled
                                    </Typography>
                                    {products.filter((cntnt) => cntnt.tag === "fulfilled").map((data, index) => {
                                        return (
                                            <Box key={index} className="orderProductBox" sx={{ display: "flex", }}>
                                                <Box className="orderProductBox__img">
                                                    <Badge overlap="circular">
                                                        <Avatar alt="Sample user" src={data.img} variant="square" />
                                                    </Badge>
                                                </Box>
                                                <Box className="orderProductBoxCntnt" sx={{ display: "flex" }}>
                                                    <Box className="orderProductBoxCntnt__link">
                                                        <Typography variant="subtitle2" component="div">{data.name}</Typography>

                                                        <Typography variant="body2" component="div"> {data.sku} </Typography>
                                                        <Typography variant="body2" component="div"> {data.desc}</Typography>
                                                        <Typography variant="subtitle2" component="div"> ₹{(data.price).toFixed(2)} </Typography>
                                                    </Box>
                                                    <Box className="orderProductBoxCntnt__qntty">
                                                        <Quantity
                                                            quantity={data.quantity || 0}
                                                            handleQtyChange={(e) => handleQtyChange(e, data.quantity)}
                                                            incriment={() => updateQty(data.quantity, data.quantity + 1)}
                                                            decrement={() => updateQty(data.quantity, data.quantity - 1)}
                                                            endorement={`/${data.totalItems}`}
                                                        />
                                                        {/* totalItems */}
                                                    </Box>
                                                    <Box className="orderProductBoxCntnt__price" sx={{ pt: 1.2 }}><Typography variant="body2" component="div">₹{(data.price).toFixed(2)} </Typography> </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </CardContent>
                                <Divider />
                                <CardContent>

                                    <FormControlLabel
                                        control={<Checkbox defaultChecked />}
                                        sx={{ mb: 2 }}
                                        label={<>
                                            <Typography variant="body1" component="div" >  Restock item at: </Typography>
                                            <Typography variant="caption" component="div" > This item will need to be restocked manually</Typography>
                                        </>
                                        }
                                    />
                                    <FormControl fullWidth size="small">
                                        <Select
                                            labelId="selectLocation-label"
                                            id="selectLocation"
                                            value={location}
                                            // label="Age"
                                            onChange={handleLocationChange}
                                        >
                                            <MenuItem value="">
                                                <em>Select a location</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Chandigarh</MenuItem>
                                            <MenuItem value={2}>Mumbai</MenuItem>
                                            <MenuItem value={3}>Goa</MenuItem>
                                        </Select>
                                    </FormControl>

                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom sx={{ mb: 3 }}> Reason for refund</Typography>
                                    <FormControl fullWidth >
                                        <TextField
                                            id="refundReason"
                                            helperText="Only you and other staff can see this reason."
                                            size="small"
                                        />
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                        <Grid item> <Typography variant="h6" component="h6" >Summary</Typography></Grid>
                                    </Grid>
                                    <Box className="refundProdCol" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                        <Box classname="refundProdCol__left">
                                            <Typography variant="body1" component="div">Items subtotal</Typography>
                                            <Typography variant="body2" component="div">2 items</Typography>
                                        </Box>
                                        <Box classname="refundProdCol__right"> <Typography variant="body1" component="div">₹157.00</Typography> </Box>
                                    </Box>
                                    <Box className="refundProdCol" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                        <Box classname="refundProdCol__left"> <Typography variant="body1" component="div">Tax</Typography> </Box>
                                        <Box classname="refundProdCol__right"> <Typography variant="body1" component="div">₹0.00</Typography> </Box>
                                    </Box>
                                    <Box className="refundProdCol" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                        <Box classname="refundProdCol__left"> <Typography variant="p" component="strong">Refund total </Typography> </Box>
                                        <Box classname="refundProdCol__right"> <Typography variant="p" component="strong">₹157.00</Typography> </Box>
                                    </Box>

                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                                        <Grid item > <Typography variant="p" component="strong">Refund amount</Typography></Grid>
                                        <Grid item >  </Grid>
                                    </Grid>
                                    <FormControl fullWidth sx={{ mb: 2 }} >
                                        <TextField
                                            label="Squareup"
                                            id="outlined-start-adornment"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                            }}
                                            helperText="₹215.00 available for refund"
                                            size="small"
                                        />
                                    </FormControl>

                                    <FormControlLabel control={<Checkbox defaultChecked />}
                                        label={<Typography variant="body1" component="div">Send a <Link to="/account/email/templates">notification</Link> to the customer</Typography>}
                                    />
                                </CardContent>
                            </Card>
                            <Button variant="contained" color="primary" className='float_btn' size="large" sx={{ ml: 1 }}>Save</Button>
                        </Grid>
                        <Grid item md={12}>
                            <Divider sx={{ my: "15px" }}></Divider>
                            <Box sx={{ textAlign: "left" }}>
                                <Button variant="contained" color="error" size="large" >Cancel</Button>
                                <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container >

        </>
    );
}

export default Refund