export default [
    {
        type: "image_file",
        id: "image_src",
        name: "image_src",
        label: "Client image",
        note: "",
        height: "150px",
        width: "100%",
        alt_name: "slide_img_alt",
        alt: "",
        value: "https://checkout-dev.growcart.app/slide.jpg"
    },
    {
        type: "editor",
        name: "text",
        id: "text",
        label: "Message text",
        value: "<p>Lorem ipsum</p>",
    },
    {
        type: "text",
        name: "author",
        id: "author",
        label: "Author",
        value: "",
        placeholder: ""
    }
]