import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, FormHelperText, Typography, FormGroup, Select, TextField, InputBase, Container, OutlinedInput, InputLabel, Chip, Alert, AlertTitle, Stack, Skeleton, LinearProgress
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ReactPlayer from "react-player"

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import update from "immutability-helper";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { alpha } from '@mui/material/styles';
import { Link, useNavigate, useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import htmlToDraft from "html-to-draftjs";

import { EditorState, ContentState } from "draft-js";

import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import { useDropzone } from 'react-dropzone';

import Seo from '../../../common/Seo/Seo';
import Fileupload from '../../../common/Fileupload/Fileupload';
import CustomEditor from '../../../common/CustomEditor/CustomEditor';
// import AutocompleteComp from '../../.././AutoComplete/Autocomplete';
import Variants from '../Variants/Variants';
import _, { indexOf } from "underscore";
import CreatableSelect from '../../../AutoComplete/CreatableSelect';
import * as Api from '../../../../Container/config/Apis';
import config from '../../../../Container/config';
import VariantModal from '../Variants/VariantModal';

import { useSnackbar } from 'notistack';
import PopupModal from '../../../common/PopupModal/PopupModal';
import VariantOptions from './VariantOptions';
import DialogAlert from '../../../common/Alerts/Dialog';
import MediaFiles from '../../../common/Fileupload/MediaFIles';

// file drag drop 

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        // borderRadius:"10px",
        // background: "#f7f8fa",
        margin: 0,
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

// file drag drop  

const AddProduct = () => {
    const param = useParams();
    let navigate = useNavigate()
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });

    const [loadingBar, setloadingBar] = React.useState(false);
    const [editProduct, setEditProduct] = useState(false);
    const [weight, setWeight] = useState(1);
    const [prodStatus, setProdStatus] = useState(1);
    const [media, setMedia] = useState([]);
    const [modalTitle, setmodalTitle] = useState("");
    const [openModal, setopenModal] = useState(false);
    const [openModal2, setopenModal2] = useState(false);
    const [modalType, setmodalType] = useState({
        editSku: false,
        ImageURL: false,
        variantImage: false,
        variantEdit: false,
    });
    const [images, setImages] = useState([])
    const [savebtnTxt, setsavebtnTxt] = useState("Save changes");
    const [activeOption, setactiveOption] = useState("");
    const [showError, setshowError] = useState(false)
    const [body, setBody] = useState({
        title: "",
        body_html: "<p></p>",
        status: "Active",
        product_type: "",
        vender: "",
        tags: [],
        published: true,
        variants: [],
        options: [],
        metafields_global_title_tag: "",
        metafields_global_description_tag: "",
        price: "",
        compare_at_price: "",
        sku: "",
        product_image: "",
        attachment: [],
        taxable: false,
        weight: 0,
        weight_unit: "kg",
        slug: "",
        barcode: "",
        track: true,
        quantity: 0,
        category_ids: "",
        out_of_stock: false
    });
    const [value, setValue] = useState({
        product_type: "",
        category_ids: [],
        tags: []
    });
    const [newtag, setNewtag] = useState("");
    const [brand, setBrand] = useState(null);
    const [common, setCommon] = useState({
        isVariant: false
    });
    const [selected, setSelected] = useState({
        product_type: [],
        vender: [],
        category_ids: [],
        tags: [],
    })
    const [pricing, setPricing] = useState({
        price: 0,
        cost: 0,
        margin: 0,
        profit: 0,
    });

    const [current, setCurrent] = useState("")
    const [urlImage, seturlImage] = useState("")
    const [selectedFile, setSelectedFile] = useState([])
    const [preview, setPreview] = useState([])
    const [optionArray, setoptionArray] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [dataVal, setData] = useState("");
    const [allVariants, setAllVariants] = useState("");
    const [showEditAll, setShowEditAll] = useState(false);
    const [contentState, setcontentState] = useState()
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [files, setFiles] = useState([]);

    //////////Variant block start

    const [variantModal, setVariantModal] = useState([])
    const [variant, setVariant] = useState([])
    const [prodOption, setprodOption] = useState([
        { id: 1, optionName: "", optionValue: [{ id: 1, value: "" }], edit: false, error: { name: false, value: false } },
    ]);
    const [showAlert, setShowAlert] = useState(false)
    const [errors, setErrors] = useState([
        { name: "title", valid: false, error: "Title can't be blank" },
        // { name: "product_image", valid: false, error: "Please add product images" },
        // { name: "product_type", valid: false, error: "Please select product type" },
        // // { name: "vender", valid: false, error: "Please add or select brand name" },
        // { name: "category_ids", valid: false, error: "Please select product category" },
        // { name: "price", valid: false, error: "Price can't be blank" },
        // { name: "sku", valid: false, error: "SKU can't be blank" },
    ])

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const closeDialog = () => {
        setOpen(false);
        setDelete()
        //     {
        //     type: "",
        //     url: "",
        //     data: "",
        //     api: ""
        // })
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    const getChangedPos = useCallback((currentPos, newPos) => {
        console.log(currentPos, newPos);
        let newArr = array_move(preview, currentPos, newPos)
        console.log("newArr: ", newArr)
        setPreview([...new Set(newArr)]);
    }, [preview]);
    console.log("preview: ", preview)

    const addMore = useCallback((id) => {
        let newArr = []
        prodOption.map((obj) => {
            let newVal = [...obj.optionValue]
            let newId = _.last(newVal)
            if (obj.id.toString() === id.toString()) {
                newVal.push({ id: newId.id + 1, value: "" })
                newArr.push({ ...obj, 'optionValue': newVal })
            } else {
                newArr.push(obj)
            }
        })
        setprodOption(newArr)
    }, [prodOption])

    const addOption = useCallback(() => {
        let newId = _.last(prodOption)
        let newObj = { id: newId.id + 1, optionName: "", optionValue: [{ id: 1, value: "" }], edit: false, error: { name: false, value: false } }
        prodOption.push(newObj)
        setprodOption([...prodOption])
    }, [prodOption])

    const deleteOption = (id) => {
        if (editProduct) {
            setContent({ ...content, 'message': "You want to delete this option. This can't be undone." })
            setOpen(true)
            // setVariantModal([...variant])
            let currentIndex = _.findIndex(prodOption, { 'id': id })
            let newArr = [...prodOption]
            if (currentIndex !== -1) {
                newArr.splice(currentIndex, 1)
            }
            console.log("newArr----------------------------------------------------", newArr)

            let arrayData = []
            newArr.map((opt) => {
                let arr = []
                opt.optionValue.map((obj) => {
                    arr.push(obj.value)
                })
                arrayData.push(arr)
            })
            let array = optionVariants(arrayData)
            console.log("array: ", array)
            let variantArray = []
            array.length > 0 && array.map((opt, index) => {
                variantArray.push({
                    ...variant[index],
                    variant: opt,
                })
            })
            let newObj = {}
            let newVariantArray = []
            console.log("variantArray: ", variantArray)
            variantArray.map((opt, index) => {
                opt.variant.map((val, index) => {
                    newObj[`option${index + 1}`] = val
                    if (opt.variant.length === 2) {
                        newObj[`option3`] = null
                    } else if (opt.variant.length === 1) {
                        newObj[`option2`] = null
                        newObj[`option3`] = null
                    }
                })
                newVariantArray.push({ ...opt, ...newObj })
            })
            console.log("newVariantArray: ", newVariantArray)

            // setVariant(newVariantArray)
            let bodyData = []
            newVariantArray.map((opt) => {
                bodyData.push(clean2(opt))
            })
            console.log("bodyData: ", bodyData)
            let optionData = []
            newArr.map((opt) => {
                let optArr = []
                opt.optionValue.map((obj) => {
                    optArr.push(obj.value)
                })
                optionData.push({
                    id: opt.id,
                    product_id: body.id,
                    name: opt.optionName,
                    // position: opt.position,
                    values: optArr,
                })
            })
            console.log("optionData: ", optionData)
            let productBody = {
                'id': body.id,
                'options': optionData
            }

            setDelete({ ...deleteType, 'type': "option", bodyData, productBody })
        } else {
            let currentIndex = _.findLastIndex(prodOption, { 'id': id })
            if (currentIndex !== -1) {
                prodOption.splice(currentIndex, 1)
                setprodOption([...prodOption])
            }
        }
    }
    const deleteVal = (id, val) => {
        let newArr = []
        prodOption.map((obj) => {
            if (obj.id.toString() === id.toString()) {
                let currentIndex = _.findIndex(obj.optionValue, { 'id': val })
                if (currentIndex !== -1) {
                    obj.optionValue.splice(currentIndex, 1)
                    obj.values.splice(currentIndex, 1)
                }
            }
            newArr.push(obj)
        })

        let arrayData = []
        newArr.map((opt) => {
            let array = []
            opt.optionValue.map((obj) => {
                array.push(obj.value)
            })
            arrayData.push(array)
        })

        let array2 = optionVariants(arrayData)
        setOptionList(array2);
        setoptionArray(arrayData)

        setprodOption(newArr)
    }

    //handle variant option name change
    const handleNameChange = (e, id) => {
        let currentIndex = _.findIndex(prodOption, { 'id': id })
        if (currentIndex !== -1) {
            prodOption[currentIndex][e.target.name] = e.target.value
        }
        setprodOption([...prodOption])
    }
    //handle variant option name blur
    const handleNameBlur = (e, id) => {
        let currentIndex = _.findIndex(prodOption, { 'id': id })
        if (currentIndex !== -1) {
            if (prodOption.filter((opt) => opt.optionName === e.target.value).length > 1) {
                prodOption[currentIndex].error.name = true
            } else {
                prodOption[currentIndex].error.name = false
            }
            setprodOption([...prodOption])
        }
    }

    //handle variant option value blur
    const handleValueBlur = (e, id, val) => {
        let newArr = []
        prodOption.map((obj) => {
            if (obj.id.toString() === id.toString()) {
                let currentIndex = _.findIndex(obj.optionValue, { 'id': val })
                if (currentIndex !== -1) {
                    if (obj.optionValue.filter((opt) => opt.value === e.target.value).length > 1) {
                        obj.optionValue[currentIndex].error = true
                    } else {
                        obj.optionValue[currentIndex].error = false
                    }
                }
            }
            newArr.push(obj)
        })
        setprodOption(newArr)
    }

    //handle variant option value change
    const handleValueChange = (e, id, val) => {
        let newArr = []
        prodOption.map((obj) => {
            if (obj.id.toString() === id.toString()) {
                let currentIndex = _.findIndex(obj.optionValue, { 'id': val })
                if (currentIndex !== -1) {
                    obj.optionValue[currentIndex].value = e.target.value
                }
            }
            newArr.push(obj)
        })
        let optionData = []
        let arrayData = []
        newArr.map((opt) => {
            let array = []
            opt.optionValue.map((obj) => {
                array.push(obj.value)
            })
            optionData.push({ name: opt.optionName, values: array })
            arrayData.push(array)
        })
        setBody({ ...body, 'options': optionData })
        setprodOption(newArr)
        setoptionArray(arrayData)
    }

    //on single variant check
    const handleVariantCheck = useCallback((e, val) => {
        console.log("val: ", val)
        let variantArr = [...variant]
        let currentIndex = _.findIndex(variantArr, { 'id': val.id })
        if (currentIndex !== -1) {
            variantArr[currentIndex].checked = e.target.checked
            setVariant(variantArr)
        }
        if (variantArr.filter((opt) => opt.checked === true).length > 1) {
            setShowEditAll(true)
        } else {
            setShowEditAll(false)
        }
        if (variantArr.filter((opt) => opt.checked === false).length > 0) {
            setAllVariants(false)
        } else {
            setAllVariants(true)
        }
    }, [variant])

    //on all variant check
    const handleAllVariantCheck = useCallback((e) => {
        setAllVariants(e.target.checked)
        setShowEditAll(e.target.checked)
        let variantArr = [...variant]
        variantArr.map((opt) => {
            opt.checked = e.target.checked
        })
        console.log("variantArr: ", variantArr)
    }, [variant])

    const editVariant = (e) => {
        if (editProduct) {
            navigate(`/products/${param.id}/variant/${e.id}`)
        }
        else {
            let name = ""
            for (var i = 0; i < e.variant.length; i++) {
                name = `Edit variant (${e.variant[0]} / ${e.variant[i]})`
            }
            setmodalTitle(name)
            setactiveOption(e)
            if (editProduct) {
                setopenModal2(true)
                setmodalType({ ...modalType, 'variantEdit': true })
            } else {
                setopenModal(true)
            }
        }
    }

    const editVariants = () => {
        setopenModal(true)
        setactiveOption(variant.filter((opt) => opt.checked === true)[0])
        setmodalTitle("Edit selected variants")
    }
    const editSKUs = () => {
        setsavebtnTxt("Save changes")
        setopenModal2(true)
        setVariantModal([...variant])
        setmodalType({ ...modalType, 'editSku': true })
        setmodalTitle("Edit SKUs")
        let newObj = {}
        variant.filter((opt) => opt.checked === true).map((obj) => {
            let name = ""
            for (var i = 0; i < obj.variant.length; i++) {
                name = `${obj.variant[0]} / ${obj.variant[i]}`
            }
            newObj = { ...obj, 'variant_name': name }
            let currentIndex = _.findIndex(variant, { 'id': obj.id })
            if (currentIndex !== -1) {
                variant[currentIndex] = newObj
            }
        })
        setVariant([...variant])
    }
    console.log("activeOption: ", activeOption)

    //on edit variant 
    const editOption = useCallback((id) => {
        let newArr = []
        prodOption.map((obj) => {
            if (obj.id.toString() === id.toString()) {
                obj.edit = false
            }
            newArr.push(obj)
        })
        setprodOption(newArr)
    }, [prodOption]);

    const cancelCreate = useCallback((id) => {
        let newArr = []
        prodOption.map((obj) => {
            if (obj.id.toString() === id.toString()) {
                obj.edit = true
            }
            newArr.push(obj)
        })
        setprodOption(newArr)
    }, [prodOption]);

    function optionVariants(optArr) {
        let array = []
        if (optArr.length > 0) {
            if (optArr.length === 2) {
                optArr[0].forEach(x => {
                    optArr[1].forEach(y => {
                        console.log([x, y]);
                        array.push([x, y])
                    });
                });
            } else if (optArr.length === 3) {
                optArr[0].forEach(x => {
                    optArr[1].forEach(y => {
                        optArr[2].forEach(z => {
                            console.log([x, y, z]);
                            array.push([x, y, z])
                        });
                    });
                });
            } else {
                optArr[0].forEach(x => {
                    console.log([x]);
                    array.push([x])
                });
            }
        }
        return array
    }

    function clean2(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || propName === "id" || propName === "checked" || propName === "variant" || propName === "quantity" || propName === "created_at" || propName === "updated_at" || propName === "presentment_prices" || propName === "img" || propName === "Inventory_Item" || propName === "inventory_item_id" || propName === "position" || propName === "title") {
                delete obj[propName];
            }
        }
        return obj
    }

    const doneCreate = useCallback((id) => {
        // let newId = _.last(variant)
        let newArr = []
        prodOption.map((obj) => {
            if (obj.id.toString() === id.toString()) {
                obj.edit = true
            }
            newArr.push(obj)
        })

        let newArray = []
        let array = optionVariants(optionArray)
        console.log("array: ", array)
        if (editProduct) {
            let variantArray = []
            // variant.length > 0 && variant.map((opt, index) => {
            let lastvariant = _.last(variant)
            let newSku2 = lastvariant.sku ? lastvariant.sku.split("-") : ""
            array.length > 0 && array.map((opt, index) => {
                if (variant.length >= index + 1) {
                    variantArray.push({
                        ...variant[index],
                        variant: opt,
                        weight: opt.weight ? opt.weight.toString() : "0",
                    })
                } else {
                    variantArray.push({
                        ...lastvariant,
                        id: lastvariant.id + (index - 1),
                        variant: opt,
                        weight: opt.weight ? opt.weight.toString() : "0",
                        sku: newSku2.length > 0 ? parseInt(newSku2[1]) > index + 1 ? `${newSku2[0]}-${parseInt(newSku2[1]) + 1}` : `${newSku2[0]}-${parseInt(newSku2[1]) + index}` : ""
                    })
                }
            })
            console.log("variantArray: ", variantArray)
            // let newId = _.last(variantArray)
            // let newSku = newId.sku.split("-")

            // array.map((obj, index) => {
            //     let elem = newId
            //     elem['product_id'] = body.id

            //     console.log("elem: ", elem)
            //     newArray.push({
            //         ...elem,
            //         id: index + 1,
            //         variant: obj,
            //         sku: newSku.length > 0 ? parseInt(newSku[1]) > index + 1 ? `${newSku[0]}-${parseInt(newSku[1]) + 1}` : `${newSku[0]}-${parseInt(newSku[1]) + index}` : "",
            //         checked: false
            //     })
            // })
            // let finalArray = [...newArray]
            let finalArray = []
            variantArray.map((opt, ind) => {
                finalArray[ind] = opt
            })
            console.log("finalArray: ", finalArray)
            let newObj = {}
            let newVariantArray = []
            finalArray.map((opt, index) => {
                opt.variant.map((val, index) => {
                    newObj[`option${index + 1}`] = val
                })
                newVariantArray.push({ ...opt, ...newObj })
            })
            console.log("newVariantArray: ", newVariantArray)

            // setVariant(newVariantArray)
            let bodyData = []
            newVariantArray.map((opt) => {
                bodyData.push(clean2(opt))
            })
            console.log("bodyData: ", bodyData)
            let optionData = []
            prodOption.map((opt) => {
                let optArr = []
                opt.optionValue.map((obj) => {
                    optArr.push(obj.value)
                })
                optionData.push({
                    id: opt.id,
                    product_id: body.id,
                    name: opt.optionName,
                    // position: opt.position,
                    values: optArr,
                })
            })
            console.log("optionData: ", optionData)
            let productBody = {
                'id': body.id,
                'options': optionData
            }
            updateVariantFn(Api.PostApi, `${config.PRODUCT_VARIANTS}/products/${body.id}/variants`, { "product_variant": bodyData })
            updateProductFn(Api.PutApi, `${config.PRODUCTS}/${body.id}`, productBody, "variantUpdate")

        } else {
            array.map((obj, index) => {
                newArray.push({
                    id: index + 1,
                    variant: obj,
                    price: body.price,
                    compare_at_price: body.compare_at_price,
                    quantity: body.quantity,
                    sku: body.sku !== "" ? index === 0 ? body.sku : `${body.sku}-${index}` : "",
                    barcode: body.barcode,
                    weight: body.weight,
                    weight_unit: body.weight_unit,
                    checked: false
                })
            })
            setVariant(newArray)
        }
        console.log("newArray: ", newArray)
        setOptionList(array);

        setprodOption(newArr)
    }, [prodOption, variant, optionList, optionArray])

    console.log("variant: ", variant)
    console.log("optionList: ", optionList)
    console.log("optionArray: ", optionArray)

    useEffect(() => {
        console.log("prodOption: ", prodOption)
    }, [prodOption, variant])

    ////////Variant block end

    const updateTextDescription = async (state) => {
        await setEditorState(state);
    };
    const onContentStateChange = (contentState) => {
        setcontentState(draftToHtml(contentState))
        setBody({ ...body, 'body_html': draftToHtml(contentState) });
        setData(draftToHtml(contentState))
    }

    const handleHtmlChange = (val) => {
        setData(val)
        const contentBlock = htmlToDraft(val);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            setBody({ ...body, 'body_html': val });
        }
    }

    const filter = createFilterOptions();
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });
    const productTypesList = [];
    const organization = [];

    const onSelectFile = (e) => {
        e.preventDefault()
        const fileobj = [];
        let files = Array.from(e.target.files);
        console.log("files: ", files)
        fileobj.push(files);
        let reader;
        let selectedFiles = [...selectedFile]
        for (var i = 0; i < fileobj[0].length; i++) {
            selectedFiles.push(files[i])
            setSelectedFile(selectedFiles)
            setBody({ ...body, 'product_image': selectedFiles })
            reader = new FileReader();
            reader.readAsDataURL(fileobj[0][i]);
            reader.onload = event => {
                preview.push(event.target.result);
                setPreview([...new Set(preview)]);
            }
        }
        if (editProduct) {
            let data = new FormData()
            if (selectedFiles.length > 0) {
                for (var i = 0; i < selectedFiles.length; i++) {
                    data.append('product_image', selectedFiles[i]);
                }
            }
            data.append("product_id", body.id)
            addVariantImageFn(Api.PostApi, config.PRODUCT_IMAGES, data, "")
        }
    }

    const [deleteType, setDelete] = useState({
        type: "",
        url: "",
        data: "",
        api: ""
    })
    function deleteFn() {
        if (deleteType.type === "image") {
            deleteVariantImageFn(deleteType.api, deleteType.url, deleteType.data)
        } else if (deleteType.type === "option") {
            updateVariantFn(Api.PostApi, `${config.PRODUCT_VARIANTS}/products/${body.id}/variants`, { "product_variant": deleteType.bodyData })
            updateProductFn(Api.PutApi, `${config.PRODUCTS}/${body.id}`, deleteType.productBody, "variantUpdate")
        }
    }
    console.log("deleteType: ", deleteType)
    //Delete image
    const deleteImage = (file, i) => {
        if (editProduct) {
            let imgData = images.filter((opt) => opt.src === file)[0]
            console.log("imgData: ", imgData)

            setContent({ ...content, 'message': "You want to delete this image. This can't be undone." })
            setOpen(true)
            setDelete({ ...deleteType, 'type': "image", 'api': Api.DeleteApi, 'url': `${config.PRODUCT_IMAGES}/products/${imgData.product_id}/images/${imgData.id}`, 'data': i })

            // deleteVariantImageFn(Api.DeleteApi, `${config.PRODUCT_IMAGES}/products/${imgData.product_id}/images/${imgData.id}`, i)
        } else {
            let selectedFiles = [...selectedFile]
            selectedFiles.splice(i, 1)
            preview.splice(i, 1)
            setPreview([...new Set(preview)]);
            setSelectedFile(selectedFiles)
            setBody({ ...body, 'product_image': selectedFiles })
        }
    }

    //handle create new select field
    const handleCreatableSelect = (e, val, reason, id) => {
        if (reason === "clear") {
            setBody({ ...body, [id]: "" })
        } else {
            let newArr = selected[id]
            if (val !== null) {
                if (typeof val === "string") {
                    let filterData = newArr.filter((opt) => opt === val)
                    if (filterData.length === 0) {
                        newArr.push({ title: val })
                        selected[id] = newArr
                        setSelected(selected)
                    }
                    setBody({ ...body, [id]: val })
                } else if (val && val.inputValue) {
                    let filterData = newArr.filter((opt) => opt === val.inputValue)
                    if (filterData.length === 0) {
                        newArr.push({ title: val.inputValue })
                        selected[id] = newArr
                        setSelected(selected)
                    }
                    setBody({ ...body, [id]: val.inputValue })
                } else {
                    setBody({ ...body, [id]: val.title })
                }
            }
        }
    }

    const handleMultipleSelect = (e, val, reason, id) => {
        console.log(e, val, reason, id)
        console.log(e.target.getAttribute("data-option-index"))
        if (reason === "clear") {
            setBody({ ...body, [id]: "" })
            setValue({ ...value, [id]: [] })
        } else {
            let newArr = selected[id]
            let array = []
            let arrayTitle = []
            let valArray = []
            if (val !== null) {
                val.map((opt, i) => {
                    if (opt.id) {
                        valArray = _.where(val, { id: opt.id })
                    } else {
                        valArray = _.where(val, { title: opt.title } || opt)
                    }
                    if (valArray.length > 1) {
                        value[id].splice(i, 1)
                    } else {
                        if (opt.title) {
                            arrayTitle.push(opt)
                        } else {
                            if (id !== "category_ids") {
                                arrayTitle.push({ title: opt })
                                newArr.push({ title: opt })
                                if (id === "tags") {
                                    selected[id] = newArr
                                    setSelected(selected)
                                }
                            }
                        }
                    }
                    setValue({ ...value, [id]: arrayTitle })
                    if (id === "tags") {
                        array.push(opt.title)
                    } else {
                        array.push(opt.id)
                    }
                })
                setBody({ ...body, [id]: array })
            }
            if (reason === "removeOption") {
                if (val.length === 0) {
                    setValue({ ...value, [id]: [] })
                } else {
                    setValue({ ...value, [id]: val })
                }
            }
        }
    }
    console.log("value: ", value)
    console.log("selected: ", selected)

    const handleCheckboxChange = (e) => {
        setCommon({ ...common, [e.target.name]: e.target.checked })
    }

    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const handleCheckbox = (e) => {
        setBody({ ...body, [e.target.name]: e.target.checked })
    }
    const handleSelectChange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const [showSkuError, setshowSkuError] = useState(false)
    const handleOnSKUBlur = (e) => {
        if (e.target.value !== "") {
            checkSKUFn({ 'sku': e.target.value })
        }
    }
    const handleInputchange = useCallback((e) => {
        if (modalType.variantEdit && e.target.name === "quantity") {
            setactiveOption({ ...activeOption, 'inventory_quantity': e.target.value })
        } else {
            setactiveOption({ ...activeOption, [e.target.name]: e.target.value })
        }
    }, [activeOption])

    const handleModalCheckbox = useCallback((e) => {
        setactiveOption({ ...activeOption, [e.target.name]: e.target.checked })
    }, [activeOption])

    const handlevariantSelect = useCallback((e) => {
        setactiveOption({ ...activeOption, [e.target.name]: e.target.value })
    }, [activeOption])

    const handleSKUschange = useCallback((e, data) => {
        console.log("variantModal---: ", variantModal)
        let currentIndex = _.findIndex(variantModal, { 'id': data.id })
        if (currentIndex !== -1) {
            variantModal[currentIndex][e.target.name] = e.target.value
            setVariantModal([...variantModal])
            // handleClose()
        }
    }, [variantModal])
    console.log("variantModal: ", variantModal)

    const handleOnTitleChange = (e) => {
        setBody({ ...body, 'slug': ((e.target.value).trim()).split(" ").join("-").toLowerCase() })
    }

    const handleClose = (e, reason) => {
        console.log("e, reason: ", e, reason)
        if (reason !== "backdropClick") {
            setopenModal(false)
            setopenModal2(false)
            setsavebtnTxt("Save")
            setactiveOption("")
            setmodalTitle("")
            seturlImage("")
            setVariantModal([])
            let arr = []
            images.map((opt) => {
                arr.push({ ...opt, 'active': false })
            })
            setImages([...arr])
            setmodalType({})
            setshowSkuError(false)
        }
    }

    const handleSave = () => {
        let currentIndex = _.findIndex(variant, { 'id': activeOption.id })
        if (currentIndex !== -1) {
            variant[currentIndex] = activeOption
            setVariant([...variant])
            handleClose()
        }
    }

    const handleUpdateAll = useCallback(() => {
        console.log("activeOption: ", activeOption)
        let array = [...variant]
        let newObj = {}
        variant.filter((opt) => opt.checked === true).map((obj) => {
            newObj = {
                ...obj,
                'compare_at_price': activeOption.compare_at_price,
                'price': activeOption.price,
                'quantity': activeOption.quantity,
                'weight': activeOption.weight,
                'weight_unit': activeOption.weight_unit
            }
            let currentIndex = _.findIndex(variant, { 'id': obj.id })
            if (currentIndex !== -1) {
                variant[currentIndex] = newObj
            }
        })
        setVariant([...variant])
        setAllVariants(false)
        handleClose()
    }, [activeOption])

    console.log("body: ", body)

    //GET PRODUCT BY ID
    const getProductByID = useCallback((id) => {
        Api.GetApi(`${config.PRODUCTS}/${id}`).then(async res => {
            setTimeout(() => {
                setloadingBar(false)
            }, 1000);
            if (res.error === true) {
                if (!_.isUndefined(res.response.data.error)) {
                    snack(res.response.data.error, 'error');
                } else {
                    snack(res.res.data.error, 'error');
                }
            } else {
                if (res.data.data) {
                    let category_id = []
                    let tags = []
                    let categories = []
                    let bodyData = res.data.data
                    res.data.data.category.length > 0 && res.data.data.category.map((opt) => {
                        category_id.push(opt.id)
                    })
                    selected.category_ids.map((opt) => {
                        res.data.data.category.length > 0 && res.data.data.category.filter(((obj) => obj.id === opt.id)).map((val) => {
                            categories.push({ 'title': val.body_html, 'id': val.id })
                        })
                    })
                    selected.tags.map((opt) => {
                        res.data.data.tags.length > 0 && res.data.data.tags.filter(((obj) => obj.value === opt.title)).map((val) => {
                            tags.push({ 'title': val.value, 'id': val.id })
                        })
                    })
                    let prod_Option = []
                    if (res.data.data.options.length > 0) {
                        let newObj = {}
                        let newObj2 = {}
                        let optionArr = []
                        res.data.data.options.map((opt, index) => {
                            let optArr = []
                            opt.values && opt.values.length > 0 && opt.values.map((val, i) => {
                                optArr.push({ id: i + 1, value: val })
                            })
                            newObj = {
                                ...opt,
                                optionName: opt.name,
                                optionValue: optArr,
                                position: opt.position !== null ? opt.position : index + 1,
                                edit: true,
                                error: { name: false, value: false }
                            }
                            newObj2 = {
                                ...opt,
                                position: opt.position !== null ? opt.position : index + 1,
                            }
                            prod_Option.push(newObj)
                            optionArr.push(newObj2)
                            setprodOption([...prod_Option])
                            bodyData["options"] = optionArr
                        })
                    }

                    let arrayData = []
                    prod_Option.map((opt) => {
                        let array = []
                        opt.optionValue.map((obj) => {
                            array.push(obj.value)
                        })
                        arrayData.push(array)
                    })

                    let array2 = optionVariants(arrayData)
                    setOptionList(array2);

                    setoptionArray(arrayData)

                    let variantData = []
                    let variantData2 = []
                    res.data.data.variants.map((opt, index) => {
                        let imgSrc = res.data.data.images.filter((obj) => obj.id === opt.image_id)
                        variantData.push({ ...opt, 'checked': false, 'old_inventory_quantity': opt.inventory_quantity })
                        variantData2.push({
                            ...opt,
                            'img': !_.isUndefined(imgSrc) && !_.isNull(imgSrc) && imgSrc.length > 0 ? imgSrc[0].src : "",
                            'variant': array2[index],
                            'old_inventory_quantity': opt.inventory_quantity,
                            'quantity': opt.inventory_quantity,
                            'checked': false,
                            'track': opt.Inventory_Item && opt.Inventory_Item.tracked ? opt.Inventory_Item.tracked : true,
                        })
                    })
                    setVariant(variantData2)
                    setImages(_.sortBy(res.data.data.images, "position"))

                    setValue({ ...value, 'category_ids': categories, 'tags': tags })
                    bodyData['product_image'] = []
                    bodyData['category_ids'] = category_id
                    bodyData['tags'] = res.data.data.tags
                    bodyData['slug'] = bodyData.handle
                    bodyData['variants'] = variantData

                    await handleHtmlChange(bodyData.body_html)
                    await setBody(bodyData)
                    setCurrent(res.data.data)
                    let images = []
                    res.data.data.images.map((img) => {
                        images.push({ 'src': img.src, 'id': img.id, 'position': img.position })
                    })
                    setPreview([...new Set(_.sortBy(images, "position"))]);
                }
                else {
                    snack("No record found. Please check the URL.", 'error');
                }
            }
        })
    }, [prodOption, variant])
    console.log("prodOption: ", prodOption)

    //GET CATEGORIES
    const getCategoryList = useCallback(() => {
        Api.GetApi(config.CATEGORY_LIST).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let arr = selected.category_ids
                    res.data.data.map((opt) => {
                        arr.push({ 'id': opt.id, 'title': opt.body_html })
                    })
                    setSelected({ ...selected, 'category_ids': arr })
                }
            }
        })
    }, [selected])
    //GET product type
    const getProductTypeList = useCallback(() => {
        Api.GetApi(config.PRODUCT_TYPE_LIST).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let arr = selected.product_type
                    res.data.data.map((opt) => {
                        arr.push({ title: opt.product_type })
                    })
                    setSelected({ ...selected, 'product_type': arr })
                }
            }
        })
    }, [selected])
    //GET brand list
    const getBrandList = useCallback(() => {
        Api.GetApi(config.BRAND_LIST).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let arr = selected.vender
                    res.data.data.map((opt) => {
                        arr.push({ title: opt.vender })
                    })
                    setSelected({ ...selected, 'vender': arr })
                }
            }
        })
    }, [selected])
    //GET tags list
    const getTagsList = useCallback(() => {
        Api.GetApi(config.TAGS_LIST).then(res => {
            console.log("res: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let arr = selected.tags
                    res.data.data.map((opt) => {
                        arr.push({ title: opt.value, id: opt.product_id })
                    })
                    setSelected({ ...selected, 'tags': arr })
                }
            }
        })
    }, [selected])

    // const handleTagchange = (e) => {
    //     setNewtag(e.target.value)
    // }
    // const addTag = () => {
    //     let arr = selected.tags
    //     let arrayTitle = [...value.tags]
    //     arr.push({ title: newtag })
    //     if (arrayTitle.filter((opt) => opt.title === newtag).length === 0) {
    //         arrayTitle.push({ title: newtag })
    //         console.log("arrayTitle: ", arrayTitle)
    //         handleMultipleSelect("", arrayTitle, "selectOption", "tags")
    //         setaddNewTag(false)
    //         setNewtag("")
    //         setSelected({ ...selected, 'tags': arr })
    //     }

    //     // setValue({ ...value, 'tags': arrayTitle })
    // }
    const addFromURL = () => {
        setopenModal2(true)
        setmodalType({ ...modalType, 'ImageURL': true })
        setsavebtnTxt("Add file")
        setmodalTitle("Add file from URL")
    }

    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || propName === "id" || propName === "variant" || propName === "checked" || propName === "quantity" || propName === "created_at" || propName === "updated_at" || propName === "presentment_prices" || propName === "img" || propName === "Inventory_Item") {
                delete obj[propName];
            }
        }
        return obj
    }

    //handle modal save button click
    const savebtnFunct = () => {
        if (!editProduct && modalType.editSku === true) {
            setVariant(variantModal)
            handleClose()
        }
        if (editProduct && modalType.variantImage === true) {
            let data = {
                image_id: activeOption.image_id,
                product_id: activeOption.product_id,
            }
            updateVariantFn(Api.PutApi, `${config.PRODUCT_VARIANTS}/${activeOption.id}`, data)
        }
        if (editProduct && modalType.variantEdit === true) {
            let optId = activeOption.id
            let data = clean(activeOption)
            data = {
                ...activeOption,
                price: activeOption.price ? activeOption.price.toString() : "",
                compare_at_price: activeOption.compare_at_price ? activeOption.compare_at_price.toString() : "",
                weight: activeOption.weight ? activeOption.weight.toString() : "0",
            }
            updateVariantFn(Api.PutApi, `${config.PRODUCT_VARIANTS}/${optId}`, data)
        }
        if (modalType.ImageURL === true) {
            let newArr = body.attachment ? [...body.attachment] : []
            newArr.push(urlImage);
            setBody({ ...body, 'attachment': newArr })
            preview.push(urlImage);
            setPreview([...new Set(preview)]);

            if (editProduct) {
                // let data = {
                //     product_id: body.id,
                //     attachment: urlImage
                // }
                let data = new FormData()
                data.append("product_id", body.id)
                data.append("attachment[0]", urlImage)
                addVariantImageFn(Api.PostApi, config.PRODUCT_IMAGES, data, "")
            } else {
                seturlImage("")
                handleClose()
            }
        }
    }

    const handleOnURLchange = (e) => {
        seturlImage(e.target.value)
    }

    function cleanBody(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName].length === 0 || obj[propName] === undefined || obj[propName] === "" || propName === "variants" || propName === "handle" || propName === "created_at" || propName === "updated_at" || propName === "category" || propName === "product_image" || propName === "attachment" || propName === "published_at" || propName === "store_id" || propName === "_fivetran_deleted" || propName === "images") {
                delete obj[propName];
            }
        }
        return obj
    }
    function cleanVariant(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName].length === 0 || obj[propName] === undefined || obj[propName] === "" || propName === "checked") {
                delete obj[propName];
            }
        }
        return obj
    }
    const saveProduct = async () => {
        // let array = []
        // errors.map((opt) => {
        //     if (body[opt.name] === "") {
        //         opt.valid = false
        //     } else {
        //         opt.valid = true
        //     }
        //     array.push(opt)
        // })
        // setErrors(array)
        // if (array.filter((opt) => opt.valid === false).length > 0) {
        //     setshowError(true)
        //     window.scrollTo({
        //         top: 0,
        //         behavior: 'smooth',
        //     });
        if (body.title === "") {
            setshowError(true)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            let variantArray = []
            let variantObj = {
                barcode: "",
                price: 0,
                compare_at_price: 0,
                fulfillment_service: "mannual",
                inventory_quantity: 0,
                position: 1,
                sku: "",
                weight: 0,
                weight_unit: "kg"
            }
            variant.length > 0 && body.options.length > 0 && variant.map((opt, index) => {
                let newObj = {}
                opt.variant.map((val, index) => {
                    newObj[`option${index + 1}`] = val
                })
                opt = cleanVariant(variantObj)
                variantArray.push({
                    ...variantObj,
                    ...newObj,
                    "price": parseFloat(opt.price) || 0,
                    "compare_at_price": opt.compare_at_price !== "" ? parseFloat(opt.compare_at_price) : parseFloat(opt.price) || 0,
                    "inventory_quantity": body.track ? 0 : parseFloat(opt.quantity),
                    "position": index + 1,
                })
            })
            console.log("variantArray: ", variantArray)

            let data = new FormData();
            if (editProduct) {
                // let tags = []
                let bodyData = {}
                bodyData.tags && bodyData.tags.map((val, index) => {
                    if (val.value) {
                        console.log("yessssssssssssssssssssssssssssssssssssssssss")
                        bodyData.tags.push(val.value);
                    } else {
                        console.log("noooooooooooooooooooooo")
                        bodyData.tags.push(val);
                    }
                })
                // bodyData.images && bodyData.images.length > 0 && bodyData.images.map((item, index) => {
                //     bodyData.images.push({ 'id': item.id, 'position': item.position })
                // })
                bodyData.category_ids && bodyData.category_ids.length > 0 && bodyData.category_ids.map((val, index) => {
                    bodyData.category_ids.push(val);
                })
                bodyData = cleanBody(body)
                data = {
                    ...bodyData
                }
                console.log("data--------------------------: ", data)
                saveProductFn(Api.PutApi, `${config.PRODUCTS}/${body.id}`, data, "edit")
            } else {
                if (body.product_image.length > 0) {
                    for (var i = 0; i < body.product_image.length; i++) {
                        data.append('product_image', body.product_image[i]);
                    }
                }

                // data.append('variants', JSON.stringify(variantArray));

                if (body.category_ids.length > 0) {
                    body.category_ids.map((val, index) => {
                        data.append(`category_ids[${index}]`, val);
                    })
                }
                if (body.vender !== "") {
                    data.append("vender", body.vender)
                }
                if (body.options.length > 0) {
                    body.options.map((item, index) => {
                        data.append(`options[${index}][name]`, item.name);
                        item.values.map((val, i) => {
                            data.append(`options[${index}][values][${i}]`, val);
                        })
                    })
                }
                if (variantArray.length > 0) {
                    variantArray.map((item, index) => {
                        _.each(item, (val, keys) => {
                            data.append(`variants[${index}][${keys}]`, val);
                        })
                    })
                }

                data.append("title", body.title)
                data.append("body_html", body.body_html)
                data.append("status", body.status)

                if (body.product_type !== "") {
                    data.append("product_type", body.product_type)
                }
                if (body.published !== "") {
                    data.append("published", body.published)
                }
                if (body.metafields_global_title_tag !== "") {
                    data.append("metafields_global_title_tag", body.metafields_global_title_tag)
                }
                if (body.metafields_global_description_tag !== "") {
                    data.append("metafields_global_description_tag", body.metafields_global_description_tag)
                }

                data.append("slug", body.slug)
                data.append("track", body.track)

                body.tags.map((val, index) => {
                    data.append(`tags[${index}]`, val);
                })
                if (body.quantity !== "") {
                    data.append("quantity", body.track ? parseFloat(body.quantity) : 0)
                }
                data.append("out_of_stock", body.out_of_stock)
                if (body.price !== "") {
                    data.append("price", parseFloat(body.price))
                }
                if (body.compare_at_price !== "") {
                    data.append("compare_at_price", body.compare_at_price !== "" ? parseFloat(body.compare_at_price) : parseFloat(body.price))
                }
                if (body.sku !== "") {
                    data.append("sku", body.sku)
                }
                data.append("taxable", body.taxable)
                if (body.weight !== "") {
                    data.append("weight", parseFloat(body.weight))
                }
                data.append("weight_unit", body.weight_unit)
                if (body.barcode !== "") {
                    data.append("barcode", body.barcode)
                }
                saveProductFn(Api.PostApi, config.PRODUCTS, data, "add")
            }
        }
    }


    const changeVariantImage = (data) => {
        setopenModal2(true)
        setsavebtnTxt("Done")
        setmodalTitle("Choose variant image")
        setmodalType({ ...modalType, 'variantImage': true })
        setactiveOption(data)

    }

    const chooseImage = (data) => {
        let arr = []
        let currentIndex = _.findIndex(images, { 'id': data.id })
        images.map((opt) => {
            arr.push({ ...opt, 'active': false })
        })
        if (currentIndex !== -1) {
            arr[currentIndex].active = !arr[currentIndex].active
            let newData = { ...activeOption, 'image_id': data.id, 'img': data.src }
            setactiveOption(newData)
        }
        setImages([...arr])
    }

    const onVariantFileAdd = (e) => {
        e.preventDefault()
        let reader = new FileReader();
        let file = e.target.files[0];
        let data = new FormData();
        data.append("product_image", file)
        data.append("product_id", activeOption.product_id)
        addVariantImageFn(Api.PostApi, config.PRODUCT_IMAGES, data, "addNew")
        reader.readAsDataURL(file)
    }


    const saveProductFn = async (api, url, data, type) => {
        await api(url, data).then(res => {
            console.log("res save product: ", res)
            if (res.error === true) {
                let errorArr = [...errors]
                snack(res.response.data.error, 'error');
                errorArr.push({ 'valid': false, 'error': res.response.data.error })
                setErrors(errorArr)
                setShowAlert(true)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                snack(type === "add" ? "Product added successfully" : "Updated successfully", 'success');
                setTimeout(() => {
                    navigate('/products/list')
                }, 3000);
            }
        })
    }
    const updateProductFn = async (api, url, data, type) => {
        await api(url, data).then(res => {
            console.log("res update product: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack("Updated successfully", 'success');
                if (type === "variant" || type === "variantUpdate") {
                    getProductByID(param.id)
                }
                closeDialog()
            }
        })
    }

    //Update variant function
    const updateVariantFn = async (api, url, data) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                // getProductByID(param.id)
                if (editProduct && modalType.variantImage === true) {
                    let currentIndex = _.findIndex(variant, { 'id': activeOption.id })
                    if (currentIndex !== -1) {
                        variant[currentIndex] = activeOption
                        setVariant([...variant])
                        handleClose()
                    }
                } else if (editProduct && modalType.variantEdit) {
                    getProductByID(param.id)
                    handleClose()
                }
                // snack(res.data.data, 'success');
            }
        })
    }

    //Update variant image
    const checkSKUFn = async (data) => {
        await Api.PostApi(config.PRODUCT_SKU, data).then(res => {
            console.log("res SKU: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    setshowSkuError(true)
                } else {
                    setshowSkuError(false)
                }
            }
        })
    }

    //Add new variant image
    const addVariantImageFn = useCallback(async (api, url, data, type) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    res.data.data.map((opt) => {
                        setImages([...images, opt])
                    })
                    res.data.data.map((img) => {
                        preview.push(img.src)
                    })
                    if (type !== "addNew") {
                        seturlImage("")
                        handleClose()
                    }
                    // setPreview([...new Set(preview)]);
                }
            }
        })
    }, [preview])

    //update variant image
    const updateImageFn = async (api, url, data) => {
        await api(url, data).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack("Image order changed", 'success');
            }
        })
    }

    //Delete variant image
    const deleteVariantImageFn = async (api, url, i) => {
        await api(url).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                preview.splice(i, 1)
                setPreview([...new Set(preview)]);
                closeDialog()
            }
        })
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        let data = {
            'id': images[dragIndex].product_id,
            'images': [
                {
                    position: hoverIndex + 1,
                    id: images[dragIndex].id
                },
                {
                    position: dragIndex + 1,
                    id: images[hoverIndex].id
                }
            ]
        }

        updateProductFn(Api.PutApi, `${config.PRODUCTS}/${images[dragIndex].product_id}`, data, "position")
        setPreview((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]]
                ]
            })
        );
    }, [images]);

    // const moveVariant = useCallback((dragIndex, hoverIndex) => {
    //     console.log("dragIndex, hoverIndex: ", dragIndex, "----", hoverIndex)

    // }, [prodOption]);

    const moveOption = useCallback((dragIndex, hoverIndex) => {
        let optArr = [...prodOption]
        setprodOption((prevCards) =>
            optArr = update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]]
                ]
            })
        );
        let arrayData = []
        optArr.map((opt) => {
            let array = []
            opt.optionValue.map((obj) => {
                array.push(obj.value)
            })
            arrayData.push(array)
        })
        let array2 = optionVariants(arrayData)
        setOptionList(array2);
        setoptionArray(arrayData)

        let optionData = []
        optArr.map((opt, index) => {
            optionData.push({
                id: opt.id,
                product_id: opt.product_id,
                name: opt.name,
                position: index + 1,
                values: opt.values,
            })
        })
        console.log("optionData: ", optionData)
        let productBody = {
            'id': parseInt(param.id),
            'options': optionData
        }
        console.log("productBody: ", productBody)
        updateProductFn(Api.PutApi, `${config.PRODUCTS}/${parseInt(param.id)}`, productBody, "variantedit")
    }, [prodOption]);

    const confirmDialog = () => {
        deleteFn()
    }
    console.log("images: ", images)
    console.log("modalType: ", modalType)

    useEffect(() => {
        getCategoryList()
        getProductTypeList()
        getBrandList()
        getTagsList()
        if (param.id) {
            setloadingBar(true)
            setEditProduct(true)
            getProductByID(param.id)
        }
    }, []);
    // }, [editProduct]);
    console.log("current: ", current)
    useEffect(() => {
    }, [files, errors, value]);

    return (
        <React.Fragment>
            {loadingBar ?
                <LinearProgress sx={{ mt: "-25px" }} />
                :
                <Container maxWidth="lg">
                    <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item md={6}>
                                <Button component={Link} variant="text" to="/products/list" startIcon={<ArrowBackIosIcon />}>
                                    {editProduct ?
                                        current && current.title :
                                        "Product"
                                    }
                                </Button>
                            </Grid>
                            <Grid item md={6} sx={{ textAlign: "Right" }}></Grid>
                        </Grid>
                    </Box>
                    {showAlert &&
                        <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
                            <AlertTitle>Error found:</AlertTitle>
                            <ul style={{ paddingLeft: "15px" }}>
                                {errors.filter((obj) => obj.valid === false).map((opt, index) => {
                                    return (
                                        <li key={index.toString()}>
                                            {opt.error}
                                        </li>
                                    )
                                })}
                            </ul>
                        </Alert>
                    }
                    <Box>
                        <Grid container spacing={2} columns={12}>
                            <Grid item md={8}>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" gutterBottom>Product title</Typography>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="standard-basic"
                                                label=""
                                                name="title"
                                                value={body.title || ""}
                                                error={showError && body.title === "" ? true : false}
                                                helperText={showError && body.title === "" ? "Title can't be blank" : ""}
                                                onChange={handleOnchange}
                                                onBlur={handleOnTitleChange}
                                                size="small"
                                                variant="outlined" />
                                        </FormControl>
                                    </CardContent>
                                </Card>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" gutterBottom>Product description</Typography>
                                        <CustomEditor
                                            editorState={editorState}
                                            updateTextDescription={updateTextDescription}
                                            onContentStateChange={onContentStateChange}
                                            handleHtmlChange={handleHtmlChange}
                                            value={dataVal}
                                        />
                                    </CardContent>
                                </Card>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography variant="h6" component="h6" gutterBottom>Product images</Typography>
                                            <Button color="secondary" onClick={addFromURL}>Add from URL</Button>
                                        </Stack>
                                        <DndProvider backend={HTML5Backend}>
                                            <Fileupload
                                                preview={preview}
                                                onSelectFile={onSelectFile}
                                                deleteImage={deleteImage}
                                                getChangedPos={getChangedPos}
                                                moveCard={moveCard}
                                                editProduct={editProduct}
                                            />
                                        </DndProvider>
                                        {/* <Box className="container">

                                    <Box className={`fileUploader ${files.length > 0 ? "active" : null}`}>
                                        {thumbs}
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop some files here.</p>
                                        </div>
                                    </Box>
                                </Box> */}
                                    </CardContent>
                                </Card>
                                {!editProduct &&
                                    <VariantOptions
                                        body={body}
                                        showAlert={showAlert}
                                        handleOnchange={handleOnchange}
                                        handleCheckbox={handleCheckbox}
                                        handleSelectChange={handleSelectChange}
                                        handleOnSKUBlur={handleOnSKUBlur}
                                        showSkuError={showSkuError}
                                    />
                                }
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="h6" gutterBottom>Options</Typography>
                                        {!editProduct &&
                                            <FormControlLabel name="isVariant" control={<Checkbox checked={common.isVariant} onChange={(e) => handleCheckboxChange(e)} />} label="Have options?" />
                                        }
                                        {common.isVariant || editProduct ?
                                            <>
                                                <Divider sx={{ my: "15px" }}></Divider>
                                                <DndProvider backend={HTML5Backend}>
                                                    <Variants
                                                        prodOption={prodOption}
                                                        variant={variant}
                                                        handleNameChange={handleNameChange}
                                                        handleNameBlur={handleNameBlur}
                                                        handleValueBlur={handleValueBlur}
                                                        deleteOption={deleteOption}
                                                        addOption={addOption}
                                                        doneCreate={doneCreate}
                                                        editOption={editOption}
                                                        addMore={addMore}
                                                        handleValueChange={handleValueChange}
                                                        handleVariantCheck={handleVariantCheck}
                                                        handleAllVariantCheck={handleAllVariantCheck}
                                                        deleteVal={deleteVal}
                                                        allVariants={allVariants}
                                                        showEditAll={showEditAll}
                                                        editVariant={editVariant}
                                                        editVariants={editVariants}
                                                        editSKUs={editSKUs}
                                                        body={body}
                                                        editProduct={editProduct}
                                                        changeVariantImage={changeVariantImage}
                                                        // moveVariant={moveVariant}
                                                        moveOption={moveOption}
                                                        cancelCreate={cancelCreate}
                                                    />
                                                </DndProvider>
                                            </>
                                            : null
                                        }
                                    </CardContent>
                                </Card>

                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Seo
                                            handleOnchange={handleOnchange}
                                            body={body}
                                            page={"products"}
                                            title="metafields_global_title_tag"
                                            description="metafields_global_description_tag"
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={4}>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="h6" gutterBottom >Product status</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <Select
                                                labelId="ProductStatus"
                                                id="productStatus"
                                                name="status"
                                                value={body.status}
                                                onChange={handleSelectChange}
                                                sx={{ mt: 0 }}
                                                defaultValue={prodStatus} size="small"
                                            >
                                                <MenuItem value={"Active"}>Active</MenuItem>
                                                <MenuItem value={"Draft"}>Draft</MenuItem>
                                            </Select>
                                            <FormHelperText >This product will be {body.status === "Active" ? "available to" : "hidden from"} all sales channels</FormHelperText>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                                <Card sx={{ mb: 1 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="h6" gutterBottom>Product Type</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <CreatableSelect
                                                value={body.product_type || ""}
                                                handleCreatableSelect={handleCreatableSelect}
                                                list={selected.product_type}
                                                id={"product_type"}
                                                multiple={false}
                                                type="Select or add product type"
                                                showAlert={showAlert}
                                                body={body}
                                            />
                                        </FormControl>
                                    </CardContent>
                                    <CardContent sx={{ pt: 0 }}>
                                        <Typography variant="h6" component="h6" gutterBottom>Brand</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <CreatableSelect
                                                value={body.vender || ""}
                                                handleCreatableSelect={handleCreatableSelect}
                                                list={selected.vender}
                                                id={"vender"}
                                                multiple={false}
                                                type="Select or add product brand"
                                                showAlert={showAlert}
                                                body={body}
                                            />
                                        </FormControl>
                                    </CardContent>
                                    <CardContent sx={{ pt: 0 }}>
                                        <Typography variant="h6" component="h6" gutterBottom>Category</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <CreatableSelect
                                                value={value.category_ids}
                                                handleCreatableSelect={handleMultipleSelect}
                                                list={selected.category_ids}
                                                id={"category_ids"}
                                                multiple={true}
                                                type="Select categories"
                                                showAlert={showAlert}
                                                body={body}
                                            />
                                        </FormControl>
                                    </CardContent>
                                    <CardContent sx={{ pt: 0 }}>
                                        <Typography variant="h6" component="h6" gutterBottom>Tags</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <CreatableSelect
                                                value={value.tags}
                                                handleCreatableSelect={handleMultipleSelect}
                                                list={selected.tags}
                                                id={"tags"}
                                                multiple={true}
                                                type="Select tags"
                                                showAlert={showAlert}
                                                body={body}
                                            />
                                        </FormControl>
                                        <Typography variant="caption" component="div" sx={{ fontStyle: 'italic' }} color="#999" gutterBottom>*Type and press enter to add new tag</Typography>
                                        {/* {addNewTag === false ?
                                    <Button color="success" onClick={() => setaddNewTag(true)}>+ Add tag</Button>
                                    :
                                    <>
                                        <Button color="error" onClick={() => setaddNewTag(false)}>Cancel</Button>
                                        <Grid container spacing={2} columns={12} alignItems="center" sx={{ pt: 1 }}>
                                            <Grid item sm={9}>
                                                <FormControl fullWidth sx={{ m: 0 }}>
                                                    <TextField
                                                        size="small"
                                                        label="Enter tag name"
                                                        id="newtag"
                                                        name="newtag"
                                                        onChange={handleTagchange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{ m: 0, width: '100%' }} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Button color="success" variant='contained' onClick={addTag}>+ Add</Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                } */}
                                    </CardContent>
                                </Card>
                                <Button variant="contained" color="primary" className='float_btn' onClick={saveProduct} size="large" sx={{ mt: 2 }}>{editProduct ? "Update product" : "Save product"}</Button>
                            </Grid>
                            <Grid item md={12}>
                                <Divider sx={{ my: "15px" }}></Divider>
                                <Box sx={{ textAlign: "left" }}>
                                    <Button variant="contained" color="error" size="large" onClick={() => navigate("/products/list")}>Cancel</Button>
                                    <Button variant="contained" color="primary" size="large" onClick={saveProduct} sx={{ ml: 1 }}>{editProduct ? "Update product" : "Save product"}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            }
            <VariantModal
                handleClose={handleClose}
                title={modalTitle}
                handleSave={handleSave}
                openModal={openModal}
                data={activeOption}
                handleInputchange={handleInputchange}
                handlevariantSelect={handlevariantSelect}
                body={body}
                showEditAll={showEditAll}
                handleUpdateAll={handleUpdateAll}
            />
            <PopupModal
                open={openModal2}
                isDisabled={showSkuError}
                ModalClose={handleClose}
                title={modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt={savebtnTxt}
                savebtnFunct={savebtnFunct} >
                {modalType.editSku &&
                    <>
                        {variant.filter((opt) => opt.checked === true).map((obj) => {
                            return (
                                <Box key={obj.id}>
                                    <Grid container spacing={2} alignItems={"center"}>
                                        <Grid item sm={4}>
                                            <Typography variant="p" component="strong" gutterBottom>{obj.variant_name}</Typography>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <FormControl fullWidth sx={{ mb: 1 }} >
                                                <TextField
                                                    label="SKU"
                                                    defaultValue={obj.sku}
                                                    variant="outlined"
                                                    sx={{ fontSize: "12px !important" }}
                                                    size="small"
                                                    name="sku"
                                                    onBlur={handleOnSKUBlur}
                                                    helperText={showSkuError ?
                                                        <Alert variant="outlined" severity="error" sx={{ border: 0, p: 0, background: "none !important" }}>SKU already used!</Alert> : ""
                                                    }
                                                    onChange={(e) => handleSKUschange(e, obj)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <FormControl fullWidth sx={{ mb: 1 }} >
                                                <TextField
                                                    label="Barcode"
                                                    defaultValue={obj.barcode}
                                                    variant="outlined"
                                                    sx={{ fontSize: "12px !important" }}
                                                    size="small"
                                                    name="barcode"
                                                    onChange={(e) => handleSKUschange(e, obj)}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 1, mb: 2 }}></Divider>
                                </Box>
                            )
                        })}
                    </>
                }
                {modalType.ImageURL &&
                    <FormControl fullWidth>
                        <TextField
                            id="attachment"
                            label="Image, YouTube, or Vimeo URL"
                            name="attachment"
                            onChange={handleOnURLchange}
                            size="small"
                            variant="outlined" />
                    </FormControl>
                }
                {modalType.variantImage &&
                    <MediaFiles
                        onVariantFileAdd={onVariantFileAdd}
                        images={images}
                        chooseImage={chooseImage}
                    />
                    // <Grid container spacing={2} columns={12}>
                    //     <Grid item md={3}>
                    //         <Box className="customFileUpload">
                    //             <input
                    //                 accept=".jpg,.jpeg,.png,.webp,.svg"
                    //                 type='file'
                    //                 onChange={onVariantFileAdd}
                    //             />
                    //             <label style={{ textAlign: 'center' }}>
                    //                 <FileUploadIcon fontSize='small' sx={{ verticalAlign: "middle", display: "block", margin: "auto" }} />
                    //                 Add new
                    //             </label>
                    //         </Box>
                    //     </Grid>
                    //     {images.length > 0 && images.map((img) => {

                    //         return (
                    //             <Grid key={img.id} item md={3}>
                    //                 <Button sx={{ position: 'relative', border: "#eee 1px solid", width: "100%", height: "100%" }} onClick={() => chooseImage(img)}>
                    //                     {Api.getId(img.src) === null || Api.getId(img.src) === undefined ?
                    //                         <img src={img.src} alt="" />
                    //                         :
                    //                         <ReactPlayer
                    //                             url={img.src}
                    //                             controls={false}
                    //                             config={{
                    //                                 youtube: {
                    //                                     playerVars: { showinfo: 0 }
                    //                                 }
                    //                             }}
                    //                             className="video_preview_product"
                    //                             width={"100%"}
                    //                             height={"100%"}
                    //                             playsinline={true}
                    //                             playbackRate={0}
                    //                             light={true}
                    //                         />
                    //                     }
                    //                     {img.active &&
                    //                         <CheckCircleIcon className='float-top-left' />
                    //                     }
                    //                 </Button>
                    //             </Grid>
                    //         )
                    //     })}
                    // </Grid>
                }
                {modalType.variantEdit &&
                    <React.Fragment>
                        <VariantOptions
                            body={activeOption}
                            showAlert={showAlert}
                            handleOnchange={handleInputchange}
                            handleCheckbox={handleModalCheckbox}
                            handleSelectChange={handlevariantSelect}
                            handleOnSKUBlur={handleOnSKUBlur}
                            showSkuError={showSkuError}
                            variantEdit={modalType.variantEdit}
                        />
                    </React.Fragment>
                }
            </PopupModal>
            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />
        </React.Fragment>
    );
}

export default AddProduct