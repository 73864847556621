export default [
    {
        type: "image_file",
        id: "image_src",
        name: "image_src",
        label: "Gallery image",
        note: "",
        height: "150px",
        width: "100%",
        alt_name: "img_alt",
        alt: "",
        value: "https://checkout-dev.growcart.app/side-image.jpg"
    },
    {
        type: "text",
        name: "caption",
        label: "Caption",
        value: "Lorem ipsum dolor",
        placeholder: ""
    },
    {
        type: "text",
        name: "link",
        label: "Link",
        value: "",
        placeholder: "(Optional)"
    },
]