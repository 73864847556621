export default {
    CURRENCY: '₹',
    PRODUCTS_LIST: '/products/list',
    PRODUCTS: '/products',
    PRODUCTS_COUNT: '/products/product/count',
    CATEGORY_LIST: '/category/list',
    CATEGORY: '/category',
    CATEGORY_DETAIL: '/category/categoryproduct',
    PRODUCT_TYPE_LIST: '/products/product-type/list',
    BRAND_LIST: '/products/vendor/list',
    TAGS_LIST: '/products/product-tag/list',
    PRODUCT_VARIANTS: '/product_variants',
    PRODUCT_VARIANTS_LIST: '/product_variants/list',
    PRODUCT_VARIANTS_COUNT: '/product_variants/count',
    PRODUCT_IMAGES: '/product_images',
    PRODUCT_SKU: '/products/product/sku',
    USER_LIST: '/customers',
    USER_SEND_INVITE: '/customers/send_invite',
    USER_ADDRESS: '/customer_address',
    DISCOUNT_RULE: '/discount_rule',
    DISCOUNT_RULE_COUNT: '/discount_rule/count',
}