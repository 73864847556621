import React, { useEffect, useState, useRef } from 'react';
import {
    Box, Button, Grid, Menu, MenuItem, Card, CardContent, CardActionArea, CardMedia,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, Radio, FormLabel, RadioGroup, FormHelperText, Typography,
    FormGroup, Select, TextField, InputBase, Container, InputLabel,
    Avatar, ListItemAvatar, ListItemText, ListItemButton, ListItem, List, IconButton, OutlinedInput,
    Chip, Badge, styled, ListItemIcon
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Link } from "react-router-dom";

// icons 
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import PrintIcon from '@mui/icons-material/Print';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import bundleprod from '../../../../Assets/images/bundleprod.jpg';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import PopupModal from '../../../common/PopupModal/PopupModal';
import Countries from '../../../common/CommonJason/Countries';
import PhoneInput from 'react-phone-input-2'

import { useReactToPrint } from 'react-to-print';


import './OrderDetail.css';


// common components

// file drag drop  
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `1px solid ${theme.palette.background.paper}`
}));


const AddProduct = () => {
    const [anchorEl, setAnchorEl] = React.useState({
        button1: null,
        button2: null,
        moreActionBtn: null,
    });
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })
    const [selectCountry, setSelectCountry] = useState(103)
    const [countrycode, setCountrycode] = useState({
        phone: '91'
    })
    const [country, setCountry] = useState(103)
    const [addressData, setaddressData] = useState(addressInit)
    const [addressType, setAddressType] = useState("")

    const [body, setBody] = useState({
        status: "active",
        note: ""
    });
    let addressInit = {
        address1: "",
        address2: "",
        city: "",
        company: "",
        first_name: "",
        last_name: "",
        phone: "",
        province: "",
        country: "India",
        zip: "",
        country_code: "IN",
    }
    const products = [
        {
            id: 1,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "10inch Anime Cute Digital Monster Digimon Koromon Soft Plush Toy Doll Pillow",
            price: 20.00,
            quantity: 1,
            sku: "testBundle1",
            desc: "6 product set",
            variant: ["s", "red"],

        },
        {
            id: 2,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 20.00,
            quantity: 2,
            sku: "testBundle1",
            desc: "6 product set",
            variant: ["s", "yello"],

        },
        {
            id: 2,
            tag: "fulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 139.00,
            quantity: 3,
            sku: "testBundle1",
            desc: "6 product set",
            variant: ["s", "green"],

        },
    ];
    const open1 = Boolean(anchorEl.button1);
    const open2 = Boolean(anchorEl.button2);
    const open3 = Boolean(anchorEl.moreActionBtn);

    const handleClick = (event, data) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
    };
    const handleClose = () => {
        setAnchorEl({
            button1: null,
            button2: null,
            moreActionBtn: null,
        });
    };

    const ModalOpen = () => setOpenModal(true);
    const ModalClose = () => setOpenModal(false);
    const triggerModal = (name, title) => {
        setOpenModal(true);
        setModalContent({ "modalShowing": name, "modalTitle": title })
        console.log("modalContent.modalShowing", modalContent.modalShowing)
    }

    const countrySelect = (event) => {
        setSelectCountry(event.target.value);
    };

    const handleNumberChange = (value, data, type) => {
        if (type === "address") {
            setaddressData({
                ...addressData,
                'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`
            })
        } else {
            setBody({ ...body, 'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}` })
        }
    };
    const componentRef = useRef();
    const printOrderRef = useRef();
    const printPackegingSlip = useReactToPrint({
        content: () => componentRef.current,
    });
    const printOrderPage = useReactToPrint({
        content: () => printOrderRef.current,
    });

    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={6}>
                            <Button component={Link} variant="text" to="/orders" startIcon={<ArrowBackIosIcon />}> Orders</Button>

                            <Chip sx={{ ml: 1, height: "26px", fontSize: "12px" }} label="Paid" icon={<CircleIcon fontSize="12px" />} />
                            <Chip sx={{ ml: 1, height: "26px", fontSize: "12px" }} label="Unfulfilled" icon={<CircleOutlinedIcon fontSize="12px" />} style={{ backgroundColor: '#ffcc80' }} />
                            <Chip sx={{ ml: 1, height: "26px", fontSize: "12px" }} label="Archived" />
                            <Chip sx={{ ml: 1, height: "26px", fontSize: "12px" }} label="Return in progress" style={{ backgroundColor: "#ffee58" }} />

                            <Typography variant="caption" component="div" sx={{ pl: 1 }}>December 9, 2021 at 7:57 am from Online Store</Typography>
                        </Grid>
                        <Grid item md={6} sx={{ textAlign: "Right" }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", }} >
                                <Button component={Link} to="/orders/details/return" variant="text" color="primary" size="large" sx={{ ml: 1 }}>Return</Button>
                                <Button component={Link} to="/orders/details/refund" variant="text" color="primary" size="large" sx={{ ml: 1 }}>Refund</Button>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Button
                                    id="MoreAction"
                                    aria-controls={open3 ? "basic-MoreAction" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open3 ? "true" : undefined}
                                    onClick={(e) => handleClick(e, "moreActionBtn")}
                                    endIcon={<ArrowDropDownIcon />}
                                >
                                    More Action
                                </Button>
                                <Menu
                                    id="basic-MoreAction"
                                    anchorEl={anchorEl.moreActionBtn}
                                    open={open3}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "MoreAction"
                                    }} >
                                    <MenuItem onClick={printOrderPage}>
                                        <ListItemIcon>
                                            <PrintIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText> Print order page </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={printPackegingSlip}>
                                        <ListItemIcon>
                                            <PrintIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText> Print packing slips </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <ListItemIcon>
                                            <CancelOutlinedIcon fontSize="small" color="error" />
                                        </ListItemIcon>
                                        <ListItemText> Cancel fulfillment </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <ListItemIcon>
                                            <CancelOutlinedIcon fontSize="small" color="error" />
                                        </ListItemIcon>
                                        <ListItemText color="error" > Cancel order </ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={6}>
                                            <Typography variant="h6" component="div" gutterBottom
                                                sx={{ display: "flex", alignItems: "center" }}>
                                                <CheckCircleTwoToneIcon color="primary" sx={{ mr: 1 }} /> Fulfilled
                                                <Typography variant="subtitle1" component="span" sx={{ ml: 1 }} > 1281-F1</Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} sx={{ textAlign: "Right" }}></Grid>
                                    </Grid>
                                    <Box sx={{ mb: 1 }}>
                                        <Typography variant="body2" component="div"> Location</Typography>
                                        <Typography variant="body2" component="div"> Zirakpur Punjab India</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="body2" component="div"> Other tracking</Typography>
                                        <Typography variant="body2" component="div">fr424 </Typography>
                                    </Box>
                                    {products.map((data, index) => {
                                        return (
                                            <Box key={index} className="orderProductBox" sx={{ display: "flex", }}>
                                                <Box className="orderProductBox__img">
                                                    <Badge
                                                        overlap="circular"
                                                        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                        badgeContent={
                                                            <SmallAvatar sx={{ fontSize: 10, width: "auto", height: "auto", py: 0.5, px: 0.6 }} > {data.quantity} </SmallAvatar>
                                                        }  >
                                                        <Avatar alt="Sample user" src={data.img} variant="square" />
                                                    </Badge>
                                                </Box>
                                                <Box className="orderProductBoxCntnt" sx={{ display: "flex" }}>
                                                    <Box className="orderProductBoxCntnt__link">
                                                        <Link to="" color="inherit">{data.name}</Link>
                                                        <Typography variant="caption" component="div">{data.variant.join(' / ')} </Typography>
                                                        <Typography variant="caption" component="div">SKU: {data.sku} </Typography>
                                                    </Box>
                                                    <Box className="orderProductBoxCntnt__qntty" sx={{ minWidth: "120px", textAlign: "right" }}><Typography variant="subtitle1" component="div">₹{(data.price).toFixed(2)} × {data.quantity} </Typography> </Box>
                                                    <Box className="orderProductBoxCntnt__price"><Typography variant="subtitle1" component="div">₹{(data.price * data.quantity).toFixed(2)}</Typography> </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </CardContent>
                                <Divider />
                                <Box sx={{ p: 2, textAlign: "right" }}>
                                    <Button component={Link} to="/orders/details/restock" variant="contained" color="primary" sx={{ ml: 1 }}>Restock</Button>
                                    <Button variant="contained" color="primary" sx={{ ml: 1 }}
                                        onClick={() => triggerModal("addTracking", "Add tracking")}
                                    >Add tracking</Button>
                                </Box>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12} sx={{ mb: 1 }}>
                                        <Grid item md={6}>
                                            <Typography variant="p" component="strong" gutterBottom>Tracking</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle2" component="div">Tracking number </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>12122545</Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle2" component="div" >Shipping carrier</Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>Shipping carrier name </Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle2" component="div">Tracking URL </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>https://www.google.com/ </Typography> </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
                                        <Grid item md={6}>
                                            <Typography variant="h6" component="div" gutterBottom
                                                sx={{ display: "flex", alignItems: "center" }}>
                                                <CheckCircleTwoToneIcon color="primary" sx={{ mr: 1 }} /> Paid
                                                <Typography variant="subtitle1" component="span" sx={{ ml: 1 }} > 1281-F1</Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} sx={{ textAlign: "Right" }}>

                                        </Grid>
                                    </Grid>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle2" component="div"  >Subtotal </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>1 item</Typography> </Box>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>₹5.00 </Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle2" component="div" >Shipping </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>Standard Shipping (0.34 kg)</Typography> </Box>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>₹5.50 </Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="p" component="strong" >Total </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                            <Box> <Typography variant="subtitle1" component="strong" sx={{ ml: 1 }}>₹10.50</Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ mb: 1 }} />
                                    <Box className="ordertabular" sx={{ display: "flex", }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle2" component="div" sx={{ ml: 1 }}>Paid by customer </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}> ₹10.50</Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ pt: 1 }}>
                                        <Divider sx={{ mb: 1 }} />
                                        <Box sx={{ pt: 2 }}>
                                            <Typography variant="p" component="strong" gutterBottom >Payment summary</Typography>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Order</Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                <Typography variant="body2" component="div" >#1265 </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Authorization key</Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                <Typography variant="body2" component="div" >vJN0dkF3bBzBpPzzJvW4b07BxJbZY</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Amount</Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                <Typography variant="body2" component="div" >₹10.50</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Gateway</Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                <Typography variant="body2" component="div" > Squareup</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Status  </Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                <Typography variant="body2" component="div" >success </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Type  </Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                <Typography variant="body2" component="div" >sale </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" > Created </Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                <Typography variant="body2" component="div" > May 17, 2021, 2:33 pm</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                        <Grid item> <Typography variant="h6" component="h6" >Customer</Typography></Grid> 
                                    </Grid>
                                    <Typography variant="subtitle1" component="div" gutterBottom >dddddddd</Typography>
                                    <Box><Link to="" color="inherit">Villiam Singh</Link></Box>
                                    <Box><Link to="" color="inherit">8 orders</Link></Box>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                        <Grid item > <Typography variant="p" component="strong">Customer Information</Typography></Grid>
                                        <Grid item >
                                            <Button
                                                variant="text"
                                                className="noHover"
                                                sx={{ p: 0, justifyContent: "flex-end" }}
                                                onClick={() => triggerModal("customerInfo", "Edit contact information")}
                                            >Edit</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center"  >
                                        <Grid item > <Link to="" color="inherit">sharon@adessobrands.com </Link></Grid>
                                        <Grid item >
                                            <IconButton aria-label="copy" sx={{ width: "35px", height: "35px" }}>
                                                <ContentCopyOutlinedIcon sx={{ width: "15px" }} /></IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                        <Grid item > <Typography variant="p" component="strong">Shipping address </Typography></Grid>
                                        <Grid item >
                                            <Button variant="text" className="noHover"
                                                sx={{ p: 0, justifyContent: "flex-end" }}
                                                onClick={() => triggerModal("shippingAddress", "Edit shipping address")}
                                            >Edit</Button>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle1" component="div" > test test</Typography>
                                    <Typography variant="subtitle1" component="div" > 704 S Spring St Ste 1202</Typography>
                                    <Typography variant="subtitle1" component="div" > Los Angeles CA 90014</Typography>
                                    <Typography variant="subtitle1" component="div" > United States</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                        <Grid item > <Typography variant="p" component="strong">Billing Address </Typography></Grid>
                                        {/* <Grid item ><Button variant="text" className="noHover" sx={{ p: 0, justifyContent:"flex-end" }}>Edit</Button> </Grid> */}
                                    </Grid>
                                    <Typography variant="subtitle1" component="div" > Same as shipping address</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={12}>
                            <Divider sx={{ my: "15px" }}></Divider>
                            <Box sx={{ textAlign: "left" }}>
                                <Button variant="contained" color="error" size="large" >Delete order</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container >

            <Box sx={{ height: 0, width: 0, overflow: "hidden" }}>
                <Box ref={componentRef} >
                    <table className="packingslip" cellSpacing="0" cellPadding="0" style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <table align="left" cellSpacing="0" cellPadding="0" >
                                        <tbody>
                                            <tr>
                                                <td><Typography variant="p" component="strong">VSD22</Typography></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table align="right" cellSpacing="0" cellPadding="0" >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div><Typography variant="body2" component="div">Order #1265</Typography></div>
                                                    <div><Typography variant="body2" component="div">May 17, 2021</Typography></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table align="left" width="300" cellSpacing="0" cellPadding="0" >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="subtitle2" component="div" gutterBottom>SHIP TO</Typography>
                                                    <Typography variant="body2" component="div">Villiam JIt Singh</Typography>
                                                    <Typography variant="body2" component="div">704</Typography>
                                                    <Typography variant="body2" component="div">South Spring Street</Typography>
                                                    <Typography variant="body2" component="div">Los Angeles CA 90014</Typography>
                                                    <Typography variant="body2" component="div">United States</Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table align="left" width="300" cellSpacing="0" cellPadding="0" >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="subtitle2" component="div" gutterBottom>BILL TO</Typography>
                                                    <Typography variant="body2" component="div">Villiam JIt Singh</Typography>
                                                    <Typography variant="body2" component="div">704</Typography>
                                                    <Typography variant="body2" component="div">South Spring Street</Typography>
                                                    <Typography variant="body2" component="div">Los Angeles CA 90014</Typography>
                                                    <Typography variant="body2" component="div">United States</Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Divider sx={{ my: 2, borderBottomWidth: "2px" }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellSpacing="0" cellPadding="0" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <th align="left"> <Typography variant="subtitle2" component="strong">ITEMS </Typography></th>
                                                <th align="right"> <Typography variant="subtitle2" component="strong">QUANTITY </Typography></th>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <Box className='productPackegingSlip'>
                                                        <Box className="productPackegingSlip__img"><img src={bundleprod} alt="" /></Box>
                                                        <Box className="productPackegingSlip__cntnt" sx={{ px: 1 }}><Typography variant="body2" component="div">Unicorn Fruit Whipped Body Butter</Typography></Box>
                                                    </Box>
                                                </td>
                                                <td align="right" valign="top"><Typography variant="body2" component="div">1 of 1</Typography></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td><Typography variant="body2" component="div">There are other items from your order not included in this shipment.</Typography></td>
                            </tr>
                            <tr>
                                <td>
                                    <Divider sx={{ my: 2, borderBottomWidth: "2px" }} />
                                </td>
                            </tr>
                            <tr>
                                <td height="20"></td>
                            </tr>
                            <tr>
                                <td className="textCenter">
                                    <Typography variant="body2" component="div" gutterBottom>Thank you for shopping with us!</Typography>
                                    <Typography variant="subtitle2" component="strong" >vsd22</Typography>
                                    <Box sx={{ pt: 2 }}>
                                        <Typography variant="body2" component="div">624, Tower - A, 6th Floor, Bestech Business Towers, Sector-66, Mohali, 15432, Ukraine</Typography>
                                        <Typography variant="body2" component="div">Test@gmail.com</Typography>
                                        <Typography variant="body2" component="div">vsd22.myshopify.com</Typography>
                                    </Box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
            <Box sx={{ height: 0, width: 0, overflow: "hidden" }}>
                <Box ref={printOrderRef}>
                    <table className="packingslip" cellSpacing="0" cellPadding="0" style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <table align="left" className="printordertable__Left">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Typography className="floatLeft" variant="h6" component="div" >1281</Typography>
                                                        <Chip className="floatLeft" variant="outlined" sx={{ ml: 1, height: "26px", fontSize: "12px" }} label="Paid" icon={<CircleIcon fontSize="12px" />} />
                                                        <Chip className="floatLeft" variant="outlined" sx={{ ml: 1, height: "26px", fontSize: "12px" }} label="Paid" icon={<CircleIcon fontSize="12px" />} />
                                                        <Box sx={{ clear: "both" }}></Box>
                                                    </Box>
                                                    <Box>May 31, 2022 at 11:52 am from vs-shoipify</Box>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td height="15"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h6" component="div" gutterBottom
                                                        sx={{ display: "flex", alignItems: "center" }}>Fulfilled
                                                        <Typography variant="subtitle1" component="span" sx={{ ml: 1 }} > 1281-F1</Typography>
                                                    </Typography>
                                                    <Box sx={{ mb: 1 }}>
                                                        <Typography variant="body2" component="div"> Location</Typography>
                                                        <Typography variant="body2" component="div"> Zirakpur Punjab India</Typography>
                                                    </Box>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant="body2" component="div"> Other tracking</Typography>
                                                        <Typography variant="body2" component="div">fr424 </Typography>
                                                    </Box>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Box className="orderProductBox" sx={{ display: "flex", }}>
                                                        <Box className="orderProductBox__img">
                                                            <Badge
                                                                overlap="circular"
                                                                // anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                                badgeContent={
                                                                    <SmallAvatar sx={{ fontSize: 10, width: "auto", height: "auto", py: 0.5, px: 0.6 }} > 1 </SmallAvatar>
                                                                }  >
                                                                <Avatar alt="Sample user" src={bundleprod} variant="square" />
                                                            </Badge>
                                                        </Box>
                                                        <Box className="orderProductBoxCntnt" sx={{ display: "flex" }}>
                                                            <Box className="orderProductBoxCntnt__link">
                                                                <Link to="" color="inherit">Unicorn Fruit Whipped Body Butter</Link>
                                                                <Typography variant="caption" component="div">s / red</Typography>
                                                                <Typography variant="caption" component="div">SKU: TOY136 </Typography>
                                                            </Box>
                                                            <Box className="orderProductBoxCntnt__qntty" sx={{ minWidth: "120px", textAlign: "right" }}><Typography variant="subtitle1" component="div">₹10.00 × 1 </Typography> </Box>
                                                            <Box className="orderProductBoxCntnt__price"><Typography variant="subtitle1" component="div">₹10.00</Typography> </Box>
                                                        </Box>
                                                    </Box>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h6" component="div" gutterBottom>Paid</Typography>
                                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                        <Box className="ordertabular__title" >
                                                            <Typography variant="subtitle2" component="div"  >Subtotal </Typography>
                                                        </Box>
                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>1 item</Typography> </Box>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>₹5.00 </Typography> </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                        <Box className="ordertabular__title" >
                                                            <Typography variant="subtitle2" component="div" >Shipping </Typography>
                                                        </Box>
                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>Standard Shipping (0.34 kg)</Typography> </Box>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>₹5.50 </Typography> </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                        <Box className="ordertabular__title" >
                                                            <Typography variant="p" component="strong" >Total </Typography>
                                                        </Box>
                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                                            <Box> <Typography variant="subtitle1" component="strong" sx={{ ml: 1 }}>₹10.50</Typography> </Box>
                                                        </Box>
                                                    </Box>
                                                    <Divider sx={{ mb: 1 }} />
                                                    <Box className="ordertabular" sx={{ display: "flex", }}>
                                                        <Box className="ordertabular__title" >
                                                            <Typography variant="subtitle2" component="div" sx={{ ml: 1 }}>Paid by customer </Typography>
                                                        </Box>
                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}> ₹10.50</Typography> </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ pt: 1 }}>
                                                        <Divider sx={{ mb: 1 }} />
                                                        <Box sx={{ pt: 2 }}>
                                                            <Typography variant="p" component="strong" gutterBottom >Payment summary</Typography>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Order</Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >#1265 </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Authorization key</Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >vJN0dkF3bBzBpPzzJvW4b07BxJbZY</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Amount</Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >₹10.50</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Gateway</Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" > Squareup</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Status  </Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >success </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Type  </Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >sale </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" > Created </Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" > May 17, 2021, 2:33 pm</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table align="right" className="printordertable__right">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant="h6" component="h6" gutterBottom>Customer</Typography>
                                                        <Box><Link to="" color="inherit">Villiam Singh</Link></Box>
                                                        <Box><Link to="" color="inherit">8 orders</Link></Box>
                                                    </Box>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant="p" component="strong" gutterBottom>Customer Information</Typography>
                                                        <Box><Link to="" color="inherit">sharon@adessobrands.com</Link></Box>
                                                        <Typography variant="subtitle1" component="div">No phone number</Typography>
                                                    </Box>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant="p" component="strong" gutterBottom>Shipping address</Typography>
                                                        <Typography variant="subtitle1" component="div">test test</Typography>
                                                        <Typography variant="subtitle1" component="div">704 S Spring St Ste 1202</Typography>
                                                        <Typography variant="subtitle1" component="div">Los Angeles CA 90014</Typography>
                                                        <Typography variant="subtitle1" component="div">United States</Typography>
                                                    </Box>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant="p" component="strong">Billing Address</Typography>
                                                        <Typography variant="subtitle1" component="div" >Same as shipping address</Typography>
                                                    </Box>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt="Save"
            // hideFooter={modalContent.modalShowing === "manageUser" ? true : false}

            // savebtnFunct={savebtnFunct} 
            >
                {modalContent.modalShowing === "customerInfo" ?
                    <Box>
                        <Box sx={{ mb: 2.5 }}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="customerInfo"
                                    type="text"
                                    id="storename"
                                    name="storename"
                                    size="small"
                                    defaultValue="villiam22@yopmail.com"
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ mb: 2.5 }}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Phone number"
                                    type="text"
                                    id="legalName"
                                    name="legalName"
                                    size="small"
                                    defaultValue="Vsd"
                                />
                            </FormControl>
                        </Box>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Update customer profile" />
                    </Box>
                    : null}
                {modalContent.modalShowing === "shippingAddress" ?
                    <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Country/region</InputLabel>
                                <Select
                                    labelId="countryRegion"
                                    id="countryRegion"
                                    value={selectCountry}
                                    label="Country/region"
                                    size="small"
                                    onChange={countrySelect}
                                >
                                    {Countries.map((data, index) => {
                                        return <MenuItem key={index} value={index}>{data.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="First name"
                                    type="text"
                                    id="prodPrice"
                                    name="price"
                                    size="small"
                                    // onChange={handleOnchange}
                                    // onBlur={getprofit}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Last name"
                                    type="text"
                                    id="comparePrice"
                                    size="small"
                                    name="compare_at_price"
                                    sx={{ m: 0, width: '100%' }}
                                // onChange={handleOnchange}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Company"
                                    id="Company"
                                    name="Company"
                                    type="text"
                                    size="small"
                                    // onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Address"
                                    id="Address"
                                    name="Address"
                                    type="text"
                                    size="small"
                                    // onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Apartment, suite, etc."
                                    id="Apartment"
                                    name="Apartment"
                                    type="text"
                                    size="small"
                                    // onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="City"
                                    id="City"
                                    name="City"
                                    type="text"
                                    size="small"
                                    // onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="State"
                                    id="State"
                                    name="State"
                                    type="text"
                                    size="small"
                                    // onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="PIN code"
                                    id="pin"
                                    name="PIN"
                                    type="text"
                                    size="small"
                                    // onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <PhoneInput
                                    enableSearch={true}
                                    disableAreaCodes={false}
                                    country="in"
                                    value={body.phone}
                                    inputExtrastate={{ name: 'mobile', required: true }}
                                    inputProps={{ maxLength: '17' }}
                                    onChange={(e, x) => handleNumberChange(e, x, "body")}
                                    isValid={(value, data) => {
                                        if (
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length < 9 &&
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length > 0
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    placeholder=""
                                    countryCodeEditable={false}
                                    defaultMask=".. ... ...."
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : null}

                {modalContent.modalShowing === "addTracking" ? <>

                    <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>

                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Tracking number"
                                    type="text"
                                    id="storename"
                                    name="storename"
                                    size="small"

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Shipping carrier"
                                    type="text"
                                    id="legalName"
                                    name="legalName"
                                    size="small"

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Tracking URL"
                                    type="text"
                                    id="trackingURL"
                                    name="trackingURL"
                                    size="small"
                                    helperText="Enter the tracking page link"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Send notification email to customer" />



                </> : null}
            </PopupModal>
        </>
    );
}

export default AddProduct