export default [
    {
        type: "text",
        name: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "range",
        label: "Items per row",
        id: "item_per_row",
        name: "item_per_row",
        value: 3,
        min: 2,
        max: 4,
        step: 1,
        unit: ""
    },
    {
        type: "list_item",
        label: "Column",
    },
]