import React, { useEffect, useState, useRef } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, Typography, Select, TextField, Container,
    Avatar, Badge, InputLabel, RadioGroup, Radio, Paper
} from '@mui/material';
import { Link } from "react-router-dom";
// icons  
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import ArticleIcon from '@mui/icons-material/Article';
// images
import bundleprod from '../../../../../Assets/images/bundleprod.jpg';
// common components
import Quantity from '../../../../common/Quantity/Quantity';
import SingleFileupload from '../../../../common/Fileupload/SingleFileupload';


const Return = () => {
    const [location, setlocation] = useState("UNKNOWN");
    const [shippingStat, setshippingStat] = useState("shipping");
    const [selectedFile, setSelectedFile] = useState("")
    const [preview, setPreview] = useState("")
    const [fileName, setFileName] = useState(null)
    const [returnLabel, setReturnLabel] = useState(false)



    const products = [
        {
            id: 1,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "Unicorn Fruit Whipped Body ButterUnicorn Fruit Whipped Body ButterUnicorn Fruit Whipped Body Butter",
            price: 20.00,
            totalItems: 2,
            quantity: 0,
            SKU: "testBundle1",
            desc: "6 product set"

        },
        {
            id: 2,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 20.00,
            totalItems: 2,
            quantity: 0,
            SKU: "testBundle1",
            desc: "6 product set"

        },
        {
            id: 3,
            tag: "fulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 139.00,
            totalItems: 1,
            quantity: 0,
            sku: "testBundle1",
            desc: "6 product set"

        },
    ];
    const handleQtyChange = (e) => {
        console.log("handleQtyChange", e)
    }
    const updateQty = (e, val) => { }
    const handleLocationChange = (event) => {
        setlocation(event.target.value);
    };
    const handleShippingChange = (event) => {
        setshippingStat(event.target.value);
    };
    const controlShippingProps = (item) => ({
        checked: shippingStat === item,
        onChange: handleShippingChange,
        value: item,
        name: "shippingLabel",
        inputProps: { "aria-label": item }
    });

    const onFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setSelectedFile(file)
        reader.onloadend = () => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
        console.log("file", file)

        setFileName(file)
    }


    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={6}>
                            <Button component={Link} variant="text" to="/orders/details" startIcon={<ArrowBackIosIcon />}> Return</Button>
                        </Grid>
                        <Grid item md={6} sx={{ textAlign: "Right" }}></Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                                        <CheckCircleTwoToneIcon color="primary" sx={{ mr: 1 }} /> Fulfilled
                                    </Typography>

                                    {products.filter((cntnt) => cntnt.tag === "Unfulfilled").map((data, index) => {
                                        return (
                                            <Box key={index} className="orderProductBox" sx={{ display: "flex", }}>
                                                <Box className="orderProductBox__img">
                                                    <Badge overlap="circular">
                                                        <Avatar alt="Sample user" src={data.img} variant="square" />
                                                    </Badge>
                                                </Box>
                                                <Box className="orderProductBoxCntnt" sx={{ display: "flex" }}>
                                                    <Box className="orderProductBoxCntnt__link">
                                                        <Typography variant="subtitle2" component="div">{data.name}</Typography>

                                                        <Typography variant="body2" component="div">₹{(data.price).toFixed(2)} </Typography>
                                                    </Box>
                                                    <Box className="orderProductBoxCntnt__qntty">
                                                        <Quantity
                                                            quantity={data.quantity || 0}
                                                            handleQtyChange={(e) => handleQtyChange(e, data.quantity)}
                                                            incriment={() => updateQty(data.quantity, data.quantity + 1)}
                                                            decrement={() => updateQty(data.quantity, data.quantity - 1)}
                                                            endorement={`/${data.totalItems}`}
                                                        />
                                                        {/* totalItems */}
                                                    </Box>
                                                    <Box className="orderProductBoxCntnt__price" sx={{ pt: 1.2 }}><Typography variant="body2" component="div">₹{(data.price).toFixed(2)} </Typography> </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                    <Box sx={{ pt: 2 }}>
                                        <FormControl fullWidth size="small" >
                                            <InputLabel id="demo-simple-select-label">Select a return reason</InputLabel>
                                            <Select
                                                labelId="selectLocation-label"
                                                id="selectLocation"
                                                value={location}
                                                label="Select a return reason"
                                                onChange={handleLocationChange}
                                            >
                                                <MenuItem value="">
                                                    <em>Select a reason</em>
                                                </MenuItem>
                                                <MenuItem value="UNKNOWN">Unknown</MenuItem>
                                                <MenuItem value="SIZE_TOO_SMALL">Size was too small</MenuItem>
                                                <MenuItem value="SIZE_TOO_LARGE">Size was too large</MenuItem>
                                                <MenuItem value="UNWANTED">Customer changed their mind</MenuItem>
                                                <MenuItem value="NOT_AS_DESCRIBED">Item not as described</MenuItem>
                                                <MenuItem value="WRONG_ITEM">Received the wrong item</MenuItem>
                                                <MenuItem value="DEFECTIVE">Damaged or defective</MenuItem>
                                                <MenuItem value="STYLE">Style</MenuItem>
                                                <MenuItem value="COLOR">Color</MenuItem>
                                                <MenuItem value="OTHER">Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>

                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom sx={{ mb: 3 }}> Return shipping options</Typography>
                                    <Box  >
                                        <label>
                                            <Radio {...controlShippingProps("shipping")} size="small" /> Upload a return label
                                        </label>
                                    </Box>
                                    {fileName !== null ?
                                        <>
                                            <Box sx={{ mb: 2, display: "flex", alignItems: "center" }} className="documentHolderBox">
                                                <Box className="documentHolderBox__icon" ><ArticleIcon color="primary" sx={{ display: "block" }} /></Box>
                                                <Box className="documentHolderBox__cntnt" sx={{ flexGrow: 1, }}>
                                                    <Typography variant="p" component="div"> {fileName.name}</Typography>
                                                    <Typography variant="p" component="span">
                                                        {parseFloat(fileName.size) < 1000 ? `${parseFloat(fileName.size)} Bytes`
                                                            : parseFloat(fileName.size) > 1000 && parseFloat(fileName.size) < 1000000 ?
                                                                `${parseFloat(parseFloat(fileName.size) / 1000).toFixed(2)} KB`
                                                                : parseFloat(fileName.size) > 1000000 ? `${parseFloat(parseFloat(fileName.size) / 1000000).toFixed(2)} MB` : ""
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </>
                                        : null}
                                    {shippingStat === "shipping" ?
                                        <>
                                            {returnLabel ? <Box sx={{ mb: 2, mt: 2 }}>
                                                <TextField
                                                    required
                                                    id="tracking-number"
                                                    label="Return label URL"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Box> : <Box sx={{ mb: 2, mt: 2 }}><SingleFileupload
                                                preview={preview}
                                                onFileChange={onFileChange}
                                                typeFile
                                            /></Box>}
                                            <Button className="noHover" variant="text" sx={{ mb: 2 }} onClick={() => setReturnLabel(!returnLabel)}>
                                                {returnLabel ? " or upload a return label file" : "or add a return label URL"}
                                            </Button> 
                                            <Grid container spacing={2} columns={12}>
                                                <Grid item md={6}>
                                                    <TextField
                                                        required
                                                        id="tracking-number"
                                                        label="Tracking number"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={6} >
                                                    <TextField
                                                        required
                                                        id="tracking-url"
                                                        label="Tracking URL"
                                                        helperText="Enter the tracking page link"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid> 
                                            </Grid> 
                                        </> : null}
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Box>
                                        <label>
                                            <Radio {...controlShippingProps("noShipping")} size="small" />
                                            No shipping required
                                        </label>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                        <Grid item> <Typography variant="h6" component="h6" >Summary</Typography></Grid>
                                    </Grid>

                                    <ul>
                                        <li>
                                            <Typography variant="body1" component="div"> Returning 1 item </Typography>
                                            <Typography variant="body2" component="div">Reason: Size was too large</Typography>
                                        </li>
                                    </ul>

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={12}>
                            <Divider sx={{ my: "15px" }}></Divider>
                            <Box sx={{ textAlign: "left" }}>
                                <Button variant="contained" color="error" size="large" >Cancel</Button>
                                <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Create return</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container >

        </>
    );
}

export default Return