import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, IconButton, Container, Checkbox, Popover,
} from '@mui/material'
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { DataGrid } from '@mui/x-data-grid';
import { createTheme } from '@mui/material/styles';

import './Orders.css'

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: 0,
    marginLeft: 0,
    width: '200px',
    [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        maxWidth: '100%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        borderBottom: "1px solid #d9d9d9",
        // borderRadius:"10px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

// dropdown  

const handleToggleAllCompleted = (e) => {
    console.log("e", e);
};

const handleToggleCompleted = (e) => {
    console.log("e", e);
};


const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});



export default function Orders() {
    // dropdown btn
    let navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState({
        customer: null,
        sortby: null,
        date: null,
        status: null,
        paymentStatus: null,
    });
    const [activeTab, setactiveTab] = useState("all")
    const [selectionModel, setSelectionModel] = useState([]);

    // popover
    const [activeId, setactiveId] = useState("")
    const openPopover = (event, id) => {
        setAnchorEl({ ...anchorEl, 'customer': event.currentTarget });
        setactiveId(id)
        // setAnchorEl(event.currentTarget);
    };

    const popoverClose = (event, id) => {
        setAnchorEl({ ...anchorEl, ['customer']: null });
        setactiveId(null)
        // setAnchorEl(null);
    };


    const columns = [
        {
            field: 'orderNo', headerName: 'Order ',
            renderCell: (params) => <Button component={Link} to="/orders/details" color="primary" className='noHover'>{params.value}</Button>, width: 130, sortable: false,
        },
        { field: 'date', headerName: 'Date', sortable: false, width: 150, },
        {
            field: 'customer', headerName: 'Customer', sortable: false, width: 200,
            renderCell: (params) =>
                <>
                    {/* {console.log("activeId == params.id: ", activeId, "==", params.id)} */}
                    <Button aria-describedby={params.id} variant="text" onClick={(e) => openPopover(e, params.id)} endIcon={<ArrowDropDownIcon />}>
                        {params.value}  {params.id}
                    </Button>
                    <Popover
                        id={params.id}
                        open={activeId == params.id}
                        anchorEl={anchorEl.customer}
                        onClose={(e) => popoverClose(e, params.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }} >
                        <Typography component="div" sx={{ p: 2 }}>

                            <Typography variant="body1" component="div"> {params.value}</Typography>
                            <Typography variant="caption" component="div"> New York NY, United States </Typography>
                            <Typography variant="caption" component="div">8 orders </Typography>
                            <Typography variant="caption" sx={{ mb: 1 }} component="div">
                                <a href="mailto:villiamjit@weoinfotech.com" color="secondary">villiamjit@weoinfotech.com</a>
                            </Typography>
                            <Button variant="outlined" >View customer</Button>
                        </Typography>
                    </Popover>
                </>
            // 
        },
        { field: 'total', headerName: 'Total', sortable: false, width: 130, },
        {
            field: 'paymentStatus', headerName: 'Payment status', sortable: false, width: 130,
            renderCell: (params) => <Chip icon={
                params.value === "Paid" ? <CircleIcon fontSize="12px" /> : <CircleOutlinedIcon fontSize="12px" />}
                label={params.value}
                variant=" "
            />
        },
        {
            field: 'fulfillmentStatus', headerName: 'Fulfillment  status', sortable: false, width: 150,
            renderCell: (params) => <Chip
                label={params.value}
                variant=" "
                // color={params.value === "Fulfilled" ? "info" : "secondary"}
                style={{ backgroundColor: params.value === "Fulfilled" ? '#a5d6a7' : '#ffcc80' }}
            />
        },
        { field: 'items', headerName: 'Items', sortable: false, width: 130, },
        { field: 'deliverymethod', headerName: 'Delivery method', sortable: false, width: 300, },


    ]
    const rows = [
        { id: 1, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 2, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 3, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 4, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 5, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 6, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 7, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 8, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 9, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 10, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 12, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 13, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 14, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 15, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 16, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 17, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 18, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 19, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 20, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 21, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 22, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 22, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 24, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 25, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },

        { id: 26, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 27, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 28, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 29, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 30, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 31, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 32, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 33, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 34, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 35, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 36, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 37, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 38, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 39, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 40, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 41, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 42, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 43, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 44, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 45, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 46, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Villiam JIt Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Fulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 47, orderNo: "1212120", date: "May 4 at 2:25 pm", customer: "Amandeep Singh", total: "₹270.37", paymentStatus: "Paid", fulfillmentStatus: "Unfulfilled", items: '5 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 48, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Ravi Dass", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Fulfilled", items: '3 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 49, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },
        { id: 50, orderNo: "121212", date: "May 4 at 2:25 pm", customer: "Satyam", total: "₹270.37", paymentStatus: "Refunded", fulfillmentStatus: "Unfulfilled", items: '4 items', deliverymethod: 'Free Shipping (4 to 7 business days)', },



    ];
    const tabs = [
        { label: "All", name: "all" },
        { label: "Unfulfilled", name: "unfulfilled" },
        { label: "Unpaid", name: "unpaid" },
        { label: "Delivered", name: "delivered" },
    ]

    const [sortby, setSortby] = useState([
        { name: "Oldest to newest", key: "created", value: "asc", active: false },
        { name: "Newest to oldest", key: "created", value: "desc", active: false },

    ])
    const [sortDate, setSortDate] = useState([
        { name: "Today", key: "created", value: "asc", active: false },
        { name: "Last 7 days", key: "created", value: "asc", active: false },
        { name: "Last 30 days", key: "created", value: "asc", active: false },
        { name: "Last 90 days", key: "created", value: "asc", active: false },
        { name: "Last 12 months", key: "created", value: "asc", active: false },

    ])
    const [paymentStatus, setPaymentStatus] = useState([
        { name: "Paid", key: "created", value: "asc", active: false },
        { name: "Partially refunded", key: "created", value: "asc", active: false },
        { name: "Pending", key: "created", value: "asc", active: false },
        { name: "Refunded", key: "created", value: "asc", active: false },
        { name: "Unpaid", key: "created", value: "asc", active: false },
    ])

    const [sortStatus, setSortStatus] = useState([
        { name: "Open", key: "title", value: "asc", active: false },
        { name: "Archived", key: "title", value: "desc", active: false },
        { name: "Cancelled", key: "created_at", value: "asc", active: false },
    ])

    const handleClick = (event, data) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
    };
    const handleClose = () => {
        setAnchorEl({
            customer: null,
            sortby: null,
            date: null,
            status: null,
            paymentStatus: null,
        });
        // setAnchorEl({...anchorEl, data : null});
    };
    const clickTab = (name) => {
        setactiveTab(name);
    };
    const editProduct = () => {
        navigate("/products/edit-product")
    };


    const openSort = Boolean(anchorEl.sortby);
    const openDate = Boolean(anchorEl.date);
    const openStatus = Boolean(anchorEl.status);
    const openPaymentStatus = Boolean(anchorEl.paymentStatus);

    const popOpen = Boolean(anchorEl.customer);
    // popover

    console.log("anchorEl", anchorEl)
    console.log("activeId", activeId)

    // dropdown btn
    return (
        <React.Fragment>
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg" className="orderPage">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Orders </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Button component={Link} to="/orders/create" variant="contained">Create order</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: 2, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={2} columns={12} alignItems="center">
                            <Grid item lg={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: 'column-reverse', lg: 'row' },
                                    }}
                                >
                                    <ButtonGroup size="small" variant="outlined" color="primary" aria-label="medium success button group">
                                        {tabs.map((tab) => {
                                            return (
                                                <Button key={tab.name} variant={`${activeTab === tab.name ? 'contained' : 'outlined'}`} onClick={() => clickTab(tab.name)}>{tab.label}</Button>
                                            )
                                        })}
                                    </ButtonGroup>
                                    <Search sx={{
                                        mb: { xs: 2, lg: 0 }
                                    }}>
                                        <SearchIconWrapper>
                                            <SearchIcon color="grey" />
                                        </SearchIconWrapper>
                                        <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                                    </Search>
                                </Box>
                            </Grid>
                            <Grid item lg={6}>
                                <Box sx={{ textAlign: "Right", display: "flex", justifyContent: "flex-end" }}>
                                    <Box>
                                        <Button
                                            id="status"
                                            aria-controls={openStatus ? 'statusArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openStatus ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            onClick={(e) => handleClick(e, "status")}
                                            size="small"
                                            endIcon={<KeyboardArrowDownIcon />
                                            }>
                                            Status
                                        </Button>
                                        <StyledMenu
                                            id="statusArea"
                                            MenuListProps={{ 'aria-labelledby': 'status', }}
                                            anchorEl={anchorEl.status}
                                            open={openStatus}
                                            onClose={handleClose}>

                                            {sortStatus.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={handleClose} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                            {/* {dropdowndata.map()} */}
                                        </StyledMenu>
                                    </Box>
                                    <Box>
                                        <Button
                                            id="paymentstatue"
                                            aria-controls={openPaymentStatus ? 'paymentstatueArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openPaymentStatus ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            onClick={(e) => handleClick(e, "paymentStatus")}
                                            endIcon={<KeyboardArrowDownIcon />}>
                                            Payment Status
                                        </Button>

                                        <StyledMenu
                                            id="paymentstatueArea"
                                            MenuListProps={{ 'aria-labelledby': 'paymentstatue', }}
                                            anchorEl={anchorEl.paymentStatus}
                                            open={openPaymentStatus}
                                            onClose={handleClose}>

                                            {paymentStatus.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={handleClose} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box>
                                    <Box>
                                        <Button
                                            id="discountCodeType"
                                            aria-controls={openDate ? 'discountCodeTypeArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openDate ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            onClick={(e) => handleClick(e, "date")}
                                            endIcon={<KeyboardArrowDownIcon />}>
                                            Date
                                        </Button>

                                        <StyledMenu
                                            id="discountCodeTypeArea"
                                            MenuListProps={{ 'aria-labelledby': 'discountCodeType', }}
                                            anchorEl={anchorEl.date}
                                            open={openDate}
                                            onClose={handleClose}>

                                            {sortDate.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={handleClose} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box>
                                    <Box>
                                        <Button
                                            id="sortButton"
                                            aria-controls={openSort ? 'sortButtonArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openSort ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            onClick={(e) => handleClick(e, "sortby")}
                                            endIcon={<KeyboardArrowDownIcon />}>
                                            Sort by
                                        </Button>
                                        <StyledMenu
                                            id="sortButtonArea"
                                            MenuListProps={{ 'aria-labelledby': 'sortButton', }}
                                            anchorEl={anchorEl.sortby}
                                            open={openSort}
                                            onClose={handleClose}>

                                            {sortby.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={handleClose} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}

                                            {/* <MenuItem onsClick={handleClose} disableRipple>Newest to oldest</MenuItem> */}
                                        </StyledMenu>

                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="discountTable" sx={{ width: '100%' }}>
                        <Box
                        // sx={{ width: "100%",   }}
                        >
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                checkboxSelection
                                disableColumnMenu
                                disableSelectionOnClick={true}
                                autoHeight={true}
                                density={"comfortable"}

                                // rowHeight={60}
                                // headerHeight={0}
                                // onSelectionModelChange={(itm) => onTableCheckcl(itm)}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                className="ordersTable"
                                selectionModel={selectionModel}
                                sx={{
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                }}
                            />

                        </Box>
                    </Box>
                </Card>
            </Container>
        </React.Fragment>
    )
}

