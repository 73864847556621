export default [
    {
        type: "text",
        name: "heading",
        id: "heading",
        label: "Heading",
        value: "Blog posts",
        placeholder: ""
    },
    {
        type: "heading",
        text: "Blog"
    },
    {
        type: "nav_list",
        label: "Static blog",
        current: {
            name: "News",
            link: ""
        }
    },
    {
        type: "select",
        label: "",
        name: "blog_category",
        value: "news_blog",
        items: [
            { name: "News", value: "news_blog" },
            { name: "Fashion", value: "fashion_blog" }
        ]
    },
    {
        type: "divider"
    },
    {
        type: "range",
        label: "Show posts",
        id: "show_posts",
        name: "show_posts",
        value: 3,
        min: 2,
        max: 6,
        step: 1,
        unit: ""
    },
    {
        type: "checkbox",
        id: "show_date",
        name: "show_date",
        label: "Show date",
        value: true
    },
    {
        type: "checkbox",
        id: "show_author",
        name: "show_author",
        label: "Show author",
        value: true
    },
]