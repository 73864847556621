import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, CardContent, IconButton, Container, Checkbox, ListItemIcon, Pagination, LinearProgress,
    FormControl, TextField, FormHelperText, RadioGroup, FormControlLabel, Radio, Select,
    InputLabel, ListSubheader, Autocomplete
} from '@mui/material'


import moment from "moment";

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Seo from '../../../../common/Seo/Seo';
import CreatableSelect from '../../../../AutoComplete/CreatableSelect';
import SingleFileupload from '../../../../common/Fileupload/SingleFileupload';

import CustomEditor from '../../../../common/CustomEditor/CustomEditor';
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export default function AddBlog() {
    let navigate = useNavigate()
    const [body, setBody] = useState({
        slug: "",
        title: "",
        body_html: "<p></p>",
        metafields_title: "",
        metafields_description: "",
        visibilityDate: moment(new Date()),
        showvisibilityDate: false,
        author: "",
        blog: "",

    })
    const [selectedFile, setSelectedFile] = useState("")
    const [preview, setPreview] = useState("")
    const [themeTemp, setThemeTemp] = useState('');
    const [dataVal, setData] = useState("");
    const [contentState, setcontentState] = useState()
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [visibility, setVisibility] = useState([
        { name: "Visible", key: "visible", active: false },
        { name: "Hidden", key: "hidden", active: true },
    ]);



    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const tagList = [
        { title: 'Vintage', },
        { title: 'cotton ', },
        { title: 'summer', },

    ];


    const handelSelectchange = (event) => {
        setBody({ ...body, [event.target.name]: event.target.value });
    }
    console.log("body.blog", body);


    const handleThemeTempChange = (event) => {
        setThemeTemp(event.target.value);
    };
    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const updateTextDescription = async (state) => {
        await setEditorState(state);
    };
    const onContentStateChange = (contentState) => {
        setcontentState(draftToHtml(contentState))
        setBody({ ...body, 'body_html': draftToHtml(contentState) });
        setData(draftToHtml(contentState))
    }
    const handleHtmlChange = (val) => {
        setData(val)
        const contentBlock = htmlToDraft(val);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            setBody({ ...body, 'body_html': val });
        }
    }
    const currentDate = new Date().toLocaleString();
    const toggleVisibility = (data) => {
        console.log("data", data)
        let updatemenu = [];
        visibility.map((data) => {
            return updatemenu.push({ ...data, 'active': false })
        })
        updatemenu[data].active = true
        setVisibility(updatemenu)
    }

    const changeVisibilityState = () => {
        setBody({ ...body, 'showvisibilityDate': !body.showvisibilityDate })
    }

    const handleDateChange = (newValue) => {
        setBody({ ...body, 'visibilityDate': newValue });
    };
    const onFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setSelectedFile(file)
        reader.onloadend = () => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
    }


    const handleMultipleSelect = (e, val, reason, id) => {

    }


    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" to="/store/blogs" startIcon={<ArrowBackIosIcon />}>Blogs</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Button variant="text" startIcon={<VisibilityOutlinedIcon />}>View</Button>
                             <Button variant="contained" color="primary" size="large" sx={{ ml: 2 }}>Save</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Title</Typography>
                                    <FormControl fullWidth  >
                                        <TextField
                                            id="title"
                                            name="title"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            variant="outlined"
                                        // value={discountCode} 
                                        // onChange={updateDiscountCode}
                                        />
                                    </FormControl>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Content</Typography>
                                    <CustomEditor
                                        editorState={editorState}
                                        updateTextDescription={updateTextDescription}
                                        onContentStateChange={onContentStateChange}
                                        handleHtmlChange={handleHtmlChange}
                                        value={dataVal}
                                    />
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Excerpt</Typography>
                                    <Typography variant="subtitle1" component="div" gutterBottom>Add a summary of the post to appear on your home page or blog. </Typography>
                                    <FormControl fullWidth sx={{ mb: 2 }} >
                                        <TextField  
                                        //  label="Item name" 
                                         variant="outlined" size="small" />
                                    </FormControl>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Seo page={"blogs"} handleOnchange={handleOnchange} body={body} title="metafields_global_title_tag" description="metafields_global_description_tag" />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom >Visibility</Typography>
                                    <FormControl sx={{ mb: 2, }}>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"   >

                                            {visibility.map((data, index) => {
                                                return (
                                                    <FormControlLabel key={index} value={data.key} control={
                                                        <Radio
                                                            size="small"
                                                            checked={data.active}
                                                            onChange={() => toggleVisibility(index)}
                                                        />}
                                                        label={
                                                            `${data.name} 
                                                            ${data.key == "visible" && data.active == true ? `(as of ${currentDate})` : ""} 
                                                            ${data.key == "hidden" && data.active == true && body.showvisibilityDate == true ? `(will become visible on ${currentDate})` : ""} `}
                                                    />
                                                )
                                            })}
                                        </RadioGroup>
                                    </FormControl>

                                    {body.showvisibilityDate ?
                                        <Box sx={{ pb: 1, }} >
                                            <LocalizationProvider dateAdapter={DateAdapter} >
                                                <DateTimePicker
                                                    label="Visibility date"
                                                    value={body.visibilityDate}
                                                    onChange={(e) => handleDateChange(e, "start")}
                                                    // minDateTime={new Date()}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                    disablePast

                                                />
                                            </LocalizationProvider>
                                        </Box> : null}
                                    <Box>
                                        <Button variant="text" onClick={changeVisibilityState}>{body.showvisibilityDate ? "Clear date" : "Set visibility date"}</Button>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent >
                                    <Typography variant="h6" component="h6" gutterBottom >Featured image </Typography>

                                    <SingleFileupload
                                        preview={preview}
                                        onFileChange={onFileChange}
                                    />
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent sx={{ pt: 3 }}>
                                    <Box sx={{ mb: 2.5 }}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="authorLabel">Author</InputLabel>
                                            <Select
                                                labelId="authorLabel"
                                                id="author"
                                                name="author"
                                                value={body.author}
                                                label="Author"
                                                onChange={handelSelectchange} >
                                                <MenuItem value={1}>Villiam Jit Singh</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {/* <Box sx={{ mb: 2 }}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="BlogLabel">Blog</InputLabel>
                                            <Select
                                                labelId="BlogLabel"
                                                id="Blog"
                                                value={body.blog}
                                                label="Blog"
                                                onChange={(e) => handelSelectchange(e, "blog")} >
                                                <ListSubheader>News </ListSubheader>
                                                <MenuItem value={1}>News</MenuItem>
                                                <MenuItem value={2}>Sports</MenuItem>
                                                <ListSubheader>Action</ListSubheader>
                                                <MenuItem value={3}>Create a new blog</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box> */}


                                    <Box sx={{ mb: 2 }}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="BlogLabel">Category</InputLabel>
                                            <Select
                                                labelId="BlogLabel"
                                                id="Blog"
                                                value={body.blog}
                                                label="Category"
                                                name="blog"
                                                onChange={handelSelectchange} >

                                                <MenuItem value={1}>News</MenuItem>
                                                <MenuItem value={2}>Sports</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Divider sx={{ mb: 2 }} />
                                    <Typography variant="h6" component="h6" gutterBottom>Tags</Typography>
                                    <Box>
                                        <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={tagList}
                                            disableCloseOnSelect
                                            size="small"
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            style={{ width: "100%", }}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Vintage, cotton, summer" />
                                            )}
                                        />

                                    </Box>
                                </CardContent>
                            </Card>
                            {/* <Card sx={{ mb: 2 }}>
                                <CardContent >
                                    <Typography variant="h6" component="h6" gutterBottom >Online store </Typography>

                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Theme template </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={themeTemp}
                                            label="Theme template"
                                            onChange={handleThemeTempChange} >
                                            <MenuItem value={10}>Default blog</MenuItem>
                                        </Select>
                                        <FormHelperText sx={{ mt: 1, mx: 0 }}> Assign a template from your current theme to define how the blog post is displayed.</FormHelperText>

                                    </FormControl>
                                </CardContent>
                            </Card> */}
                           
                        </Grid>
                    </Grid>
                </Box>
                <Grid item md={12}>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Box sx={{ textAlign: "right" }}>
                        <Button variant="contained" color="error" size="large" onClick={() => navigate("/store/blogs")}>Cancel</Button>
                        <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                    </Box>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

