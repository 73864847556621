import React, { useCallback, useEffect, useState } from "react"
import { ListItem, ListItemButton, ListItemIcon, ListItemText, List } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { renderImport } from "../../../../../../../Container/Exports";
import { ImageCard } from "../../../../../../Pages/Product/ImageCard";
import update from "immutability-helper";

export default function ListItems({ data, addNewItem, editItem, array, activeDetail, disableBtn, deleteItem }) {
    const params = new URLSearchParams(window.location.search)
    console.log("activeDetail: ", activeDetail)
    const [list, setList] = useState([])
    const moveOption = useCallback((dragIndex, hoverIndex) => {
        console.log(dragIndex, hoverIndex)
        setList((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]]
                ]
            })
        )
    }, [list])

    useEffect(() => {
        setList(array)
    }, [list])

    console.log("list: ", list)

    return (
        <List sx={{ m: 0, p: 0 }} className="list_items">
            {list.length > 0 && list.map((opt, index) => {
                return (
                    <React.Fragment key={index.toString()}>
                        <ListItem disablePadding className={`item_drag ${params.get('item') === opt.id.toString() || params.get('column') === opt.id.toString() ? "active_item" : ""}`}>
                            <ImageCard
                                index={index}
                                id={opt.id}
                                text={
                                    <ListItemButton sx={{ p: 0.5, width: "100%", borderBottom: '#e1e1e1 1px solid' }}>
                                        <ListItemIcon sx={{ minWidth: "30px" }}>
                                            <DragIndicatorIcon fontSize="small" />
                                        </ListItemIcon>
                                        {/* <ListItemText primary={`${data.label} ${opt.id}`} /> */}
                                        <ListItemText primary={data.label} />
                                        <ListItemIcon>
                                            <EditIcon fontSize="inherit" sx={{ minWidth: "30px" }} onClick={() => editItem(opt)} />
                                            <DeleteIcon fontSize="inherit" sx={{ minWidth: "30px" }} color="error" onClick={() => deleteItem(opt)} />
                                        </ListItemIcon>
                                    </ListItemButton>
                                }
                                moveCard={moveOption}
                            />
                        </ListItem>
                        {params.get('item') == opt.id.toString() || params.get('column') == opt.id.toString() ?
                            renderImport(activeDetail)
                            : null
                        }
                    </React.Fragment>
                )
            })}
            {!disableBtn &&
                <ListItem disablePadding>
                    <ListItemButton sx={{ p: 0.5, borderBottom: '#eee 1px solid' }} onClick={addNewItem}>
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                            <AddCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Add new" />
                    </ListItemButton>
                </ListItem>
            }
        </List>
    )
}