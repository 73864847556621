import axios from "axios";
import { useEffect, useRef } from "react";

export const Api = axios.create({
    baseURL: `https://shoprep.webexpertsonline.org/v1`
});

export const getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const regExp2 = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    const match = url.match(regExp);
    const match2 = url.match(regExp2);
    if (match !== null && match2 === null) {
        return (match && match[2].length === 11)
            ? match[2]
            : null;
    } else if (match === null && match2 !== null) {
        return "vimeo"
    }
}
export const getData = () => {
    fetch("http://localhost:3000/db").then(res => res.json()).then(result => {
        console.log("result-------------", result)
        return result
    }
    )
}

export const useUnload = fn => {
    const cb = useRef(fn);
    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = (...args) => cb.current?.(...args);

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
};

//Handle API response
function handleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response
    }
    if (response.status === 500 || response.status === 404) {
        return { response: { data: { error: response.data.error } }, error: true }
    }
    else {
        return { response: { data: { error: response.data.error.message } }, error: true }
    }
}

//API methods
export const GetApi = (url) => {
    return Api.get(url).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: {
                    error: err.response && err.response.data ? (err.response.status === 500 ? err.response.data.error : err.response.data.error.message) : "Something went wrong"
                }
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApi = async (url, body) => {
    return Api.post(url, body).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: {
                    error: err.response && err.response.data ? (err.response.status === 500 ? err.response.data.error : err.response.data.error.message) : "Something went wrong"
                }
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApi = async (url, body) => {
    return Api.put(url, body).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: {
                    error: err.response && err.response.data ? (err.response.status === 500 ? err.response.data.error : err.response.data.error.message) : "Something went wrong"
                }
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const DeleteApi = async (url) => {
    return Api.delete(url).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: {
                    error: err.response && err.response.data ? (err.response.status === 500 ? err.response.data.error : err.response.data.error.message) : "Something went wrong"
                }
            },
            res: err.response || "Something went wrong"
        }
    })
}