import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Button, Box, Card, CardContent, Container, Checkbox,
    FormControl, TextField, FormControlLabel,

} from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload';


import { grey } from '@mui/material/colors';
import "./Preferences.css"
// file drag drop  

const Preferences = () => {
    let navigate = useNavigate()
    const [body, setBody] = useState({
        slug: "",
        title: "",
        body_html: "<p></p>",
        metafields_title: "",
        metafields_description: "",
        author: "",
        blog: "",

    })

    const [preview, setPreview] = useState("")
    const [selectedFile, setSelectedFile] = useState("")


    const onFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setSelectedFile(file)
        reader.onloadend = () => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const grey4 = grey[500];




    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Preferences </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}> </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 2, pb: 1 }}>
                                        <Typography variant="h6" component="div" gutterBottom>Title and meta description </Typography>
                                        <Typography variant="body1" gutterBottom>Please enter your domain so our customer support vefify that and you will use that store after verification</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="title"
                                                name="title"
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                label="Homepage title"
                                                variant="outlined"
                                                placeholder="Add title"
                                                helperText="0 of 320 characters used"
                                            // onChange={updateDiscountCode}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="title"
                                                name="title"
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                label="Homepage meta description"
                                                variant="outlined"
                                                placeholder="Enter a description to get a better ranking on search engines like Google"
                                                helperText="0 of 70 characters used"
                                                multiline
                                                rows={4}
                                            // onChange={updateDiscountCode}
                                            />
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 2, pb: 1 }}>
                                        <Typography variant="h6" component="div" gutterBottom>Google Analytics
                                            <Typography variant="body1" component="span" sx={{ ml: 0.5 }}>
                                                <a href="https://analytics.google.com/analytics/web/provision/#/provision" target="_blank">Setup guide</a>
                                            </Typography>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>Google Analytics enables you to track the visitors to your store, and generates reports that will help you with your marketing.
                                        </Typography>
                                    </Box>

                                    <Box sx={{ mb: 2 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="title"
                                                name="title"
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                label="Google Analytics code"
                                                variant="outlined"
                                                placeholder="Paste your code from Google here"
                                                helperText="0 of 70 characters used"
                                                multiline
                                                rows={4}
                                            // onChange={updateDiscountCode}
                                            />
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Facebook Pixel</Typography>
                                    <Typography variant="body1" gutterBottom>Facebook Pixel tracks customer behavior on your online store. This data improves your marketing abilities, including retargeting ads.</Typography>
                                    <Box sx={{ pt: 1 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="domain"
                                                name="domain"
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                variant="outlined"
                                                label="Facebook Pixel Id"
                                            // onChange={updateDiscountCode}
                                            />
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="h6" component="div" gutterBottom>Social sharing image  </Typography>
                                        <Typography variant="body1" gutterBottom>When you share a link to your store on social media, an image is usually shown in your post. This one will be used if another relevant image can’t be found. Image (Recommended size: 1200 x 628 px) </Typography>
                                    </Box>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={8}>
                                            <Card>
                                                <CardContent>
                                                    <Grid container spacing={2} columns={12} sx={{ alignItems: "center" }}>
                                                        <Grid item md={4}>
                                                            <Box className="fileUploader fileUploader__prefrence socialSharingImage">
                                                                <Box className="customFileUpload">
                                                                    <input
                                                                        accept=".jpg,.jpeg,.png,.webp,.svg"
                                                                        type='file'
                                                                        onChange={onFileChange}
                                                                    />
                                                                    <label>
                                                                        {preview && preview !== "" &&
                                                                            <div className="filePreviewBox__img" >
                                                                                <img src={preview} />
                                                                            </div>
                                                                        }
                                                                        {preview && preview !== "" ? null :
                                                                            <Box>
                                                                                <FileUploadIcon fontSize='small' sx={{ verticalAlign: "middle" }} />
                                                                                {preview && preview !== "" ? 'Change' : 'Choose'} image
                                                                            </Box>
                                                                        }
                                                                    </label>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={8}>
                                                            <Box>
                                                                <Typography variant="subtitle2" gutterBottom component="div" sx={{}}> Page title </Typography>
                                                                <Typography variant="p" gutterBottom component="div" sx={{ color: grey4, }}> https://xxx.mystore.com </Typography>
                                                                <Typography variant="p" gutterBottom component="div" sx={{ color: grey4, }}> Meta description</Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="h6" component="div" gutterBottom>Password protection  </Typography>
                                        <Typography variant="body1" gutterBottom>Enable the password to restrict access to your online store. Only customers with the password can access it</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Enable password" />
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="password"
                                                name="title"
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                label="Password"
                                                variant="outlined"
                                                placeholder="Password"
                                                helperText="0 of 100 characters used"
                                            // onChange={updateDiscountCode}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="title"
                                                name="title"
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                label="Message for your visitors"
                                                variant="outlined"
                                                helperText="0 of 5000 characters used"
                                                multiline
                                                rows={4}
                                            // onChange={updateDiscountCode}
                                            />
                                        </FormControl>
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Grid item md={12}>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Box sx={{ textAlign: "right" }}>
                        <Button variant="contained" color="error" size="large" onClick={() => navigate("/store/blogs")}>Cancel</Button>
                        <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                    </Box>
                </Grid>
            </Container >
        </React.Fragment >
    );
}

export default Preferences