import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, IconButton, Container, ListItemIcon, Pagination, LinearProgress, Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import ReactPlayer from "react-player"

import SearchIcon from '@mui/icons-material/Search';
import noImage from '../../../Assets/images/no_image.jpg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Check from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { DataGrid } from '@mui/x-data-grid';
import { tab } from '@testing-library/user-event/dist/tab';
import * as Api from "../../../Container/config/Apis"
import config from '../../../Container/config';
import _ from "underscore";
import { useSnackbar } from 'notistack';
import DialogAlert from '../../common/Alerts/Dialog';
import "./Product.css";

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: "20px !important",
    // width: '200px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <Box sx={{ mt: 15 }}>
                <Typography variant="h5" component="div">No result found!</Typography>
            </Box>
        </StyledGridOverlay>
    );
}

// dropdown
export default function Product() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [showDialog, setOpen] = React.useState(false);
    const [loadingData, setloadingData] = React.useState(true);
    const [loadingBar, setloadingBar] = React.useState(true);
    const [count, setCount] = useState(0)
    const [totalProduct, setTotalProduct] = useState(0)
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });
    const [id, setId] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [activeTab, setactiveTab] = useState("all")
    const [limit, setLimit] = useState(50)
    const [params, setParams] = useState({
        search: "",
        status: "",
        product_type: "",
        sort_key: "",
        sort_value: "desc",
        page: 1
    })
    const [product, setProduct] = useState([
        { id: 0, image: noImage, productname: "Product name", status: "Active", inventory: "0 in stock for 4 variants", type: "", action: "" }
    ])
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const closeDialog = () => {
        setId("")
        setOpen(false);
    }

    const columns = [
        {
            field: 'id', headerName: 'ID', width: "60",
            renderCell: (params) => loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> : <Typography variant="p" component="strong">{params.value}</Typography>, sortable: false,
        },
        {
            field: 'image', headerName: 'Image', width: "80",
            renderCell: (params) => loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                Api.getId(params.value) === null || Api.getId(params.value) === undefined ?
                    <img src={params.value} width="50" style={{ border: "1px solid #eee", borderRadius: "3px", maxHeight: "50px", cursor: "pointer" }} onClick={() => navigate(`/products/${params.row.id}`)} />
                    :
                    <ReactPlayer
                        url={params.value}
                        controls={false}
                        config={{
                            youtube: {
                                playerVars: { showinfo: 0 }
                            }
                        }}
                        onClick={() => navigate(`/products/${params.row.id}`)}
                        className="video_preview_product"
                        width={"100%"}
                        height={"100%"}
                        playsinline={true}
                        playbackRate={0}
                        light={true}
                    />, sortable: false,
        },
        {
            field: 'productname', headerName: 'Product name',
            renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <Button component={Link} to={`/products/${params.row.id}`} color="primary" variant="text" className='noHover'>{params.value}</Button>
            ,
            flex: 1, sortable: false,
        },
        {
            field: 'status', headerName: 'Status', sortable: false, width: "100", renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <Chip label={params.value} variant="contained" color={params.value === "Active" ? "success" : "info"} />
        },
        { field: 'inventory', headerName: 'Inventory', flex: 1, renderCell: (params) => loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> : null, sortable: false, },
        { field: 'type', headerName: 'Type', sortable: false, renderCell: (params) => loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> : null, flex: 1 },
        {
            field: 'action', headerName: 'Action', sortable: false, width: "150",
            renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <>
                        <IconButton aria-label="view" color="primary"><VisibilityIcon /></IconButton>
                        <IconButton aria-label="edit" color="success" onClick={() => editProduct(params.value)}><EditIcon /></IconButton>
                        <IconButton aria-label="delete" color="error" onClick={() => deleteProduct(params.value)}><DeleteIcon /></IconButton>
                    </>
        },
    ]

    const tabs = [
        { label: "All", name: "all" },
        { label: "Active", name: "active" },
        { label: "Draft", name: "draft" },
        { label: "Archived", name: "archived" },
    ]
    const [options, setOptions] = useState([
        { name: "Product title A-Z", key: "title", value: "asc", active: false },
        { name: "Product title Z-A", key: "title", value: "desc", active: false },
        { name: "Created (oldest first)", key: "created_at", value: "asc", active: false },
        { name: "Created (newest first)", key: "created_at", value: "desc", active: false },
    ])
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    //Status on change
    const clickTab = (name) => {
        setloadingData(true)
        setactiveTab(name);
        if (name !== "all") {
            setParams({ ...params, 'status': name })
            setparam("status", name)
        } else {
            setParams({ ...params, 'status': "" })
            deleteparam("status")
        }
    };

    //Search change
    const [search, setSearch] = useState('')
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }
    const handleSearchBlur = (e) => {
        setloadingData(true)
        if (search !== "") {
            setParams({ ...params, 'search': search })
            setparam("search", search)
        } else {
            setParams({ ...params, 'search': "" })
            deleteparam("search")
        }
    }

    //Sort by on change
    const handleSortChange = (val) => {
        setloadingData(true)
        let newArr = []
        options.map((opt) => {
            if (opt.name === val.name) {
                newArr.push({ ...opt, 'active': true })
                setParams({ ...params, "sort_key": val.key, "sort_value": val.value })
                setparam("sort_key", val.key)
                setparam("sort_value", val.value)
            } else {
                newArr.push({ ...opt, 'active': false })
            }
        })
        setOptions(newArr)
        handleClose()
    }

    //Clear sort by filter
    const clearSortBy = () => {
        setloadingData(true)
        let newArr = []
        setParams({ ...params, "sort_key": "", "sort_value": "" })
        deleteparam("sort_key")
        deleteparam("sort_value")
        options.map((opt) => {
            newArr.push({ ...opt, 'active': false })
        })
        setOptions(newArr)
    }
    const handleChangePage = (_event, newPage) => {
        setloadingData(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };

    const editProduct = (id) => {
        navigate(`/products/${id}`)
    };

    //Get product count
    const getProductCount = () => {
        Api.GetApi(config.PRODUCTS_COUNT).then(res => {
            console.log("res count: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                setTotalProduct(res.data.data)
                let newCount = Math.ceil(parseInt(res.data.data) / limit)
                if (newCount !== 0) {
                    setCount(newCount)
                } else {
                    setCount(1)
                }
            }
        })
    }

    //Get product list
    const getProductList = (param) => {
        Api.GetApi(`${config.PRODUCTS_LIST}${param}`).then(res => {
            setTimeout(() => {
                setloadingData(false)
                setloadingBar(false)
            }, 500);
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                res.data.data && res.data.data.map((data, index) => {
                    let qty = 0
                    let qtyArray = []
                    data.variants.map((opt) => {
                        qtyArray.push(opt.inventory_quantity)
                    })
                    qty = _.reduce(qtyArray, function (memo, num) { return memo + num; }, 0);
                    let url = noImage
                    if (data.images.length > 0) {
                        url = _.sortBy(data.images, "position")[0].src
                    }

                    arr.push({
                        id: data.id,
                        image: url,
                        productname: data.title,
                        status: data.status,
                        inventory: `${qty} in stock for ${data.variants.length} variants`,
                        type: data.product_type || "-",
                        action: data.id
                    })
                })
                setProduct(arr)
            }
        })
    }

    const onPageSizeChange = (e) => {
        setLimit(e)
    }

    const deleteFn = () => {
        Api.DeleteApi(`${config.PRODUCTS}/${id}`).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                closeDialog()
                getProductList(location.search)
            }
        })
    }

    const deleteProduct = (id) => {
        setId(id)
        setContent({ ...content, 'message': 'You want to delete this product.' })
        setOpen(true)
    }
    const confirmDialog = () => {
        deleteFn()
    }


    useEffect(() => {
        let newArr = []

        searchParams.set("page", params.page)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getProductList(location.search)
        setactiveTab(searchParams.get('status') || "all")
        //Set SortBy active value
        if (searchParams.get("sort_key") || searchParams.get("sort_value")) {
            options.map((obj) => {
                if (obj.key === searchParams.get("sort_key") && obj.value === searchParams.get("sort_value")) {
                    newArr.push({ ...obj, 'active': true })
                } else {
                    newArr.push({ ...obj, 'active': false })
                }
            })
            setOptions(newArr)
        }
    }, [limit, params])

    useEffect(() => {
        getProductCount()
        if (searchParams.get("search")) {
            setSearch(searchParams.get("search"))
        }
    }, [])


    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Products ({totalProduct})</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Button component={Link} to="/products/add" variant="contained">Add product</Button>
                        </Grid>
                    </Grid>
                </Box>

                <Divider sx={{ mb: 2 }} />
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: 2, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={2} columns={12}>

                            <Grid item xs={6}>
                                <Box sx={{ display: "flex" }}>
                                    <ButtonGroup variant="outlined" color="primary" aria-label="medium success button group">
                                        {tabs.map((tab) => {
                                            return (
                                                <Button key={tab.name} variant={`${activeTab === tab.name ? 'contained' : 'outlined'}`} onClick={() => clickTab(tab.name)}>{tab.label}</Button>
                                            )
                                        })}
                                    </ButtonGroup>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon color="grey" />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search', value: search }}
                                            onBlur={handleSearchBlur}
                                            onChange={handleSearchChange}
                                        />
                                    </Search>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: "Right" }}>
                                    {searchParams.get("sort_key") || searchParams.get("sort_value") ?
                                        <Button variant='text' color="error" size="small" sx={{ mr: 2 }} endIcon={<ClearIcon />} onClick={clearSortBy}>Clear</Button>
                                        : null
                                    }
                                    <Button
                                        id="sortButton"
                                        aria-controls="sortButtonArea"
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="text"
                                        disableElevation
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDownIcon />}>
                                        Sort by
                                    </Button>
                                    <StyledMenu
                                        id="sortButtonArea"
                                        MenuListProps={{ 'aria-labelledby': 'sortButton', }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}>
                                        {options.map((opt) => {
                                            return (
                                                <MenuItem key={opt.name} onClick={() => handleSortChange(opt)} disableRipple className={`sort_list ${opt.active ? "active" : ""}`} sx={{ fontSize: "12px" }}>
                                                    {opt.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </StyledMenu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%', px: 1 }}>
                        <DataGrid
                            sx={{ border: 0 }}
                            rows={product}
                            columns={columns}
                            pageSize={limit}
                            onPageSizeChange={(e) => onPageSizeChange(e)}
                            rowsPerPageOptions={[]}
                            checkboxSelection={false}
                            disableColumnMenu
                            disableSelectionOnClick={true}
                            autoHeight={true}
                            density={"standard"}
                            rowHeight={60}
                            hideFooterPagination={true}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loadingData}
                        />
                        <Pagination count={count} showFirstButton showLastButton sx={{ p: 2, float: "right" }} onChange={handleChangePage} />
                    </Box>
                </Card>
            </Container>
            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />
        </React.Fragment>
    )
}

