import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, FormHelperText, Typography, FormGroup, Select, TextField, InputBase, Container, OutlinedInput, InputLabel, Chip, Alert, AlertTitle, Stack
} from '@mui/material';
import config from '../../../../Container/config';
// file drag drop  

export default function VariantOptions({ body, showAlert, handleOnchange, handleCheckbox, handleSelectChange, handleOnSKUBlur, showSkuError, variantEdit }) {
    return (
        <React.Fragment>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" component="h6" sx={{ mb: 2 }}>Pricing</Typography>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <InputLabel htmlFor="price">Price</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    id="price"
                                    name="price"
                                    value={body.price || ""}
                                    size="small"
                                    error={showAlert && body.price === "" ? true : false}
                                    onChange={handleOnchange}
                                    // onBlur={getprofit}
                                    sx={{ m: 0, width: '100%' }}
                                    startAdornment={<InputAdornment position="start">{config.CURRENCY}</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <InputLabel htmlFor="compare_at_price">Compare with price</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    id="compare_at_price"
                                    name="compare_at_price"
                                    value={body.compare_at_price}
                                    size="small"
                                    onChange={handleOnchange}
                                    // onBlur={getprofit}
                                    sx={{ m: 0, width: '100%' }}
                                    startAdornment={<InputAdornment position="start">{config.CURRENCY}</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider sx={{ my: 1 }}></Divider>
                <CardContent>
                    <Typography variant="h6" component="h6" gutterBottom>Inventory</Typography>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="SKU (Stock Keeping Unit) "
                                    id="sku"
                                    name="sku"
                                    value={body.sku || ""}
                                    error={(showAlert && body.sku === "") || showSkuError ? true : false}
                                    onChange={handleOnchange}
                                    onBlur={handleOnSKUBlur}
                                    helperText={showSkuError ? <Alert variant="outlined" severity="error" sx={{ border: 0, p: 0, background: "none !important" }}>SKU already used!</Alert> : ""}
                                    size="small"
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    size="small"
                                    label="Barcode (ISBN, UPC, GTIN, etc.) at price"
                                    id="barcode"
                                    name="barcode"
                                    value={body.barcode || ""}
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ paddingTop: "15px" }}>
                            <FormGroup>
                                <FormControlLabel name="track" control={<Checkbox checked={body.track ? "checked":""} onChange={handleCheckbox} />} label="Track quantity" />
                                {body.track &&
                                    <FormControlLabel name="out_of_stock" control={<Checkbox checked={body.out_of_stock ? "checked":""} onChange={handleCheckbox} />} label="Continue selling when out of stock" />
                                }
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Typography variant="h6" component="h6" gutterBottom>Quantity</Typography>
                    {body.track ?
                        <FormControl fullWidth sx={{ m: 0 }}>
                            <TextField
                                label="Available"
                                type="number"
                                id="quantity"
                                name="quantity"
                                value={variantEdit ? body.inventory_quantity : body.quantity || 0}
                                onChange={handleOnchange}
                                size="small"
                            />
                        </FormControl>
                        :
                        <Chip label="Not tracked" />
                    }
                </CardContent>
            </Card>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" component="h6" gutterBottom>Weight</Typography>
                    <Typography variant="body1" gutterBottom>Used to calculate shipping rates at checkout and label prices during fulfillment.</Typography>
                    <TextField
                        label="Weight"
                        type="number"
                        id="weight"
                        name="weight"
                        value={body.weight || 0}
                        onChange={handleOnchange}
                        size="small"
                        sx={{ mt: 1, }}
                        className="customheightNumber"
                    />
                    <FormControl sx={{ m: 1 }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={body.weight_unit || "kg"}
                            size="small"
                            name="weight_unit"
                            onChange={handleSelectChange}
                            sx={{ m: 0, marginLeft: "8px" }} >
                            <MenuItem value={"kg"}>Kg</MenuItem>
                            <MenuItem value={"lbs"}>LBS</MenuItem>
                            <MenuItem value={"pound"}>Pound</MenuItem>
                        </Select>
                    </FormControl>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}