import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import * as heading from "./Typography/Exports"

export default function HeadingBlock() {
    const dataVal = useSelector((state) => state.dataValue.components.heading_block)
    return (
        <Grid container spacing={2} justifyContent="center" sx={{ pb: 3 }} id="heading_block">
            <Grid item sm={12} md={8} >
                <Box sx={{ textAlign: 'center' }}>
                    {dataVal && dataVal.size === "large" ?
                        <heading.HeadingH1
                            ishtml={false}
                            type="div"
                            data={dataVal && dataVal.heading}
                        /> :
                        dataVal && dataVal.size === "medium" ?
                            <heading.HeadingH2
                                ishtml={false}
                                type="div"
                                data={dataVal && dataVal.heading}
                            /> :
                            <heading.HeadingH3
                                ishtml={false}
                                type="div"
                                data={dataVal && dataVal.heading}
                            />
                    }
                    {dataVal && dataVal.subheading &&
                        <>
                            {dataVal && dataVal.size === "large" ?
                                <heading.HeadingH5
                                    ishtml={true}
                                    type="div"
                                    data={dataVal && dataVal.subheading}
                                /> :
                                dataVal && dataVal.size === "medium" ?
                                    <heading.HeadingH6
                                        ishtml={true}
                                        type="div"
                                        data={dataVal && dataVal.subheading}
                                    /> :
                                    <Typography variant="p" component="div" dangerouslySetInnerHTML={{ __html: dataVal && dataVal.subheading }}>
                                    </Typography>
                            }
                        </>
                    }
                </Box>

            </Grid>
        </Grid>
    )
}