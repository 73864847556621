export default [
    {
        type: "text",
        id: "heading",
        name: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "select",
        label: "Choose category",
        name: "product_category",
        value: "",
        items: [
            { name: "Category 1", value: "1" },
            { name: "Category 2", value: "2" },
            { name: "Category 3", value: "3" },
            { name: "Category 4", value: "4" }
        ]
    },
    {
        type: "divider"
    },
    {
        type: "heading_flex",
        text: "Total products",
        name: "total_products",
        value: ""
    },
    {
        type: "number",
        id: "show_products",
        name: "show_products",
        label: "Show products",
        value: "",
        placeholder: ""
    },
    {
        type: "range",
        label: "Items per row",
        id: "item_per_row",
        name: "item_per_row",
        value: 3,
        min: 2,
        max: 4,
        step: 1,
        unit: ""
    },
    {
        type: "checkbox",
        id: "is_slider",
        name: "is_slider",
        label: "Show as slider",
        value: false
    },
]