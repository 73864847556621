import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Geocode from "react-geocode";
import * as heading from "./Typography/Exports"

export default function MapBlock() {
    const dataVal = useSelector((state) => state.dataValue.components.map)
    // console.log("map dataVal: ",dataVal)
    // useEffect(() => {
    //     Geocode.setApiKey("AIzaSyDse0zu_eGm4rzlV9_D8r73cTol0SFfrNY");
    //     Geocode.setLanguage("en");
    //     Geocode.setRegion("es");
    //     Geocode.setLocationType("ROOFTOP");
    //     Geocode.enableDebug();
    //     Geocode.fromAddress("301 Front St W, Toronto").then(
    //         (response) => {
    //             console.log("response : ", response)
    //         })
    // }, [])
    return (
        <Box id="map" sx={{ position: 'relative' }}>
            <img src={dataVal && dataVal.image} alt="" className="full-img" />
            <Box className="map_address_outer">
                <Container maxWidth="lg" sx={{ height: "100%", py: 3 }}>
                    <Grid
                        container
                        spacing={4}
                        alignItems={dataVal && dataVal.vertical === "top" ? "flex-start" : dataVal.vertical === "bottom" ? "flex-end" : "center"}
                        justifyContent={dataVal && dataVal.horizontal === "left" ? "flex-start" : dataVal.horizontal === "right" ? "flex-end" : "center"}
                        sx={{ height: "100%" }}
                    >
                        <Grid item sm={4}>
                            <Box className="map_address_block">
                                <heading.HeadingH5
                                    ishtml={false}
                                    type="div"
                                    data={dataVal && dataVal.heading}
                                />
                                <Typography variant="p" component="div" dangerouslySetInnerHTML={{ __html: dataVal && dataVal.address_text }}>
                                </Typography>
                                <Button variant="contained" size="large">{dataVal && dataVal.button_label}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}