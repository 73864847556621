export default [
    {
        id: 123456789,
        title: "Testing product",
        slug: "testing-product",
        price: 100.00,
        vendor: "vsd22",
        image: "https://checkout-dev.growcart.app/side-image.jpg"
    },
    {
        id: 123456349,
        title: "Testing product",
        slug: "testing-product",
        price: 100.00,
        vendor: "vsd22",
        image: "https://checkout-dev.growcart.app/side-image.jpg"
    },
    {
        id: 123452389,
        title: "Testing product",
        slug: "testing-product",
        price: 100.00,
        vendor: "vsd22",
        image: "https://checkout-dev.growcart.app/side-image.jpg"
    },
    {
        id: 1234457689,
        title: "Testing product",
        slug: "testing-product",
        price: 100.00,
        vendor: "vsd22",
        image: "https://checkout-dev.growcart.app/side-image.jpg"
    },
    {
        id: 123785789,
        title: "Testing product",
        slug: "testing-product",
        price: 100.00,
        vendor: "vsd22",
        image: "https://checkout-dev.growcart.app/side-image.jpg"
    },
    {
        id: 123758789,
        title: "Testing product",
        slug: "testing-product",
        price: 100.00,
        vendor: "vsd22",
        image: "https://checkout-dev.growcart.app/side-image.jpg"
    },
]