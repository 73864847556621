import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

export function Overlay() {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <Box className="bg_overlay"
            sx={{
                background: dataVal.components.colors.overlay_bg_color,
                opacity: dataVal.components.colors.overlay_opacity / 100
            }}
        ></Box>
    )
}
export function HeadingH1({ data, ishtml, type }) {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <>
            {ishtml ?
                <Typography variant="h1" component={type}
                    sx={{
                        mb: 2,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: dataVal.components.typography.heading_base_size * 2,
                        color: dataVal.components.colors.heading_color
                    }}
                    dangerouslySetInnerHTML={{ __html: data }}
                >
                </Typography>
                :
                <Typography variant="h1" component={type}
                    sx={{
                        mb: 2,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: dataVal.components.typography.heading_base_size * 2,
                        color: dataVal.components.colors.heading_color
                    }}
                >
                    {data}
                </Typography>
            }
        </>
    )
}
export function HeadingH2({ data, ishtml, type }) {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <>
            {ishtml ?
                <Typography variant="h2" component={type}
                    sx={{
                        mb: 2,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size / 1.5),
                        color: dataVal.components.colors.heading_color
                    }}
                    dangerouslySetInnerHTML={{ __html: data }}
                >
                </Typography>
                :
                <Typography variant="h2" component={type}
                    sx={{
                        mb: 2,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size * 1.5),
                        color: dataVal.components.colors.heading_color
                    }}
                >
                    {data}
                </Typography>
            }
        </>
    )
}
export function HeadingH3({ data, ishtml, type }) {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <>
            {ishtml ?
                <Typography variant="h3" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: dataVal.components.typography.heading_base_size,
                        color: dataVal.components.colors.heading_color
                    }}
                    dangerouslySetInnerHTML={{ __html: data }}
                >
                </Typography>
                :
                <Typography variant="h3" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: dataVal.components.typography.heading_base_size,
                        color: dataVal.components.colors.heading_color
                    }}
                >
                    {data}
                </Typography>
            }
        </>
    )
}
export function HeadingH4({ data, ishtml, type }) {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <>
            {ishtml ?
                <Typography variant="h4" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size / 1.3),
                        color: dataVal.components.colors.heading_color
                    }}
                    dangerouslySetInnerHTML={{ __html: data }}
                >
                </Typography>
                :
                <Typography variant="h4" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size / 1.3),
                        color: dataVal.components.colors.heading_color
                    }}
                >
                    {data}
                </Typography>
            }
        </>
    )
}
export function HeadingH5({ data, ishtml, type }) {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <>
            {ishtml ?
                <Typography variant="h5" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size / 1.6),
                        color: dataVal.components.colors.heading_color
                    }}
                    dangerouslySetInnerHTML={{ __html: data }}
                >
                </Typography>
                :
                <Typography variant="h5" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size / 1.6),
                        color: dataVal.components.colors.heading_color
                    }}
                >
                    {data}
                </Typography>
            }
        </>
    )
}
export function HeadingH6({ data, ishtml, type }) {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <>
            {ishtml ?
                <Typography variant="h6" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size / 1.8),
                        color: dataVal.components.colors.heading_color
                    }}
                    dangerouslySetInnerHTML={{ __html: data }}
                >
                </Typography>
                :
                <Typography variant="h6" component={type}
                    sx={{
                        mb: 1,
                        fontFamily: dataVal.components.typography.heading_font,
                        fontSize: parseInt(dataVal.components.typography.heading_base_size / 1.8),
                        color: dataVal.components.colors.heading_color
                    }}
                >
                    {data}
                </Typography>
            }
        </>
    )
}