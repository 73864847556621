
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';

import Header from '../Components/Header/Header';
import Sidebar from '../Components/Sidebar/Sidebar';
import { drawerWidth } from './Exports';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Home from '../Components/Pages/Home/Home';
import Product from '../Components/Pages/Product/Product';
import AddProduct from '../Components/Pages/Product/AddProduct/AddProduct';
import EditProduct from '../Components/Pages/Product/EditProduct/EditProduct';
import AddVariant from '../Components/Pages/Product/EditProduct/AddVariant/AddVariant';
import ScrollToTop from './ScrollToTop';

import Layout from '../Components/ThemeEditor/Layout/Layout';

import './App.css';
import Inventory from '../Components/Pages/Product/Inventory/Inventory';
import Category from '../Components/Pages/Product/Category/Category';
import AddCategory from '../Components/Pages/Product/Category/AddCategory/AddCategory';
import EditCategory from '../Components/Pages/Product/Category/EditCategory/EditCategory';
import Discount from '../Components/Pages/Discount/Discount';
import AddDiscount from '../Components/Pages/Discount/AddDiscount/AddDiscount';

import User from '../Components/Pages/Users/Users';
import AddUser from '../Components/Pages/Users/AddUser/AddUser';
import EditUser from '../Components/Pages/Users/EditUser/EditUser';

import StoreDetails from '../Components/Pages/Settings/StoreDetails/StoreDetails';
import Plan from '../Components/Pages/Settings/Plan/Plan';
import UserAndPermission from '../Components/Pages/Settings/UserAndPermission/UserAndPermission';
import Payments from '../Components/Pages/Settings/Payments/Payments';
import Notifications from '../Components/Pages/Settings/Notifications/Notifications';
import CustomizeEmail from '../Components/Pages/Settings/Notifications/CustomizeEmail/CustomizeEmail';

import Orders from '../Components/Pages/Orders/Orders';
import CreateOrder from '../Components/Pages/Orders/CreateOrder/CreateOrder';
import OrderDetail from '../Components/Pages/Orders/OrderDetail/OrderDetail';
import Refund from '../Components/Pages/Orders/OrderDetail/Refund/Refund';
import Restock from '../Components/Pages/Orders/OrderDetail/Restock/Restock';
import Return from '../Components/Pages/Orders/OrderDetail/Return/Return';

// my store
import Templates from '../Components/Pages/Store/Templates/Templates';
import TemplatesView from '../Components/Pages/Store/Templates/TemplatesView/TemplatesView';
import Blogs from '../Components/Pages/Store/Blogs/Blogs';
import BlogCategory from '../Components/Pages/Store/Blogs/BlogCategory/BlogCategory';
import AddBlogCategory from '../Components/Pages/Store/Blogs/BlogCategory/AddBlogCategory/AddBlogCategory';
import AddBlog from '../Components/Pages/Store/Blogs/AddBlog/AddBlog';

import Pages from '../Components/Pages/Store/Pages/Pages';
import AddPages from '../Components/Pages/Store/Pages/AddPages/AddPages';

import Preferences from '../Components/Pages/Store/Preferences/Preferences';
import DomainVerification from '../Components/Pages/Store/DomainVerification/DomainVerification';

import Menu from '../Components/Pages/Store/Menu/Menu';
import AddMenuNav from '../Components/Pages/Store/Menu/AddMenu/AddMenu';
import EditMenu from '../Components/Pages/Store/Menu/EditMenu/EditMenu';

import URlRedirects from '../Components/Pages/Store/Menu/URlRedirects/URlRedirects';
import AddURlRedirects from '../Components/Pages/Store/Menu/URlRedirects/AddURlRedirects/AddURlRedirects';

import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Template1 from '../Components/ThemeEditor/Layout/Container/Templates/Template1';

const theme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: "#f5f5f5"
    },
    primary: {
      // main: "#030303",
      // dark: '#161A1D',
      main: "#0d7e5e",
      dark: '#565555',
      contrastText: '#fff'
    },
    secondary: {
      main: '#5838e1',
      contrastText: '#fff'
    },
    info: {
      main: '#999999',
    },
    success: {
      main: "#00ce56",
      dark: "#007A66",
      light: "#00ce56",
      contrastText: '#fff'
    },
    white: {
      main: "#fff"
    }
  },
  typography: {
    fontFamily: "Roboto",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    allVariants: {
      textTransform: "none",
    },
  }
});

function App() {
  const location = window.location.pathname
  const [mobileOpen, setMobileOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Box sx={{ display: 'flex' }}>
          <Router>
            <ScrollToTop />
            {!location.includes("customizer") &&
              <>
                <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
                <Header handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
              </>
            }
            <Box
              component="main"
              sx={{ flexGrow: 1, 
              // p: 3, 
              p: { xs: 3, lg: 3 },
              px: { xs: 0, lg: 3 },
              width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
              <Toolbar />
              <Routes>
                <Route path="/" element={<Home />} exact />
                <Route path="/products/list" element={<Product />} exact />
                <Route path="/products/add" element={<AddProduct />} exact />
                <Route path="/products/:id" element={<AddProduct />} exact />
                <Route path="/products/:id/variant/:vid" element={<AddVariant />} exact />
                <Route path="/products/inventory" element={<Inventory />} exact />
                <Route path="/products/categories" element={<Category />} exact />
                <Route path="/products/categories/add" element={<AddCategory />} exact />
                <Route path="/products/categories/:id" element={<AddCategory />} exact />

                <Route path="/discount" element={<Discount />} exact />
                <Route path="/discount/add" element={<AddDiscount />} exact />
                <Route path="/discount/:id" element={<AddDiscount />} exact />

                <Route path="/customizer" element={<Layout />} exact />
                <Route path="/customizer/1" element={<Template1 />} exact />

                <Route path="/users" element={<User />} exact />
                <Route path="/users/add" element={<AddUser />} exact />
                <Route path="/users/:id" element={<EditUser />} exact />

                <Route path="/account/details" element={<StoreDetails />} exact />
                <Route path="/account/plan" element={<Plan />} exact />
                <Route path="/account/permission" element={<UserAndPermission />} exact />
                <Route path="/account/payments" element={<Payments />} exact />
                <Route path="/account/email/templates" element={<Notifications />} exact />
                <Route path="/account/email/settings" element={<CustomizeEmail />} exact />

                <Route path="/orders" element={<Orders />} exact />
                <Route path="/orders/create" element={<CreateOrder />} exact />
                <Route path="/orders/details" element={<OrderDetail />} exact />
                <Route path="/orders/details/refund" element={<Refund />} exact />
                <Route path="/orders/details/restock" element={<Restock />} exact />
                <Route path="/orders/details/return" element={<Return />} exact />

                <Route path="/store/templates" element={<Templates />} exact />
                <Route path="/store/templates/view" element={<TemplatesView />} exact />

                <Route path="/store/blogs" element={<Blogs />} exact />
                <Route path="/store/blogs-category" element={<BlogCategory />} exact />
                <Route path="/store/blogs-category/add" element={<AddBlogCategory />} exact />
                <Route path="/store/blogs/add" element={<AddBlog />} exact />

                <Route path="/store/pages" element={<Pages />} exact />
                <Route path="/store/pages/add" element={<AddPages />} exact />
                <Route path="/store/preferences" element={<Preferences />} exact />
                <Route path="/store/domain-verification" element={<DomainVerification />} exact />

                <Route path="/store/menu" element={<Menu />} exact />
                <Route path="/store/menu/add" element={<AddMenuNav />} exact />
                <Route path="/store/menu/edit" element={<EditMenu />} exact />

                <Route path="/store/redirects" element={<URlRedirects />} exact />
                <Route path="/store/redirects/add" element={<AddURlRedirects />} exact />

              </Routes>
            </Box>
          </Router>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
