import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';

export default function DialogAlert({ showDialog, closeDialog, content, confirmDialog, smallContent }) {
    return (
        <Dialog
            fullWidth={true}
            open={showDialog}
            onClose={closeDialog}
            aria-labelledby="responsive-dialog-title"
            sx={{ textAlign: 'center', p: 4 }}
            maxWidth={'sm'}
        >
            <DialogContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                    {content.title}
                </Typography>
                <DialogContentText>
                    <Typography variant={smallContent ? "p" : "h6"} component={"span"}>
                        {content.message}
                    </Typography>
                </DialogContentText>
                <DialogActions sx={{ mt: 5, justifyContent: 'center' }}>
                    <Button color="error" size="large" sx={{ minWidth: "120px" }} variant="outlined" autoFocus onClick={closeDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" size="large" sx={{ minWidth: "120px" }} variant="contained" onClick={confirmDialog} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}