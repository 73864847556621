import React from "react";
import schema from "../../../../../Container/schema";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ImageCard } from "../../../../Pages/Product/ImageCard";

export default function CurrentBlock({openSection, activeList, moveOption }) {
    return (
        <>
            {activeList.length > 0 && activeList.map((opt, index) => {
                let activeName = schema.components[opt].name
                return (
                    <ListItem disablePadding key={index.toString()} className="item_drag">
                        <ImageCard
                            index={index}
                            id={opt}
                            text={
                                <ListItemButton onClick={() => openSection(opt)} sx={{ px: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '25px' }}>
                                        <DragIndicatorIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={activeName} />
                                </ListItemButton>
                            }
                            moveCard={moveOption}
                        />
                    </ListItem>
                )
            })}
        </>
    )
}