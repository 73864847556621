export default [
    {
        type: "image_file",
        id: "image_src",
        name: "image_src",
        label: "Image",
        note: "",
        height: "150px",
        width: "100%",
        alt_name: "img_alt",
        alt: "",
        value: "https://checkout-dev.growcart.app/slide.jpg"
    },
    {
        type: "text",
        name: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "editor",
        name: "text",
        id: "text",
        label: "Text",
        value: "<p>Lorem ipsum</p>",
    },
]