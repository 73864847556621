export default [
    {
        type: "text",
        name: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "range",
        label: "Items per row",
        id: "item_per_row",
        name: "item_per_row",
        value: 3,
        min: 2,
        max: 5,
        step: 1,
        unit: ""
    },
    {
        type: "select",
        label: "Gallery type",
        name: "type",
        value: "masonry",
        items: [
            { name: "Masonry", value: "masonry" },
            { name: "Quilted", value: "quilted" }
        ]
    },
    // {
    //     type: "checkbox",
    //     id: "lightbox",
    //     name: "lightbox",
    //     label: "Enable lightbox",
    //     value: false
    // },
    {
        type: "divider"
    },
    {
        type: "list_item",
        label: "Image",
    },
]