import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, IconButton, Container, FormControl, Select, ListItemIcon, Pagination, LinearProgress, Skeleton
} from '@mui/material'
import { styled } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import noImage from '../../../../Assets/images/no_image.jpg';
import ClearIcon from '@mui/icons-material/Clear';
import Check from '@mui/icons-material/Check';

import { DataGrid } from '@mui/x-data-grid';

import _ from "underscore"
import * as Api from "../../../../Container/config/Apis"
import config from '../../../../Container/config';
import { useSnackbar } from 'notistack';
import CurrencyFormat from 'react-currency-format';
import DialogAlert from '../../../common/Alerts/Dialog';

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: "0 !important",
    // width: '200px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <Box sx={{ mt: 15 }}>
                <Typography variant="h5" component="div">No result found!</Typography>
            </Box>
        </StyledGridOverlay>
    );
}

export default function Category() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingData, setloadingData] = React.useState(true);
    const [loadingBar, setloadingBar] = React.useState(true);
    const [count, setCount] = useState(0)
    const [totalCategories, settotalCategories] = useState(0)
    const [limit, setLimit] = useState(50)
    const [params, setParams] = useState({
        search: "",
        sort_key: "",
        sort_value: "desc",
        page: 1
    })
    const [options, setOptions] = useState([
        { name: "Product title A-Z", key: "title", value: "asc", active: false },
        { name: "Product title Z-A", key: "title", value: "desc", active: false },
        { name: "Created (oldest first)", key: "created_at", value: "asc", active: false },
        { name: "Created (newest first)", key: "created_at", value: "desc", active: false },
    ])

    const [rows, setrows] = useState([])
    const columns = [
        {
            field: 'path', headerName: 'Image', width: "80",
            renderCell: (params) => loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> : <img src={params.value || noImage} width="50" style={{ border: "1px solid #eee", borderRadius: "3px", maxHeight: "50px" }} />, sortable: false,
        },
        {
            field: 'title', flex: 1, headerName: 'Title', renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <Button component={Link} to={`/products/categories/${params.row.id}`} color="primary" variant="text" className='noHover' sx={{ pl: 0, justifyContent: 'flex-start' }}>{params.value}</Button>, sortable: false,
        },
        // {
        //     field: 'body_html', flex: 1, headerName: 'Description', sortable: false, renderCell: (params) =>
        //         loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
        //             <Typography variant="p" component="div" dangerouslySetInnerHTML={{ __html: params.value }}></Typography>
        // },
        {
            field: 'action', headerName: 'Action', sortable: false, width: "200",
            renderCell: (params) =>
                loadingBar ? <Skeleton animation="wave" sx={{ width: '100%' }} /> :
                    <>
                        <IconButton aria-label="view" color="primary"><VisibilityIcon /></IconButton>
                        <IconButton aria-label="edit" color="success" onClick={() => navigate(`/products/categories/${params.row.id}`)}><EditIcon /></IconButton>
                        <IconButton aria-label="delete" color="error" onClick={() => deletecategory(params)}><DeleteIcon /></IconButton>
                    </>
        },
    ]
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const closeDialog = () => {
        setOpen(false);
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Get user count
    const getCategoryCount = () => {
        Api.GetApi(`${config.CATEGORY}/count`).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    settotalCategories(res.data.data)
                    let newCount = Math.ceil(parseInt(res.data.data) / limit)
                    if (newCount !== 0) {
                        setCount(newCount)
                    } else {
                        setCount(1)
                    }
                }
            }
        })
    }
    //Get users list
    const getCategoryList = (param) => {
        Api.GetApi(`${config.CATEGORY_LIST}${param}`).then(res => {
            setTimeout(() => {
                setloadingData(false)
                setloadingBar(false)
            }, 500);
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    setrows(res.data.data)
                }

            }
        })
    }


    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    //Search change
    const [search, setSearch] = useState('')
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }
    const handleSearchBlur = (e) => {
        setloadingData(true)
        if (search !== "") {
            setParams({ ...params, 'search': search })
            setparam("search", search)
        } else {
            setParams({ ...params, 'search': "" })
            deleteparam("search")
        }
    }

    //Sort by on change
    const handleSortChange = (val) => {
        setloadingData(true)
        let newArr = []
        options.map((opt) => {
            if (opt.name === val.name) {
                newArr.push({ ...opt, 'active': true })
                setParams({ ...params, "sort_key": val.key, "sort_value": val.value })
                setparam("sort_key", val.key)
                setparam("sort_value", val.value)
            } else {
                newArr.push({ ...opt, 'active': false })
            }
        })
        setOptions(newArr)
        handleClose()
    }

    //Clear sort by filter
    const clearSortBy = () => {
        setloadingData(true)
        let newArr = []
        setParams({ ...params, "sort_key": "", "sort_value": "" })
        deleteparam("sort_key")
        deleteparam("sort_value")
        options.map((opt) => {
            newArr.push({ ...opt, 'active': false })
        })
        setOptions(newArr)
    }
    const handleChangePage = (_event, newPage) => {
        setloadingData(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };

    const onPageSizeChange = (e) => {
        setLimit(e)
    }

    const [deleteId, setId] = useState("")
    function deletecategory(data) {
        console.log("data: ", data)
        setId(data.id)
        setContent({ ...content, 'message': 'You want to delete this category.' })
        setOpen(true)
    }

    const confirmDialog = () => {
        // deleteFn()
        categoryFn(Api.DeleteApi, `${config.CATEGORY}/${deleteId}`, "", "delete")
    }


    const categoryFn = async (api, url, data, type) => {
        await api(url, data).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(type === "delete" ? "Category deleted successfully" : res.data.data, 'success');
                closeDialog()
                getCategoryList(location.search)
            }
        })
    }

    useEffect(() => {
        let newArr = []

        searchParams.set("page", params.page)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getCategoryList(location.search)

        //Set SortBy active value
        if (searchParams.get("sort_key") || searchParams.get("sort_value")) {
            options.map((obj) => {
                if (obj.key === searchParams.get("sort_key") && obj.value === searchParams.get("sort_value")) {
                    newArr.push({ ...obj, 'active': true })
                } else {
                    newArr.push({ ...obj, 'active': false })
                }
            })
            setOptions(newArr)
        }
    }, [limit, params])

    useEffect(() => {
        getCategoryCount()
        if (searchParams.get("search")) {
            setSearch(searchParams.get("search"))
        }
    }, [])

    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Categories ({totalCategories})</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Button component={Link} to="/products/categories/add" variant="contained">Create category</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: 2, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={6}>
                                <Box sx={{ display: "flex" }}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon color="grey" />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search', value: search }}
                                            onBlur={handleSearchBlur}
                                            onChange={handleSearchChange}
                                        />
                                    </Search>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: "Right" }}>
                                    {searchParams.get("sort_key") || searchParams.get("sort_value") ?
                                        <Button variant='text' color="error" size="small" sx={{ mr: 2 }} endIcon={<ClearIcon />} onClick={clearSortBy}>Clear</Button>
                                        : null
                                    }
                                    <Button
                                        id="sortButton"
                                        aria-controls="sortButtonArea"
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="text"
                                        disableElevation
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDownIcon />}>
                                        Sort by
                                    </Button>
                                    <StyledMenu
                                        id="sortButtonArea"
                                        MenuListProps={{ 'aria-labelledby': 'sortButton', }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}>
                                        {options.map((opt) => {
                                            return (
                                                <MenuItem key={opt.name} onClick={() => handleSortChange(opt)} disableRipple className={`sort_list ${opt.active ? "active":""}`} sx={{fontSize: "12px"}}>
                                                    {opt.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </StyledMenu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            sx={{ border: 0 }}
                            rows={rows}
                            columns={columns}
                            pageSize={limit}
                            onPageSizeChange={(e) => onPageSizeChange(e)}
                            rowsPerPageOptions={[]}
                            checkboxSelection={false}
                            disableColumnMenu
                            disableSelectionOnClick={true}
                            autoHeight={true}
                            density={"standard"}
                            rowHeight={60}
                            hideFooterPagination={true}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loadingData}
                        />
                        <Pagination count={count} showFirstButton showLastButton sx={{ p: 2, float: "right" }} onChange={handleChangePage} />
                    </Box>
                </Card>
            </Container>
            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />
        </React.Fragment>
    )
}

