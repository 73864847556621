import React from 'react'
import {
    ListItem, ListItemButton, ListItemText,
    ListItemAvatar, Checkbox, Avatar
} from "@mui/material/";

export default function CheckBoxList({ value, handleToggle, labelId, checked, name, image, avatar, child, icon, }) {
    return (

        <ListItemButton
            role={undefined}
            dense
            sx={{ width: "100%", border: "1px solid #eee", pl: child ? 5 : 2 }}
        >
            <Checkbox
                edge="start"
                checked={checked}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
                onChange={handleToggle}
            />
            {avatar ?
                <ListItemAvatar sx={{ minWidth: icon ? "30px" : null }} >
                    <Avatar
                        variant="square"
                        src={image}
                        sx={{ width: icon ? "20px" : null, height: icon ? "20px" : null }}

                    />
                </ListItemAvatar> : null
            }
            <ListItemText id={labelId} primary={name} />
        </ListItemButton>


    )
}
