export default [
    {
        type: "text",
        name: "heading",
        id: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "editor",
        name: "subheading",
        id: "subheading",
        label: "Subheading",
        value: "<p></p>",
    },
    {
        type: "select",
        label: "Text Size",
        name: "size",
        value: "small",
        items: [
            { name: "Small", value: "small" },
            { name: "Medium", value: "medium" },
            { name: "Large", value: "large" },
        ]
    }
]