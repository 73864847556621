import React, { useCallback, useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent, FormControl,
    Divider, Typography, TextField, Container, InputLabel,
    Select, MenuItem, IconButton, Avatar, FormHelperText,
    FormControlLabel, Switch, ButtonGroup

} from '@mui/material';
import { ColorPicker } from 'material-ui-color';

import Fileupload from '../../../../common/Fileupload/Fileupload';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import PopupModal from '../../../../common/PopupModal/PopupModal';

const CustomizeEmail = () => {
    const [images, setImages] = useState([])

    const [files, setFiles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })
    const [color, setcolor] = useState("#000000");


    const [unitSystem, setUnitSystem] = useState([
        { label: "Order confirmation", content: "Sent automatically to the customer after they place their order.", checked: false },
        { label: "Order edited", content: "Sent to the customer after their order is edited (if you select this option).", checked: false },
        { label: "Abandoned POS checkout", content: "Sent to the customer when you email their cart from POS. Includes a link to buy online.).", checked: false },
    ])

    const handleSwitchChange = (index) => {
        let arr = []
        // unitSystem.map((data) => {
        //     return arr.push(data)
        // })
        unitSystem[index].checked = !unitSystem[index].checked

        setUnitSystem([...unitSystem])


    }

    const onVariantFileAdd = (e) => {
        e.preventDefault()
        let reader = new FileReader();
        let file = e.target.files[0];
        let data = new FormData();
        data.append("product_image", file)
        // data.append("product_id", activeOption.product_id)
        // addVariantImageFn(Api.PostApi, config.PRODUCT_IMAGES, data)
        // reader.readAsDataURL(file)
    }
    const ModalOpen = () => setOpenModal(true);
    const ModalClose = () => setOpenModal(false);
    const triggerModal = (name, title) => {
        setOpenModal(true);
        setModalContent({ "modalShowing": name, "modalTitle": title })
        console.log("modalContent.modalShowing", modalContent.modalShowing)
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Customize email templates </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <ButtonGroup variant="outlined" aria-label="outlined primary button group" size="small">
                                    <Button><ChevronLeftOutlinedIcon /></Button>
                                    <Button><ChevronRightOutlinedIcon /></Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>

                        <Grid item xs={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" component="h6" gutterBottom>Order #9999 confirmed</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                                <ButtonGroup variant="outlined" aria-label="outlined primary button group" size="small">
                                                    <Button><ChevronLeftOutlinedIcon /></Button>
                                                    <Button><ChevronRightOutlinedIcon /></Button>
                                                </ButtonGroup>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom>Logo</Typography>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Button variant="outlined" startIcon={<FileUploadIcon />} onClick={() => triggerModal("logoChange", "Select image")}>
                                            Choose Image
                                        </Button>
                                    </Box>

                                    <Divider sx={{ mt: 2, mb: 3 }} />
                                    <Box sx={{ textAlign: "center" }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="filled-number"
                                                label="Logo width (pixels)"
                                                type="number"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            // variant="filled"
                                            />
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom>Color</Typography>
                                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                                        <ColorPicker
                                            name='color'
                                            // defaultValue='#000000'
                                            value={color}
                                            hideTextfield
                                            disableAlpha
                                            onChange={color => setcolor(`#${color.hex}`)}
                                        />
                                        <Box sx={{ ml: 1 }}>
                                            <Typography variant="p" gutterBottom component="div" sx={{mb:0}}>
                                                {color}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Box>
            </Container>

            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt="Save"
            // hideFooter={modalContent.modalShowing === "manageUser" ? true : false}

            // savebtnFunct={savebtnFunct} 
            >

                {modalContent.modalShowing == "logoChange" ?
                    <>
                        <Grid container spacing={2} columns={12}>
                            <Grid item md={3}>
                                <Box className="customFileUpload">
                                    <input
                                        accept=".jpg,.jpeg,.png,.webp,.svg"
                                        type='file'
                                        onChange={onVariantFileAdd}
                                    />
                                    <label style={{ textAlign: 'center' }}>
                                        <FileUploadIcon fontSize='small' sx={{ verticalAlign: "middle", display: "block", margin: "auto" }} />
                                        Add new
                                    </label>
                                </Box>
                            </Grid>
                            {/* {images.length > 0 && images.map((img) => {
                                        return (
                                            <Grid key={img.id} item md={3}>
                                                <Button sx={{ position: 'relative', border: "#eee 1px solid" }} onClick={() => chooseImage(img)}>
                                                    <img src={img.src} alt="" />
                                                    {img.active &&
                                                        <CheckCircleIcon className='float-top-left' />
                                                    }
                                                </Button>
                                            </Grid>
                                        )
                                    })} */}
                        </Grid>
                    </> : null}

            </PopupModal>
        </>
    );
}

export default CustomizeEmail