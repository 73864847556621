import { Button, Container, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import HeadingText from "./HeadingText";

export default function Gallery() {
    const dataVal = useSelector((state) => state.dataValue.components.gallery)
    console.log("gallery dataVal: ", dataVal)
    return (
        <Box id="gallery">
            <Container maxWidth="lg">
                <HeadingText
                    data={dataVal && dataVal}
                />
                <ImageList variant={dataVal && dataVal.type} cols={dataVal && dataVal.item_per_row} gap={8}>
                    {dataVal && dataVal.items.length > 0 && dataVal && dataVal.items.map((item) => (
                        <ImageListItem key={item.id}>
                            <img
                                src={`${item.image_src}`}
                                srcSet={`${item.image_src}`}
                                alt={""}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Container>
        </Box >
    )
}