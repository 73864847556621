import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Grid, Divider, Typography,
    Button, Box, Card, CardContent, IconButton, Container,
    FormControl, TextField, alpha, styled, Collapse, SvgIcon, SvgIconProps
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import PopupModal from '../../../../common/PopupModal/PopupModal';

import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';

import { useSpring, animated } from '@react-spring/web';

import DialogAlert from '../../../../common/Alerts/Dialog';

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(0px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 0}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));


export default function EditMenu() {
    let navigate = useNavigate()
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })
    const ModalOpen = () => setOpenModal(true);
    const ModalClose = () => setOpenModal(false);
    const triggerModal = (name, title) => {
        setOpenModal(true);
        setModalContent({ "modalShowing": name, "modalTitle": title })
        console.log("modalContent.modalShowing", modalContent.modalShowing)
    }

    const closeDialog = () => {
        setOpen(false);
    }
    const confirmDialog = () => {
        console.log("Confirm")
    }
    const deleteModal = (name) => {
        setContent({ ...content, 'message': `You want to remove the ${name} menu item. ` })
        setOpen(true)
    }
    function menuDiv(data) {
        return (
            <Box className="menuHead">
                <Box className="menuHead__left" sx={{ display: "flex" }}>
                    <DragIndicatorIcon className="menuHead__leftIcon" />
                    <Typography variant="body1" component="div"  >{data}</Typography>
                </Box>
                <Box className="menuHead__right">
                    <IconButton color="success" fontSize="10px"  ><EditIcon fontSize="inherit" /></IconButton>
                    <IconButton color="error" fontSize="10px" onClick={() => deleteModal(data)} ><DeleteIcon fontSize="inherit" /></IconButton>
                </Box>
            </Box >
        )
    }
    function addmenuDiv() {
        return (
            <Box className="menuHead">
                <Button variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => triggerModal("addmenuitem", "Add menu item")} >Add menu item </Button>
            </Box>
        )
    }


    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" to="/store/menu" startIcon={<ArrowBackIosIcon />}>Main menu</Button>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: "right"}}>
                            <Button variant="outlined" color="error" size="large" startIcon={<DeleteIcon />}  >Delete Menu</Button>
                        </Grid>

                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12} >
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Title</Typography>
                                    <FormControl fullWidth  >
                                        <TextField
                                            id="title"
                                            name="title"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            variant="outlined"
                                        // value={discountCode}

                                        // onChange={updateDiscountCode}
                                        />
                                    </FormControl>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Menu items </Typography>
                                    <TreeView
                                        aria-label="customized"
                                        defaultExpanded={['1']}
                                        defaultCollapseIcon={<MinusSquare />}
                                        defaultExpandIcon={<PlusSquare />}
                                        defaultEndIcon={<CloseSquare />}
                                        sx={{ minHeight: 300, flexGrow: 1, overflowY: 'auto', pb: 2, }}
                                    >
                                        <StyledTreeItem nodeId="1" label={menuDiv("Home")}>
                                            <StyledTreeItem nodeId="3" label={menuDiv("Subtree with children")}>
                                                <StyledTreeItem nodeId="6" label={menuDiv("Hello")} >
                                                    <StyledTreeItem nodeId="19" label={addmenuDiv()} />
                                                </StyledTreeItem >
                                                <StyledTreeItem nodeId="7" label={menuDiv("Subtree with children")}>
                                                    <StyledTreeItem nodeId="9" label={menuDiv("Child 1")} />
                                                    <StyledTreeItem nodeId="10" label={addmenuDiv()} />
                                                </StyledTreeItem>

                                                <StyledTreeItem nodeId="16" label={addmenuDiv()} />

                                            </StyledTreeItem>
                                            <StyledTreeItem nodeId="5" label={menuDiv("Something")} >
                                                <StyledTreeItem nodeId="17" label={addmenuDiv()} />
                                            </StyledTreeItem>
                                            <StyledTreeItem nodeId="19" label={addmenuDiv()} />
                                        </StyledTreeItem>

                                        <StyledTreeItem nodeId="12" label={menuDiv("About us")} >
                                            <StyledTreeItem nodeId="13" label={menuDiv("Team")} >
                                                <StyledTreeItem nodeId="20" label={addmenuDiv()} />
                                            </StyledTreeItem>
                                        </StyledTreeItem>
                                        <StyledTreeItem nodeId="14" label={menuDiv("About us")} >
                                            <StyledTreeItem nodeId="18" label={addmenuDiv()} />
                                        </StyledTreeItem>

                                    </TreeView>
                                </CardContent>
                                <Divider />
                                <Box sx={{ p: 1.5 }}>
                                    <Button variant="text"
                                        startIcon={<AddIcon />}
                                        onClick={() => triggerModal("addmenuitem", "Add menu item")} >Add menu item </Button>
                                </Box>
                            </Card>


                        </Grid>
                       
                    </Grid>
                </Box>
                <Grid item md={12}>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Box sx={{ textAlign: "right" }}>
                        <Button variant="contained" color="error" size="large" onClick={() => navigate("/store/menu")}>Cancel</Button>
                        <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                    </Box>
                </Grid>
            </Container>

            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt="Save"
            // hideFooter={modalContent.modalShowing === "manageUser" ? true : false}

            // savebtnFunct={savebtnFunct} 
            >
                {modalContent.modalShowing === "addmenuitem" ?
                    <Box>
                        <Box>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    id="name"
                                    name="title"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    variant="outlined"
                                    label="Name"
                                    placeholder='e.g. About us'
                                // value={discountCode}

                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <TextField
                                    id="link"
                                    name="title"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    variant="outlined"
                                    label="Link"
                                // value={discountCode}

                                />
                            </FormControl>
                        </Box>
                    </Box> : null}



            </PopupModal>
            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />

        </React.Fragment>
    )
}

