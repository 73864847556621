export default [
    {
        name: "Typography",
        type: "typography",
        settings: [
            {
                type: "heading",
                text: "Headings"
            },
            {
                type: "font_family",
                label: "Font family",
                id: "heading_font",
                name: "heading_font",
                value: "Roboto"
            },
            {
                type: "range",
                label: "Size",
                id: 'heading_base_size',
                value: 30,
                min: 20,
                max: 40,
                step: 2,
                unit: "px"
            },
            {
                type: "heading",
                text: "Body text"
            },
            {
                type: "font_family",
                label: "Font family",
                id: "body_font",
                name: "body_font",
                value: "Roboto"
            },
            {
                type: "range",
                id: "body_text_base_size",
                label: "Base size",
                value: 16,
                min: 12,
                max: 20,
                step: 1,
                unit: "px"
            }
        ]
    },
    {
        name: "Colors",
        type: "colors",
        settings: [
            {
                type: "heading",
                text: "Text colors"
            },
            {
                type: "color_picker",
                id: "heading_color",
                name: "heading_color",
                label: "Headings",
                value: "#000000"
            },
            {
                type: "color_picker",
                id: "body_text_color",
                name: "body_text_color",
                label: "Body text",
                value: "#000000"
            },
            {
                type: "heading",
                text: "Button colors"
            },
            {
                type: "color_picker",
                id: "button_color",
                name: "button_color",
                label: "Button color",
                value: "#000000"
            },
            {
                type: "color_picker",
                id: "button_text_color",
                name: "button_text_color",
                label: "Button text color",
                value: "#ffffff"
            },
            {
                type: "heading",
                text: "Body colors"
            },
            {
                type: "color_picker",
                id: "body_bg_color",
                name: "body_bg_color",
                label: "Background",
                value: "#ffffff"
            },
            {
                type: "heading",
                text: "Overlay color"
            },
            {
                type: "color_picker",
                id: "overlay_bg_color",
                name: "overlay_bg_color",
                label: "Background",
                value: "#000000"
            },
            {
                type: "range",
                id: "overlay_opacity",
                name: "overlay_opacity",
                label: "Opacity",
                value: 40,
                min: 0,
                max: 100,
                step: 5,
                unit: "%"
            }
        ]
    },
    {
        name: "Favicon",
        type: "favicon",
        settings: [
            {
                type: "heading",
                text: "Add favicon image"
            },
            {
                type: "image_file",
                id: "favicon",
                label: "Add favicon image",
                note: "Recommended size 64 X 64",
                height: "64px",
                width: "100%",
                alt: "",
                value: "https://checkout-dev.growcart.app/logo-light.svg"
            }
        ]
    },
    {
        name: "Social share",
        type: "share_button",
        settings: [
            {
                type: "heading",
                text: "Social sharing"
            },
            {
                type: "checkbox",
                id: "facebook_share",
                label: "Share on Facebook",
                value: true
            },
            {
                type: "checkbox",
                id: "twitter_share",
                label: "Share on Twitter",
                value: true
            },
            {
                type: "checkbox",
                id: "pinterest_share",
                label: "Share on Pinterest",
                value: false
            }
        ]
    },
    {
        name: "Social links",
        type: "social_button",
        settings: [
            {
                type: "text",
                name: "facebook_link",
                label: "Facebook",
                value: "",
                placeholder: ""
            },
            {
                type: "text",
                name: "twitter_link",
                label: "Twitter",
                value: "",
                placeholder: ""
            },
            {
                type: "text",
                name: "pinterest_link",
                label: "Pinterest",
                value: "",
                placeholder: ""
            },
            {
                type: "text",
                name: "instagram_link",
                label: "Instagram",
                value: "",
                placeholder: ""
            },
            {
                type: "text",
                name: "youtube_link",
                label: "Youtube",
                value: "",
                placeholder: ""
            },
            {
                type: "text",
                name: "vimeo_link",
                label: "Vimeo",
                value: "",
                placeholder: ""
            },
            {
                type: "text",
                name: "tumblr_link",
                label: "Tumblr",
                value: "",
                placeholder: ""
            },
            {
                type: "text",
                name: "snapchat_link",
                label: "Snapchat",
                value: "",
                placeholder: ""
            }
        ]
    },
    {
        name: "Header",
        type: "header",
        settings: [
            {
                type: "header"
            }
        ]
    },
    {
        name: "Slider",
        type: "hero_slider",
        settings: [
            {
                type: "hero_slider"
            }
        ]
    },
    {
        name: "Text Over Image",
        type: "text_over_image",
        settings: [
            {
                type: "text_over_image"
            }
        ]
    },
    {
        name: "Product list",
        type: "product_list",
        settings: [
            {
                type: "product_list"
            }
        ]
    },
    {
        name: "Text column with image",
        type: "text_column_with_image",
        settings: [
            {
                type: "text_column_with_image"
            }
        ]
    },
    {
        name: "Gallery",
        type: "gallery",
        settings: [
            {
                type: "gallery"
            }
        ]
    },
    {
        name: "Content block",
        type: "content_block",
        settings: [
            {
                type: "content_block"
            }
        ]
    },
    {
        name: "Brand list",
        type: "brands_list",
        settings: [
            {
                type: "brands_list"
            }
        ]
    },
    {
        name: "Map",
        type: "map",
        settings: [
            {
                type: "map"
            }
        ]
    },
    {
        name: "Heading block",
        type: "heading_block",
        settings: [
            {
                type: "heading_block"
            }
        ]
    },
    {
        name: "Testimonials",
        type: "testimonials",
        settings: [
            {
                type: "testimonials"
            }
        ]
    },
    {
        name: "Blog Post",
        type: "blog_post",
        settings: [
            {
                type: "blog_post"
            }
        ]
    },
    {
        name: "Newsletter",
        type: "newsletter",
        settings: [
            {
                type: "newsletter"
            }
        ]
    },
    {
        name: "Video",
        type: "video",
        settings: [
            {
                type: "video"
            }
        ]
    }
]