export default [
    {
        type: "text",
        name: "heading",
        label: "Section Heading",
        value: "Lorem ipsum dolor",
        placeholder: ""
    },
    {
        type: "select",
        label: "Order",
        id: "order",
        name: "order",
        value: "default",
        items: [
            { name: "Default", value: "default" },
            { name: "Reverse", value: "reverse" },
        ]
    },
    {
        type: "image_file",
        id: "image",
        label: "Image",
        note: "",
        height: "",
        width: "100%",
        alt: "",
        value: "https://checkout-dev.growcart.app/side-image.jpg"
    },
    {
        type: "select",
        label: "Content alignment",
        name: "alignment",
        value: "center",
        items: [
            { name: "Top", value: "top" },
            { name: "Center", value: "center" },
            { name: "Bottom", value: "bottom" },
        ]
    },
    {
        type: "text",
        name: "content_heading",
        label: "Heading",
        value: "Lorem ipsum dolor sit",
        placeholder: ""
    },
    {
        type: "editor",
        name: "paragraph",
        id: "paragraph",
        label: "Text",
        value: "<p></p>",
    },
    {
        type: "text",
        name: "button_label",
        label: "Button label",
        value: "Shop now",
        placeholder: ""
    },
    {
        type: "text",
        name: "button_link",
        label: "Button link",
        value: "",
        placeholder: ""
    },
]