import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import HeadingText from "./HeadingText";
// import * as heading from "./Typography/Exports"

export default function Newsletter() {
    const dataVal = useSelector((state) => state.dataValue.components.newsletter)
    return (
        <Box sx={{ textAlign: 'center', py: 5 }} id="newsletter">
            <HeadingText
                data={dataVal && dataVal}
            />
            <Grid container spacing={2} justifyContent="center">
                <Grid item sm={12} md={6}>
                    <Box sx={{ display: "flex" }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            type={'email'}
                            name={"email"}
                            placeholder={"Email address"}
                        />
                        <Button variant="contained" sx={{ ml: 2, minWidth: "150px" }} size="large">{dataVal && dataVal.button_label}</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}