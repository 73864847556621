import React, { useState } from "react";
import { Button, Divider, FormControl, Grid, MenuItem, Paper, Select } from "@mui/material";
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export default function LayoutHeader({ changeView, previewPage, activeTab }) {
    const pages = [
        { name: "Home page", value: "home" },
        { name: "Product page", value: "product" },
        { name: "Category page", value: "category" },
        { name: "Category List page", value: "category_list" },
    ]
    const [current, setCurrent] = useState("home")
    const handleChange = (e) => {
        setCurrent(e.target.value)
    }
    const tabs = [
        { name: "Desktop", value: "desktop", icon: <DesktopMacIcon fontSize="small" /> },
        { name: "Tablet", value: "tablet", icon: <TabletMacIcon fontSize="small" /> },
        { name: "Mobile", value: "mobile", icon: <PhoneAndroidIcon fontSize="small" /> },
    ]
    return (
        <Paper elevation={3} sx={{ borderRadius: "0", position: "relative", zIndex: 9 }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="header_outer_block"
                sx={{ flexWrap: "nowrap", p: 1 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ flexWrap: "nowrap", p: 1 }}
                    item md={6}
                >
                    <Button href="/" startIcon={<ArrowBackIcon />}>
                        Exit
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <FormControl fullWidth variant="standard" sx={{ ml: 5, width: 300 }}>
                        <Select
                            labelId="current-page"
                            onChange={handleChange}
                            value={current}
                            id="current-page-id"
                        >
                            {pages.map((page, index) => {
                                return (
                                    <MenuItem key={index.toString()} sx={{ fontSize: "14px" }} value={page.value}>{page.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    {tabs.map((tab) => {
                        return (
                            <Button key={tab.value} title={tab.name} onClick={() => changeView(tab.value)} color="primary" sx={{ minWidth: "inherit", opacity: activeTab === tab.value ? "1" : "0.6" }}>{tab.icon}</Button>
                        )
                    })}

                    <Divider orientation="vertical" flexItem color="#ddd" />

                    <Button title="Preview" onClick={previewPage}  color="primary" sx={{ minWidth: "inherit", opacity: activeTab === "preview" ? "1" : "0.6" }}><RemoveRedEyeIcon fontSize="small" /></Button>
                </Grid>
                <Grid
                    item md={4}
                    container
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                    sx={{ flexWrap: "nowrap", whiteSpace: 'nowrap' }}
                >
                    <Button variant="outlined" color="error">Reset</Button>
                    <Button variant="contained" sx={{ mx: 3 }}>Save</Button>
                </Grid>
            </Grid>
        </Paper>
    )
}