import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import * as heading from "./Typography/Exports"

export default function HeadingText({ data }) {
    return (
        <Grid container spacing={2} sx={{ mb: 2 }} justifyContent="center">
            <Grid item sm={12} md={8}>
                <Box sx={{ textAlign: 'center' }}>
                    {data.size === "large" ?
                        <heading.HeadingH1
                            ishtml={false}
                            type="div"
                            data={data.heading}
                        /> :
                        data.size === "medium" ?
                            <heading.HeadingH2
                                ishtml={false}
                                type="div"
                                data={data.heading}
                            /> :
                            <heading.HeadingH3
                                ishtml={false}
                                type="div"
                                data={data.heading}
                            />
                    }
                    {data.subheading &&
                        <>
                            {data.size === "large" ?
                                <heading.HeadingH5
                                    ishtml={true}
                                    type="div"
                                    data={data.subheading}
                                /> :
                                data.size === "medium" ?
                                    <heading.HeadingH6
                                        ishtml={true}
                                        type="div"
                                        data={data.subheading}
                                    /> :
                                    <Typography variant="p" component="div" dangerouslySetInnerHTML={{ __html: data.subheading }}>
                                    </Typography>
                            }
                        </>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}