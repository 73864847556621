import { Button, Container, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import HeadingText from "./HeadingText";
import * as heading from "./Typography/Exports"

export default function BlogPost() {
    const dataVal = useSelector((state) => state.dataValue.components.blog_post)
    return (
        <Box id="blog_post" sx={{py:4}}>
            <Container maxWidth="lg">
                <HeadingText
                    data={dataVal && dataVal}
                />
                <Grid container spacing={4}>
                    {dataVal && dataVal.items.length > 0 && dataVal && dataVal.items.slice(0, parseInt(dataVal.show_posts)).map((item, index) => {
                        return (
                            <Grid key={index.toString()} item sm={6} md={4}>
                                <img src={item.image_src} alt="" className="full-img" />
                                <Box sx={{ py: 2 }}>
                                    <heading.HeadingH4
                                        ishtml={false}
                                        type="div"
                                        data={item.title}
                                    />

                                    {dataVal && dataVal.show_date &&
                                        <Typography variant="button" component="span" color="#999" sx={{ mr: 2 }}>
                                            {item.date}
                                        </Typography>
                                    }
                                    {dataVal && dataVal.show_author &&
                                        <Typography variant="button" component="span" color="#999">
                                            by {item.author}
                                        </Typography>
                                    }
                                    <Typography variant="p" sx={{ mb: 2 }} component="div" dangerouslySetInnerHTML={{ __html: item.text }}>
                                    </Typography>
                                    <Link href={`/${item.slug}`} style={{ color: "#ff0000" }}>Read more...</Link>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Box >
    )
}