import React, { useCallback, useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent, CardMedia,
    FormControl, Divider, Typography,
    TextField, Container, ButtonGroup, Chip,
    InputAdornment, InputLabel, Select, MenuItem, IconButton, FormLabel,
    FormGroup, FormControlLabel, Checkbox, LinearProgress, Skeleton,
} from '@mui/material';
import { Link, useParams } from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import prodimg from '../../../../Assets/images/bundleprod.jpg'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AddBusinessTwoToneIcon from '@mui/icons-material/AddBusinessTwoTone';
import PopupModal from '../../../common/PopupModal/PopupModal'
import DialogAlert from '../../../common/Alerts/Dialog';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Countries from '../../../common/CommonJason/Countries';
import * as Api from '../../../../Container/config/Apis';
import config from '../../../../Container/config';
import { useSnackbar } from 'notistack';
import _ from "underscore"
import moment from "moment"
// file drag drop 

// file drag drop  

const EditUser = () => {
    const param = useParams()
    const [body, setBody] = useState({
        status: "active",
        note: ""
    });
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })
    const [selectCountry, setSelectCountry] = useState(103)
    const [countrycode, setCountrycode] = useState({
        phone: '91'
    })
    const [country, setCountry] = useState(103)
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(true);
    const [showDialog, setOpen] = useState(false);
    const [editNote, setEditNote] = useState(false);
    const [showError, setshowError] = useState(false)
    const [content, setContent] = useState({
        title: "Are you sure?",
        message: ""
    });
    let addressInit = {
        address1: "",
        address2: "",
        city: "",
        company: "",
        first_name: "",
        last_name: "",
        phone: "",
        province: "",
        country: "India",
        zip: "",
        country_code: "IN",
    }
    const [addressData, setaddressData] = useState(addressInit)
    const [addressType, setAddressType] = useState("")
    const [accountType, setaccountType] = useState("")
    const [userTime, setuserTime] = useState("")
    const [bcc, setBCC] = useState([
        { id: 1, email: "test@gmail.com", checked: false },
        { id: 2, email: "test2@gmail.com", checked: false }
    ])
    const from = "testing@gmail.com"
    const [account, setAccount] = useState({
        to: "",
        bcc: "",
        subject: "",
        custom_message: ""
    })

    const emailFrom = [
        { name: "test 1", email: "test1@gmail.com", },
        { name: "test 2", email: "test2@gmail.com", },
        { name: "test 3", email: "test3@gmail.com", },
    ]

    const [selectValue, setSelectValue] = useState({
        contactUserFrom: 1,
        accountInviteFrom: 1,
    })
    const [defaultAddress, setDefaultAddress] = useState({})

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const closeDialog = () => {
        setId("")
        setOpen(false);
    }
    const [copied, setcopied] = useState(false)
    const copiedFn = () => {
        setcopied(true)
        setTimeout(() => {
            setcopied(false)
        }, 2000);
    }

    const selectValueFunction = (e) => {
        setSelectValue({ ...selectValue, [e.target.name]: e.target.value })
    }

    const countrySelect = (event) => {
        setSelectCountry(event.target.value);
    };
    const ModalOpen = () => setOpenModal(true);
    const ModalClose = () => {
        setOpenModal(false)
        setaddressData(addressInit)
        setAddressType("")
    };
    const triggerModal = (e, data, type, title) => {
        setOpenModal(true);
        setModalContent({ "modalShowing": e.target.name, "modalTitle": title })
        console.log("modalContent.modalShowing", modalContent.modalShowing)
        if (e.target.name === "address") {
            if (type === "add") {
                data = {
                    ...addressInit,
                    country_code: "IN",
                    country: "India",
                }
            }
            setaddressData(data)
            setAddressType(type)
        }
        // if(e.target.name === "sendInvite"){
        //     setAccount()
        // }
    }

    const addressFn = (api, url, data, type) => {
        api(url, data).then(res => {
            if (res.error === true) {
                if (!_.isUndefined(res.response.data.error)) {
                    snack(res.response.data.error, 'error');
                } else {
                    snack(res.res.data.error, 'error');
                }
            } else {
                snack(res.data.data, 'success');
                if (param.id) {
                    getUserByID(param.id)
                }
                ModalClose()
                setOpen(false)
                setconfirmType({ type: "", state: "" })
                setEditNote(false)
            }
        })
    }

    const handleOnchange = (e, type) => {
        if (type === "address") {
            setaddressData({ ...addressData, [e.target.name]: e.target.value })
        } else if (type === "account") {
            setAccount({ ...account, [e.target.name]: e.target.value })
        } else {
            setBody({ ...body, [e.target.name]: e.target.value })
        }
    }
    const resetPassword = () => {
        setContent({ ...content, 'message': 'You want to reset the password for this user? The user will be sent an email with instructions to reset their password.' })
        setOpen(true)
    }
    const disableAccount = (type) => {
        setContent({ ...content, 'message': `You want to ${type === "disbaled" ? "enable" : "disable"} the account for this user.` })
        setconfirmType({ type: "state", state: type })
        setOpen(true)
    }
    const deleteUser = () => {
        setContent({ ...content, 'message': 'You want to delete the account for this user.' })
        setOpen(true)
    }


    const onCountryChange = useCallback((e) => {
        console.log(Countries[e.target.value])
        setCountry(e.target.value)
        setaddressData({
            ...addressData,
            [e.target.name]: Countries[e.target.value].label,
            'country_code': Countries[e.target.value].code
        })
    }, [addressData]);
    console.log("addressData: ", addressData)

    const handleNumberChange = (value, data, type) => {
        if (type === "address") {
            setaddressData({
                ...addressData,
                'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`
            })
        } else {
            setBody({ ...body, 'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}` })
        }
    };

    //GET User BY ID
    const getUserByID = useCallback((id) => {
        Api.GetApi(`${config.USER_LIST}/${id}`).then(async res => {
            setTimeout(() => {
                setLoading(false)
            }, 1000);
            if (res.error === true) {
                if (!_.isUndefined(res.response.data.error)) {
                    snack(res.response.data.error, 'error');
                } else {
                    snack(res.res.data.error, 'error');
                }
            } else {
                if (res.data.data) {
                    let bodyData = res.data.data
                    if (bodyData.addresses && bodyData.addresses.length > 0) {
                        if (bodyData.addresses.filter((opt) => opt.is_default === true).length > 0) {
                            setDefaultAddress(bodyData.addresses.filter((opt) => opt.is_default === true)[0])
                        } else {
                            setDefaultAddress(bodyData.addresses[0])
                        }
                    } else {
                        setDefaultAddress()
                    }
                    setaccountType(
                        bodyData.state === "declined" ? "Account declined" :
                            bodyData.state === "invited" ? "Account invite sent" :
                                bodyData.state === "enabled" ? "Has an account" : "No account"
                    )
                    setuserTime(moment(bodyData.created_at).fromNow(true))
                    await setBody(bodyData)

                    setAccount({
                        ...account,
                        to: bodyData.email,
                        subject: "User account activation"
                    })
                } else {
                    snack("No record found. Please check the URL.", 'error');
                }
            }
        })
    }, [])

    function cleanFn(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || obj[propName] === []) {
                delete obj[propName];
            }
        }
        return obj
    }
    //handle modal save button click
    const savebtnFunct = () => {
        if (modalContent.modalShowing === "address") {
            let data = {
                address1: addressData.address1,
                address2: addressData.address2,
                city: addressData.city,
                company: addressData.company,
                first_name: addressData.first_name,
                last_name: addressData.last_name,
                phone: addressData.phone,
                province: addressData.province,
                country: addressData.country,
                zip: addressData.zip,
                country_code: addressData.country_code,
            }
            if (param.id) {
                if (addressData.address1 === "" || addressData.city === "" || addressData.first_name === "" || addressData.province === "" || addressData.zip === "") {
                    setshowError(true)
                } else {
                    if (addressType === "add") {
                        data = {
                            ...data,
                            customer_id: param.id
                        }
                        addressFn(Api.PostApi, config.USER_ADDRESS, cleanFn(data), "add")
                    } else {
                        addressFn(Api.PutApi, `${config.USER_ADDRESS}/${body.id}/address/${addressData.id}/update`, cleanFn(data), "edit")
                    }
                }
            }
        } else if (modalContent.modalShowing === "editUser") {
            let data = {
                first_name: body.first_name,
                last_name: body.last_name,
                email: body.email,
                phone: body.phone,
            }
            addressFn(Api.PutApi, `${config.USER_LIST}/${parseInt(body.id)}`, data, "edit")
        } else if (modalContent.modalShowing === "sendInvite") {
            let bccEmails = []
            bcc.filter((opt) => opt.checked).map((x) => {
                bccEmails.push(x.email)
            })
            let data = {
                ...account,
                bcc: bccEmails
            }
            addressFn(Api.PostApi, `${config.USER_SEND_INVITE}/${parseInt(body.id)}`, data, "send_invite")
        }
    }
    console.log("account: ", account)

    const makedefault = (data) => {
        addressFn(Api.PutApi, `${config.USER_ADDRESS}/${parseInt(body.id)}/address/${data.id}/default`, data, "edit")
    }
    const deleteAddress = (data) => {
        addressFn(Api.DeleteApi, `${config.USER_ADDRESS}/${parseInt(body.id)}/address/${data.id}`, "", "delete")
    }

    const updateNote = () => {
        let data = {
            note: body.note
        }
        addressFn(Api.PutApi, `${config.USER_LIST}/${parseInt(body.id)}`, data, "edit")
    }

    const handleBCCchange = (e, data) => {
        let currentIndex = _.findIndex(bcc, { 'email': data.email })
        if (currentIndex !== -1) {
            bcc[currentIndex].checked = e.target.checked
            setBCC([...bcc])
        }
    }
    const editNoteClick = () => {
        if (editNote === true) {
            if (param.id) {
                getUserByID(param.id)
            }
        }
        setEditNote(!editNote)
    }

    const [confirmType, setconfirmType] = useState({
        type: "",
        state: ""
    })
    const confirmDialog = () => {
        if (confirmType.type === "state") {
            let data = {
                state: confirmType.state
            }
            addressFn(Api.PutApi, `${config.USER_LIST}/${parseInt(body.id)}/state`, data, "")
        }
    }


    useEffect(() => {
        if (param.id) {
            getUserByID(param.id)
        }
    }, []);
    console.log("selectValue", selectValue)
    console.log("body", body)

    return (
        <>
            <Container maxWidth="lg">

                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={4}>
                            <Button component={Link} variant="text" to="/users" startIcon={<ArrowBackIosIcon />}>
                                {loading ?
                                    <Skeleton variant="text" height={50} width={200} />
                                    :
                                    <>
                                        {body.name ? body.name : body.first_name && body.last_name ? `${body.first_name} ${body.last_name}` : "Back"}
                                    </>
                                }
                            </Button>
                        </Grid>
                        <Grid item md={8} sx={{ textAlign: "Right" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Box>
                                    {body.state !== "enabled" &&
                                        <Button aria-haspopup="true" variant="outlined" name="sendInvite" onClick={(e) => triggerModal(e, "", "", "Send account invite")} startIcon={body.state && body.state !== "invited" && <SendOutlinedIcon />} sx={{ mr: 2 }} >
                                            {body.state && body.state === "invited" ? "Resend account invite" : "Send account invite"}
                                        </Button>
                                    }
                                    {body.state && body.state === "enabled" &&
                                        <Button aria-label="view" variant="outlined" onClick={resetPassword} startIcon={<LockResetOutlinedIcon />} sx={{ mr: 2 }}>Reset password</Button>
                                    }
                                    <Button
                                        aria-label="view"
                                        variant={body.state === "disabled" ? "contained" : "outlined"}
                                        color="error"
                                        onClick={() => disableAccount(body.state === "disabled" ? "enabled" : "disabled")}
                                        startIcon={<DoDisturbAltOutlinedIcon />}
                                        sx={{ mr: 2 }}
                                    >
                                        {body.state === "disabled" ?
                                            "Account Disabled" : "Disable account"
                                        }
                                    </Button>
                                </Box>
                                {/* <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                    <Button><ChevronLeftOutlinedIcon /></Button>
                                    <Button><ChevronRightOutlinedIcon /></Button>
                                </ButtonGroup> */}
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={8}>
                            {body.orders_count == 0 || body.orders_count == "" || body.orders_count == null || body.orders_count == undefined ?
                                <Card sx={{ mb: 2 }}>
                                    {loading ?
                                        <Box sx={{ p: 2 }}>
                                            <Skeleton variant="text" height={80} />
                                            <Skeleton animation="wave" width={'100%'} height={260} sx={{ transform: 'none' }} />
                                        </Box>
                                        :
                                        <CardContent>
                                            <Typography variant="h6" component="div" gutterBottom>Last order placed</Typography>
                                            <Box sx={{ p: 5, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                <Box sx={{ margin: "auto", textAlign: 'center' }}>
                                                    <Box sx={{ fontSize: "150px", mb: 3 }} className="no_order_vector">
                                                        <AddBusinessTwoToneIcon fontSize='inherit' />
                                                    </Box>
                                                    <Typography variant="p" component="div" sx={{ fontWeight: "normal" }} gutterBottom>This user hasn’t placed any order yet.</Typography>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    }
                                </Card>
                                :
                                <React.Fragment>
                                    <Box sx={{ mb: 2 }}>
                                        <Grid container spacing={2} columns={12}>
                                            <Grid item md={4}>
                                                <Card sx={{ mb: 1, }}>
                                                    <CardContent sx={{ paddingBottom: "16px !important" }}>
                                                        <Typography variant="caption" display="block" gutterBottom>Last Order</Typography>
                                                        <Typography variant="p" sx={{ mb: 0.3 }} component="strong" display="block">5 months ago</Typography>
                                                        <Typography variant="subtitle2" display="block" gutterBottom>From Online Store</Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item md={4}>
                                                <Card sx={{ mb: 1, }}>
                                                    <CardContent sx={{ paddingBottom: "16px !important" }}>
                                                        <Typography variant="caption" display="block" gutterBottom>Total spent</Typography>
                                                        <Typography variant="p" sx={{ mb: 0.3 }} component="strong" display="block"> ₹1,173,188.24</Typography>
                                                        <Typography variant="subtitle2" display="block" gutterBottom> 167 orders</Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item md={4}>
                                                <Card sx={{ mb: 1, }}>
                                                    <CardContent sx={{ paddingBottom: "16px !important" }}>
                                                        <Typography variant="caption" display="block" gutterBottom>Average order value</Typography>
                                                        <Typography variant="p" sx={{ pb: 3.5 }} component="strong" display="block"> ₹7,025.08</Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                    <Card sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Typography variant="h6" component="div" gutterBottom>Last order placed</Typography>
                                            <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
                                                <Grid item md={8}>
                                                    <Box sx={{ mb: 1 }}>
                                                        <Button variant="text" component={Link} to="" sx={{ p: 0 }} > Order #1015 </Button>
                                                        <Chip icon={<CircleOutlinedIcon sx={{ width: "12px", ml: "8px !important" }} />} label="Fulfilled" sx={{ ml: 1 }} />
                                                        <Chip icon={<CircleOutlinedIcon sx={{ width: "12px", ml: "8px !important" }} />} label="Fulfilled" sx={{ ml: 1 }} color="primary" />
                                                    </Box>
                                                    <Typography sx={{ mb: 1 }} variant="p" component="div" >₹22.75 from Customer and order access (via import)</Typography>
                                                </Grid>
                                                <Grid item md={4} sx={{ textAlign: "right" }}> <Typography variant="caption" display="block" gutterBottom>Oct 12, 2020</Typography> </Grid>
                                            </Grid>
                                            <Grid container spacing={2} columns={12} sx={{ textAlign: "center", pt: 2 }}>
                                                <Grid item md={6}>
                                                    <Card sx={{ display: 'flex', boxShadow: "none", }}>
                                                        <Box sx={{ width: 50 }}>
                                                            <img sx={{ width: "100%" }} src={prodimg} alt="" />
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                                            <Typography component="div" variant="p" sx={{ lineHeight: "normal" }}> Live From Space  </Typography>
                                                            <Typography variant="p" component="div" sx={{ lineHeight: "normal" }}>  Mac Miller  </Typography>

                                                        </Box>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <Card sx={{ display: 'flex', boxShadow: "none", }}>
                                                        <Box sx={{ width: 50 }}>
                                                            <img sx={{ width: "100%" }} src={prodimg} alt="" />
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                                            <Typography component="div" variant="p" sx={{ lineHeight: "normal" }}> Live From Space  </Typography>
                                                            <Typography variant="p" color="text.secondary" component="div" sx={{ lineHeight: "normal" }}>  Mac Miller  </Typography>

                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ my: 2 }} />
                                            <Box sx={{ textAlign: "right" }}>
                                                <Button variant="outlined" color="primary" size="large" sx={{ ml: 1 }}>View all orders</Button>
                                                {/* <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Create order</Button> */}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid item md={4}>
                            <Card sx={{ mb: 2 }}>
                                {loading ?
                                    <Box sx={{ p: 2 }}>
                                        <Skeleton variant="text" height={40} />
                                        <Skeleton animation="wave" width={'100%'} height={100} sx={{ transform: 'none' }} />
                                    </Box>
                                    :
                                    <>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                                <Box>
                                                    <Typography variant="h6" component="div" sx={{ mb: 0, wordBreak: "break-all" }}>
                                                        {body.name ? body.name : body.first_name && body.last_name ? `${body.first_name} ${body.last_name}` : "User not found"}
                                                    </Typography>
                                                    <Typography variant="caption" component="div" color="#999" sx={{ width: "100%" }} gutterBottom>
                                                        {userTime && userTime !== "" && `User for ${userTime}`}
                                                    </Typography>
                                                </Box>

                                                <Button variant="text" name="editUser" onClick={(e) => triggerModal(e, "", "", "Edit user")} sx={{ p: 0 }} > Edit </Button>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                                <Typography variant="button" component="div" sx={{ wordBreak: "break-all" }}>
                                                    {body.email ? body.email : "No email available"}
                                                </Typography>

                                                <IconButton aria-label="delete" sx={{ width: "35px", height: "35px" }}>
                                                    <CopyToClipboard text={body.email} onCopy={copiedFn}>
                                                        {copied ?
                                                            <DoneIcon sx={{ width: "15px" }} />
                                                            :
                                                            <ContentCopyOutlinedIcon sx={{ width: "15px" }} />
                                                        }
                                                    </CopyToClipboard>
                                                </IconButton>
                                            </Box>
                                            <Typography variant="p" color="text.secondary" component="div" >{accountType} </Typography>


                                        </CardContent>
                                        <Divider sx={{ my: 0 }} />
                                        <CardContent>
                                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                <Typography variant="h6" component="h6" gutterBottom>Default Address</Typography>
                                                <Button variant="text" sx={{ p: 0 }} name="manageUser" onClick={(e) => triggerModal(e, "", "", "Manage  addresses")} > Manage </Button>
                                            </Box>
                                            {!_.isEmpty(defaultAddress) ?
                                                <React.Fragment>
                                                    {defaultAddress && defaultAddress.name &&
                                                        <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{defaultAddress.name}</Typography>
                                                    }
                                                    {defaultAddress && defaultAddress.address1 &&
                                                        <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{defaultAddress.address1}</Typography>
                                                    }
                                                    <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{defaultAddress && defaultAddress.province && defaultAddress.province}, {defaultAddress && defaultAddress.city && defaultAddress.city}</Typography>
                                                    {defaultAddress && defaultAddress.country &&
                                                        <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{defaultAddress.country}</Typography>
                                                    }
                                                </React.Fragment>
                                                :
                                                <Typography variant="p" component="div" color="#999" gutterBottom>No address added</Typography>
                                            }

                                            <Button variant="outlined" sx={{ mt: 1 }} name="address" onClick={(e) => triggerModal(e, {}, "add", "Add new address")} > Add new address </Button>
                                        </CardContent>
                                    </>
                                }
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                {loading ?
                                    <Box sx={{ p: 2 }}>
                                        <Skeleton animation="wave" width={'100%'} height={150} sx={{ transform: 'none' }} />
                                    </Box>
                                    :
                                    <CardContent>
                                        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" component="div" sx={{ mb: 0 }}>
                                                Customer Note
                                            </Typography>
                                            <Button variant="text" sx={{ p: 0 }} color={editNote ? "error" : "primary"} onClick={editNoteClick} >
                                                {!editNote ?
                                                    "Edit" : "Cancel"
                                                }
                                            </Button>
                                        </Box>
                                        <Box sx={{ display: 'flex', mt: 1 }}>
                                            {editNote ?
                                                <FormControl fullWidth  >
                                                    <TextField
                                                        id="note"
                                                        name="note"
                                                        value={body.note ? body.note : ""}
                                                        label=""
                                                        onChange={(e) => handleOnchange(e, "")}
                                                        size="small"
                                                        variant="outlined"
                                                        placeholder='Add a note'
                                                    />
                                                </FormControl>
                                                :
                                                <Typography variant="p" component="div" color={body.note ? "" : "#999"} gutterBottom>{body.note ? body.note : "No notes for this user"}</Typography>
                                            }
                                            {editNote &&
                                                <Button variant='contained' size="small" sx={{ ml: 2 }} onClick={updateNote}>Update</Button>
                                            }
                                        </Box>
                                    </CardContent>
                                }
                            </Card>


                        </Grid>
                        {/* <Grid item md={12}>
                            <Divider sx={{ my: "15px" }}></Divider>
                            <Box sx={{ textAlign: "left" }}>
                                <Button variant="contained" color="error" size="large" onClick={deleteUser}>Delete user</Button>

                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt={modalContent.modalShowing === "sendInvite" ? "Send invite" : "Save"}
                hideFooter={modalContent.modalShowing === "manageUser" ? true : false}
                savebtnFunct={savebtnFunct}
            >

                {modalContent.modalShowing === "editUser" ? <>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="First name"
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    value={body.first_name ? body.first_name : ""}
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "body")}
                                    // onBlur={getprofit}
                                    sx={{ m: 0, width: '100%' }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Last name"
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    value={body.last_name ? body.last_name : ""}
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "body")}
                                    sx={{ m: 0, width: '100%' }}

                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={body.email ? body.email : ""}
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "body")}
                                    sx={{ m: 0, width: '100%' }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <PhoneInput
                                    enableSearch={true}
                                    disableAreaCodes={false}
                                    country="in"
                                    value={body.phone}
                                    inputExtrastate={{ name: 'mobile', required: true }}
                                    inputProps={{ maxLength: '17' }}
                                    onChange={(e, x) => handleNumberChange(e, x, "body")}
                                    isValid={(value, data) => {
                                        if (
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length < 9 &&
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length > 0
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    placeholder=""
                                    countryCodeEditable={false}
                                    defaultMask=".. ... ...."
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </> : null}

                {modalContent.modalShowing === "manageUser" &&
                    <>
                        {body.addresses && body.addresses.length > 0 && body.addresses.map((obj) => {
                            return (
                                <Box className="addressHolder" key={obj.id}>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs={6}>
                                            {obj.is_default &&
                                                <Chip sx={{ mb: 1 }} label="Default Address" />
                                            }
                                            {obj.name &&
                                                <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{obj.name}</Typography>
                                            }
                                            {obj.address1 &&
                                                <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{obj.address1}</Typography>
                                            }
                                            <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{obj.province && obj.province}, {obj.city && obj.city}</Typography>
                                            {obj.country &&
                                                <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">{obj.country}</Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: "right", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <Box>
                                                <Button variant="text" sx={{ p: 0 }} name="address" onClick={(e) => triggerModal(e, obj, "edit", "Edit address")} > Edit address </Button>
                                                <Button variant="text" color="error" sx={{ p: 0 }} name="address" onClick={() => deleteAddress(obj)} >
                                                    <DeleteIcon />
                                                </Button>
                                            </Box>
                                            <Box>
                                                {!obj.is_default &&
                                                    <Button variant="outlined" color="primary" onClick={() => makedefault(obj)}> Make default</Button>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        })}
                    </>
                }

                {modalContent.modalShowing === "address" ? <>
                    <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="First name"
                                    type="text"
                                    value={addressData && addressData.first_name}
                                    error={showError && addressData.first_name === "" ? true : false}
                                    helperText={showError && addressData.first_name === "" ? "Can't be blank" : ""}
                                    id="first_name"
                                    name="first_name"
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Last name"
                                    type="text"
                                    id="last_name"
                                    value={addressData && addressData.last_name}
                                    size="small"
                                    name="last_name"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Company"
                                    type="text"
                                    id="company"
                                    value={addressData && addressData.company}
                                    size="small"
                                    name="company"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <PhoneInput
                                    enableSearch={true}
                                    disableAreaCodes={false}
                                    country="in"
                                    value={addressData.phone}
                                    inputExtrastate={{ name: 'mobile', required: true }}
                                    inputProps={{ maxLength: '17' }}
                                    onChange={(e, x) => handleNumberChange(e, x, "address")}
                                    isValid={(value, data) => {
                                        if (
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length < 9 &&
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length > 0
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    placeholder=""
                                    countryCodeEditable={false}
                                    defaultMask=".. ... ...."
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Address line 1"
                                    id="address1"
                                    name="address1"
                                    value={addressData && addressData.address1}
                                    error={showError && addressData.address1 === "" ? true : false}
                                    helperText={showError && addressData.address1 === "" ? "Can't be blank" : ""}
                                    type="text"
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Apartment, building, etc."
                                    id="address2"
                                    name="address2"
                                    value={addressData && addressData.address2}
                                    type="text"
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="City"
                                    id="city"
                                    name="city"
                                    value={addressData && addressData.city}
                                    error={showError && addressData.city === "" ? true : false}
                                    helperText={showError && addressData.city === "" ? "Can't be blank" : ""}
                                    type="text"
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Province"
                                    id="province"
                                    name="province"
                                    value={addressData && addressData.province}
                                    error={showError && addressData.province === "" ? true : false}
                                    helperText={showError && addressData.province === "" ? "Can't be blank" : ""}
                                    type="text"
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Postal code"
                                    id="zip"
                                    name="zip"
                                    value={addressData && addressData.zip}
                                    error={showError && addressData.zip === "" ? true : false}
                                    helperText={showError && addressData.zip === "" ? "Can't be blank" : ""}
                                    type="text"
                                    size="small"
                                    onChange={(e) => handleOnchange(e, "address")}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Country/region</InputLabel>
                                <Select
                                    labelId="countryRegion"
                                    id="countryRegion"
                                    value={country}
                                    name="country"
                                    size="small"
                                    sx={{ mt: 0 }}
                                    onChange={onCountryChange}
                                >
                                    {Countries.map((data, index) => {
                                        return <MenuItem key={index} value={index}>{data.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </> : null}


                {modalContent.modalShowing === "sendInvite" ? <>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="To"
                                    type="text"
                                    id="to"
                                    name="to"
                                    size="small"
                                    value={account.to}
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                    sx={{ m: 0, width: '100%' }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="From"
                                    type="text"
                                    id="from"
                                    name="from"
                                    size="small"
                                    value={from}
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel id="demo-radio-buttons-group-label">Send bcc to:</FormLabel>
                            <FormGroup sx={{ flexDirection: "row" }}>
                                {bcc.length > 0 && bcc.map((opt, index) => {
                                    return (
                                        <FormControlLabel key={index} control={<Checkbox checked={opt.checked} onChange={(e) => handleBCCchange(e, opt)} />} label={opt.email} sx={{ mr: 4 }} />
                                    )
                                })}
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Subject"
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    size="small"
                                    value={account.subject}
                                    onChange={(e) => handleOnchange(e, "account")}
                                    sx={{ m: 0, width: '100%' }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    id="custom_message"
                                    name="custom_message"
                                    label="Custom message (optional)"
                                    value={account.custom_message}
                                    onChange={(e) => handleOnchange(e, "account")}
                                    multiline
                                    rows={4}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </> : null}
            </PopupModal>
            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                smallContent={true}
                confirmDialog={confirmDialog}
            />



        </>
    );
}

export default EditUser