import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, OutlinedInput, FormControl, InputLabel, InputAdornment, Grid, TextField, Divider, Typography, MenuItem, Select, Chip, Stack } from '@mui/material';
import _ from "underscore"
import "./Variants.css"
import config from "../../../../Container/config";

export default function VariantModal({ handleClose, title, handleSave, openModal, handleInputchange, handlevariantSelect, data, body, showEditAll, handleUpdateAll }) {
    // console.log("data: ", data)
    return (
        <Dialog
            maxWidth="sm"
            open={openModal}
            scroll={'paper'}
            onClose={(e, reason)=>handleClose(e, reason)}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Grid container alignItems="center" spacing={2} columns={12}>
                    <Grid item sm={6}>
                        <FormControl fullWidth sx={{ mb: 1 }} >
                            <InputLabel htmlFor="price">Price</InputLabel>
                            <OutlinedInput
                                type="number"
                                id="price"
                                name="price"
                                size="small"
                                value={data && data.price}
                                onChange={handleInputchange}
                                sx={{ m: 0, width: '100%' }}
                                startAdornment={<InputAdornment position="start">{config.CURRENCY}</InputAdornment>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                        <FormControl fullWidth sx={{ mb: 1 }} >
                            <InputLabel htmlFor="compare_at_price">Compare with price</InputLabel>
                            <OutlinedInput
                                type="number"
                                id="compare_at_price"
                                name="compare_at_price"
                                size="small"
                                value={data && data.compare_at_price}
                                onChange={handleInputchange}
                                sx={{ m: 0, width: '100%' }}
                                startAdornment={<InputAdornment position="start">{config.CURRENCY}</InputAdornment>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <Divider sx={{ mb: 2 }}></Divider>
                    </Grid>
                    <Grid item sm={6}>
                        {body.track ?
                            <FormControl fullWidth >
                                <TextField
                                    label="Available Quantity"
                                    value={data && data.quantity}
                                    variant="outlined"
                                    size="small"
                                    name="quantity"
                                    onChange={handleInputchange}
                                />
                            </FormControl>
                            :
                            <Chip label="Not tracked" />
                        }
                    </Grid>
                    <Grid item sm={6}>
                        <Stack direction="row" spacing={0}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Weight"
                                    type="number"
                                    name="weight"
                                    value={data && data.weight}
                                    onChange={handleInputchange}
                                    size="small"
                                    sx={{ mt: 0.5 }}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 0.5 }} fullWidth>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={data && data.weight_unit}
                                    size="small"
                                    name="weight_unit"
                                    onChange={handlevariantSelect}
                                    sx={{ m: 0, marginLeft: "8px" }} >
                                    <MenuItem value={"kg"}>Kg</MenuItem>
                                    <MenuItem value={"lbs"}>LBS</MenuItem>
                                    <MenuItem value={"pound"}>Pound</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                    {!showEditAll &&
                        <>
                            <Grid item sm={12}>
                                <Divider sx={{ mb: 2 }}></Divider>
                            </Grid>
                            <Grid item sm={6}>
                                <FormControl fullWidth sx={{ mb: 1 }} >
                                    <TextField
                                        label="SKU (Stock Keeping Unit)"
                                        value={data && data.sku}
                                        variant="outlined"
                                        size="small"
                                        name="sku"
                                        onChange={handleInputchange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                                <FormControl fullWidth sx={{ mb: 1 }} >
                                    <TextField
                                        label="Barcode (ISBN, UPC, GTIN, etc.) at price"
                                        value={data && data.barcode}
                                        variant="outlined"
                                        size="small"
                                        name="barcode"
                                        onChange={handleInputchange}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    }
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button autoFocus variant="outlined" color="error" onClick={(e, reason)=>handleClose(e, reason)}>
                    Cancel
                </Button>
                {showEditAll ?
                    <Button variant="contained" onClick={handleUpdateAll}>Update all</Button>
                    :
                    <Button variant="contained" onClick={handleSave}>Save changes</Button>
                }
            </DialogActions>
        </Dialog>
    )
}