export default [
    {
        type: "text",
        name: "heading",
        id: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "text",
        name: "link",
        label: "Video link",
        value: "",
        placeholder: ""
    },
    {
        type: "divider"
    },
    {
        type: "select",
        label: "Style",
        name: "video_height",
        value: "large",
        items: [
            { name: "Small", value: "small" },
            { name: "Medium", value: "medium" },
            { name: "Large", value: "large" },
        ]
    },
    {
        type: "select",
        label: "Style",
        name: "style",
        value: "background_video",
        items: [
            { name: "Background video", value: "background_video" },
            { name: "With controls", value: "with_control" }
        ]
    },
    {
        type: "checkbox",
        id: "show_overlay",
        name: "show_overlay",
        label: "Show overlay",
        value: true
    },
]