export default [
    {
        type: "image_file",
        id: "image_src",
        name: "image_src",
        label: "Image",
        note: "",
        height: "150px",
        width: "100%",
        alt_name: "item_img_alt",
        alt: "",
        value: "https://checkout-dev.growcart.app/slide.jpg"
    },
    {
        type: "text",
        name: "heading",
        label: "Heading",
        value: "Lorem ipsum dolor",
        placeholder: ""
    },
    {
        type: "editor",
        name: "text",
        id: "text",
        label: "Text",
        value: "<p></p>",
    },
    {
        type: "text",
        name: "btn_label",
        label: "Button label",
        value: "Shop now",
        placeholder: ""
    },
    {
        type: "text",
        name: "link",
        label: "Link",
        value: "",
        placeholder: ""
    },
]