import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PhotoCamera } from "@material-ui/icons";
import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@mui/system";
import { Card, CardContent, Typography, CardMedia, CardActions, Button, TextField, Divider, IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import gallery from "../../gallery";

export default function ImagePicker({ data, doneUpload, handleAltChange }) {
    const [itemData, setItemData] = useState(gallery);
    const [activeImage, setActiveImage] = useState("")
    const showUploader = (id) => {
        document.getElementById(id).classList.add("active")
    }
    const backToImage = (id) => {
        document.getElementById(id).classList.remove("active")
    }
    const Input = styled('input')({
        display: 'none',
    });

    const clickImage = (index, img) => {
        let newArr = []
        itemData.map((opt) => {
            newArr.push({ ...opt, 'current': false })
        })
        newArr[index].current = true
        setItemData(newArr)
        setActiveImage(img)
    }

    const handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        console.log("file: ", file)
        reader.onloadend = () => {
            setActiveImage(reader.result)
        }
        reader.readAsDataURL(file)
    }
    console.log("image data:111111111", data)
    return (
        <Box sx={{ mb: 3 }}>
            <Box id={data.id} className="uploader_outer">
                <Card sx={{ pb: 1 }} className="pre_uploader">
                    <Box sx={{ p: 1 }}>
                        <CardMedia
                            component="img"
                            width={data.width}
                            image={data.value}
                            alt={data.alt}
                            sx={{ maxHeight: data.height, objectFit: "contain" }}
                        />
                    </Box>
                    <Divider sx={{ pt: 0, mb: 1 }} />
                    <CardContent sx={{ pt: 1 }}>
                        {data.note !== "" &&
                            <Typography variant="caption" component="div" sx={{ mb: 1 }}>
                                <i>{data.note}</i>
                            </Typography>
                        }
                        <TextField
                            label="Alt text"
                            defaultValue={data.alt}
                            onChange={(e)=>handleAltChange(e, data.alt_name)}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </CardContent>
                    <CardActions sx={{ px: 2 }}>
                        <Button fullWidth variant="contained" onClick={() => showUploader(data.id)}>Change</Button>
                    </CardActions>
                </Card>
                <Card sx={{ p: 2 }} className="post_uploader">
                    <Box sx={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
                        <Button startIcon={<ArrowBackIcon />} onClick={() => backToImage(data.id)}>
                            Back
                        </Button>
                        <Button variant="contained" disabled={activeImage === "" ? true : false} onClick={() => doneUpload(data.id, activeImage)}>
                            Done
                        </Button>
                    </Box>
                    <Divider sx={{ pt: 1, mb: 1 }} />
                    <Box sx={{ border: "#ccc 2px dashed", textAlign: 'center', p: 1, cursor: 'pointer' }}>
                        <label htmlFor={`img_${data.id}`}>
                            <Input accept="image/*" id={`img_${data.id}`} type="file" onChange={handleImageChange} />
                            {activeImage === "" ?
                                <>
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                    <Typography variant="p" gutterBottom component="div">
                                        Upload new
                                    </Typography>
                                </>
                                :
                                <Box className="edit_selected_image">
                                    <PhotoCamera fontSize="large" />
                                    <img src={activeImage} alt="" className="img-fluid" />
                                </Box>
                            }
                        </label>
                    </Box>
                    <Box sx={{ width: '100%', height: 250, overflowY: 'scroll', my: 1 }}>
                        <ImageList variant="masonry" cols={3} gap={4} sx={{ mt: 0 }}>
                            {itemData.map((item, index) => (
                                <ImageListItem key={item.img} onClick={() => clickImage(index, item.img)}>
                                    <img
                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                    {item.current &&
                                        <ImageListItemBar
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: '#fff', p: 0 }}
                                                    aria-label={`info about ${item.title}`}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                            }
                                        />
                                    }
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>

                </Card>
            </Box>
        </Box>
    )
}