import React from "react";
import { Checkbox, Chip, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function CreatableSelect({ value, handleCreatableSelect, list, id, multiple, type, showAlert, body }) {
    const filter = createFilterOptions();
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue, reason) => {
                // let val = multiple ? 
                // newValue.filter((ele, ind) => ind === newValue.findIndex(elem => elem.id === ele.id))
                // : newValue
                handleCreatableSelect(event, newValue, reason, id)
            }
            }
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                if (multiple === false) {
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            title: `+ Add "${inputValue}"`,
                        });
                    }
                }
                return filtered;
            }}
            multiple={multiple}
            selectOnFocus
            clearOnBlur
            disableCloseOnSelect={multiple ? true : false}
            handleHomeEndKeys
            id={id}
            options={list}
            limitTags={3}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option.title;
            }}
            // disableClearable={true}
            // renderTags={(value, getTagProps) =>
            //     value.map((option, index) => (
            //         // console.log("option------", option)
            //         <Chip
            //             label={option.title}
            //             {...getTagProps({ index })}
            //         />
            //     ))
            // }
            renderOption={(props, option, { selected }) => (
                <>
                    {selected = multiple && value.filter((opt) => opt.id === option.id).length > 0 ? true : false}
                    {multiple ?
                        <>
                            <li style={{ padding: "0" }} {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        </>
                        :
                        <li {...props}>
                            {option.title}
                        </li>
                    }
                </>
            )
            }
            sx={{ width: "100%" }}
            freeSolo
            renderInput={(params) => (
                <TextField error={showAlert && (body[id] && body[id] === "" || body[id].length === 0) ? true : false} {...params} label={type} size="small" />
            )}
        />
    )
}