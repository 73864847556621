import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import HeadingText from "./HeadingText";
import * as heading from "./Typography/Exports"

export default function ContentBlock() {
    const allData = useSelector((state) => state.dataValue)
    const dataVal = useSelector((state) => state.dataValue.components.content_block)
    return (
        <Box sx={{ py: 5 }} id="content_block">
            <Container maxWidth="lg">
                <HeadingText
                    data={dataVal && dataVal}
                />
                <Grid container spacing={4} alignItems={dataVal && dataVal.alignment === "top" ? "flex-start" : dataVal && dataVal.alignment === "bottom" ? "flex-end" : 'center'} direction={dataVal && dataVal.order === "reverse" ? "row-reverse" : "row"}>
                    <Grid item sm={12} md={6} >
                        <img src={dataVal && dataVal.image} alt="" className="img-fluid" />
                    </Grid>
                    <Grid item sm={12} md={6} >
                        <heading.HeadingH3
                            ishtml={false}
                            type="div"
                            data={dataVal && dataVal.content_heading}
                        />
                        <Typography variant="p" component="div" sx={{ mb: 2 }} dangerouslySetInnerHTML={{ __html: dataVal && dataVal.paragraph }}>
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            href={dataVal && dataVal.button_link}
                            sx={{background: allData.components.colors.button_color, color: allData.components.colors.button_text_color}}
                        >
                            {dataVal && dataVal.button_label}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}