import React, { useEffect, useState, } from 'react';
import {
    Box, Button, Grid,
    Card, CardContent,
    Typography, Container, Tabs,
    Tab, Avatar, FormControlLabel, TextField, FormControl,
    Accordion, AccordionSummary, AccordionDetails, Switch,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import MaterialUISwitch from '../../../common/Switch/SwitchStyle';

import ccAvenue from '../../../../Assets/images/ccAvenue.png';
import payubiz from '../../../../Assets/images/payubiz.png';
import rajorpay from '../../../../Assets/images/rajorpay.png';

import paytm from '../../../../Assets/images/paytm.png';
import cashfreePayments from '../../../../Assets/images/cashfreePayments.png';
import cashondelivery from '../../../../Assets/images/cashondelivery.jpg';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}> {children}   </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const payment = [

]


const Payments = () => {
    const [value, setValue] = useState(0);
    const [payment, setpayment] = useState([
        { id: 0, checked: false, mainkey: "ccAvenue", name: "ccAvenue", img: ccAvenue, secretKey: "", publishKey: "", production: true },
        { id: 1, checked: false, mainkey: "payubiz", name: "Payubiz", img: payubiz, secretKey: "", publishKey: "", production: true },
        { id: 2, checked: true, mainkey: "razorpay", name: "Razorpay", img: rajorpay, secretKey: "", publishKey: "", production: true },
        { id: 3, checked: false, mainkey: "paytm", name: "Paytm", img: paytm, secretKey: "", publishKey: "", production: true },
        { id: 4, checked: false, mainkey: "cashfree", name: "Cash free payments", img: cashfreePayments, secretKey: "", publishKey: "", production: true },
        { id: 5, checked: false, mainkey: "cashondelivery", name: "Cash on delivery", img: cashondelivery, secretKey: "", publishKey: "", production: true },
    ]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleproductionChange = (data) => {
        payment[data].production = !payment[data].production
        setpayment([...payment])


    }
    const changeSwitch = (cntnt) => {
        console.log("cntnt", cntnt)
    }

    console.log("payment: ", payment)

    useEffect(() => {

    }, []);

    console.log("payment -:", payment)
    return (
        <>
            <Container maxWidth="md">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Payments </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>   </Grid>
                    </Grid>
                </Box>
                <Box>
                    {payment.map((data, index) => {
                        return (
                            // disableGutters
                            <Accordion disableGutters key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}-content`} id={`panel1a-header${index}`}>
                                    <Box sx={{ display: "flex", alignItems: "center", }}>
                                        <Avatar src={data.img} sx={{ width: "auto", height: "inherit", maxHeight: '90px', maxWidth: '100px' }} variant="square" />
                                        {/* {data.name} */}
                                        {data.checked ? <CheckOutlinedIcon color="primary" sx={{ ml: 2, }} /> : null}
                                    </Box>
                                </AccordionSummary>
                                {/* mainkey: "cashondelivery" */}
                                <AccordionDetails sx={{ borderTop: "1px solid #ddd" }}>
                                    <Box className="accordianContent">
                                        <Card sx={{ mt: 0, boxShadow: "none" }}>
                                            <CardContent>
                                                <Grid container spacing={0} columns={12} sx={{ alignItems: "center" }}>
                                                    <Grid item md={6}>
                                                        <Box sx={{ display: "flex", alignItems: "center", }}>
                                                            <Typography variant="h6" component="div"> {data.name}</Typography>

                                                            {data.mainkey == "cashondelivery" ? <FormControlLabel sx={{ ml: 2 }} control={
                                                                <Switch />} label="Enable" /> : null}
                                                        </Box>

                                                    </Grid>
                                                    <Grid item md={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                                                        {data.mainkey == "cashondelivery" ? null :
                                                            <>
                                                                <label style={{ marginRight: "8px" }}>Production</label>
                                                                <FormControlLabel
                                                                    control={<MaterialUISwitch color="primary" sx={{ m: 1 }} />}
                                                                    label="Sandbox"
                                                                    color="secondary"
                                                                    checked={data.production ? true : false}
                                                                    // value={production}
                                                                    onChange={() => handleproductionChange(data.id)}
                                                                />
                                                                {/* <label>Sandbox</label> */}
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {data.mainkey == "cashondelivery" ? null : <>
                                                    <Box sx={{ maxWidth: 600, width: "100%", mt: 2.5, mx: "auto", px: 2, }}>
                                                        <FormControl fullWidth sx={{ mb: 2.5 }}>
                                                            <TextField id="secretKey" label="Secret Key" variant="outlined" size="small" />
                                                        </FormControl>
                                                        <FormControl fullWidth sx={{ mb: 2.5 }} >
                                                            <TextField id="publishkey" label="Publish key" variant="outlined" size="small" />
                                                        </FormControl>
                                                        <Box sx={{ display: "flex" }}>
                                                            <Button variant="contained" color="primary">Submit</Button>
                                                            <FormControlLabel sx={{ ml: 2 }} control={<Switch />} label="Enable" />
                                                        </Box>
                                                    </Box>
                                                </>}
                                            </CardContent>
                                        </Card>

                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}

                </Box>
            </Container>
        </>
    );
}

export default Payments