import React, { useCallback, useEffect, useState } from "react";
import { Divider, Link } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ImagePicker from "../Components/ThemeEditor/Layout/Sidebar/Components/ImagePicker";
import TextFields from "../Components/ThemeEditor/Layout/Sidebar/Components/TextField";
import SelectBox from "../Components/ThemeEditor/Layout/Sidebar/Components/Select";
import CheckBox from "../Components/ThemeEditor/Layout/Sidebar/Components/CheckBox";
import RadioButton from "../Components/ThemeEditor/Layout/Sidebar/Components/RadioButton";
import Colors from "../Components/ThemeEditor/Layout/Sidebar/Components/ColorPicker";
import RangeSlider from "../Components/ThemeEditor/Layout/Sidebar/Components/RangeSlider";
import TextEditor from "../Components/ThemeEditor/Layout/Sidebar/Components/TextEditor";
import Heading from "../Components/ThemeEditor/Layout/Sidebar/Components/Heading";
import Header from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Header";
import HeroSlider from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Slider/HeroSlider";
import TextOverImage from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/TextOverImage";
import Gallery from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Gallery/Gallery";
import ProductList from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/ProductList";
import ListItems from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/ListItem/ListItems";
import TextColumnWithImage from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/TextColumnWithImage/TextColumnWithImage";
import ContentBlock from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/ContentBlock";
import BrandList from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/BrandList/BrandList";
import MapBlock from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/MapBlock";
import HeadingBlock from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/HeadingBlock";
import Testimonials from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Testimonials/Testimonials";
import FontFamily from "../Components/ThemeEditor/Layout/Sidebar/Components/FontFamily";
import BlogPost from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/BlogPost";
import { Box } from "@mui/system";
import NavList from "../Components/ThemeEditor/Layout/Sidebar/Components/NavList";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import SlideSettings from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Slider/SlideSettings";
import TextColumnWithImageDetail from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/TextColumnWithImage/TextColumnWithImageDetail";
import ListItemDetail from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/ListItem/ListItemDetail";
import GalleryEdit from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Gallery/GalleryEdit";
import BrandEdit from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/BrandList/BrandEdit";
import TestimonialSettings from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Testimonials/TestimonialSettings";

import update from "immutability-helper";
import schema from "./schema.js";
import settingSchema from "./setting-schema";
import htmlToDraft from "html-to-draftjs";

import { dataValue } from "../Components/ThemeEditor/Layout/CommonReducer";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useLocation, useNavigate } from "react-router-dom";
import Newsletter from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Newsletter";
import Video from "../Components/ThemeEditor/Layout/Sidebar/Components/Common/Video";
import HeadingFlex from "../Components/ThemeEditor/Layout/Sidebar/Components/HeadingFlex";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useUnload } from "./config/Apis";
// import HeadingBlock from "../Components/ThemeEditor/Layout/Container/Components/HeadingBlock";


export const drawerWidth = 220;

export const renderImport = (arr) => {
    return (
        <React.Fragment>
            {arr.length > 0 && arr.map((opt, index) => {
                return (
                    <Box key={index.toString()}>
                        <RenderFn data={opt} />
                    </Box>
                )
            })}
        </React.Fragment>
    )
}


export default function RenderFn({ data }) {
    const params = new URLSearchParams(window.location.search)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [schemaData, setSchemaData] = useState(schema)
    const [main, setMain] = useState([])
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const location = useLocation()
    const [activeDetail, setactiveDetail] = useState([])
    const [array, setArray] = useState([])
    const [disableBtn, setdisableBtn] = useState(false)

    const [contentState, setcontentState] = useState()

    console.log("schemaData: ", schemaData)
    //Default dispatch function
    const dispatchFn = useCallback((id, val) => {
        let currentId = {}
        let newObj = {}
        let newOpt = {}
        let currentIndex = 0
        if (params.get('item')) {
            let schemaDataItems = [...schemaData.components[params.get('type')].items]
            schemaDataItems.filter((obj) => obj.id && obj.id.toString() === params.get('item').toString()).map((opt) => {
                newOpt = { ...opt }
                Object.assign(newOpt, {
                    [id]: val
                })
                currentIndex = _.findLastIndex(schemaDataItems, { 'id': opt.id })
            })
            schemaDataItems[currentIndex] = newOpt
            currentId = schemaData.components[params.get('type')]
            newObj = { ...currentId, 'items': schemaDataItems }
        } else {
            currentId = schemaData.components[params.get('type')]
            newObj = { ...currentId, [id]: val }
        }
        currentId = Object.assign({}, schemaData, {
            'components': Object.assign({}, schemaData.components, {
                [params.get('type')]: newObj
            })
        })
        Object.assign(schemaData, currentId)
        setSchemaData(schemaData)
        // document.getElementById('customizer').contentWindow.location.reload(true)
        
    }, [main, schemaData])


    const updateTextDescription = async (state, name) => {
        await setEditorState(state);
        // const htmlData = draftToHtml(
        //     convertToRaw(editorState && editorState.getCurrentContent())
        // )
        // setMain([...main])
    }

    useUnload(e => {
        e.preventDefault();
        // e.returnValue = '';
    });

    const onContentStateChange = useCallback(async (contentState, name) => {
        setcontentState(draftToHtml(contentState))
        dispatchFn(name, draftToHtml(contentState))
        main.map((opt) => {
            // console.log("opt: ", opt)
            let i = ""
            if (opt.name && opt.name === name) {
                console.log("opt editor: ", opt)
                i = _.findLastIndex(main, { id: opt.id })
                if (i !== -1) {
                    main[i].value = draftToHtml(contentState)
                }
            }
        })
        // setMain([...main])
    }, [main, schemaData])

    //Range slider onchange
    const rangeValue = useCallback((e, value, thumb, unit) => {
        dispatchFn(e.target.name, value)
        main.filter((obj) => obj.name === e.target.name).map((opt) => {
            opt.value = value
        })
        setMain([...main])
        return `${value} ${unit}`;
    }, [main, schemaData])

    //Color picker on change
    const handleColorChange = useCallback((name, val) => {
        dispatchFn(name, `#${val.hex}`)
        main.filter((obj) => obj.name === name).map((opt) => {
            opt.value = `#${val.hex}`
        })
        setMain([...main])
    }, [main, schemaData])

    //Radio button on change
    const handleRadioChange = useCallback((e, id) => {
        dispatchFn(e.target.name, e.target.value)
        main.filter((obj) => obj.name === e.target.name).map((opt) => {
            opt.options.map((val) => {
                if (val.id !== id) {
                    val.checked = false
                } else {
                    val.checked = e.target.checked
                }
            })
        })
        setMain([...main])
    }, [main, schemaData])

    //Checkbox button on change
    const handleCheckboxChange = useCallback((e) => {
        dispatchFn(e.target.name, e.target.checked)
        main.filter((obj) => obj.name === e.target.name).map((opt) => {
            opt.value = e.target.checked
        })
        setMain([...main])
    }, [main, schemaData])

    //image uploader
    const doneUpload = useCallback((id, img) => {
        dispatchFn(id, img)
        main.filter((obj) => obj.id === id).map((opt) => {
            opt.value = img
        })
        setMain([...main])
    }, [main, schemaData])

    //Text/input change
    const handleInputChange = useCallback((e) => {
        dispatchFn(e.target.name, e.target.value)
        main.filter((obj) => obj.name === e.target.name).map((opt) => {
            console.log("opt: ", opt)
            opt.value = e.target.value
        })
        setMain([...main])
    }, [main, schemaData])

    //Select change
    const handleSelectChange = useCallback((e, name) => {
        dispatchFn(name, e.target.value)
        main.filter((obj) => obj.name === name).map((opt) => {
            opt.value = e.target.value
        })
        setMain([...main])
    }, [main, schemaData])

    const handleAltChange = useCallback((e, name) => {
        dispatchFn(name, e.target.value)
        main.filter((obj) => obj.alt_name === name).map((opt) => {
            opt.alt = e.target.value
        })
        setMain([...main])
    }, [main, schemaData])

    const addNewItem = useCallback(() => {
        let schemaDataItems = [...schemaData.components[params.get('type')].items]
        let newObj = {}
        schemaDataItems.map((opt) => {
            newObj = { ...opt }
        })
        _.each(newObj, (val, key) => {
            if (key === "id") {
                newObj[key] = val + 1
            } else if (key === "image_src") {
                newObj[key] = "https://checkout-dev.growcart.app/slide.jpg"
            } else {
                newObj[key] = ""
            }
        })
        schemaDataItems.push(newObj)

        let currentId = schemaData.components[params.get('type')]
        newObj = { ...currentId, 'items': schemaDataItems }

        currentId = Object.assign({}, schemaData, {
            'components': Object.assign({}, schemaData.components, {
                [params.get('type')]: newObj
            })
        })
        Object.assign(schemaData, currentId)
        setSchemaData(schemaData)
        updateArray()
        dispatch(dataValue(schemaData))
    }, [main, schemaData])

    const editItem = useCallback((data) => {
        let pageType = "item"
        if (params.get(pageType) && params.get(pageType).toString() !== data.id.toString()) {
            params.set(pageType, data.id)
            updateMain(true)
        } else if (params.get(pageType) && params.get(pageType).toString() === data.id.toString()) {
            params.delete(pageType)
            updateMain(false)
        } else {
            params.append(pageType, data.id)
            updateMain(true)
        }
        const to = { pathname: location.pathname, search: params.toString() };
        navigate(to, { replace: true });

    }, [activeDetail, main, schemaData])

    const updateMain = useCallback((type) => {
        if (params.get('type')) {
            if (type) {
                let schemaDataItems = [...schemaData.components[params.get('type')].items]
                let currentIndex = ""
                let filterData = schemaDataItems.filter((obj) => obj.id && obj.id.toString() === params.get('item').toString())
                let newArr = []
                let main = ListItemDetail

                if (params.get('type') === "hero_slider") {
                    main = SlideSettings
                } else if (params.get('type') === "text_column_with_image") {
                    main = TextColumnWithImageDetail
                } else if (params.get('type') === "gallery") {
                    main = GalleryEdit
                } else if (params.get('type') === "brands_list") {
                    main = BrandEdit
                } else if (params.get('type') === "testimonials") {
                    main = TestimonialSettings
                } else {
                    main = ListItemDetail
                }
                if (filterData.length > 0) {
                    filterData.map((obj) => {
                        currentIndex = _.findLastIndex(schemaDataItems, { 'id': obj.id })
                        main.map((val) => {
                            if (val.type === "editor") {
                                console.log("val: ", val)
                                const contentBlock = htmlToDraft(obj[val.name]);
                                if (contentBlock) {
                                    const contentState = ContentState.createFromBlockArray(
                                        contentBlock.contentBlocks
                                    );
                                    const editorState = EditorState.createWithContent(contentState);
                                    setEditorState(editorState)
                                }
                            }

                            newArr.push({ ...val, 'value': obj[val.name] })
                        })
                        // opt.value = schemaDataItems[currentIndex][opt.name]
                    })
                } else {
                    navigate(`?context=page&type=${params.get('type')}`)
                    window.location.reload(false)
                }
                console.log("filterData: ", filterData)
                console.log("newArr: ", newArr)

                setactiveDetail(newArr)
                // setMain([...newArr])

            } else {
                main.map((opt) => {
                    if (opt.type !== "heading") {
                        opt.value = !_.isEmpty(schemaData) && schemaData.components[params.get('type')][opt.name]
                    }
                    if (opt.type === "editor") {
                        // let editorData = EditorState.createWithContent(
                        //     ContentState.createFromBlockArray(
                        //         convertFromHTML(opt.value)
                        //     )
                        // )
                        // setEditorState(editorData)
                        const contentBlock = htmlToDraft(opt.value);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(
                                contentBlock.contentBlocks
                            );
                            const editorState = EditorState.createWithContent(contentState);
                            setEditorState(editorState)
                        }
                    }
                })
            }
            // console.log(opt, "=====", opt.name, "----", opt.value, "))))))))", schemaData.components[params.get('type')][opt.name])
        }
    }, [main, schemaData, activeDetail])

    const updateArray = useCallback(() => {
        if (!_.isEmpty(schemaData) && schemaData.components[params.get('type')] && schemaData.components[params.get('type')].items && schemaData.components[params.get('type')].items.length > 0) {
            setArray(schemaData.components[params.get('type')].items)
            if (schemaData.components[params.get('type')].items.length < schemaData.components[params.get('type')].limit) {
                setdisableBtn(false)
            } else {
                setdisableBtn(true)
            }
        }
    }, [array, activeDetail])

    const deleteItem = (data) => {
        let schemaDataItems = [...schemaData.components[params.get('type')].items]
        let currentIndex = _.findLastIndex(schemaDataItems, { 'id': data.id })
        if (currentIndex !== -1 && schemaDataItems.length > 1) {
            schemaDataItems.splice(currentIndex, 1)
            let currentId = schemaData.components[params.get('type')]
            let newObj = { ...currentId, 'items': schemaDataItems }
            currentId = Object.assign({}, schemaData, {
                'components': Object.assign({}, schemaData.components, {
                    [params.get('type')]: newObj
                })
            })
            Object.assign(schemaData, currentId)
            setSchemaData(schemaData)
            updateArray()
            dispatch(dataValue(schemaData))
        }
    }


    // console.log("main: ", main)

    useEffect(() => {
        if (params.get('type') === "header") {
            setMain(Header)
        }
        else if (params.get('type') === "hero_slider") {
            // if (params.get('item')) {
            //     setMain(SlideSettings)
            // } else {
            setMain(HeroSlider)
            // }
        }
        else if (params.get('type') === "text_over_image") {
            setMain(TextOverImage)
        }
        else if (params.get('type') === "product_list") {
            setMain(ProductList)
        }
        else if (params.get('type') === "text_column_with_image") {
            setMain(TextColumnWithImage)
        }
        else if (params.get('type') === "gallery") {
            setMain(Gallery)
        }
        else if (params.get('type') === "content_block") {
            setMain(ContentBlock)
        }
        else if (params.get('type') === "brands_list") {
            setMain(BrandList)
        }
        else if (params.get('type') === "map") {
            setMain(MapBlock)
        }
        else if (params.get('type') === "heading_block") {
            setMain(HeadingBlock)
        }
        else if (params.get('type') === "testimonials") {
            setMain(Testimonials)
        }
        else if (params.get('type') === "blog_post") {
            setMain(BlogPost)
        }
        else if (params.get('type') === "newsletter") {
            setMain(Newsletter)
        }
        else if (params.get('type') === "video") {
            setMain(Video)
        }
        else {
            let currentObj = {}
            settingSchema.filter((opt) => opt.type === params.get('type')).map((val) => {
                currentObj = val.settings
            })
            setMain(currentObj)
        }

        if (params.get('item')) {
            updateMain(true)
        } else {
            updateMain(false)
        }
        // if (params.get('item')) {
        updateArray()
        // }

        // console.log("schemaData: ", schemaData)
        if (params.get('context') === "page" && !_.isEmpty(schemaData) && schemaData.active.filter((opt) => opt === params.get('type')).length == 0) {
            navigate('/customizer')
        }
        dispatch(dataValue(schemaData))
    }, [main, schemaData])
    // console.log("main: ", main)

    return (
        <>
            {data.type === "heading" &&
                <Heading
                    data={data}
                />
            }
            {data.type === "heading_flex" &&
                <HeadingFlex
                    data={data}
                />
            }
            {data.type === "font_family" &&
                <FontFamily
                    data={data}
                    handleFontChange={handleSelectChange}
                />
            }
            {data.type === "range" &&
                <RangeSlider
                    data={data}
                    rangeValue={rangeValue}
                />
            }
            {data.type === "color_picker" &&
                <Colors
                    data={data}
                    handleColorChange={handleColorChange}
                />
            }
            {data.type === "image_file" &&
                <ImagePicker
                    data={data}
                    doneUpload={doneUpload}
                    handleAltChange={handleAltChange}
                />
            }
            {data.type === "checkbox" &&
                <CheckBox
                    data={data}
                    handleCheckboxChange={handleCheckboxChange}
                />
            }
            {data.type === "radio" &&
                <RadioButton
                    data={data}
                    handleRadioChange={handleRadioChange}
                />
            }
            {data.type === "text" || data.type === "number" ?
                <TextFields
                    data={data}
                    handleInputChange={handleInputChange}
                /> : null
            }
            {data.type === "select" &&
                <SelectBox
                    data={data}
                    handleSelectChange={handleSelectChange}
                />
            }
            {data.type === "nav_list" &&
                <NavList
                    data={data}
                />
            }
            {data.type === "divider" &&
                <Divider sx={{ my: 2 }} />
            }
            {data.type === "list_item" ?

                <DndProvider backend={HTML5Backend}>
                    <ListItems
                        data={data}
                        addNewItem={addNewItem}
                        editItem={editItem}
                        deleteItem={deleteItem}
                        array={array}
                        activeDetail={activeDetail}
                        disableBtn={disableBtn}
                        handleInputChange={handleInputChange}
                    />
                </DndProvider>
                : null
            }
            {data.type === "editor" &&
                <TextEditor
                    data={data}
                    editorState={editorState}
                    updateTextDescription={updateTextDescription}
                    onContentStateChange={onContentStateChange}
                />
            }
            {data.type === "header" ||
                data.type === "hero_slider" ||
                data.type === "text_over_image" ||
                data.type === "product_list" ||
                data.type === "text_column_with_image" ||
                data.type === "gallery" ||
                data.type === "content_block" ||
                data.type === "brands_list" ||
                data.type === "map" ||
                data.type === "heading_text" ||
                data.type === "heading_block" ||
                data.type === "testimonials" ||
                data.type === "newsletter" ||
                data.type === "video" ||
                data.type === "blog_post" ?
                renderImport(main)
                : null
            }
        </>
    )
}
