import React from "react";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ReactPlayer from "react-player"
import * as Api from '../../../Container/config/Apis';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function MediaFiles({onVariantFileAdd, images, chooseImage}) {
    return (
        <Grid container spacing={2} columns={12}>
            <Grid item md={3}>
                <Box className="customFileUpload" sx={{height: "100%"}}>
                    <input
                        accept=".jpg,.jpeg,.png,.webp,.svg"
                        type='file'
                        onChange={onVariantFileAdd}
                    />
                    <label style={{ textAlign: 'center' }}>
                        <FileUploadIcon fontSize='small' sx={{ verticalAlign: "middle", display: "block", margin: "auto" }} />
                        Add new
                    </label>
                </Box>
            </Grid>
            {images.length > 0 && images.map((img) => {

                return (
                    <Grid key={img.id} item md={3}>
                        <Button sx={{ position: 'relative', border: "#eee 1px solid", width: "100%", height: "100%" }} onClick={() => chooseImage(img)}>
                            {Api.getId(img.src) === null || Api.getId(img.src) === undefined ?
                                <img src={img.src} alt="" />
                                :
                                <ReactPlayer
                                    url={img.src}
                                    controls={false}
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 0 }
                                        }
                                    }}
                                    className="video_preview_product"
                                    width={"100%"}
                                    height={"100%"}
                                    playsinline={true}
                                    playbackRate={0}
                                    light={true}
                                />
                            }
                            {img.active &&
                                <CheckCircleIcon className='float-top-left' />
                            }
                        </Button>
                    </Grid>
                )
            })}
        </Grid>
    )
}