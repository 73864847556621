import React, { useCallback, useEffect, useState } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, FormHelperText, Typography, FormGroup, Select, TextField, InputBase, Container,
    Autocomplete, InputLabel

} from '@mui/material';
import { useSnackbar } from 'notistack';

import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Countries from '../../../common/CommonJason/Countries';
import PhoneInput from 'react-phone-input-2'
import * as Api from '../../../../Container/config/Apis';
import config from '../../../../Container/config';
import _ from "underscore"
import 'react-phone-input-2/lib/style.css'

// file drag drop  

const AddUser = () => {
    const param = useParams()
    const navigate = useNavigate()
    const [body, setBody] = useState({
        accepts_marketing: false,
        email: "",
        first_name: "",
        last_name: "",
        state: "enabled",
        phone: "",
        tax_exempt: false,
        note: "",
        address: {
            first_name: "",
            last_name: "",
            address1: "",
            address2: "",
            city: "",
            company: "",
            country: "India",
            country_code: "IN",
            province: "",
            phone: "",
            zip: ""
        },
        // tags: []
    });
    const [country, setCountry] = useState(103)
    const [showError, setshowError] = useState(false)

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const handleOnchange = (e, type) => {
        if (type === "address") {
            setBody({
                ...body, 'address': {
                    ...body.address,
                    [e.target.name]: e.target.value
                }
            })
        } else {
            setBody({ ...body, [e.target.name]: e.target.value })
        }
    }
    const handleCheckbox = (e) => {
        setBody({ ...body, [e.target.name]: e.target.checked })
    };
    const onSelectChange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    };
    const onCountryChange = (e) => {
        console.log(Countries[e.target.value])
        setCountry(e.target.value)
        setBody({
            ...body, 'address': {
                ...body.address,
                [e.target.name]: Countries[e.target.value].label,
                'country_code': Countries[e.target.value].code
            }
        })
    };

    const handleNumberChange = (value, data, type) => {
        if (type === "address") {
            setBody({
                ...body, 'address': {
                    ...body.address,
                    'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`
                }
            })
        } else {
            setBody({ ...body, 'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}` })
        }
    };

    console.log("body: ", body)
    // console.log("Countries: ", Countries)

    const userFn = async (api, url, data) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                setTimeout(() => {
                    navigate('/users')
                }, 1000);
            }
        })
    }
    function cleanFn(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || obj[propName] === []) {
                delete obj[propName];
            }
        }
        return obj
    }
    function saveUser() {
        if (body.first_name === "" || body.last_name === "" || body.email === "") {
            setshowError(true)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            userFn(Api.PostApi, config.USER_LIST, cleanFn(body))
        }
    }
    return (
        <Container maxWidth="lg">
            <Box sx={{ mb: 2 }}>
                <Grid sx={{ justifyContent: 'center' }} container spacing={2} columns={12}>
                    <Grid item md={8}>
                        <Button component={Link} variant="text" to="/users" startIcon={<ArrowBackIosIcon />}> Add User </Button>
                    </Grid>

                </Grid>
            </Box>
            <Box>
                <Grid sx={{ justifyContent: 'center' }} container spacing={2} columns={12}>
                    <Grid item md={8}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="h6" gutterBottom>User overview</Typography>
                                <Grid container spacing={2} columns={12}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ m: 0 }} >
                                            <TextField
                                                label="First name"
                                                type="text"
                                                value={body.first_name}
                                                id="first_name"
                                                error={showError && body.first_name === "" ? true : false}
                                                helperText={showError && body.first_name === "" ? "First name can't be blank" : ""}
                                                name="first_name"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "")}
                                                // onBlur={getprofit}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Last name"
                                                type="text"
                                                id="last_name"
                                                value={body.last_name}
                                                error={showError && body.last_name === "" ? true : false}
                                                helperText={showError && body.last_name === "" ? "Last name can't be blank" : ""}
                                                size="small"
                                                name="last_name"
                                                onChange={(e) => handleOnchange(e, "")}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Email"
                                                type="email"
                                                id="email"
                                                value={body.email}
                                                error={showError && body.email === "" ? true : false}
                                                helperText={showError && body.email === "" ? "Email can't be blank" : ""}
                                                size="small"
                                                name="email"
                                                onChange={(e) => handleOnchange(e, "")}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <PhoneInput
                                                enableSearch={true}
                                                disableAreaCodes={false}
                                                country="in"
                                                value={body.phone}
                                                inputExtrastate={{ name: 'mobile', required: true }}
                                                inputProps={{ maxLength: '17' }}
                                                onChange={(e, x) => handleNumberChange(e, x, "")}
                                                isValid={(value, data) => {
                                                    if (
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length < 9 &&
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length > 0
                                                    ) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder=""
                                                countryCodeEditable={false}
                                                defaultMask=".. ... ...."
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: "15px" }}></Divider>
                                <Grid container spacing={2} columns={12}>

                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            name='accepts_marketing'
                                            control={<Checkbox checked={body.accepts_marketing} onChange={handleCheckbox} />}
                                            label="Agreed to receive marketing emails."
                                        />
                                        <Typography variant="caption" component="div" sx={{opacity: "0.7"}}>*Please ask your customers before you subscribe them to your marketing emails or SMS.</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="h6" gutterBottom>Address</Typography>
                                <Typography variant="body1" component="h6" gutterBottom>The primary address of this customer</Typography>
                                <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ m: 0 }} >
                                            <TextField
                                                label="First name"
                                                type="text"
                                                value={body.address && body.address.first_name}
                                                id="first_name"
                                                name="first_name"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Last name"
                                                type="text"
                                                id="last_name"
                                                value={body.address && body.address.last_name}
                                                size="small"
                                                name="last_name"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Company"
                                                type="text"
                                                id="company"
                                                value={body.address && body.address.company}
                                                size="small"
                                                name="company"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <PhoneInput
                                                enableSearch={true}
                                                disableAreaCodes={false}
                                                country="in"
                                                value={body.address.phone}
                                                inputExtrastate={{ name: 'mobile', required: true }}
                                                inputProps={{ maxLength: '17' }}
                                                onChange={(e, x) => handleNumberChange(e, x, "address")}
                                                isValid={(value, data) => {
                                                    if (
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length < 9 &&
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length > 0
                                                    ) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder=""
                                                countryCodeEditable={false}
                                                defaultMask=".. ... ...."
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="Address line 1"
                                                id="address1"
                                                name="address1"
                                                value={body.address && body.address.address1}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="Apartment, building, etc."
                                                id="address2"
                                                name="address2"
                                                value={body.address && body.address.address2}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="City"
                                                id="city"
                                                name="city"
                                                value={body.address && body.address.city}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="Province"
                                                id="province"
                                                name="province"
                                                value={body.address && body.address.province}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="Postal code"
                                                id="zip"
                                                name="zip"
                                                value={body.address && body.address.zip}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address")}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Country/region</InputLabel>
                                            <Select
                                                labelId="countryRegion"
                                                id="countryRegion"
                                                value={country}
                                                name="country"
                                                size="small"
                                                sx={{ mt: 0 }}
                                                onChange={onCountryChange}
                                            >
                                                {Countries.map((data, index) => {
                                                    return <MenuItem key={index} value={index}>{data.label}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Box sx={{ pt: 3 }}>
                                    <Typography variant="h6" component="h6" gutterBottom>Tax exemptions</Typography>
                                    <FormControl fullWidth sx={{ m: 0 }}>

                                        <FormControlLabel name='tax_exempt' control={<Checkbox checked={body.tax_exempt} onChange={handleCheckbox} />} label="Collect tax" />
                                    </FormControl>
                                </Box>
                                <Box sx={{ pt: 3 }}>
                                    <Typography variant="h6" component="h6" gutterBottom>Notes</Typography>
                                    <Typography variant="body1" gutterBottom >Add notes about your customer.</Typography>
                                    <FormControl fullWidth sx={{ m: 0 }}>
                                        <TextField
                                            label=""
                                            type="text"
                                            id="note"
                                            value={body.note}
                                            name="note"
                                            multiline={true}
                                            onChange={(e) => handleOnchange(e, "")}
                                            size="small"
                                        />
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={8}>
                        <Divider sx={{ my: "15px" }}></Divider>
                        <Box sx={{ textAlign: "left" }}>
                            <Button variant="contained" color="error" size="large" >Cancel</Button>
                            <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }} onClick={saveUser}>Save</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default AddUser