import React, { useCallback, useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent, FormControl, 
    Divider, Typography, TextField, Container, InputLabel, 
    Select, MenuItem, IconButton, Avatar, FormHelperText
} from '@mui/material'; 

import StorefrontIcon from '@mui/icons-material/Storefront';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import MapIcon from '@mui/icons-material/Map';
import TimeZone from '../../../common/CommonJason/TimeZone'; 

import Countries from '../../../common/CommonJason/Countries';
import PopupModal from '../../../common/PopupModal/PopupModal'

const StoreDetails = () => {
    const [body, setBody] = useState({
        status: "active"
    });

    const [selectValue, setSelectValue] = useState({
        timeZone: 91,
        unitSystem: "metric",
        unitSuffix: "kilogram",
        storeType: 'other',
    })
    const [ordrFormat, setOrdrFormat] = useState({
        prefix: '#',
        suffix: ''
    })
    const [selectCountry, setSelectCountry] = useState(103)
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })
    const unitSystem = [
        { label: "Metric system", value: "metric", },
        { label: "Imperial system", value: "imperial", },
    ]
    const unitSuffix = [
        { label: "Kilogram (kg)", value: "kilogram", key: "metric", },
        { label: "Gram (g)", value: "gram", key: "metric", },
        { label: "Pound (lb)", value: "pound", key: "imperial", },
        { label: "Ounce (oz)", value: "ounce", key: "imperial", }
    ]

    const storeType = [
        { value: "beauty", label: "Beauty" },
        { value: "clothing", label: "Clothing" },
        { value: "electronics", label: "Electronics" },
        { value: "furniture", label: "Furniture" },
        { value: "handcrafts", label: "Handcrafts" },
        { value: "jewelry", label: "Jewelry" },
        { value: "painting", label: "Painting" },
        { value: "photography", label: "Photography" },
        { value: "restaurants", label: "Restaurants" },
        { value: "groceries", label: "Groceries" },
        { value: "other_food_drink", label: "Other food &amp; drink" },
        { value: "sports", label: "Sports" },
        { value: "toys", label: "Toys" },
        { value: "services", label: "Services" },
        { value: "virtual_services", label: "Virtual services" },
        { value: "other", label: "Other" },
        { value: "do_not_know", label: "I haven’t decided yet" },
    ]
    const ModalOpen = () => setOpenModal(true);
    const ModalClose = () => setOpenModal(false);
    const triggerModal = (name, title) => {
        setOpenModal(true);
        setModalContent({ "modalShowing": name, "modalTitle": title })
        console.log("modalContent.modalShowing", modalContent.modalShowing)
    }
    const countrySelect = (event) => {
        setSelectCountry(event.target.value);
    };
    const selectValueFunction = useCallback((e) => {
        if (e.target.name === "unitSystem") {
            let activeVal = unitSuffix.filter((optn) => optn.key === e.target.value)[0]
            setSelectValue({ ...selectValue, 'unitSuffix': activeVal.value, [e.target.name]: e.target.value })
        } else {
            setSelectValue({ ...selectValue, [e.target.name]: e.target.value })
        }
    }, [selectValue])
    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const ordrFormatChange = (e) => {
        setOrdrFormat({ ...ordrFormat, [e.target.name]: e.target.value })
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Store details</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>   </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={6}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }}>
                                        <Box>
                                            <Typography variant="p" component="strong" gutterBottom>Basic information </Typography>
                                        </Box>
                                        <Box>
                                            <IconButton onClick={() => triggerModal("basicInformation", "Edit basic information")} >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={6}>
                                            <Card sx={{ display: 'flex', alignItems: "center", boxShadow: "none", }}>
                                                <Box sx={{ width: 40 }}>
                                                    <Avatar sx={{ bgcolor: '#008060' }} >
                                                        <StorefrontIcon />
                                                    </Avatar>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                                    <Typography component="div" variant="p" sx={{ lineHeight: "normal" }}> VSD22  </Typography>
                                                    <Typography variant="body2" color="text.secondary" component="div" sx={{ lineHeight: "normal" }}>  vsd22</Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Card sx={{ display: 'flex', alignItems: "center", boxShadow: "none", }}>
                                                <Box sx={{ width: 40 }}>
                                                    <Avatar sx={{ bgcolor: '#008060' }} >
                                                        <FactoryOutlinedIcon />
                                                    </Avatar>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                                    <Typography component="div" variant="p" sx={{ lineHeight: "normal" }}> Industry  </Typography>
                                                    <Typography variant="body2" color="text.secondary" component="div" sx={{ lineHeight: "normal" }}> Services</Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={6}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }}>
                                        <Box>
                                            <Typography variant="p" component="strong" gutterBottom>Address </Typography>
                                        </Box>
                                        <Box>
                                            <IconButton onClick={() => triggerModal("address", "Edit address")}>
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Card sx={{ display: 'flex', alignItems: "center", boxShadow: "none", }}>
                                        <Box sx={{ width: 40 }}>
                                            <Avatar sx={{ bgcolor: '#008060' }} >
                                                <MapIcon />
                                            </Avatar>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                            <Typography component="div" variant="p" sx={{ lineHeight: "normal" }}> VSD22, 624, Tower - A, 6th Floor, Bestech Business Towers, Sector-66, 140603 Mohali Punjab, India  </Typography>
                                        </Box>
                                    </Card>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }}>
                                        <Box>
                                            <Typography variant="p" component="strong" gutterBottom>Contact information  </Typography>
                                        </Box>
                                        <Box>
                                            <IconButton onClick={() => triggerModal("contactInformation", "Contact information ")}>
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>

                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={6}>
                                            <Card sx={{ display: 'flex', alignItems: "center", boxShadow: "none", }}>
                                                <Box sx={{ width: 40 }}>
                                                    <Avatar sx={{ bgcolor: '#008060' }} >
                                                        <CallOutlinedIcon />
                                                    </Avatar>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                                    <Typography component="strong" variant="p" sx={{ lineHeight: "normal" }}> 9876543210  </Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Card sx={{ display: 'flex', alignItems: "center", boxShadow: "none", }}>
                                                <Box sx={{ width: 40 }}>
                                                    <Avatar sx={{ bgcolor: '#008060' }} >
                                                        <MailOutlinedIcon />
                                                    </Avatar>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                                    <Typography component="strong" variant="p" sx={{ lineHeight: "normal" }}> villiamjit@weoinfotech.com  </Typography>
                                                    <Typography variant="body1" color="text.secondary" component="div" sx={{ lineHeight: "normal" }}>  Shopify uses this to contact you</Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Card sx={{ display: 'flex', alignItems: "center", boxShadow: "none", }}>
                                                <Box sx={{ width: 40 }}>
                                                    <Avatar sx={{ bgcolor: '#008060' }} >
                                                        <MailOutlinedIcon />
                                                    </Avatar>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, pl: 2, textAlign: "left", }}>
                                                    <Typography component="strong" variant="p" sx={{ lineHeight: "normal" }}> villiamjit@weoinfotech.com  </Typography>
                                                    <Typography variant="p" color="text.secondary" component="div" sx={{ lineHeight: "normal" }}>  Shopify uses this to contact you</Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 2, }}>
                                        <Box>
                                            <Typography variant="p" component="strong" gutterBottom>Standards and formats</Typography>
                                            <Typography variant="body2" component="div" gutterBottom>Used to calculate product prices, shipping weights, and order times.</Typography>
                                        </Box>
                                    </Box>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={8}>
                                            <Grid container spacing={2} columns={12}>
                                                <Grid item md={12}>
                                                    <FormControl fullWidth sx={{ margin: "0px" }}>
                                                        <InputLabel id="">Time zone</InputLabel>
                                                        <Select
                                                            labelId="Timezone"
                                                            id="Timezone"
                                                            value={selectValue.timeZone}
                                                            label="Time zone"
                                                            size="small"
                                                            name="timeZone"
                                                            onChange={selectValueFunction}
                                                        >
                                                            {TimeZone.map((data, index) => {
                                                                return <MenuItem key={index} value={index}> {data.label}   </MenuItem>
                                                            })}


                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <FormControl fullWidth sx={{ margin: "0px" }}>
                                                        <InputLabel id="">Unit system</InputLabel>
                                                        <Select
                                                            labelId="UnitSystem"
                                                            id="UnitSystem"
                                                            value={selectValue.unitSystem}
                                                            label="Unit system"
                                                            size="small"
                                                            name="unitSystem"
                                                            onChange={selectValueFunction} >
                                                            {unitSystem.map((data, index) => {
                                                                return <MenuItem key={index} value={data.value}> {data.label}   </MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <FormControl fullWidth sx={{ margin: "0px" }}>
                                                        <InputLabel id="">Default weight unit</InputLabel>
                                                        <Select
                                                            labelId="unitSuffix"
                                                            id="unitSuffix"
                                                            value={selectValue.unitSuffix}
                                                            label="Default weight unit"
                                                            size="small"
                                                            name="unitSuffix"
                                                            onChange={selectValueFunction}
                                                        >
                                                            {unitSuffix.filter((optn) => optn.key === selectValue.unitSystem).map((data, index) => {
                                                                return <MenuItem key={index} value={data.value}> {data.label} </MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Box sx={{ mb: 2, }}>
                                        <Box>
                                            <Typography variant="p" component="strong" gutterBottom>Edit order id format</Typography>
                                            <Typography variant="body2" component="div" gutterBottom>Order numbers start at #1001 by default. While you can’t change the order number itself, you can add a prefix or suffix to create IDs like "EN1001" or "1001-A."
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={8}>
                                            <Grid container spacing={2} columns={12}>
                                                <Grid item md={6}>
                                                    <FormControl fullWidth sx={{ m: 0 }} >
                                                        <TextField
                                                            type="text"
                                                            id="Prefix"
                                                            name="prefix"
                                                            size="small"
                                                            label="Prefix"
                                                            value={ordrFormat.prefix}
                                                            onChange={ordrFormatChange}
                                                            sx={{ m: 0, }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <FormControl fullWidth sx={{ m: 0 }} >
                                                        <TextField
                                                            label="Suffix"
                                                            type="text"
                                                            id="Suffix"
                                                            name="suffix"
                                                            size="small"
                                                            value={ordrFormat.suffix}
                                                            onChange={ordrFormatChange}
                                                            sx={{ m: 0, }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Typography variant="body2" component="div" gutterBottom> Your order ID will appear as
                                        {ordrFormat.prefix}1001{ordrFormat.suffix},&nbsp;
                                        {ordrFormat.prefix}1002{ordrFormat.suffix},&nbsp;
                                        {ordrFormat.prefix}1003{ordrFormat.suffix} ...  </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }}>
                                        <Typography variant="p" component="strong" gutterBottom>Store currency</Typography>
                                    </Box>
                                    <Box>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <InputLabel id="">Store currency</InputLabel>
                                            <Select
                                                labelId="Storecurrency"
                                                id="Storecurrency"
                                                value="1"
                                                label="Unit system"
                                                size="small"
                                                name="Storecurrency"
                                            // onChange={selectValueFunction}
                                            >
                                                <MenuItem value="1">   Indian rupees (INR)  </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={12}>
                            <Box sx={{ textAlign: "left" }}>
                                <Box sx={{ textAlign: "right" }}>
                                    <Button variant="contained" color="error" size="large"  >Cancel</Button>
                                    <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                                    {/* <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Create order</Button> */}
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt="Save"
            // hideFooter={modalContent.modalShowing === "manageUser" ? true : false}

            // savebtnFunct={savebtnFunct} 
            >

                {modalContent.modalShowing === "basicInformation" ?
                    <Box>
                        <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Store name"
                                    type="text"
                                    id="storename"
                                    name="storename"
                                    size="small"
                                    defaultValue="Vsd"
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Legal name of company "
                                    type="text"
                                    id="legalName"
                                    name="legalName"
                                    size="small"
                                    defaultValue="Vsd"
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <InputLabel id="">Store industry</InputLabel>
                                <Select
                                    labelId="storeindus"
                                    id="storeindus"
                                    value={selectValue.storeType}
                                    label="Store industry"
                                    size="small"
                                    name="storeType"
                                    onChange={selectValueFunction} >
                                    {storeType.map((data, index) => {
                                        return <MenuItem key={index} value={data.value}> {data.label}   </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>


                    </Box>
                    : null}

                {modalContent.modalShowing === "address" ?
                    <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Country/region</InputLabel>
                                <Select
                                    labelId="countryRegion"
                                    id="countryRegion"
                                    value={selectCountry}
                                    label="Country/region"
                                    size="small"
                                    onChange={countrySelect}
                                >
                                    {Countries.map((data, index) => {
                                        return <MenuItem key={index} value={index}>{data.label}</MenuItem>
                                    })}


                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="First name"
                                    type="text"
                                    id="prodPrice"
                                    name="price"
                                    size="small"
                                    onChange={handleOnchange}
                                    // onBlur={getprofit}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Last name"
                                    type="text"
                                    id="comparePrice"
                                    size="small"
                                    name="compare_at_price"
                                    sx={{ m: 0, width: '100%' }}
                                    onChange={handleOnchange}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Company"
                                    id="Company"
                                    name="Company"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Address"
                                    id="Address"
                                    name="Address"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Apartment, suite, etc."
                                    id="Apartment"
                                    name="Apartment"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="City"
                                    id="City"
                                    name="City"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="State"
                                    id="State"
                                    name="State"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="PIN code"
                                    id="pin"
                                    name="PIN"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : null}
                {modalContent.modalShowing === "contactInformation" ?
                    <Box>
                        <Box sx={{ mb: 2.5 }}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Phone"
                                    type="number"
                                    id="phonenumber"
                                    name="phonenumber"
                                    size="small"
                                    defaultValue="9876543212"
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ mb: 2.5 }}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Store contact email"
                                    type="email"
                                    id="legalName"
                                    name="legalName"
                                    size="small"
                                    defaultValue="villiamjit@weoinfotech.com"
                                />
                                <FormHelperText id="my-helper-text">Shopify uses this to contact you</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box sx={{ mb: 2.5 }}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="Sender email"
                                    type="email"
                                    id="legalName"
                                    name="legalName"
                                    size="small"
                                    defaultValue="villiamjit@weoinfotech.com"
                                />
                                <FormHelperText id="my-helper-text">Customers see this if you email them</FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                    : null}


            </PopupModal>
        </>
    );
}

export default StoreDetails