import React, { useEffect, useState, useRef } from 'react';
import {
    Box, Button, Grid, Card, CardContent, CardMedia, Divider,
     Typography, Container, 
} from '@mui/material'; 

import themepreview from '../../../../../Assets/images/themepreview.jpg'

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';

import { Link } from 'react-router-dom';
// file drag drop  

const TemplatesView = () => { 
    return (
        <Container maxWidth="lg">
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item xs={6}>
                        <Button component={Link} variant="text" to="/store/templates" startIcon={<ArrowBackIosIcon />}> Templates</Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}> 
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ mb: 2 }} > 
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={8}>
                            <Typography variant="p" component="strong" gutterBottom>Current Templates</Typography>
                            <Typography variant="body2" component="div" gutterBottom>This is the theme customers see when they visit your store. </Typography>
                        </Grid> 
                    </Grid>
                </Box>
            </Box>
            <Divider sx={{ mb: 2 }} /> 
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            <Typography variant="h6" component="div" gutterBottom>Current Theme </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <Button component={Link} to="/" variant="contained" color="primary" size="small" startIcon={<RemoveRedEyeOutlinedIcon />}>Preview</Button>
                            <Button href="/customizer" variant="contained" color="primary" size="small" startIcon={<ConstructionOutlinedIcon />} sx={{ ml: 1 }}>Customize</Button>
                            <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon />} sx={{ ml: 1 }}>Apply</Button>
                        </Grid>
                    </Grid> 
                    <Box className="tempPreviewBox">
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="500"
                            image={themepreview}
                        />

                    </Box>  
                </CardContent>
            </Card>




        </Container >
    );
}

export default TemplatesView