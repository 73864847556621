export default [
    {
        type: "image_file",
        id: "image_src",
        name: "image_src",
        label: "Brand image",
        note: "",
        height: "70px",
        width: "100%",
        alt_name: "img_alt",
        alt: "",
        value: "https://checkout-dev.growcart.app/logo-light.svg"
    },
    {
        type: "text",
        name: "link",
        label: "Link",
        value: "",
        placeholder: ""
    },
]