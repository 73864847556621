export default [
    {
        type: "text",
        name: "heading",
        label: "Heading",
        value: "",
        placeholder: ""
    },
    {
        type: "range",
        label: "Items per row",
        id: "item_per_row",
        name: "item_per_row",
        value: 1,
        min: 1,
        max: 3,
        step: 1,
        unit: ""
    },
    {
        type: "divider"
    },
    {
        type: "list_item",
        label: "Testimonial",
    },
]