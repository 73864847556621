import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper,
    Button, Box, Card, CardContent, Container,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material'

import './Menu.css'
// file drag drop  

const MenuNav = () => {
    let navigate = useNavigate()

    function createData(name1, menuItem1) {
        return { name1, menuItem1 };
    }

    const rows = [
        createData("Main menu", ["Home, Wishlist, Collection, Cart"]),
        createData("Footer menu", ["Home, About us, Collection, Cart"])
    ];

    return (
        <React.Fragment >
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Navigation </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }} >
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={8}>
                            <Typography variant="p" component="strong" gutterBottom>Menus</Typography>
                            <Typography variant="body2" component="div" gutterBottom>Menus, or link lists, help your customers navigate around your online store.
                                You can also create nested menus to display drop-down menus, and group products or pages together </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 2 }}>
                                        <Grid container spacing={2} columns={12}>
                                            <Grid item xs={6}>
                                                <Typography variant="h6" component="div" gutterBottom>Menus</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: "right", }}>
                                                {/* <Button component={Link} variant="outlined" color="primary" to=""> Add menu</Button> */}
                                                <Button component={Link} to="/store/menu/add" variant="contained" sx={{ ml: 1 }}>Add menu</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Title</TableCell>
                                                    <TableCell align="right">Menu items</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Link to="/store/menu/edit"> {row.name1}</Link>
                                                        </TableCell>
                                                        <TableCell align="right">{row.menuItem1}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                </Box>

            </Container>
        </React.Fragment>
    );
}

export default MenuNav