import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, CardContent, IconButton, Container, Checkbox, ListItemIcon, Pagination, LinearProgress,
    FormControl, TextField, FormHelperText, RadioGroup, FormControlLabel, Radio, Select,
    InputLabel, ListSubheader, Autocomplete
} from '@mui/material'


import moment from "moment";

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Seo from '../../../../../common/Seo/Seo';
import CustomEditor from '../../../../../common/CustomEditor/CustomEditor';
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function AddURlRedirects() {
    let navigate = useNavigate()
    const [body, setBody] = useState({
        slug: "",
        title: "",
        body_html: "<p></p>",
        metafields_title: "",
        metafields_description: "",
        visibilityDate: moment(new Date()),
        showvisibilityDate: false,
        author: "",
        blog: "",

    })
    const [selectedFile, setSelectedFile] = useState("")
    const [preview, setPreview] = useState("")
    const [themeTemp, setThemeTemp] = useState('');
    const [dataVal, setData] = useState("");
    const [contentState, setcontentState] = useState()
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [visibility, setVisibility] = useState([
        { name: "Visible", key: "visible", active: false },
        { name: "Hidden", key: "hidden", active: true },
    ]);



    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const tagList = [
        { title: 'The Shawshank Redemption', },
        { title: 'The Godfather', },
        { title: 'The Godfather: Part II', },

    ];


    const handelSelectchange = (event, name) => {
        setBody({ ...body, [name]: event.target.value });
    };
    const handleThemeTempChange = (event) => {
        setThemeTemp(event.target.value);
    };
    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const updateTextDescription = async (state) => {
        await setEditorState(state);
    };
    const onContentStateChange = (contentState) => {
        setcontentState(draftToHtml(contentState))
        setBody({ ...body, 'body_html': draftToHtml(contentState) });
        setData(draftToHtml(contentState))
    }
    const handleHtmlChange = (val) => {
        setData(val)
        const contentBlock = htmlToDraft(val);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            setBody({ ...body, 'body_html': val });
        }
    }
    const currentDate = new Date().toLocaleString();
    const toggleVisibility = (data) => {
        console.log("data", data)
        let updatemenu = [];
        visibility.map((data) => {
            return updatemenu.push({ ...data, 'active': false })
        })
        if (data === 0) {
            setBody({ ...body, 'showvisibilityDate': false })
        }
        updatemenu[data].active = true
        setVisibility(updatemenu)


    }

    const changeVisibilityState = () => {
        toggleVisibility(1)
        setBody({ ...body, 'showvisibilityDate': !body.showvisibilityDate })


    }
    console.log("body.showvisibilityDate ", body.showvisibilityDate)

    const handleDateChange = (newValue) => {
        setBody({ ...body, 'visibilityDate': newValue });
    };
    const onFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setSelectedFile(file)
        reader.onloadend = () => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
    }


    const handleMultipleSelect = (e, val, reason, id) => {

    }
    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" to="/store/redirects" startIcon={<ArrowBackIosIcon />}>URL redirect</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>

                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={12}>
                            <Grid container spacing={2} columns={12}>
                                <Grid item md={4}>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="h6" component="div"  >Redirect from</Typography>
                                        <Typography variant="caption" component="div" color="#666" gutterBottom>The original URL that you'd like to forward visitors from. Usually, this is an old page the visitors can no longer access because the content was moved to a different URL or deleted.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={8}>
                                    <Card sx={{ mb: 2, pt: 2 }}>
                                        <CardContent>
                                            <FormControl fullWidth sx={{ mb: 1 }}>
                                                <TextField
                                                    id="title"
                                                    name="title"
                                                    InputLabelProps={{ shrink: true }}
                                                    size="small"
                                                    variant="outlined"
                                                    label="Redirect from"
                                                // value={discountCode} 
                                                // onChange={updateDiscountCode}
                                                />
                                            </FormControl>
                                        </CardContent>
                                    </Card>

                                </Grid>
                            </Grid>
                            <Grid container spacing={2} columns={12}>
                                <Grid item md={4}>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="h6" component="div"  >Redirect to</Typography>
                                        <Typography variant="caption" component="div" color="#666" gutterBottom>The new URL that visitors should be forwarded to. If you want to redirect to your store's homepage, enter / (a forward slash).</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={8}>
                                    <Card sx={{ mb: 2, pt: 2 }}>
                                        <CardContent>
                                            <FormControl fullWidth sx={{ mb: 1 }}>
                                                <TextField
                                                    id="title"
                                                    name="title"
                                                    InputLabelProps={{ shrink: true }}
                                                    size="small"
                                                    variant="outlined"
                                                    label="Redirect to"
                                                // value={discountCode} 
                                                // onChange={updateDiscountCode}
                                                />
                                            </FormControl>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>





                        </Grid>
                    </Grid>
                </Box>
                <Grid item md={12}>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Box sx={{ textAlign: "right" }}>
                        <Button variant="contained" color="error" size="large" onClick={() => navigate("/store/redirects")}>Cancel</Button>
                        <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                    </Box>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

