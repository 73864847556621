import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box, Button, Grid, Card, CardContent, CardMedia, Divider,
    Checkbox, Typography,
    Container, InputLabel,
    Avatar, ListItemAvatar, ListItemText, ListItemButton, ListItem, List,
    Chip,
} from '@mui/material';


import ListItemIcon from '@mui/material/ListItemIcon';

import themepreview from '../../../../Assets/images/themepreview.jpg'
 

import './Templates.css'


// file drag drop  

const Templates = () => {
    const [checked, setChecked] = useState([0]);
    let navigate = useNavigate();

    const Category = [
        { label: "category1" },
        { label: "category2" },
        { label: "category3" },
        { label: "category4" },
        { label: "category5" },
    ]

    const templates = [
        { label: "theme 1", active: "true", image:themepreview, },
        { label: "theme 2", active: "false", image:themepreview, },
        { label: "theme 3", active: "false", image:themepreview, },
        { label: "theme 4", active: "false", image:themepreview, },
        { label: "theme 5", active: "false", image:themepreview, },
        { label: "theme 6", active: "false", image:themepreview, },
    ]

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    
    const routeChange = () => {
        navigate('/store/templates/view');
    }
    return (
        <Container maxWidth="lg">
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item xs={6}>
                        <Typography variant="h5" component="div"> Templates</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>

                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={3}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>Category</Typography>
                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 0, }}>
                                    {Category.map((value, index) => {
                                        const labelId = `checkbox-list-label-${index}`;

                                        return (
                                            <ListItem key={index} disablePadding >
                                                <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                                                    <ListItemIcon sx={{ minWidth: "auto", p: 0, mr: 1 }}>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.index !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            sx={{ p: 0 }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={value.label} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={9}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>Templates List</Typography>
                                <Grid container spacing={2} columns={12}>

                                    {templates.map((data, index) => {
                                        return (
                                            <Grid item md={4} key={index}>
                                                <Box className="tempPreviewBox" onClick={routeChange}>
                                                    <CardMedia
                                                        component="img"
                                                        alt="green iguana"
                                                        height="200"
                                                        image={data.image}
                                                    />
                                                    {data.active == "true" ? <Chip label="Active" className="tempPreviewBox__activeFlag" color="primary" size="small" /> : null}
                                                </Box>
                                            </Grid>
                                        )
                                    })}




                                </Grid>


                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>


        </Container >
    );
}

export default Templates