import { Box } from "@mui/system";
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSelector } from "react-redux";
import _ from "underscore";
import * as heading from "./Typography/Exports"
import HeadingText from "./HeadingText";

export default function Testimonials() {
    const dataVal = useSelector((state) => state.dataValue.components.testimonials)
    const slider = useRef(null)
    var settings = {
        "autoplay": dataVal.autoplay,
        "infinite": dataVal.infinite,
        "dots": dataVal.dots,
        "arrows": dataVal.arrows,
        "slidesToShow": dataVal.item_per_row,
        "pauseOnHover": false
    }
    const prevslide = () => {
        slider.current.slickPrev()
    }
    const nextslide = () => {
        slider.current.slickNext()
    }
    return (
        <Box sx={{ py: 5 }} id="testimonials">
            <Container maxWidth="lg">
                <HeadingText
                    data={dataVal && dataVal}
                />
                <Box className="testimonial_slider_outer">
                    {dataVal && dataVal.arrows &&
                        <Button className="slick-arrows slick-arrows-prev" onClick={prevslide}>
                            <ArrowBackIosNewIcon fontSize="large" />
                        </Button>
                    }
                    <Container maxWidth="lg">
                        <Slider ref={slider} {...settings} className="testimonial_slider">
                            {dataVal && dataVal.items.length > 0 && dataVal && dataVal.items.map((slide, index) => {
                                return (
                                    <Box key={index.toString()} className="testimonial_slider_inner">
                                        <Box className="client_image">
                                            <img src={slide.image_src} alt="" className="full-img" />
                                        </Box>
                                        <Box className="testimonial_slider_content">
                                            <Box>
                                                <Typography variant="p" sx={{ mb: 2 }} component="div" dangerouslySetInnerHTML={{ __html: slide.text }}>
                                                </Typography>
                                                <heading.HeadingH5
                                                    ishtml={false}
                                                    type="div"
                                                    data={`- ${slide.author}`}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Slider>
                    </Container>
                    {dataVal && dataVal.arrows &&
                        <Button className="slick-arrows slick-arrows-next" onClick={nextslide}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    }
                </Box>
            </Container>
        </Box>
    )
}