import React, { useCallback, useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent,
    Divider, Typography, Container,
} from '@mui/material';
import './Plan.css';
const Plan = () => {
    useEffect(() => {
    }, []);
    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Plan </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>   </Grid>
                    </Grid>

                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>

                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 2, }}>
                                        <Box>
                                            <Typography variant="p" component="strong" gutterBottom>Custom plan</Typography>
                                            <Typography variant="body2" component="div" gutterBottom>Your plan has add-ons or other customizations. To learn more about its features and pricing,
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ mb: 4.5 }} />


                                    <Grid container spacing={2} columns={12} sx={{ justifyContent: "center" }}>
                                        <Grid item md={10}>
                                            <Grid container spacing={2} columns={12}>
                                                <Grid item md={4}>

                                                    <Card className="paymentCard paymentCard--active">
                                                        <CardContent className="paymentCard__innnr" sx={{ textAlign: "center" }}>
                                                            <Box className="paymentCard__active">Active</Box>
                                                            <Typography variant="h5" component="div" gutterBottom color="primary" sx={{ fontWeight: "bold" }}>Plan 1</Typography>
                                                            <Typography variant="p" className="paymentCard__heading" component="div" gutterBottom>  Ideal for new eCommerce businesses</Typography>
                                                            <Typography variant="h6" component="strong" gutterBottom sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            ₹29
                                                                <Typography variant="caption" display="block" sx={{ m: 0 }}> ₹/mo</Typography>
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <Card className="paymentCard">
                                                        <CardContent className="paymentCard__innnr" sx={{ textAlign: "center" }}>
                                                            <Typography variant="h5" component="div" gutterBottom color="primary" sx={{ fontWeight: "bold" }}>Plan 2</Typography>
                                                            <Typography variant="p" className="paymentCard__heading" component="div" gutterBottom>Ideal for expanding businesses
                                                                with consistent online sales </Typography>
                                                            <Typography variant="h6" component="strong" gutterBottom sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            ₹79
                                                                <Typography variant="caption" display="block" sx={{ m: 0 }}> ₹/mo</Typography>
                                                            </Typography>
                                                            <Button variant="outlined" color="primary" size="large" sx={{ ml: 1 }}>Choose plan</Button>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <Card className="paymentCard">
                                                        <CardContent className="paymentCard__innnr" sx={{ textAlign: "center" }}>
                                                            <Typography variant="h5" component="div" gutterBottom color="primary" sx={{ fontWeight: "bold" }}>Plan 3</Typography>
                                                            <Typography variant="p" className="paymentCard__heading" component="div" gutterBottom> Ideal for established businesses
                                                                with high-volume sales</Typography>
                                                            <Typography variant="h6" component="strong" gutterBottom sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            ₹299
                                                                <Typography variant="caption" display="block" sx={{ m: 0 }}> ₹/mo</Typography>
                                                            </Typography>
                                                            <Button variant="outlined" color="primary" size="large" sx={{ ml: 1 }}>Choose plan</Button>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ my: 4.5, }} />
                                    <Grid item md={12}>
                                        <Box sx={{ textAlign: "center" }}>
                                            <Button variant="contained" color="primary" size="large">Upgrade Plan</Button>
                                        </Box>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default Plan