import React, { useCallback, useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent,
    FormControl, Divider, Typography,
    TextField, Container
} from '@mui/material';
import { Link, useNavigate, useParams } from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import htmlToDraft from "html-to-draftjs";

import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useDropzone } from 'react-dropzone';

import Seo from '../../../../common/Seo/Seo';
import CustomEditor from '../../../../common/CustomEditor/CustomEditor';

import SingleFileupload from '../../../../common/Fileupload/SingleFileupload';
import * as Api from '../../../../../Container/config/Apis';
import config from '../../../../../Container/config';
import _ from 'underscore';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';


const AddCategory = () => {
    const param = useParams();
    const navigate = useNavigate()
    const [showError, setshowError] = useState(false)
    const [loading, setloading] = useState(false)
    const [selectedFile, setSelectedFile] = useState("")
    const [preview, setPreview] = useState("")
    const [body, setBody] = useState({
        slug: "",
        title: "",
        body_html: "<p></p>",
        metafields_title: "",
        metafields_description: "",
    })

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const [editCategory, seteditCategory] = useState(false)
    const [dataVal, setData] = useState("");
    const [contentState, setcontentState] = useState()
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [files, setFiles] = useState([]);

    const updateTextDescription = async (state) => {
        await setEditorState(state);
    };
    const onContentStateChange = (contentState) => {
        setcontentState(draftToHtml(contentState))
        setBody({ ...body, 'body_html': draftToHtml(contentState) });
        setData(draftToHtml(contentState))
    }

    const handleHtmlChange = (val) => {
        setData(val)
        const contentBlock = htmlToDraft(val);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            setBody({ ...body, 'body_html': val });
        }
    }

    const onFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setSelectedFile(file)
        reader.onloadend = () => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }

    const handleOnTitleChange = (e) => {
        setBody({ ...body, 'slug': ((e.target.value).trim()).split(" ").join("-").toLowerCase() })
    }

    const savecategory = () => {
        if (body.title === "") {
            setshowError(true)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            setloading(true)
            let data = new FormData();
            data.append("title", body.title)
            data.append("slug", body.slug)
            data.append("body_html", body.body_html)
            if (selectedFile !== "") {
                data.append("category_image", selectedFile)
            }
            if (body.metafields_description !== "") {
                data.append("metafields_description", body.metafields_description)
            }
            if (body.metafields_title !== "") {
                data.append("metafields_title", body.metafields_title)
            }
            if (editCategory) {
                categoryFn(Api.PutApi, `${config.CATEGORY}/${body.id}`, data, "edit")
            } else {
                categoryFn(Api.PostApi, config.CATEGORY, data, "add")
            }
        }
    }

    const categoryFn = async (api, url, data, type) => {
        await api(url, data).then(res => {
            console.log("res save category: ", res)
            if (res.error === true) {
                setloading(false)
                snack(res.response.data.error, 'error');
            } else {
                snack(type === "add" ? "Category added successfully" : "Updated successfully", 'success');
                setTimeout(() => {
                    setloading(false)
                    navigate('/products/categories')
                }, 1000);
            }
        })
    }
    //GET category BY ID
    const getCategoryByID = useCallback((id) => {
        Api.GetApi(`${config.CATEGORY_DETAIL}/${id}`).then(async res => {
            // setTimeout(() => {
            //     setloadingBar(false)
            // }, 1000);
            if (res.error === true) {
                if (!_.isUndefined(res.response.data.error)) {
                    snack(res.response.data.error, 'error');
                } else {
                    snack(res.res.data.error, 'error');
                }
            } else {
                if (res.data.data) {
                    let bodyData = res.data.data
                    bodyData['slug'] = bodyData.handle || ""
                    if (bodyData.path) {
                        setPreview(bodyData.path)
                    }
                    await handleHtmlChange(bodyData.body_html)
                    await setBody(bodyData)
                } else {
                    snack("No record found. Please check the URL.", 'error');
                }
            }
        })
    }, [])

    useEffect(() => {
        if (param.id) {
            getCategoryByID(param.id)
            seteditCategory(true)
        }
    }, [])


    console.log("selectedFile: ", selectedFile)
    console.log("body: ", body)

    return (
        <Container maxWidth="lg">
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={6}>
                        <Button component={Link} variant="text" to="/products/list" startIcon={<ArrowBackIosIcon />}>
                            {editCategory ?
                                body && body.title :
                                "Category"
                            }
                        </Button>
                    </Grid>
                    <Grid item md={6} sx={{ textAlign: "Right" }}></Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={8}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>Title</Typography>
                                <FormControl fullWidth sx={{ mb: 3 }} >
                                    <TextField
                                        id="title"
                                        label="Title"
                                        name="title"
                                        value={body.title}
                                        onChange={handleOnchange}
                                        onBlur={handleOnTitleChange}
                                        error={showError && body.title === "" ? true : false}
                                        helperText={showError && body.title === "" ? "Title can't be blank" : ""}
                                        size="small"
                                        variant="outlined" />
                                </FormControl>
                                <Typography variant="h6" component="div" gutterBottom>Description</Typography>
                                <CustomEditor
                                    editorState={editorState}
                                    updateTextDescription={updateTextDescription}
                                    onContentStateChange={onContentStateChange}
                                    handleHtmlChange={handleHtmlChange}
                                    value={dataVal}
                                />
                            </CardContent>
                        </Card>

                        <Card sx={{ mb: 2 }}>
                            <CardContent>

                                <Seo
                                    handleOnchange={handleOnchange}
                                    body={body}
                                    page={"category"}
                                    title="metafields_title"
                                    description="metafields_description"
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="h6" gutterBottom>Category images</Typography>


                                <SingleFileupload
                                    preview={preview}
                                    onFileChange={onFileChange}
                                />
                            </CardContent>
                        </Card>

                        <LoadingButton
                            sx={{ ml: 1, whiteSpace: 'nowrap' }}
                            color="primary"
                            size="large"
                            onClick={savecategory}
                            loading={loading}
                            loadingIndicator="Please wait..."
                            variant="contained"
                        >
                            {!editCategory ? "Save" : "Update"}
                        </LoadingButton>
                    </Grid>
                    <Grid item md={12}>
                        <Divider sx={{ my: "15px" }}></Divider>
                        <Box sx={{ textAlign: "left" }}>
                            <Button variant="contained" onClick={() => navigate("/products/categories")} color="error" size="large" >Cancel</Button>
                            <LoadingButton
                                sx={{ ml: 1, whiteSpace: 'nowrap' }}
                                color="primary"
                                size="large"
                                onClick={savecategory}
                                loading={loading}
                                loadingIndicator="Please wait..."
                                variant="contained"
                            >
                                {!editCategory ? "Save" : "Update"}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default AddCategory