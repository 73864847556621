import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import HeadingText from "./HeadingText";
import { Overlay } from "./Typography/Exports";

export default function TextOverImage() {
    const dataVal = useSelector((state) => state.dataValue.components.text_over_image)
    return (
        <Box id="text_over_image">
            {dataVal.layout === "fixed" ?
                <Container maxWidth="lg">
                    <Box sx={{ position: 'relative' }}>
                        <img src={dataVal && dataVal.image} alt="" className="full-img" />
                        <Box className={`hero_slider_inner ${dataVal && dataVal.alignment}`}>
                            <Overlay />
                            <Box className="hero_slider_content" sx={{ my: 2 }}>
                                <Box>
                                    <HeadingText
                                        data={dataVal && dataVal}
                                    />
                                    {dataVal && dataVal.button_label !== "" &&
                                        <Button variant="contained" color="white" size="large" href={dataVal && dataVal.button_link}>{dataVal && dataVal.button_label}</Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                :
                <Box sx={{ position: 'relative' }}>
                    <img src={dataVal && dataVal.image} alt="" className="full-img" />
                    <Container maxWidth="lg">
                        <Box className={`hero_slider_inner ${dataVal && dataVal.alignment}`}>
                            <Overlay />
                            <Box className="hero_slider_content" sx={{ my: 2 }}>
                                <Box>
                                    <HeadingText
                                        data={dataVal && dataVal}
                                    />
                                    {dataVal && dataVal.button_label !== "" &&
                                        <Button variant="contained" color="white" sx={{ color: "#000" }} size="large" href={dataVal && dataVal.button_link}>{dataVal && dataVal.button_label}</Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            }
        </Box>
    )
}