import React, { useCallback, useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, CardContent, IconButton, Container, Checkbox, ListItemIcon, Pagination, LinearProgress,
    FormControl, TextField, FormHelperText, RadioGroup, FormControlLabel, Radio, Select,
    InputLabel,
} from '@mui/material';

import Seo from '../../../../../common/Seo/Seo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function AddBlogCategory() {
    let navigate = useNavigate()
    const [body, setBody] = useState({
        slug: "",
        title: "",
        body_html: "<p></p>",
        metafields_title: "",
        metafields_description: "",
    })
    const [themeTemp, setThemeTemp] = useState('');

    const handleThemeTempChange = (event) => {
        setThemeTemp(event.target.value);
    };


    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" to="/store/blogs-category" startIcon={<ArrowBackIosIcon />}>Blogs categories</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Category title</Typography>
                                    <FormControl fullWidth  >
                                        <TextField
                                            id="title"
                                            name="title"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            variant="outlined"
                                        // value={discountCode} 
                                        // onChange={updateDiscountCode}
                                        />
                                    </FormControl>
                                </CardContent>
                            </Card>

                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Seo
                                    page={"blogs"}
                                        handleOnchange={handleOnchange}
                                        body={body}
                                        title="metafields_global_title_tag"
                                        description="metafields_global_description_tag"
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom >Comments</Typography>
                                    <Typography variant="subtitle1" component="div" gutterBottom >Manage how comments are handled on this blog.</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="female" control={<Radio size="small" />} label="Comments are disabled" />
                                            <FormControlLabel value="male" control={<Radio size="small" />} label="Comments are allowed, pending moderation" />
                                            <FormControlLabel value="other" control={<Radio size="small" />} label="Comments are allowed, and are automatically published" />
                                        </RadioGroup>
                                    </FormControl>
                                </CardContent>
                            </Card>
                            {/* <Card sx={{ mb: 2 }}>
                                <CardContent >
                                    <Typography variant="h6" component="h6" gutterBottom >Online store </Typography>

                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Theme template </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={themeTemp}
                                            label="Theme template"
                                            onChange={handleThemeTempChange} >
                                            <MenuItem value={10}>Default blog</MenuItem>
                                        </Select>
                                    </FormControl>


                                </CardContent>
                            </Card> */}
                        </Grid>
                    </Grid>
                </Box>
                <Grid item md={12}>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Box sx={{ textAlign: "right" }}>
                        <Button variant="contained" color="error" size="large" onClick={() => navigate("/store/blogs-category")}  >Cancel</Button>
                        <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                    </Box>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

