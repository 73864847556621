import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import {
    Grid, Divider, Typography, Button, Box, Card, CardContent, Container,
    FormControl, TextField, Stepper, Step, StepLabel, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Chip, Link,

} from '@mui/material'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

 
import "./DomainVerification.css"
// file drag drop  

const DomainVerification = () => {
    let navigate = useNavigate()
    const [body, setBody] = useState({
        slug: "",
        title: "",
        body_html: "<p></p>",
        metafields_title: "",
        metafields_description: "",
        author: "",
        blog: "",
        editdomain: false,

    })

    const ondomainChange = () => {
        setBody({ ...body, 'editdomain': !body.editdomain })
    }
    const steps = [
        'Step 1',
        'Step 2',
        'Step 3',
    ];
    function createData(type, name, value, ttl,) {
        return { type, name, value, ttl, };
    }

    const rows = [
        createData('CNAME', "www", "xxx.mystore.com", "12 Hour",),
        createData('CNAME', "A", "xxx.mystore.com", "12 Hour",),
    ];

    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Domain Verification </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}> </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Domain Verification</Typography>
                                    <Typography variant="body1" gutterBottom>Please enter your domain so our customer support vefify that and you will use that store after verification</Typography>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs >
                                            <FormControl fullWidth >
                                                <TextField
                                                    id="domain"
                                                    name="domain"
                                                    InputLabelProps={{ shrink: true }}
                                                    size="small"
                                                    variant="outlined"
                                                // onChange={updateDiscountCode}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item >
                                            <Button variant="outlined" >Submit for verification</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ width: '100%', pt: 2, mb: 2 }}>
                                        <Stepper activeStep={0} alternativeLabel>
                                            {steps.map((label) => (
                                                <Step key={label}>
                                                    <StepLabel>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>
                                    <Box className="prefrenceStepHeading">
                                        <Avatar sx={{ margin: "0 auto 20px", width: 56, height: 56, bgcolor: "#ffa726" }}>
                                            <PublishedWithChangesOutlinedIcon sx={{ fontSize: "30px" }} />
                                        </Avatar>
                                        <Typography variant="h6" component="div" gutterBottom color="primary">Request Received:</Typography>
                                        <Typography variant="caption" gutterBottom>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: "center", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant="body" sx={{ textAlign: "center", display: 'flex', alignItems: "center", }}><Box sx={{ mr: 1 }}><strong>Domain:</strong></Box>
                                            {body.editdomain ?
                                                <TextField id="outlined-basic" size="small" label="Edit domain" variant="outlined" /> : " www.mysite.com"}

                                        </Typography>
                                        {body.editdomain ?
                                            <>
                                                <Button variant="text" onClick={ondomainChange} sx={{ ml: 0.5 }} >Save</Button>
                                                <Button variant="text" onClick={ondomainChange} color="error" sx={{ ml: 0.5 }}> Cancel </Button>
                                            </>
                                            : <Button variant="text" onClick={ondomainChange} sx={{ ml: 0.5 }} >Edit</Button>
                                        }

                                    </Box>

                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ width: '100%', pt: 2, mb: 2 }}>
                                        <Stepper activeStep={1} alternativeLabel>
                                            {steps.map((label) => (
                                                <Step key={label}>
                                                    <StepLabel>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>
                                    <Box className="prefrenceStepHeading"  >
                                        <Avatar sx={{ margin: "0 auto 20px", width: 56, height: 56, bgcolor: "#03a9f4", }}>
                                            <AddOutlinedIcon sx={{ fontSize: "30px" }} />
                                        </Avatar>
                                        <Typography variant="h6" component="div" gutterBottom color="primary">Please add these records into your domain:</Typography>
                                        <Typography variant="caption" gutterBottom>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s </Typography>
                                    </Box>
                                    <Box sx={{ mb: 2, textAlign: "center", }}>
                                        <TableContainer component={Paper} elevation={4} sx={{ maxWidth: "800px", m: "0 auto" }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell >Name</TableCell>
                                                        <TableCell >Value</TableCell>
                                                        <TableCell >TTL</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.type}
                                                            </TableCell>
                                                            <TableCell >{row.name}</TableCell>
                                                            <TableCell >{row.value}</TableCell>
                                                            <TableCell >{row.ttl}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Box sx={{ textAlign: "center", mt: 3 }}><Button variant='contained' color="primary" size="large" >Done</Button></Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ width: '100%', pt: 2, mb: 2 }}>
                                        <Stepper activeStep={2} alternativeLabel>
                                            {steps.map((label) => (
                                                <Step key={label}>
                                                    <StepLabel>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>
                                    <Box className="prefrenceStepHeading" sx={{}}>
                                        <Avatar sx={{ margin: "0 auto 20px", width: 56, height: 56, bgcolor: "#2e7d32" }}>
                                            <CheckOutlinedIcon sx={{ fontSize: "30px" }} />
                                        </Avatar>
                                        <Typography variant="h6" component="div" gutterBottom color="primary">Request Completed</Typography>

                                    </Box>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Typography variant="body" component="div" gutterBottom>
                                            <strong>Domain Pointed: </strong> www.mysite.com
                                        </Typography>
                                        <Typography variant="body" component="div" gutterBottom>
                                            <strong>My store managed domain: </strong>xxx.mystore.com
                                        </Typography>
                                        <Typography variant="body1" component="div" gutterBottom>
                                            <strong>Custom Domain: </strong>mysite.com  <Chip label="Primary" size="small" sx={{ ml: 1 }} />
                                        </Typography>

                                        <Box sx={{ pt: 3, }}>
                                            <Typography variant="caption" component="div" > Domain pointed but not working ? <Link
                                                href="mailto:network@mystore.com?subject=Domain pointed not working"
                                                color="primary">Contact Us</Link></Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Grid item md={12}>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Box sx={{ textAlign: "right" }}>
                        <Button variant="contained" color="error" size="large" onClick={() => navigate("/store/blogs")}>Cancel</Button>
                        <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                    </Box>
                </Grid>
            </Container >
        </React.Fragment >
    );
}

export default DomainVerification