import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, CardContent, IconButton, Container, Checkbox, ListItemIcon, Pagination, LinearProgress,
    FormControl, TextField, FormHelperText, RadioGroup, FormControlLabel, Radio, Select,
    InputLabel, ListSubheader, Autocomplete
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import PopupModal from '../../../../common/PopupModal/PopupModal';

export default function AddMenuNav() {
    let navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })


    const ModalOpen = () => setOpenModal(true);
    const ModalClose = () => setOpenModal(false);
    const triggerModal = (name, title) => {
        setOpenModal(true);
        setModalContent({ "modalShowing": name, "modalTitle": title })
        console.log("modalContent.modalShowing", modalContent.modalShowing)
    }

    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" to="/store/menu" startIcon={<ArrowBackIosIcon />}>Add menu</Button>
                        </Grid>

                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Title</Typography>
                                    <FormControl fullWidth  >
                                        <TextField
                                            id="title"
                                            name="title"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            variant="outlined"
                                        // value={discountCode}

                                        // onChange={updateDiscountCode}
                                        />
                                    </FormControl>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>Menu items </Typography>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "150px" }}>
                                        <Typography variant="body1" component="div" gutterBottom>This menu doesn't have any items </Typography>
                                    </Box>
                                </CardContent>
                                <Divider />
                                <Box sx={{ p: 1.5 }}>
                                    <Button variant="text"
                                        startIcon={<AddIcon />}
                                        onClick={() => triggerModal("addmenuitem", "Add menu item")}
                                    >Add menu item </Button>
                                </Box>
                            </Card>


                        </Grid>
                       
                    </Grid>
                </Box>
                <Grid item md={12}>
                    <Divider sx={{ my: "15px" }}></Divider>
                    <Box sx={{ textAlign: "right" }}>
                        <Button variant="contained" color="error" size="large" onClick={() => navigate("/store/blogs")}>Cancel</Button>
                        <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Save</Button>
                    </Box>
                </Grid>
            </Container>

            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt="Save"
            // hideFooter={modalContent.modalShowing === "manageUser" ? true : false}

            // savebtnFunct={savebtnFunct} 
            >
                {modalContent.modalShowing === "addmenuitem" ?
                    <Box>
                        <Box>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    id="name"
                                    name="title"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    variant="outlined"
                                    label="Name"
                                    placeholder='e.g. About us'
                                // value={discountCode}

                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <TextField
                                    id="link"
                                    name="title"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    variant="outlined"
                                    label="Link"
                                // value={discountCode}

                                />
                            </FormControl>
                        </Box>
                    </Box> : null}



            </PopupModal>
        </React.Fragment>
    )
}

