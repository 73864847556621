import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper,
    Button, InputBase, Menu, MenuItem, Box,
    Card, IconButton, Container, Pagination, LinearProgress,
} from '@mui/material'
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import * as Api from "../../../../../Container/config/Apis"
import config from '../../../../../Container/config';
import _ from "underscore";
import moment from "moment";
import { useSnackbar } from 'notistack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: "20px !important",
    // width: '200px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <Box sx={{ mt: 15 }}>
                <Typography variant="h5" component="div">No result found!</Typography>
            </Box>
        </StyledGridOverlay>
    );
}

export default function BlogCategory() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const sortRef = React.useRef();
    const actionRef = React.useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [loadingData, setloadingData] = useState(true);
    const [count, setCount] = useState(0)
    const [totalProduct, setTotalDiscount] = useState(0)
    const [limit, setLimit] = useState(50)
    const [params, setParams] = useState({
        search: "",
        type: "",
        product_type: "",
        sort_key: "",
        sort_value: "desc",
        page: 1,
    })
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const columns = [
        {
            field: 'categoryName', headerName: ' ', flex: 1, sortable: false,
            renderCell: (params) =>
                <Button component={Link} to="" sx={{ pl: 0 }} color="primary" className='noHover'>{params.value}</Button>
        },

        { field: 'date', headerName: '', sortable: false, flex: 1 },
        {
            field: 'action', headerName: '', sortable: false, flex: 1,
            renderCell: (params) =>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <IconButton aria-label="view" color="primary"><VisibilityIcon /></IconButton>
                    <IconButton aria-label="edit" color="success" ><EditIcon /></IconButton>
                    <IconButton aria-label="delete" color="error"><DeleteIcon /></IconButton>
                </Box>
        },

    ]
    const rows1 = [
        { id: 1, categoryName: "Category name", date: "May 4 at 2:25 pm", },
        { id: 2, categoryName: "Category name", date: "May 4 at 2:25 pm", },
        { id: 3, categoryName: "Category name", date: "May 4 at 2:25 pm", },
        { id: 4, categoryName: "Category name", date: "May 4 at 2:25 pm", },
    ];

    const [rows, setrows] = useState([])

    const [options, setOptions] = useState([
        { name: "Category title A-Z", key: "title", value: "asc", active: false },
        { name: "Category title Z-A", key: "title", value: "desc", active: false },
        { name: "Created (oldest first)", key: "created_at", value: "asc", active: false },
        { name: "Created (newest first)", key: "created_at", value: "desc", active: false }, 
    ])
    const [actions, setactions] = useState([
        { name: "Enable discount codes", key: "", value: "", active: false },
        { name: "Disable discount codes", key: "", value: "", active: false },
        { name: "Delete discount codes", key: "", value: "", active: false },
    ])

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleClick = (event) => {
        if (event === "sort") {
            setAnchorEl(sortRef.current);
            setAnchorEl2(null);
        } else {
            setAnchorEl(null);
            setAnchorEl2(actionRef.current);
        }
    };

    useEffect(() => {
        setAnchorEl(sortRef.current);
        setAnchorEl(actionRef.current);
    }, [sortRef, actionRef]);

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
    };
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    //Type on change


    //Search change
    const [search, setSearch] = useState('')
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }
    const handleSearchBlur = (e) => {
        setloadingData(true)
        if (search !== "") {
            setParams({ ...params, 'search': search })
            setparam("search", search)
        } else {
            setParams({ ...params, 'search': "" })
            deleteparam("search")
        }
    }

    //Sort by on change
    const handleSortChange = (val) => {
        setloadingData(true)
        let newArr = []
        options.map((opt) => {
            if (opt.name === val.name) {
                newArr.push({ ...opt, 'active': true })
                setParams({ ...params, "sort_key": val.key, "sort_value": val.value })
                setparam("sort_key", val.key)
                setparam("sort_value", val.value)
            } else {
                newArr.push({ ...opt, 'active': false })
            }
        })
        setOptions(newArr)
        handleClose()
    }

    const handleActionChange = (val) => {
        // setloadingData(true)
        let newArr = []
        actions.map((opt) => {
            if (opt.name === val.name) {
                newArr.push({ ...opt, 'active': true })

            } else {
                newArr.push({ ...opt, 'active': false })
            }
        })
        setactions(newArr)
        handleClose()
    }

    //Clear sort by filter
    const clearSortBy = () => {
        setloadingData(true)
        let newArr = []
        setParams({ ...params, "sort_key": "", "sort_value": "" })
        deleteparam("sort_key")
        deleteparam("sort_value")
        options.map((opt) => {
            newArr.push({ ...opt, 'active': false })
        })
        setOptions(newArr)
    }
    const handleChangePage = (_event, newPage) => {
        setloadingData(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };


    //Get product count
    const getDiscountCount = () => {
        Api.GetApi(config.DISCOUNT_RULE_COUNT).then(res => {
            console.log("res count: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                setTotalDiscount(res.data.data)
                let newCount = Math.ceil(parseInt(res.data.data.count) / limit)
                if (newCount !== 0) {
                    setCount(newCount)
                } else {
                    setCount(1)
                }
            }
        })
    }

    //Get DISCOUNT list
    const getDiscountList = (param) => {
        Api.GetApi(`${config.DISCOUNT_RULE}${param}`).then(res => {
            setTimeout(() => {
                setloadingData(false)
            }, 2000);
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []

                res.data.data && res.data.data.map((data) => {
                    arr.push({
                        ...data,
                        expire: data.starts_at && data.ends_at ? `${moment(data.starts_at).format("Do MMM, YY")} - ${moment(data.ends_at).format("Do MMM, YY")}` : "",
                        used: `${data.value}/${data.usage_limit} used`,
                        type: data.value_type === "fixed_amount" ? "Fixed amount" : "Percentage"
                    })
                })
                setrows(arr)
            }
        })
    }

    console.log("rows: ", rows)

    const onPageSizeChange = (e) => {
        setLimit(e)
    }


    useEffect(() => {
        let newArr = []
        searchParams.set("page", params.page)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getDiscountList(location.search)
        //Set SortBy active value
        if (searchParams.get("sort_key") || searchParams.get("sort_value")) {
            options.map((obj) => {
                if (obj.key === searchParams.get("sort_key") && obj.value === searchParams.get("sort_value")) {
                    newArr.push({ ...obj, 'active': true })
                } else {
                    newArr.push({ ...obj, 'active': false })
                }
            })
            setOptions(newArr)
        }
    }, [limit, params])

    useEffect(() => {
        getDiscountCount()
        if (searchParams.get("search")) {
            setSearch(searchParams.get("search"))
        }
    }, [])


    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" to="/store/blogs" startIcon={<ArrowBackIosIcon />}>Blogs</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Button component={Link} to="/store/blogs-category/add" variant="contained" sx={{ ml: 1 }}>Add blog category</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: 2, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={6}>
                                <Box sx={{ display: "flex" }}>
                                    <Search sx={{ ml: "0 !important" }}>
                                        <SearchIconWrapper>
                                            <SearchIcon color="grey" />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search', value: search }}
                                            onBlur={handleSearchBlur}
                                            onChange={handleSearchChange}
                                        />
                                    </Search>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: "Right" }}>
                                    {searchParams.get("sort_key") || searchParams.get("sort_value") ?
                                        <Button variant='text' color="error" size="small" sx={{ mr: 2 }} endIcon={<ClearIcon />} onClick={clearSortBy}>Clear</Button>
                                        : null
                                    }
                                    <Button
                                        id="sortButton"
                                        ref={sortRef}
                                        aria-controls="sortButtonArea"
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="text"
                                        disableElevation
                                        onClick={() => handleClick("sort")}
                                        endIcon={<KeyboardArrowDownIcon />}>
                                        Sort by
                                    </Button>
                                    <StyledMenu
                                        id="sortButtonArea"
                                        MenuListProps={{ 'aria-labelledby': 'sortButton', }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}>
                                        {options.map((opt) => {
                                            return (
                                                <MenuItem key={opt.name} onClick={() => handleSortChange(opt)} disableRipple className={`sort_list ${opt.active ? "active" : ""}`} sx={{ fontSize: "12px" }}>
                                                    {opt.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </StyledMenu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="discountTable" sx={{ width: '100%' }}>
                        <Box className='discountTable__cntnt' sx={{ display: "flex", alignItems: "center" }}>
                            <Box className='discountTable__cntntInr' >
                                {selectionModel.length > 0 ?
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography variant="p" component="strong"> {selectionModel.length} selected  </Typography>
                                        <Button
                                            id="action"
                                            ref={actionRef}
                                            aria-controls={open ? 'actionArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            variant="outlined"
                                            disableElevation
                                            onClick={() => handleClick("action")}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            sx={{ ml: 2, }} >
                                            Action
                                        </Button>
                                        <StyledMenu
                                            id="actionArea"
                                            MenuListProps={{ 'aria-labelledby': 'action', }}
                                            anchorEl={anchorEl2}
                                            open={open2}
                                            onClose={handleClose}>
                                            {actions.map((opt) => {
                                                return (
                                                    <MenuItem key={opt.name} onClick={() => handleActionChange(opt)} disableRipple className={`action_list ${opt.active ? "active" : ""}`} sx={{ fontSize: "12px" }}>
                                                        {opt.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box> : <Typography variant="p" component="strong"> Showing {rows1.length} Blog categories</Typography>
                                }
                            </Box>
                        </Box>
                        <DataGrid
                            rows={rows1}
                            columns={columns}
                            pageSize={limit}
                            rowsPerPageOptions={[]}
                            onPageSizeChange={(e) => onPageSizeChange(e)}
                            checkboxSelection
                            disableColumnMenu
                            disableSelectionOnClick={true}
                            autoHeight={true}
                            density={"standard"}
                            rowHeight={60}
                            hideFooterPagination={true}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loadingData}

                            sx={{
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                }
                            }}
                        />
                        <Pagination count={count} showFirstButton showLastButton sx={{ p: 2, float: "right" }} onChange={handleChangePage} />
                    </Box>
                </Card>
            </Container>
        </React.Fragment>
    )
}

