import React, { useState } from "react";
import LayoutContainer from "./Container/Container";
import LayoutHeader from "./Header";
import "./Layout.css";
import LayoutSidebar from "./Sidebar/Sidebar";

export default function Layout() {
    const [view, setView] = useState("desktop")
    const [activeTab, setactiveTab] = useState("desktop")
    const [preview, setPreview] = useState(false)
    function changeView(type) {
        setView(type)
        setactiveTab(type)
        setPreview(false)
    }
    function previewPage() {
        setPreview(!preview)
        setactiveTab("preview")
    }
    console.log("activeTab: ", activeTab)
    return (
        <div className="customizer_outer">
            <LayoutHeader
                changeView={changeView}
                previewPage={previewPage}
                activeTab={activeTab}
            />
            <div className={`customizer_wrapper ${preview ? "show_preview" : ""}`}>
                <LayoutSidebar />
                <LayoutContainer
                    view={view}
                />
            </div>
        </div>
    )
}