export default [
    {
        type: "text",
        name: "heading",
        label: "Heading",
        value: "Heading goes here",
        placeholder: ""
    },
    {
        type: "range",
        label: "Items per row",
        id: "item_per_row",
        name: "item_per_row",
        value: 4,
        min: 2,
        max: 6,
        step: 1,
        unit: ""
    },
    {
        type: "checkbox",
        id: "is_slider",
        name: "is_slider",
        label: "Enable slider",
        value: true
    },
    {
        type: "divider"
    },
    {
        type: "list_item",
        label: "Brand",
    },
]