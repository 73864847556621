import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, Typography, Select, TextField, Container,
    Avatar, Badge, alpha, styled, InputBase, TableContainer,
    Table, TableBody, TableRow, TableHead, TableCell, Paper, IconButton,
    Autocomplete, FormHelperText, InputLabel, Radio, FormLabel, RadioGroup,
    Popover, Menu, FormGroup,
    ListItemAvatar, ListItemText, ListItem, List,
} from '@mui/material';

import { Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import config from "../../../../Container/config";
import * as Api from "../../../../Container/config/Apis"
import _ from "underscore";
import moment from "moment";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DatePicker from '@mui/lab/DatePicker';
import PhoneInput from 'react-phone-input-2'
import Countries from '../../../common/CommonJason/Countries';
import Quantity from '../../../common/Quantity/Quantity';
import PopupModal from '../../../common/PopupModal/PopupModal';
import CheckBoxList from '../../../common/CheckBoxList/CheckBoxList'

// icons  
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import noImage from '../../../../Assets/images/no_image.jpg';
import bundleprod from '../../../../Assets/images/bundleprod.jpg';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        // width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    display: 'flex',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        // borderRadius:"10px",
        // background: "#f7f8fa",
        margin: 0,
        // display: 'flex',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    '&.Mui-error': {
        border: "1px solid #d32f2f",
    },
}));



const CreateOrder = () => {
    const [paymentCollEl, setpaymentCollEl] = useState(null);
    const [location, setlocation] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalContent, setModalContent] = useState({
        modalShowing: "",
        modalTitle: "",
    })
    const [modalData, setmodalData] = useState({
        searchProduct: [],
    })
    const [params, setParams] = useState({
        search: "",
        page: 1
    })
    const [search, setSearch] = useState('')
    const [customerEditEl, setCustomerEditEl] = useState(null);
    const [slctAddrEl, setSlctAddrEl] = useState(null);
    const [selectCountry, setSelectCountry] = useState(103)
    const [body, setBody] = useState({
        visibilityDate: moment(new Date()),
        paymentDate: moment(new Date()),
        weight: 1,
        requireShipping: false,
        discountType: "amount",
        shipping: "free",
        paymentTerms: "1",
        paymentDueLater: false,
        address: {
            first_name: "",
            last_name: "",
            address1: "",
            address2: "",
            city: "",
            company: "",
            country: "India",
            country_code: "IN",
            province: "",
            phone: "",
            zip: ""
        },

    });
    const [copied, setcopied] = useState({
        email: false,
        address: false
    });
    const [productList, setproductList] = useState([])
    const searchInput = useRef()
    const [products, setProducts] = useState([
        {
            id: 1,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "Unicorn Fruit Whipped Body ButterUnicorn Fruit Whipped Body ButterUnicorn Fruit Whipped Body Butter",
            price: 20.00,
            quantity: 1,
            SKU: "testBundle1",
            desc: "6 product set"

        },
        {
            id: 2,
            tag: "Unfulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 20.00,
            quantity: 1,
            SKU: "testBundle1",
            desc: "6 product set"

        },
        {
            id: 3,
            tag: "fulfilled",
            img: bundleprod,
            name: "Coco Cloud Whipped Luxury Shave Butter",
            price: 139.00,
            quantity: 1,
            sku: "testBundle1",
            desc: "6 product set"

        },
    ]);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const tagList = [
        { title: 'Vintage', },
        { title: 'cotton ', },
        { title: 'summer', },

    ];

    const handleLocationChange = (event) => {
        setlocation(event.target.value);
    };
    const ModalOpen = () => setOpenModal(true);
    const ModalClose = () => setOpenModal(false);

    const triggerModal = useCallback((title, search, modalVal) => {
        // console.log("modalVal", modalVal);
        setOpenModal(true);
        let title_html = ""
        if (search === true) {
            title_html = <Box>
                <Typography variant="h6" component="div" gutterBottom>{title}</Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon color="grey" />
                    </SearchIconWrapper>
                    <StyledInputBase
                        sx={{ width: '100%' }}
                        placeholder="Search…"
                        name={modalVal}
                        inputProps={{ 'aria-label': 'search' }}
                        inputRef={searchInput}
                    // onChange={(e) => handleSearchChange(e, "input")}
                    />
                </Search>
            </Box>
        } else {
            title_html = title
        }
        setModalContent({ "modalShowing": modalVal, "modalTitle": title_html })
        handleEditCustClose()

    }, [searchInput])
    const handleDateChange = (newValue) => {
        setBody({ ...body, 'visibilityDate': newValue });
    };
    const handleRequireShipping = (event) => {
        setBody({ ...body, 'requireShipping': !body.requireShipping });
    };
    const handleShippingChange = (event) => {
        setBody({ ...body, 'shipping': event.target.value });
    };
    const handleTaxClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleTaxClose = () => {
        setAnchorEl(null);
    };
    const handleSelectChange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value });
    }
    const changePaymentDueStat = () => {
        setBody({ ...body, 'paymentDueLater': !body.paymentDueLater });
    }
    const taxBoxOpen = Boolean(anchorEl);
    const id = taxBoxOpen ? "simple-popover" : undefined;
    const handlePaymentDateChange = (newValue) => {
        setBody({ ...body, 'paymentDate': newValue });
    };
    const openCollect = Boolean(paymentCollEl);
    const openCollectPayment = (event) => {
        setpaymentCollEl(event.currentTarget);
    };
    const CloseCollectPayment = () => {
        setpaymentCollEl(null);
    };
    const copiedFn = (name) => {
        setcopied({ ...copied, [name]: true })
        setTimeout(() => {
            setcopied(false)
        }, 2000);
    }
    const editCustOpen = Boolean(customerEditEl);
    const handleEditCustClick = (event) => {
        setCustomerEditEl(event.currentTarget);
    };
    const handleEditCustClose = () => {
        setCustomerEditEl(null);
    };

    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const countrySelect = (event) => {
        setSelectCountry(event.target.value);
    };
    const handleNumberChange = (value, data, type) => {
        if (type === "address") {
            setBody({
                ...body, 'address': {
                    ...body.address,
                    'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`
                }
            })
        } else {
            setBody({ ...body, 'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}` })
        }
    };

    const selectAddrOpen = Boolean(slctAddrEl);
    const handleSlctAddrClick = (event) => {
        setSlctAddrEl(event.currentTarget);
    };
    const handleSlctAddrClose = () => {
        setSlctAddrEl(null);
    };
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const getproductList = (param) => {
        Api.GetApi(`${config.PRODUCTS_LIST}${param}`).then(res => {
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                let variantArr = []
                if (res.data.data) {
                    res.data.data.map((opt) => {
                        opt.variants.map((x) => {
                            return x.active = false
                        })
                        arr.push({ ...opt, active: false })
                    })
                    if (modalData.searchProduct.length > 0) {
                        arr.map((data) => {
                            modalData.searchProduct.map((opt) => {
                                if (data.id === opt.id) {
                                    data.active = true
                                    data.variants = opt.variants
                                } else {
                                    return data.active = false
                                }
                            })
                        })
                    }
                    setproductList(arr)
                }
            }
        })
    }
    useEffect(() => {
        getproductList(`?page=${params.page}`)
    }, []);

    const handleChange = useCallback((e, data, type) => {

        let updateCheck = [];
        console.log("productList: ", productList)
        if (type === "searchProduct") {
            productList.map((dta) => {
                updateCheck.push({ ...dta });
            });
        }



        let index = _.findLastIndex(updateCheck, { 'id': data.id })
        console.log("index", index)

        if (updateCheck.length > 0 && index !== -1) {
            updateCheck[index].active = e.target.checked;
            // updateCheck[index].variants && updateCheck[index].variants.length > 0 && updateCheck[index].variants.map((upd) => {
            //     updateCheck[index].variants.push({...upd, 'active': e.target.checked})
            // });
            updateCheck[index].variants && updateCheck[index].variants.length > 0 && updateCheck[index].variants.map((upd) => {
                (upd.active = e.target.checked);
            });

            if (type === "searchProduct") {
                setproductList(updateCheck);
            }
        }
    }, [productList])

    const handleinnerChange = useCallback((e, data, indx) => {
        let updateInnerCheck = [];

        productList.map((dta) => {
            return updateInnerCheck.push({ ...dta });
        });
        let index = _.findLastIndex(updateInnerCheck, { 'id': data.id })

        console.log("e.target.checked ", e.target.checked);
        updateInnerCheck[index].active = true;
        updateInnerCheck[index].variants[indx].active = e.target.checked;
        let length = updateInnerCheck[index].variants.filter((opt) => opt.active).length;

        if (length < 1) {
            updateInnerCheck[index].active = false;
        }
        setproductList(updateInnerCheck);
    }, [productList])
    const handleSearchChange = useCallback((e, type) => {
        // if (type === "browse") {
        //     triggerModal(e, e.target.name === "searchCategory" ? "Search category" : "Search products", true)
        //     setTimeout(() => {
        //         searchInput.current.focus()
        //     }, 1);
        // }
        // setSearch(e.target.value)
        // setParams({ ...params, 'search': e.target.value })
        // if (e.target.name === "searchCategory") {
        //     if (e.target.value !== "") {
        //         getCategoryList(`?page=${params.page}&search=${e.target.value}`)
        //     } else {
        //         getCategoryList(`?page=${params.page}`)
        //     }
        // } else if (e.target.name === "searchProduct") {
        //     if (e.target.value !== "") {
        //         getproductList(`?page=${params.page}&search=${e.target.value}`)
        //     } else {
        //         getproductList(`?page=${params.page}`)
        //     }
        // } else if (e.target.name === "customers") {
        //     if (e.target.value !== "") {
        //         getUserList(`?page=${params.page}&search=${e.target.value}`)
        //     } else {
        //         getUserList(`?page=${params.page}`)
        //     }
        // }
    }, [search, params])
    const handleQtyChange = (e) => {
        console.log("handleQtyChange", e)
        // let ind = _.findLastIndex(products, { 'id': e.id })
        // products[ind].quantity = e.target.value
        // setProducts([...products])
    }
    const updateQty = (type, data) => {
        let ind = _.findLastIndex(products, { 'id': data.id })
        let quantityVal = data.quantity
        if (type === "incriment") {
            products[ind].quantity = quantityVal + 1;
        } else {
            if (quantityVal > 1) {
                products[ind].quantity = quantityVal - 1;
            } 
        }
        setProducts([...products])
    }


    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={6}>
                            <Button component={Link} variant="text" to="/orders" startIcon={<ArrowBackIosIcon />}> Create order</Button>
                        </Grid>
                        <Grid item md={6} sx={{ textAlign: "Right" }}></Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={6}>
                                            <Typography variant="h6" component="div" gutterBottom > Products </Typography>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: 'flex', my: 2 }}>
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon color="grey" />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search products"
                                                inputProps={{
                                                    'aria-label': 'search',
                                                    // value: params.search 
                                                }}
                                                onChange={() => triggerModal("Select products", true)}
                                            />
                                        </Search>
                                        <Button variant="outlined" color="primary"
                                            onClick={() => triggerModal("Select products", true, "selectProduct")}
                                            size="large" sx={{ ml: 1 }} >Browse</Button>
                                    </Box>

                                    <TableContainer
                                    // component={Paper} 
                                    >
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Product  </TableCell>
                                                    <TableCell >Quantity</TableCell>
                                                    <TableCell >Total</TableCell>
                                                    <TableCell > </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {products.filter((cntnt) => cntnt.tag === "Unfulfilled").map((data, index) => {
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                            <TableCell component="th" scope="row">
                                                                <Box sx={{ display: "flex" }}>
                                                                    <Box className="orderProductBox__img">
                                                                        <Badge overlap="circular">
                                                                            <Avatar alt="Sample user" src={data.img} variant="square" />
                                                                        </Badge>
                                                                    </Box>
                                                                    <Box className="orderProductBoxCntnt__link" sx={{ pl: 1 }}>
                                                                        <Link to="/orders/create">{data.name}</Link>
                                                                        <Typography variant="body2" component="div">₹{(data.price).toFixed(2)} </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box className="orderProductBoxCntnt__qntty">
                                                                    <Quantity
                                                                        quantity={data.quantity || 0}
                                                                        handleQtyChange={(e) => handleQtyChange(e, data.quantity)}
                                                                        incriment={() => updateQty("incriment", data)}
                                                                        decrement={() => updateQty("decriment", data)}
                                                                    />
                                                                    {/* totalItems */}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>₹{(data.price * data.quantity).toFixed(2)}</TableCell>
                                                            <TableCell>
                                                                <IconButton aria-label="delete" size="small">
                                                                    <CloseIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
                                        <Grid item md={6}>
                                            <Typography variant="h6" component="div" gutterBottom >Payment </Typography>
                                        </Grid>
                                        <Grid item md={6} sx={{ textAlign: "Right" }}>

                                        </Grid>
                                    </Grid>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>Subtotal </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>1 item</Typography> </Box>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>₹5.00 </Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>
                                                <Button className="noHover" sx={{ p: 0, justifyContent: "flex-start" }} variant="text" color="primary"
                                                    onClick={() => triggerModal("Add discount", false, "addDiscount")}
                                                >Add discount</Button>

                                            </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}> -</Typography> </Box>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>₹0.00 </Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>
                                                <Button className="noHover" sx={{ p: 0, justifyContent: "flex-start" }} variant="text" color="primary"
                                                    onClick={() => triggerModal("Add shipping", false, "addShipping")}
                                                >Add shipping</Button>
                                            </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>Standard Shipping (0.34 kg)</Typography> </Box>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>₹5.50 </Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>
                                                <Button className="noHover" sx={{ p: 0, justifyContent: "flex-start" }} variant="text" color="primary"
                                                    aria-describedby={id} onClick={handleTaxClick}>Tax</Button>
                                                <Popover
                                                    id={id}
                                                    open={taxBoxOpen}
                                                    anchorEl={anchorEl}
                                                    onClose={handleTaxClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    }}
                                                >
                                                    <Box sx={{ pt: 2, px: 2, pb: 1 }}>
                                                        <Typography variant="subtitle1" component="div" sx={{ mb: 1 }}>
                                                            {" "}
                                                            Taxes are automatically calculated.{" "}
                                                        </Typography>
                                                        <FormControlLabel control={<Checkbox />} label="Charge taxes" />
                                                    </Box>
                                                    <Divider />
                                                    <Box sx={{ textAlign: "right", p: 1.3 }}>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            className="float_btn"
                                                            size="small"
                                                            sx={{ ml: 1 }}
                                                            onClick={handleTaxClose}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className="float_btn"
                                                            size="small"
                                                            sx={{ ml: 1 }}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Popover>
                                            </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>IGST 18%</Typography> </Box>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>₹17.10</Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                        <Box className="ordertabular__title" >
                                            <Typography variant="p" component="strong" sx={{ ml: 1 }}>Total </Typography>
                                        </Box>
                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                            <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}>₹10.50 </Typography> </Box>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ mb: 1 }} />
                                    <FormControlLabel control={<Checkbox
                                        checked={body.paymentDueLater} onChange={changePaymentDueStat} />} label="Payment due later" />
                                    {body.paymentDueLater === true ? <Box sx={{ mt: 2 }}>
                                        <Grid container spacing={2} columns={12}>
                                            <Grid item md={6}>
                                                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                                    <InputLabel id="paymentTerms-label">Payment terms</InputLabel>
                                                    <Select
                                                        labelId="paymentTerms-label"
                                                        id="paymentTerms"
                                                        value={body.paymentTerms}
                                                        name="paymentTerms"
                                                        onChange={handleSelectChange}
                                                        size="small">
                                                        <MenuItem value="1">Due on receipt</MenuItem>
                                                        <MenuItem value="2">Within 7 days</MenuItem>
                                                        <MenuItem value="3">Within 15 days</MenuItem>
                                                        <MenuItem value="4">Within 30 days</MenuItem>
                                                        <MenuItem value="8">Within 45 days</MenuItem>
                                                        <MenuItem value="5">Within 60 days</MenuItem>
                                                        <MenuItem value="6">Within 90 days</MenuItem>
                                                        <MenuItem value="7">Fixed date</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={6}>
                                                {body.paymentTerms !== "1" && body.paymentTerms !== "" ?
                                                    <Box sx={{ mb: 2 }}><LocalizationProvider dateAdapter={DateAdapter} >
                                                        <DatePicker
                                                            label="Issue date"
                                                            value={body.paymentDate}
                                                            onChange={handlePaymentDateChange}
                                                            // minDateTime={new Date()}
                                                            renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                                                            disablePast

                                                        />
                                                    </LocalizationProvider></Box> : null
                                                }


                                            </Grid>
                                        </Grid>

                                        <Typography variant="body2" component="div"  >Payment due on July 23, 2022 (Net 15). You’ll be able to send an invoice and collect payment from the order page.</Typography>
                                    </Box> : null}



                                    <Divider sx={{ mt: 1.5 }} />
                                    <Box sx={{ p: 2, textAlign: "right" }}>
                                        {body.paymentDueLater === false ? <>
                                            <Button variant="outlined" color="primary" size="large" sx={{ ml: 1 }}
                                                onClick={() => triggerModal("Send invoice", false, "sendInvoice")}
                                            >Send invoice</Button>
                                            <Button
                                                id="collectPayment"
                                                aria-controls={openCollect ? "basic-menu" : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openCollect ? "true" : undefined}
                                                onClick={openCollectPayment}
                                                variant="contained" color="primary" size="large" sx={{ ml: 1 }}
                                                endIcon={<ArrowDropDownIcon fontSize="inherit" />}
                                            >
                                                Collect payment
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={paymentCollEl}
                                                open={openCollect}
                                                onClose={CloseCollectPayment}
                                                MenuListProps={{
                                                    "aria-labelledby": "collectPayment"
                                                }}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                            >
                                                <MenuItem onClick={CloseCollectPayment}> Enter credit card</MenuItem>
                                                <MenuItem onClick={CloseCollectPayment}> Mark as paid</MenuItem>
                                            </Menu>
                                        </> : <Button variant="contained" color="primary" size="large" sx={{ ml: 1 }}>Create order</Button>}

                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Typography variant="h6" component="div" gutterBottom> Customer  </Typography>

                                        <IconButton
                                            aria-label="delete"
                                            id="editCust-button"
                                            aria-controls={editCustOpen ? 'editCust-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={editCustOpen ? 'true' : undefined}
                                            onClick={handleEditCustClick}
                                        ><MoreHorizIcon /></IconButton>

                                        <Menu
                                            id="editCust-menu"
                                            anchorEl={customerEditEl}
                                            open={editCustOpen}
                                            onClose={handleEditCustClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'editCust-button',
                                            }}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&:before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem onClick={() => triggerModal("Edit contact information", false, "editContactinfo",)}
                                            >Edit contact information</MenuItem>
                                            <MenuItem onClick={() => triggerModal("Edit shipping address", false, "editShippinginfo")}
                                            >Edit shipping address</MenuItem>
                                            <MenuItem onClick={() => triggerModal("Edit billing address", false, "editBillinginfo")}
                                            >Edit billing address</MenuItem>
                                            <MenuItem onClick={handleEditCustClose}>
                                                <Typography component="div" color="error"  > Remove customer </Typography>
                                            </MenuItem>

                                        </Menu>
                                    </Box>
                                    <Search sx={{ mb: 2, }}>
                                        <SearchIconWrapper>
                                            <SearchIcon color="grey" />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search or create customer"
                                            inputProps={{
                                                'aria-label': 'search',
                                                // value: params.search 
                                            }}
                                            name="searchProduct"
                                        // onChange={(e) => handleSearchChange(e, "browse")}
                                        />
                                    </Search>
                                    <Box><Button variant="text" component={Link} to="/users/7" className="noHover" sx={{ p: 0, justifyContent: "flex-start", }}>Baljit S </Button></Box>
                                    <Box><Button variant="text" component={Link} to="/orders" className="noHover" sx={{ p: 0, justifyContent: "flex-start", }}>4 orders</Button></Box>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom>Contact information</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Typography variant="button" component="div" sx={{ wordBreak: "break-all" }}>
                                            baljitsingh1@gmail.com
                                        </Typography>
                                        <IconButton aria-label="delete" sx={{ width: "35px", height: "35px" }}>
                                            <CopyToClipboard text="" onCopy={(e) => copiedFn("email")} >
                                                {copied.email ?
                                                    <DoneIcon sx={{ width: "15px" }} /> : <ContentCopyOutlinedIcon sx={{ width: "15px" }} />
                                                }
                                            </CopyToClipboard>
                                        </IconButton>
                                    </Box>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Typography variant="h6" component="h6" gutterBottom>Shipping address</Typography>
                                        <IconButton aria-label="delete" sx={{ width: "35px", height: "35px" }}>
                                            <CopyToClipboard text="" onCopy={(e) => copiedFn("address")} >
                                                {copied.address ?
                                                    <DoneIcon sx={{ width: "15px" }} /> : <ContentCopyOutlinedIcon sx={{ width: "15px" }} />
                                                }
                                            </CopyToClipboard>
                                        </IconButton>
                                    </Box>
                                    <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">Baljit S


                                    </Typography>
                                    <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">Test</Typography>
                                    <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">California CA 90001</Typography>
                                    <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">United States</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom>Shipping address</Typography>
                                    <Typography sx={{ mb: 1, lineHeight: 'normal', }} variant="p" component="div">Same as shipping address</Typography>
                                </CardContent>

                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom> Notes  </Typography>
                                    <FormControl fullWidth >
                                        <TextField size="small" />
                                    </FormControl>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom> Tags  </Typography>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={tagList}
                                        disableCloseOnSelect
                                        size="small"
                                        getOptionLabel={(option) => option.title}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.title}
                                            </li>
                                        )}
                                        style={{ width: "100%", }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Find or create tag" />
                                        )}
                                    />
                                </CardContent>
                            </Card>

                            {/* <Button variant="contained" color="primary" className='float_btn' size="large" sx={{ ml: 1 }}>Save</Button> */}
                        </Grid>
                    </Grid>
                </Box>
            </Container >
            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt={modalContent.modalShowing === "sendInvoice" ? "Send" : modalContent.modalShowing === "selectProduct" ? "Add" : "Save"}
            // hideFooter={modalContent.modalShowing === "manageUser" ? true : false}

            // savebtnFunct={savebtnFunct} 
            >

                {modalContent.modalShowing === "addDiscount" ? <>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={6}>
                            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                <InputLabel id="discountType-label">Discount type</InputLabel>
                                <Select
                                    labelId="discountType-label"
                                    id="discountType"
                                    value={body.discountType}
                                    label="Discount type"
                                    name="discountType"
                                    onChange={handleSelectChange} >
                                    <MenuItem value="amount">Amount</MenuItem>
                                    <MenuItem value="percentage">Percentage</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl fullWidth sx={{ mb: 2 }} >
                                <TextField
                                    label="Discount value"
                                    id="discountValue"
                                    type="number"

                                    InputProps={
                                        {
                                            startAdornment: body.discountType === "amount" ? <InputAdornment position="start">₹</InputAdornment> : null,
                                            endAdornment: body.discountType === "percentage" ? <InputAdornment position="end">%</InputAdornment> : null
                                        }
                                    }
                                    size="small"
                                />

                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl fullWidth sx={{ mb: 2 }} >
                                <TextField label="Reason" variant="outlined" size="small" />
                                <FormHelperText > Your customers can see this reason</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                </> : null}

                {modalContent.modalShowing === "addShipping" ? <>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={body.shipping}
                            onChange={handleShippingChange}

                        >
                            <FormControlLabel value="free" control={<Radio />} label="Free shipping" />
                            <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                        </RadioGroup>
                    </FormControl>
                    {body.shipping === "custom" ?
                        <Box sx={{ mt: 2, ml: 1 }}>
                            <Grid container spacing={2} columns={12} >
                                <Grid item md={6}>
                                    <FormControl fullWidth sx={{ mb: 2 }} >
                                        <TextField label="Reason" variant="outlined" size="small" />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl fullWidth sx={{ mb: 2 }} >
                                        <TextField
                                            label="Price"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                            }}
                                            size="small"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box> : null
                    }
                </> : null}
                {modalContent.modalShowing === "sendInvoice" ?
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="To"
                                    type="text"
                                    id="to"
                                    name="to"
                                    size="small"
                                    // value={account.to}                                   
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="From"
                                    type="text"
                                    id="from"
                                    name="from"
                                    size="small"
                                    // value={from}
                                    // InputProps={{
                                    //     readOnly: true,
                                    //     disabled: true,
                                    // }}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel id="demo-radio-buttons-group-label">Send bcc to:</FormLabel>
                            <FormGroup sx={{ flexDirection: "column" }}>
                                {/* {bcc.length > 0 && bcc.map((opt, index) => {
                                    return (
                                        <FormControlLabel key={index} control={<Checkbox checked={opt.checked} onChange={(e) => handleBCCchange(e, opt)} />} label={opt.email} sx={{ mr: 4 }} />
                                    )
                                })} */}
                                <FormControlLabel control={<Checkbox checked />} label="villiamjit@weoinfotech.com" />
                                <FormControlLabel control={<Checkbox checked />} label="Test@gmail.com" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Subject"
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    size="small"
                                    // value={account.subject}
                                    // onChange={(e) => handleOnchange(e, "account")}
                                    sx={{ m: 0, width: '100%' }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    id="custom_message"
                                    name="custom_message"
                                    label="Custom message (optional)"
                                    // value={account.custom_message}
                                    // onChange={(e) => handleOnchange(e, "account")}
                                    multiline
                                    rows={4}
                                />
                            </FormControl>
                        </Grid>
                    </Grid> : null}


                {modalContent.modalShowing === "editContactinfo" ? <>
                    <FormControl fullWidth sx={{ mb: 2 }} >
                        <TextField label="Email" type="email" variant="outlined" size="small" />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }} >
                        <TextField label="Phone number" variant="outlined" size="small" />
                    </FormControl>
                    <FormControlLabel control={<Checkbox />} label="Update customer profile" />
                </> : null}
                {modalContent.modalShowing === "editShippinginfo" || modalContent.modalShowing === "editBillinginfo" ? <>
                    <Box sx={{ mb: 2 }} >
                        <Button
                            id="selectAddr-button"
                            aria-controls={selectAddrOpen ? "selectAddr-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={selectAddrOpen ? "true" : undefined}
                            onClick={handleSlctAddrClick}

                            variant="outlined" color="primary"
                            endIcon={<ArrowDropDownIcon fontSize="inherit" />}
                        > Select address</Button>
                        <Menu
                            id="selectAddr-menu"
                            anchorEl={slctAddrEl}
                            open={selectAddrOpen}
                            onClose={handleSlctAddrClose}
                            MenuListProps={{
                                "aria-labelledby": "selectAddr-button"
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem sx={{ display: "block" }} onClick={handleSlctAddrClose}>
                                <Typography variant="body2" component="div" gutterBottom> Baljit Singh</Typography>
                                <Typography variant="body2" component="div" gutterBottom> sec63</Typography>
                                <Typography variant="body2" component="div" gutterBottom> Mohali Punjab </Typography>
                                <Typography variant="body2" component="div" gutterBottom> India  </Typography>
                            </MenuItem>
                            <MenuItem sx={{ display: "block" }} onClick={handleSlctAddrClose}>
                                <Typography variant="body2" component="div" gutterBottom> Baljit Singh</Typography>
                                <Typography variant="body2" component="div" gutterBottom> sec63</Typography>
                                <Typography variant="body2" component="div" gutterBottom> Mohali Punjab </Typography>
                                <Typography variant="body2" component="div" gutterBottom> India  </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Country/region</InputLabel>
                                <Select
                                    labelId="countryRegion"
                                    id="countryRegion"
                                    value={selectCountry}
                                    label="Country/region"
                                    size="small"
                                    onChange={countrySelect}
                                >
                                    {Countries.map((data, index) => {
                                        return <MenuItem key={index} value={index}>{data.label}</MenuItem>
                                    })}


                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ m: 0 }} >
                                <TextField
                                    label="First name"
                                    type="text"
                                    id="prodPrice"
                                    name="price"
                                    size="small"
                                    onChange={handleOnchange}
                                    // onBlur={getprofit}
                                    sx={{ m: 0, width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <TextField
                                    label="Last name"
                                    type="text"
                                    id="comparePrice"
                                    size="small"
                                    name="compare_at_price"
                                    sx={{ m: 0, width: '100%' }}
                                    onChange={handleOnchange}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Company"
                                    id="Company"
                                    name="Company"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Address"
                                    id="Address"
                                    name="Address"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="Apartment, suite, etc."
                                    id="Apartment"
                                    name="Apartment"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="City"
                                    id="City"
                                    name="City"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="State"
                                    id="State"
                                    name="State"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <TextField
                                    label="PIN code"
                                    id="pin"
                                    name="PIN"
                                    type="text"
                                    size="small"
                                    onChange={handleOnchange}
                                    sx={{ m: 0, width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ margin: "0px" }}>
                                <PhoneInput
                                    enableSearch={true}
                                    disableAreaCodes={false}
                                    country="in"
                                    value={body.address.phone}
                                    inputExtrastate={{ name: 'mobile', required: true }}
                                    inputProps={{ maxLength: '17' }}
                                    onChange={(e, x) => handleNumberChange(e, x, "address")}
                                    isValid={(value, data) => {
                                        if (
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length < 9 &&
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length > 0
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    placeholder=""
                                    countryCodeEditable={false}
                                    defaultMask=".. ... ...."
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </> : null}
                {/* {modalContent.modalShowing === "editBillinginfo" ? <>

                </> : null} */}
                {modalContent.modalShowing === "selectProduct" ?
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                        {productList.length > 0 && productList.map((value, index) => {
                            const labelId = `Product-${value.id}`;

                            return (
                                <Box key={index} >
                                    <ListItem sx={{ flexDirection: "column", alignItems: "center", }} disablePadding>
                                        <CheckBoxList
                                            key={index}
                                            value={value.id}
                                            handleToggle={(e) => handleChange(e, value, "searchProduct")}
                                            labelId={labelId}
                                            checked={value.active}
                                            name={value.title}
                                            image={value.images.length > 0 ? value.images[0].src : noImage || noImage}
                                            avatar={true}
                                            child={false}
                                        />
                                        <List sx={{ width: "100%" }} component="div" disablePadding>
                                            {value.variants && value.variants.length > 0 && value.variants.map((data, indx) => {
                                                const labelIdInnr = `checkbox-list-label-${data.id}`;
                                                return (
                                                    <CheckBoxList
                                                        key={indx}
                                                        value={data.id}
                                                        handleToggle={(e) => handleinnerChange(e, value, indx)}
                                                        labelId={labelIdInnr}
                                                        checked={data.active}
                                                        name={data.title || "Untitled"}
                                                        avatar={false}
                                                        child={true}
                                                    />
                                                );
                                            })}
                                        </List>
                                    </ListItem>
                                </Box>
                            );
                        })}
                    </List>
                    : null}



            </PopupModal>
        </>
    );
}

export default CreateOrder