import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import HeadingText from "./HeadingText";
import * as heading from "./Typography/Exports"

export default function TextColumnWithImage() {
    const dataVal = useSelector((state) => state.dataValue.components.text_column_with_image)
    console.log("dataVal: ", dataVal)
    return (
        <Box id="text_column_with_image">
            <Container maxWidth="lg">
                <HeadingText
                    data={dataVal && dataVal}
                />
                <Grid container spacing={4}>
                    {dataVal && dataVal.items.length > 0 && dataVal && dataVal.items.map((item, index) => {
                        return (
                            <Grid key={index.toString()} item sm={6} md={dataVal && dataVal.item_per_row == 4 ? 3 : dataVal.item_per_row == 3 ? 4 : 6} >
                                <img src={item.image_src} alt="" className="full-img" />
                                <Box sx={{ py: 2 }}>
                                    <heading.HeadingH4
                                        ishtml={false}
                                        type="div"
                                        data={item.heading}
                                    />
                                    <Typography variant="p" component="div" dangerouslySetInnerHTML={{ __html: item.text }}>
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Box >
    )
}