import React, { useCallback, useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent,
    Typography, Container,
    FormControlLabel, Switch

} from '@mui/material';
import { Link } from 'react-router-dom';



const Notifications = () => {



    const [unitSystem, setUnitSystem] = useState([
        { label: "Order confirmation", content: "Sent automatically to the customer after they place their order.", checked: false },
        { label: "Order edited", content: "Sent to the customer after their order is edited (if you select this option).", checked: false },
        { label: "Abandoned POS checkout", content: "Sent to the customer when you email their cart from POS. Includes a link to buy online.).", checked: false },
    ])

    const handleSwitchChange = (index) => {
        let arr = []
        // unitSystem.map((data) => {
        //     return arr.push(data)
        // })
        unitSystem[index].checked = !unitSystem[index].checked

        setUnitSystem([...unitSystem])


    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Notifications</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>   </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }}>
                                        <Typography variant="p" component="strong" gutterBottom>Order</Typography>
                                    </Box>

                                    {unitSystem.map((dta, index) => {
                                        return (
                                            <Box key={index} className="notificationList">

                                                <Grid container spacing={2} columns={12}  >
                                                    <Grid xs={10} item className="notificationList__left" >
                                                        <Grid container spacing={2} columns={12} sx={{ alignItems: "center" }} >
                                                            <Grid item xs={3}>
                                                                <Button component={Link} to="/account/email/settings" variant="text" className="noHover" color="secondary" > {dta.label}</Button>
                                                            </Grid>
                                                            <Grid item xs={9}> {dta.content}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2} className="notificationList__right">
                                                        <FormControlLabel
                                                            control={
                                                                <Switch checked={dta.checked} onChange={() => handleSwitchChange(index)} name="notification" />
                                                            }
                                                            label="Enable"
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Box >)
                                    })}

                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default Notifications