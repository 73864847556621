import React from "react";
import { TextField } from "@mui/material";

export default function TextFields({ data, handleInputChange }) {
    const params = new URLSearchParams(window.location.search)
    return (
        <React.Fragment>
            {params.get('item') ?
                <TextField
                    label={data.label}
                    defaultValue={data.value}
                    variant="outlined"
                    fullWidth
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    sx={{ mb: 2 }}
                    onChange={handleInputChange}
                />
                :
                <TextField
                    label={data.label}
                    value={data.value}
                    variant="outlined"
                    fullWidth
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    sx={{ mb: 2 }}
                    onChange={handleInputChange}
                />
            }
        </React.Fragment>
    )
}