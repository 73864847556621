import React, { useCallback, useEffect, useState } from 'react';
import {
    Box, Button, Grid,
    Card, CardContent,
    FormControl, Divider, Typography, TextField, Chip, Avatar, List, ListItemButton, ListItemText,
    ListItemAvatar, Container, LinearProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Link, useParams, useNavigate } from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import noImage from '../../../../../Assets/images/no_image.jpg';
import SingleFileupload from '../../../../common/Fileupload/SingleFileupload';
import config from '../../../../../Container/config';
import * as Api from '../../../../../Container/config/Apis';
import { useSnackbar } from 'notistack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ReactPlayer from "react-player"
import _ from "underscore";
import VariantOptions from '../../AddProduct/VariantOptions';
import PopupModal from '../../../../common/PopupModal/PopupModal';
import MediaFiles from '../../../../common/Fileupload/MediaFIles';


const AddVariant = () => {
    const param = useParams();
    let navigate = useNavigate()

    const [loadingBar, setloadingBar] = React.useState(true);
    const [loading, setloading] = useState(false)
    const [activeProduct, setActiveProduct] = useState({})
    const [allvariants, setAllvariants] = useState([])
    const [variantList, setVariantList] = useState([])
    const [variant, setVariant] = useState({})
    const [preview, setPreview] = useState("")
    const [showSkuError, setshowSkuError] = useState(false)
    const [images, setImages] = useState([])
    const [openModal, setopenModal] = useState()
    const [urlImage, seturlImage] = useState("")
    const [productOptions, setProductOptions] = useState([])
    const [valueChange, setvalueChange] = useState(false)

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const onFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
    }
    const getVariantList = useCallback(() => {
        Api.GetApi(`${config.PRODUCTS}/${param.id}`).then(res => {
            setloadingBar(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let products = {}
                    let url = noImage
                    if (res.data.data.images.length > 0) {
                        url = _.sortBy(res.data.data.images, "position")[0].src
                    }
                    products = { ...res.data.data, img: url }

                    let array = res.data.data.variants
                    let name = ""
                    array.map((opt) => {
                        let imgSrc = res.data.data.images.filter((obj) => obj.id === opt.image_id)
                        if (!opt.option1 && !opt.option2 && !opt.option3) {
                            name = ""
                        } else if (opt.option1 && !opt.option2 && !opt.option3) {
                            name = `${opt.option1}`
                        } else if (opt.option1 && opt.option2 && !opt.option3) {
                            name = `${opt.option1}/${opt.option2}`
                        } else {
                            name = `${opt.option1}/${opt.option2}/${opt.option3}`
                        }
                        opt['img'] = !_.isUndefined(imgSrc) && !_.isNull(imgSrc) && imgSrc.length > 0 ? imgSrc[0].src : ""
                        if (!opt["title"]) {
                            opt["title"] = name
                        }
                        opt["track"] = opt.Inventory_Item.tracked
                    })
                    setAllvariants(array)
                    let restArray = array.filter((opt) => opt.id != param.vid)
                    setVariantList(restArray)
                    let activeVariant = array.filter((opt) => opt.id == param.vid)[0]
                    setVariant(activeVariant)

                    setImages(_.sortBy(res.data.data.images, "position"))
                    let options = []
                    products.options.map((opt) => {
                        activeVariant.title.split("/").map((x, i) => {
                            opt.values.map((y, ind) => {
                                if (x == y) {
                                    options.push({ ...opt, "value": x, "type": `option${i + 1}`, index: ind })
                                }
                            })
                        })
                    })
                    setProductOptions(options)
                    // products.options = options
                    setActiveProduct(products)
                    setPreview(activeVariant.img)
                }
            }
        })
    }, [param])
    console.log("productOptions: ", productOptions)

    const imgFn = (item, width, height) => {
        return (
            <>
                {Api.getId(item) === null || Api.getId(item) === undefined ?
                    <Avatar className="variant_image" alt="" src={item} variant="square" sx={{ bgcolor: "#eee", width: width, height: height }}>
                        {item === "" &&
                            <AddPhotoAlternateIcon color="primary" fontSize='large' />
                        }
                    </Avatar>
                    :
                    <Box sx={{ width: width, height: width }}>
                        <ReactPlayer
                            url={item}
                            controls={false}
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 0 }
                                }
                            }}
                            className="video_preview_product"
                            width={"100%"}
                            height={"100%"}
                            playsinline={true}
                            playbackRate={0}
                            light={true}
                        />
                    </Box>
                }
            </>
        )
    }

    const handleValueChange = (e, data) => {
        let i = _.findLastIndex(productOptions, { id: data.id })
        if (i !== -1) {
            let option = []
            productOptions.map((opt, index) => {
                if (i == index) {
                    let arr = []
                    opt.values.map((x, ind) => {
                        if (ind == opt.index) {
                            arr.push(e.target.value)
                        } else {
                            arr.push(x)
                        }
                    })
                    opt.values = arr
                    option.push({ ...opt, 'value': e.target.value })
                    setVariant({ ...variant, [opt.type]: e.target.value })
                    console.log("------------------", activeProduct.options[i]["values"].filter((y) => y == e.target.value).length)
                    if (activeProduct.options[i]["values"].filter((y) => y == e.target.value).length == 0) {
                        setvalueChange(true)
                    } else {
                        setvalueChange(false)
                    }
                } else {
                    option.push({ ...opt })
                }
            })
            // products.options = option
            setProductOptions(option)
            // setActiveProduct({ ...products })
        }
    }
    console.log("valueChange====: ", valueChange)

    const handleClose = () => {
        setopenModal(false)
    }
    //Update variant image
    const checkSKUFn = async (data) => {
        await Api.PostApi(config.PRODUCT_SKU, data).then(res => {
            console.log("res SKU: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    setshowSkuError(true)
                } else {
                    setshowSkuError(false)
                }
            }
        })
    }

    const handleOnchange = (e) => {
        if (e.target.name === "quantity") {
            setVariant({ ...variant, "inventory_quantity": e.target.value })
        } else {
            setVariant({ ...variant, [e.target.name]: e.target.value })
        }
    }
    const handleCheckbox = (e) => {
        setVariant({ ...variant, [e.target.name]: e.target.checked })
    }
    const handleSelectChange = (e) => {
        setVariant({ ...variant, [e.target.name]: e.target.value })
    }
    const handleOnSKUBlur = (e) => {
        if (e.target.value !== "") {
            checkSKUFn({ 'sku': e.target.value })
        }
    }

    //Update variant function
    const updateVariantFn = async (api, url, data) => {
        await api(url, data).then(res => {
            setloading(false)
            console.log("res update fn: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack("Update successfully", 'success');
            }
        })
    }
    const deleteVariantFn = async (api, url) => {
        await api(url).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                getVariantList()
            }
        })
    }

    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || propName === "id" || propName === "variant" || propName === "checked" || propName === "quantity" || propName === "created_at" || propName === "updated_at" || propName === "presentment_prices" || propName === "img" || propName === "Inventory_Item") {
                delete obj[propName];
            }
        }
        return obj
    }
    function cleanOption(obj) {
        for (var propName in obj) {
            if (propName === "type" || propName === "value" || propName === "index") {
                delete obj[propName];
            }
        }
        return obj
    }

    const saveVariant = () => {
        if (showSkuError) {
            snack("SKU already used!", 'error');
        } else {
            setloading(true)
            let data = clean(variant)
            if (variant.track) {
                data = {
                    ...variant,
                    inventory_quantity: parseFloat(variant.inventory_quantity),
                    price: variant.price ? variant.price.toString() : "",
                    compare_at_price: variant.compare_at_price ? variant.compare_at_price.toString() : "",
                    weight: variant.weight ? variant.weight.toString() : "0",
                }
            } else {
                data = {
                    ...variant,
                    price: variant.price ? variant.price.toString() : "",
                    compare_at_price: variant.compare_at_price ? variant.compare_at_price.toString() : "",
                    weight: variant.weight ? variant.weight.toString() : "0",
                }
            }
            let optionData = []
            productOptions.map((opt, i) => {
                cleanOption(opt)
                if (_.isNull(opt.position) || _.isUndefined(opt.position)) {
                    opt.position = i + 1
                }
                optionData.push(opt)
            })
            let productBody = {
                'id': param.id,
                'options': optionData
            }
            console.log("productBody: ", productBody)
            console.log("data: ", data)
            if (valueChange === true) {
                updateVariantFn(Api.PutApi, `${config.PRODUCTS}/${param.id}`, productBody)
            }
            updateVariantFn(Api.PutApi, `${config.PRODUCT_VARIANTS}/${param.vid}`, data)
        }
    }

    const changeVariantImage = () => {
        setopenModal(true)
    }

    const chooseImage = (data) => {
        let arr = []
        let currentIndex = _.findIndex(images, { 'id': data.id })
        images.map((opt) => {
            arr.push({ ...opt, 'active': false })
        })
        if (currentIndex !== -1) {
            arr[currentIndex].active = !arr[currentIndex].active
            let newData = { ...variant, 'image_id': data.id, 'img': data.src }
            setVariant(newData)
        }
        setImages([...arr])
    }
    const onVariantFileAdd = (e) => {
        e.preventDefault()
        let reader = new FileReader();
        let file = e.target.files[0];
        let data = new FormData();
        data.append("product_image", file)
        data.append("product_id", variant.product_id)
        addVariantImageFn(Api.PostApi, config.PRODUCT_IMAGES, data, "addNew")
        reader.readAsDataURL(file)
    }

    //Add new variant image
    const addVariantImageFn = useCallback(async (api, url, data, type) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    res.data.data.map((opt) => {
                        setImages([...images, opt])
                        setPreview(opt.src)
                    })
                    if (type !== "addNew") {
                        seturlImage("")
                        handleClose()
                    }
                    // setPreview([...new Set(preview)]);
                }
            }
        })
    }, [preview])

    const deleteImage = () => {
        deleteVariantFn(Api.DeleteApi, `${config.PRODUCT_IMAGES}/products/${param.id}/images/${variant.image_id}`)
    }

    const savebtnFunct = () => {
        let imgSrc = images.filter((opt) => opt.id == variant.image_id)[0]
        if (!_.isUndefined(imgSrc) || !_.isNull(imgSrc)) {
            setPreview(imgSrc.src)
        }
        handleClose()
    }

    useEffect(() => {
        if (param.id && param.vid) {
            getVariantList()
        } else {
            navigate(-1)
        }
    }, [param])
    console.log("allvariants: ", allvariants)
    console.log("variantList: ", variantList)
    console.log("variant: ", variant)
    console.log("activeProduct: ", activeProduct)


    return (
        <React.Fragment>
            {loadingBar ?
                <LinearProgress sx={{ mt: "-25px" }} />
                :
                <Container maxWidth="lg">
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" to={`/products/${param.id}`} startIcon={<ArrowBackIosIcon />}> {variant && variant.title} </Button>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <LoadingButton
                                sx={{ whiteSpace: 'nowrap' }}
                                color="primary"
                                size="small"
                                onClick={saveVariant}
                                loading={loading}
                                loadingIndicator="Please wait..."
                                variant="contained"
                            >
                                Save variant
                            </LoadingButton>
                            <LoadingButton
                                sx={{ ml: 2, whiteSpace: 'nowrap' }}
                                color="error"
                                size="small"
                                onClick={() => navigate(`/products/${param.id}`)}
                                loading={loading}
                                loadingIndicator="Cancel"
                                variant="contained"
                            >
                                Cancel
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs={4}>
                                            <Box>
                                                {activeProduct && activeProduct.img &&
                                                    imgFn(activeProduct.img, "100%", "auto")
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom component="div">{activeProduct && activeProduct.title}</Typography>
                                            <Chip label={activeProduct && activeProduct.status} color={activeProduct && activeProduct.status == "Active" ? "success" : "info"} size="small" />
                                            <Box sx={{ pt: 1, }}>
                                                <Typography variant="caption" display="block" gutterBottom> {activeProduct
                                                    && activeProduct.variants
                                                    ? activeProduct.variants.length : "0"
                                                } variants </Typography>
                                            </Box>
                                            <Button component={Link} variant="text" to={`/products/${param.id}`} color="success" sx={{ p: 0 }} > Back to product </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div"  >Variants</Typography>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        {variantList.length > 0 && variantList.map((item) => {
                                            return (
                                                <Link to={`/products/${param.id}/variant/${item.id}`} key={item.id} className='basicAnchor' >
                                                    <ListItemButton className='noHover' sx={{ px: 0 }}>
                                                        <ListItemAvatar sx={{ minWidth: "inherit", mr: 1 }}>
                                                            {imgFn(item.img, "40px", "auto")}
                                                        </ListItemAvatar>
                                                        <ListItemText secondary={item.title} />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        })}

                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={8}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={8}>
                                            <Typography variant="h6" component="h6" gutterBottom sx={{ mb: 0 }}>Options</Typography>
                                            {productOptions && productOptions.length > 0 &&
                                                productOptions.map((option) => {
                                                    return (
                                                        <FormControl key={option.id} fullWidth sx={{ mt: 1, mb: 0 }}>
                                                            <Typography variant="subtitle" component="small" gutterBottom sx={{ mb: 0.5 }}>{option.name}</Typography>
                                                            <TextField label="" variant="outlined" size="small" value={option.value} onChange={(e) => handleValueChange(e, option)} />
                                                        </FormControl>
                                                    )
                                                })}
                                        </Grid>
                                        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography variant="h6" component="h6" gutterBottom>Image</Typography>

                                            {/* <SingleFileupload
                                                preview={preview}
                                                onFileChange={onFileChange}
                                            /> */}
                                            <Button sx={{ minWidth: "100%", flex: "auto", p: 0 }} onClick={changeVariantImage}>
                                                {imgFn(preview, "100%", "100%")}
                                            </Button>
                                            {preview !== "" && !_.isNull(preview) && !_.isUndefined(preview) &&
                                                <Button color='error' variant='contained' sx={{ mt: 1 }} onClick={deleteImage}>
                                                    Remove image
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <VariantOptions
                                body={variant && variant}
                                handleOnchange={handleOnchange}
                                handleCheckbox={handleCheckbox}
                                handleSelectChange={handleSelectChange}
                                handleOnSKUBlur={handleOnSKUBlur}
                                showSkuError={showSkuError}
                                variantEdit={true}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }}></Divider>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <Box sx={{ textAlign: "right" }}>
                                        <LoadingButton
                                            sx={{ whiteSpace: 'nowrap' }}
                                            color="primary"
                                            size="medium"
                                            onClick={saveVariant}
                                            loading={loading}
                                            loadingIndicator="Please wait..."
                                            variant="contained"
                                        >
                                            Save variant
                                        </LoadingButton>
                                        <LoadingButton
                                            sx={{ ml: 2, whiteSpace: 'nowrap' }}
                                            color="error"
                                            size="medium"
                                            onClick={() => navigate(`/products/${param.id}`)}
                                            loading={loading}
                                            loadingIndicator="Cancel"
                                            variant="contained"
                                        >
                                            Cancel
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <PopupModal
                        open={openModal}
                        ModalClose={handleClose}
                        title={"Choose variant image"}
                        cancelbtn={true}
                        cancelbtnTxt="Cancel"
                        savebtn={true}
                        savebtnTxt={"Done"}
                        savebtnFunct={savebtnFunct} >
                        <MediaFiles
                            onVariantFileAdd={onVariantFileAdd}
                            images={images}
                            chooseImage={chooseImage}
                        />
                    </PopupModal>
                </Container>
            }
        </React.Fragment>
    );
}

export default AddVariant