import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import { Box, Drawer, List, Collapse, Button, Divider } from '@mui/material';
import { Link, NavLink } from "react-router-dom";
import { drawerWidth } from '../../Container/Exports';

import Logo from '../../Assets/images/logo.png';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import StoreIcon from '@mui/icons-material/Store';




import _ from "underscore"

import './Sidebar.css';


export default function Sidebar(props) {
    const location = useLocation()
    const [sideMenu, setSideMenu] = useState([
        { id: 1, main: 'Home', link: "/", submain: [], dropDown: false, active: false, icon: <HomeIcon />, },

        {
            id: 2, main: 'Orders', link: "/orders", submain: [
                // { sbname: 'All Order', link: '', active: false },
                // { sbname: 'Create', link: '', active: false }
            ], dropDown: false, active: false, icon: <ListAltIcon />,
        },
        {
            id: 3, main: 'Products', link: "/products/list", submain: [
                // { sbname: 'List', link: "/products/list", active: false },
                { sbname: 'Inventory', link: "/products/inventory", active: false },
                { sbname: 'Categories', link: "/products/categories", active: false }
            ], dropDown: false, active: false, icon: <Inventory2OutlinedIcon />,
        },
        { id: 4, main: 'Users', link: "/users", submain: [], dropDown: false, active: false, icon: <PersonIcon />, },
        { id: 5, main: 'Discount', link: "/discount", submain: [], dropDown: false, active: false, icon: <LocalOfferOutlinedIcon />, },
        {
            id: 6, main: 'Settings', link: "/account/details", submain: [
                { sbname: 'Store details', link: "/account/details", active: false, },
                { sbname: 'Plan', link: "/account/plan", active: false, },
                { sbname: 'Users and permissions', link: "/account/permission", active: false, },
                { sbname: 'Payments', link: "/account/payments", active: false, },
                // { sbname: 'Taxes', link: "/account/taxes", active: false, },
                { sbname: 'Email/SMS', link: "/account/email/templates", active: false, },
            ], dropDown: false, active: false, icon: <SettingsIcon />,
        },
        {
            id: 7, main: 'My store', link: "/store/templates", submain: [
                { sbname: 'Templates', link: "/store/templates", active: false, },
                { sbname: 'Blogs', link: "/store/blogs", active: false, },
                { sbname: 'Pages', link: "/store/pages", active: false, },
                { sbname: 'Menu', link: "/store/menu", active: false, },
                { sbname: 'URL redirects', link: "/store/redirects", active: false, },
                { sbname: 'Preferences', link: "/store/preferences", active: false, },
                { sbname: 'Domain verification', link: "/store/domain-verification", active: false, },
            ], dropDown: false, active: false, icon: <StoreIcon />, hasTopBorder: true,
        },
    ]);

    const [storeMenu, setStoreMenu] = useState([
        {
            id: 1, main: 'Home', link: "", submain: [

            ], dropDown: false, active: false, icon: <StoreIcon />,
        },
    ])
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    const handleClick = (e, index) => {
        let updatemenu = [];
        sideMenu.map((data) => {
            return updatemenu.push({ ...data, 'dropDown': false, 'active': false })
        })
        updatemenu[index].dropDown = true
        updatemenu[index].active = true
        setSideMenu(updatemenu)
    };

    useEffect(() => {
        let activeRoute = sideMenu.filter((opt) => opt.main.toLowerCase() === location.pathname.split("/")[1])[0]
        if (!_.isUndefined(activeRoute)) {
            let currentIndex = _.findLastIndex(sideMenu, { 'id': activeRoute.id })
            handleClick("", currentIndex)
        }
    }, [])

    const drawer = (
        <>
            <div>
                <Box sx={{ textAlign: "center", padding: "16px" }} >
                    <img src={Logo} alt="" width="90" sx={{ margin: "0 auto", display: "block", }} />
                </Box>
                <List>
                    {sideMenu.map((menu, index) => (
                        <Box key={index}>
                            {menu.hasTopBorder ? <Divider sx={{ my: 1 }} /> : null}
                            <Box>
                                {menu.link === "" ?
                                    <Button onClick={(data) => handleClick(data, index)} variant="contained" className="sidebarButton">
                                        <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                            <Box className="sidebarButton__icon" sx={{ display: 'flex', mr: 1, }}>{menu.icon}</Box>
                                            {menu.main}
                                        </Box>
                                        {/* {menu.submain.length > 0 ?
                                    <> { menu.dropDown ? <ExpandLess /> : <ExpandMore />  } </> :  null
                                } */}
                                    </Button> :
                                    <NavLink component={Link} to={`${menu.link}`} onClick={(data) => handleClick(data, index)} variant="contained" className={`sidebarButton ${menu.active || location.pathname === menu.link ? "active" : ""} `}  >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box className="sidebarButton__icon" sx={{ display: 'flex', mr: 1, }}>{menu.icon}</Box>
                                            {menu.main}
                                        </Box>
                                    </NavLink>
                                }

                                {menu.submain.length > 0 ?
                                    <Collapse in={menu.dropDown} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding >
                                            {menu.submain.map((data, index1) => (
                                                <NavLink key={index1} component={Link} to={`${data.link}`} variant="contained" className={`sidebarButton sidebarButton--inner ${data.active || location.pathname === data.link ? "active" : ""} `} >  {data.sbname}</NavLink>
                                            ))}
                                        </List>
                                    </Collapse> : null}
                            </Box>
                        </Box>
                    ))}
                </List>
            </div >

        </>
    );
    const updateActivemenu = () => {
        if ((location.pathname).split("/").length > 2) {
            let updatemenu = {};
            sideMenu.filter(data => data.link === `/${(location.pathname).split("/")[1]}`).map((data) => {
                updatemenu = { ...data, 'dropDown': true, 'active': true }
            })
            console.log("updatemenu: ", updatemenu)
            // setSideMenu(updatemenu)
        }
        // let list = sideMenu.filter(data => data.link === `/${(location.pathname).split("/")[1]}`)
        // console.log("list", list);
    }
    useEffect(() => {
        // Update the document title using the browser API 
        updateActivemenu()
    });
    return (
        <Box
            component="nav"
            sx={{ boxShadow: "0 0px 6px rgb(0 0 0 / 10%)", width: { md: drawerWidth }, flexShrink: { sm: 0 }, }}
            aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {/* <Drawer
                container={container}
                variant="temporary"
                open={props.mobileOpen}
                onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', background: "#ffffff", border: "0", width: drawerWidth },
                }}>
                {drawer}
            </Drawer> */}
            <Drawer
                variant="persistent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', background: "#ffffff", border: "0", width: drawerWidth },
                }}
                open>
                {drawer}

            </Drawer>
        </Box>
    )
}
