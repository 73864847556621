export default [
    {
        type: "text",
        name: "src",
        label: "Map src",
        value: "",
        placeholder: "Add map embeded code"
    },
    {
        type: "text",
        name: "heading",
        label: "Map heading",
        value: "",
        placeholder: ""
    },
    {
        type: "text",
        name: "map_address",
        label: "Store address",
        value: "",
        placeholder: ""
    },
    {
        type: "editor",
        name: "address_text",
        id: "address_text",
        label: "Address text",
        value: "",
    },
    {
        type: "text",
        name: "button_label",
        label: "Button label",
        value: "",
        placeholder: ""
    },
    {
        type: "select",
        label: "Vertical position",
        name: "vertical",
        value: "center",
        items: [
            { name: "Top", value: "top" },
            { name: "Center", value: "center" },
            { name: "Bottom", value: "bottom" }
        ]
    },
    {
        type: "select",
        label: "Horizontal position",
        name: "horizontal",
        value: "left",
        items: [
            { name: "Left", value: "left" },
            { name: "Center", value: "center" },
            { name: "Right", value: "right" }
        ]
    },
]