import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import BlockRenderFn from "./Templates/Template1/Exports";
import { useSelector } from "react-redux";
import _ from "underscore";
import Template1 from "./Templates/Template1";
import { resetFn } from "../../../../Container/config/Apis";

export default function LayoutContainer({ view }) {
    const dataVal = useSelector((state) => state.dataValue)
    // const [dataVal, setdataVal] = useState({})
    // function getFetchData() {
    //     fetch("http://localhost:3000/db").then(res => res.json()).then(result => {
    //         let iframeBody = document.getElementById("customizer")
    //         // if (iframeBody.document) {
    //         //     iframeBody = iframeBody.document;
    //         // }
    //         console.log("iframeBody: ", iframeBody)
    //         // iframeBody.body.style.backgroundColor = "red";
    //         setdataVal(result)
    //     })
    // }
    // useEffect(() => {
    //     getFetchData()
    // }, [])

    return (
        <div className="layout_container">
            <Paper>
                <div className="layout_card" style={{
                    fontFamily: !_.isEmpty(dataVal) && dataVal.components.typography.body_font,
                    fontSize: !_.isEmpty(dataVal) && dataVal.components.typography.body_text_base_size,
                    color: !_.isEmpty(dataVal) && dataVal.components.colors.body_text_color,
                    maxWidth: view === "tablet" ? "768px" : view === "mobile" ? "480px" : "100%"
                }}
                >
                    <Template1
                        data={!_.isEmpty(dataVal) && dataVal}
                    />
                    {/* <iframe src="http://localhost:3001/customizer/1" id="customizer" style={{ maxWidth: view === "tablet" ? "768px" : view === "mobile" ? "480px" : "100%" }} width={"100%"}></iframe> */}
                </div>
            </Paper>
        </div >

        // <div className="layout_container">
        //     <div className="layout_card" style={{
        //         fontFamily: !_.isEmpty(dataVal) && dataVal.components.typography.body_font,
        //         fontSize: !_.isEmpty(dataVal) && dataVal.components.typography.body_text_base_size,
        //         color: !_.isEmpty(dataVal) && dataVal.components.colors.body_text_color,
        //         maxWidth: view === "tablet" ? "768px" : view === "mobile" ? "480px" : "100%"
        //     }}
        //     >
        //         <iframe src="http://localhost:3002/" id="customizer" style={{ maxWidth: view === "tablet" ? "768px" : view === "mobile" ? "480px" : "100%" }} width={"100%"}></iframe>
        //     </div>
        // </div>
    )
}