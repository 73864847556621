import React, { useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent,
    FormControl, Divider, Typography,
    TextField, Container, Avatar, Alert, FormHelperText, Checkbox, FormControlLabel, IconButton,
    FormGroup,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';


const UserAndPermission = () => {
    const [body, setBody] = useState({
        status: "active"
    });
    const [userDetailShw, setUserDetailShw] = useState(false);
    const [addStaff, setAddStaff] = useState(false)


    const permission = [
        { label: "Home", checked: false, },
        { label: "Orders", checked: false, },
        { label: "Draft orders", checked: false, },
        { label: "Products", checked: false, },
        { label: "Customers", checked: false, },
        { label: "Dashboards", checked: false, },
        { label: "Discounts", checked: false, },
        { label: "Draft orders", checked: false, },
    ]

    const handleOnchange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    }
    const showUserDetail = () => {
        setUserDetailShw(true)
        setAddStaff(false)
    }
    const hideUserDetail = () => {
        setUserDetailShw(false)
    }
    const showAddStaff = () => {
        setAddStaff(true)
        setUserDetailShw(false)

    }
    const hideAddStaff = () => {
        setAddStaff(false)
    }
    useEffect(() => {

    }, []);

    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Users and permissions </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>   </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ mb: 2 }} >

                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={6}>
                                <Typography variant="p" component="strong" gutterBottom>Permissions</Typography>
                                <Typography variant="body2" component="div" gutterBottom>Manage what users can see or do in your store.  </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "right" }}>
                                <Button variant="outlined" color="primary" onClick={showAddStaff} > Add staff</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box>
                    {userDetailShw ? null :
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Box sx={{ mb: 2, }}>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs={6}>
                                            <Box>
                                                <Typography variant="p" component="strong" gutterBottom>Store owner</Typography>
                                                <Typography variant="body2" component="div" gutterBottom>Your current billing currency is set to ₹.</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                                            {/* <Button variant="outlined" color="primary" >Transfer ownership</Button> */}

                                        </Grid>
                                    </Grid>
                                    <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: "none", mt: 2, }}>
                                        <Avatar>VS</Avatar>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 1, pl: 2, textAlign: "left", }}>
                                            <Button variant="text" color="secondary" className='noHover' sx={{ p: 0 }} onClick={showUserDetail} >BalJit Singh  </Button>
                                            <Typography variant="p" component="div" sx={{ lineHeight: "normal" }}>  Last login was Tuesday, December 14, 2021 10:30 AM GMT+5:30 </Typography>

                                        </Box>
                                    </Card>
                                </Box>
                            </CardContent>

                        </Card>
                    }
                    {userDetailShw ?
                        <>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box sx={{ mb: 1 }}>
                                        <Grid container spacing={2} columns={12}>
                                            <Grid item xs={8}>
                                                <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: "none", }}>
                                                    <Avatar>VS</Avatar>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 1, pl: 2, textAlign: "left", }}>
                                                        <Typography variant="p" component="div" sx={{ lineHeight: "normal" }}>  Villiam Jit Singh</Typography>
                                                        <a href="mailto:villiamjit@weoinfotech.com" color="secondary" className='noHover'>villiamjit@weoinfotech.com</a>
                                                    </Box>
                                                </Card>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                    <Box sx={{ mb: 2, }}>
                                        <Box sx={{ width: "100%", maxWidth: "600px" }}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <Box> <Typography variant="p" component="strong" gutterBottom> Two-step Authentication </Typography>  </Box>
                                                <Box sx={{ textAlign: "left" }}> <Typography variant="p" component="strong" gutterBottom>Password </Typography> </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <Box><Typography variant="p" component="div" sx={{ lineHeight: "normal" }}>  You have not enabled two-step authentication. </Typography> </Box>
                                                <Box sx={{ textAlign: "left" }}><Typography variant="p" component="div" sx={{ lineHeight: "normal" }}>      ******** </Typography> </Box>
                                            </Box>
                                        </Box>

                                    </Box>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Box sx={{ mb: 2, }}>
                                        <Typography variant="p" component="strong" sx={{ mb: 2, }}>Details</Typography>
                                    </Box>

                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <TextField
                                            label="Bio (optional)"
                                            id="bio"
                                            name="bio"
                                            type="text"
                                            size="small"
                                            multiline
                                            minRows={4}
                                            onChange={handleOnchange}
                                            sx={{ m: 0, width: '100%' }} />
                                        <FormHelperText>You can select for this to appear on blog posts that Villiam Jit publishes, from within your theme editor.</FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <TextField
                                            label="Personal website address (optional)"
                                            id="PersonalWebsite"
                                            name="PersonalWebsite"
                                            type="text"
                                            size="small"
                                            minRows={4}
                                            onChange={handleOnchange}
                                            sx={{ m: 0, width: '100%' }}
                                        />
                                    </FormControl>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Box sx={{ mb: 2, }}>
                                        <Typography variant="p" component="strong" sx={{ mb: 2, }}>Notification</Typography>
                                    </Box>
                                    <Box>
                                        <FormControlLabel control={<Checkbox defaultChecked disableRipple sx={{ py: 0 }} />} label="Allow important notifications to be sent by email" />
                                        <Typography variant="body2" component="div" sx={{ lineHeight: "normal", pl: 3.6 }} > We periodically send out important news about Shopify to our users via email. We keep the email volume to an absolute minimum.</Typography>

                                    </Box>


                                    <Box sx={{ mt: 3 }} >
                                        <Button variant="outlined" color="error" onClick={hideUserDetail}  >Cancel</Button>
                                        <Button variant="contained" color="primary" size="large " sx={{ ml: 1 }}>Save</Button>
                                    </Box>


                                </CardContent>
                            </Card>


                        </> : null}
                    <Card sx={{ mb: 2 }}>

                        {addStaff ? <>
                            <CardContent>
                                <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
                                    <Grid item xs={6}>
                                        <Box>
                                            <Typography variant="p" component="strong" >Add staff</Typography>
                                        </Box>
                                    </Grid>

                                </Grid>


                                <Grid container maxWidth="sm" spacing={2} columns={12}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField id="firstname" type="text" label="First Name" size="small" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField id="lastname" type="text" label="Last Name" size="small" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField id="email" type="text" label="Email" size="small" />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="p" component="div" sx={{ mb: 2 }}>This staff will have no permissions in this store.</Typography>
                                <Button variant="outlined" color="primary" sx={{ mb: 2 }}> Select all</Button>
                                <FormGroup sx={{ flexDirection: "row" }}>
                                    {permission.map((data, index) => {
                                        return <FormControlLabel key={index} control={<Checkbox />} label={data.label} />
                                    })}
                                </FormGroup>


                                <Box sx={{ pt: 2 }}>
                                    <Button variant="outlined" color="error" onClick={hideAddStaff}  >Cancel</Button>
                                    <Button variant="contained" color="primary" sx={{ ml: 1 }} > Send invite</Button>
                                </Box>

                            </CardContent>
                        </> :

                            <CardContent>
                                <Box sx={{ mb: 2, }}>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs={6}>
                                            <Box>
                                                <Typography variant="p" component="strong" gutterBottom>Staff (1 of 15)</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>

                                        </Grid>
                                    </Grid>
                                    <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: "none", mt: 2, }}>
                                        <Avatar>VS</Avatar>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 1, pl: 2, textAlign: "left", }}>
                                            <Button variant="text" color="secondary" className='noHover' sx={{ p: 0 }} >Villiam Jit Singh  </Button>
                                            <Typography variant="p" component="div" sx={{ lineHeight: "normal" }}>  Last login was Tuesday, December 14, 2021 10:30 AM GMT+5:30 </Typography>

                                        </Box>
                                    </Card>
                                </Box>
                            </CardContent>}


                    </Card>
                </Box>
            </Container>
        </>
    );
}

export default UserAndPermission