export default {
    active: [
        "header",
        "hero_slider",
        "product_list",
        "testimonials",
        "gallery",
        "blog_post",
        "map",
        "newsletter",
    ],
    components: {
        header: {
            name: "Header",
            logo_alignment: "left",
            logo_alt: "Logo",
            logo_image: "https://checkout-dev.growcart.app/logo-light.svg",
            logo_width: 100,
            spacing: 20,
            nav_menu: "main_menu",
            show_announcement: true,
            announcement_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            announcement_link: "",
            bar_spacing: 15,
            bar_color: "#000000",
            bar_text_color: "#ffffff",
            show_search: true
        },
        hero_slider: {
            name: "Slider",
            class: "hero-slider",
            slider_text_alignment: "center",
            autoplay: true,
            infinite: true,
            dots: true,
            arrows: true,
            limit: 5,
            items: [
                {
                    id: 1,
                    image_src: "https://checkout-dev.growcart.app/slide.jpg",
                    link: "",
                    heading: "Slide 1 heading",
                    subheading: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                    slide_img_alt: "",
                    button_label: "Shop now"
                },
                {
                    id: 2,
                    image_src: "https://checkout-dev.growcart.app/slide.jpg",
                    link: "",
                    heading: "Slide 2 heading",
                    subheading: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                    slide_img_alt: "",
                    button_label: "Shop now"
                },
                {
                    id: 3,
                    image_src: "https://checkout-dev.growcart.app/slide.jpg",
                    link: "",
                    heading: "Slide 3 heading",
                    subheading: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                    slide_img_alt: "",
                    button_label: "Shop now"
                }
            ],
        },
        content_block: {
            name: "Content block",
            heading: "Lorem ipsum dolor",
            order: "default",
            alignment: "center",
            image: "https://checkout-dev.growcart.app/side-image.jpg",
            content_heading: "Lorem ipsum dolor sit",
            paragraph: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>",
            button_label: "Shop now",
            button_link: ""
        },
        text_over_image: {
            name: "Text Over Image",
            image: "https://checkout-dev.growcart.app/slide.jpg",
            layout: "full",
            heading: "Lorem ipsum dolor sit",
            subheading: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>",
            alignment: "center",
            size: "medium",
            button_label: "Shop now",
            button_link: ""
        },
        product_list: {
            name: "Product list",
            heading: "Featured Collection",
            category: "",
            show_products: 8,
            item_per_row: 3,
            total_products: 50,
            is_slider: false,
        },
        text_column_with_image: {
            name: "Text column with image",
            heading: "",
            item_per_row: 3,
            items: [
                {
                    id: 1,
                    image_src: "https://checkout-dev.growcart.app/side-image.jpg",
                    heading: "Heading goes here",
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor sit amet, consectetur adipiscing elit.</p>",
                    img_alt: ""
                },
                {
                    id: 2,
                    image_src: "https://checkout-dev.growcart.app/side-image.jpg",
                    heading: "Heading goes here",
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor sit amet, consectetur adipiscing elit.</p>",
                    img_alt: ""
                },
                {
                    id: 3,
                    image_src: "https://checkout-dev.growcart.app/side-image.jpg",
                    heading: "Heading goes here",
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor sit amet, consectetur adipiscing elit.</p>",
                    img_alt: ""
                }
            ]
        },
        gallery: {
            name: "Gallery",
            heading: "",
            item_per_row: 3,
            lightbox: true,
            limit: 10,
            type: "masonry",
            items: [
                {
                    id: 1,
                    label: "",
                    label: "",
                    image_src: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
                    link: "",
                    caption: "Lorem ipsum dolor sit"
                },
                {
                    id: 2,
                    label: "",
                    image_src: "https://checkout-dev.growcart.app/side-image.jpg",
                    link: "",
                    caption: "Lorem ipsum dolor sit"
                },
                {
                    id: 3,
                    label: "",
                    image_src: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
                    link: "",
                    caption: "Lorem ipsum dolor sit"
                },
                {
                    id: 4,
                    label: "",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
                    link: "",
                    caption: "Lorem ipsum dolor sit"
                },
                {
                    id: 5,
                    label: "",
                    image_src: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
                    link: "",
                    caption: "Lorem ipsum dolor sit"
                },
                {
                    id: 6,
                    label: "",
                    image_src: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
                    link: "",
                    caption: "Lorem ipsum dolor sit"
                }
            ]
        },
        brands_list: {
            name: "Brand list",
            heading: "Heading goes here",
            item_per_row: 5,
            is_slider: true,
            items: [
                {
                    id: 1,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                },
                {
                    id: 2,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                },
                {
                    id: 3,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                },
                {
                    id: 4,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                },
                {
                    id: 5,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                },
                {
                    id: 6,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                },
                {
                    id: 7,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                },
                {
                    id: 8,
                    image_src: "https://checkout-dev.growcart.app/logo-light.svg",
                    link: ""
                }
            ]
        },
        map: {
            name: "Map",
            src: "https://goo.gl/maps/5ZbfyzdZQWdXLD8K6",
            image: "https://checkout-dev.growcart.app/slide.jpg",
            heading: "Store name",
            address_text: "<p>123 Fake St.<br>Toronto, Canada&nbsp;</p>\n",
            map_address: "301 Front St W, Toronto",
            button_label: "Get Directions",
            vertical: "center",
            horizontal: "left",
        },
        heading_block: {
            name: "Heading block",
            heading: "Lorem ipsum dolor sit",
            subheading: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>",
            size: "small"
        },
        testimonials: {
            name: "Testimonials",
            heading: "What our clients say",
            item_per_row: 1,
            limit: 5,
            autoplay: false,
            infinite: true,
            dots: true,
            arrows: true,
            items: [
                {
                    id: 1,
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>",
                    author: "John smith",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3"
                },
                {
                    id: 2,
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>",
                    author: "William",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3"
                },
                {
                    id: 3,
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>",
                    author: "John smith",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3"
                },
            ]
        },
        newsletter: {
            name: "Newsletter",
            heading: "Subscribe to our newsletter",
            subheading: "<p>Promotions, new products and sales. Directly to your inbox.</p>",
            button_label: "Subscribe",
        },
        blog_post: {
            name: "Blog post",
            heading: "Blog posts",
            show_posts: 3,
            blog_category: "news_blog",
            show_date: true,
            show_author: true,
            items: [
                {
                    id: 1,
                    title: "Lorem ipsum dolor sit amet",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...",
                    author: "John smith",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
                    date: "03-04-2022",
                    slug: "lorem-ipsum-dolor-sit-amet"
                },
                {
                    id: 2,
                    title: "Lorem ipsum dolor sit amet",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...",
                    author: "John smith",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
                    date: "03-04-2022",
                    slug: "lorem-ipsum-dolor-sit-amet"
                },
                {
                    id: 3,
                    title: "Lorem ipsum dolor sit amet",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...",
                    author: "John smith",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
                    date: "03-04-2022",
                    slug: "lorem-ipsum-dolor-sit-amet"
                },
                {
                    id: 4,
                    title: "Lorem ipsum dolor sit amet",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...",
                    author: "John smith",
                    image_src: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
                    date: "03-04-2022",
                    slug: ""
                },
            ]
        },
        video: {
            name: "Video",
            heading: "",
            link: "https://www.youtube.com/watch?v=_9VUPq3SxOc",
            cover_image: "https://checkout-dev.growcart.app/slide.jpg",
            show_overlay: false,
            video_height: "large",
            style: "background_video",
        },
        button: {
            name: "Button",
            label: "",
            link: "",
            type: "success",
            settings: {
                alignment: "left",
                size: "large"
            }
        },
        counter: {
            name: "Counter",
            heading: "",
            subheading: "",
            date_time: "",
            publish: true
        },
        list_style: {
            name: "List style",
            layout: "list",
            icon: "default",
            items: [
                "Lorem ipsum dolor sit amet.",
                "Lorem ipsum dolor sit amet."
            ]
        },
        accordion: {
            name: "Accordion",
            heading: "",
            items: [
                {
                    title: "",
                    description: ""
                }
            ]
        },
        custom_html: {
            name: "Custom HTML",
            code: ""
        },
        typography: {
            name: "Typography",
            heading_font: "Roboto",
            heading_base_size: 26,
            body_font: "Roboto",
            body_text_base_size: 16
        },
        colors: {
            name: "Colors",
            heading_color: "#000000",
            button_color: "#000000",
            button_text_color: "#ffffff",
            body_text_color: "#000000",
            body_bg_color: "#ffffff",
            overlay_bg_color: "#000000",
            overlay_opacity: 40
        },
        share_button: {
            name: "Share buttons",
            options: {
                facebook: true,
                twitter: true,
                pinterest: false
            }
        },
        social_button: {
            name: "Social Buttons",
            title: "Follow us:",
            options: [
                {
                    facebook: "",
                    twitter: "",
                    pinterest: "",
                    instagram: "",
                    youtube: "",
                    vimeo: "",
                    tumblr: "",
                    snapchat: ""
                }
            ]
        },
        favicon: {
            name: "Favicon",
            image_scr: ""
        },
        footer: {
            name: "Footer",
            settings: {
                background_color: "#000000",
                text_color: "#ffffff"
            },
            navigation: [
                {
                    heading: "Quick links",
                    list: [
                        {
                            label: "About",
                            link: "/about"
                        },
                        {
                            label: "Contact us",
                            link: "/contact"
                        }
                    ]
                }
            ]
        },
    },
    // page: {
    //     product_page: {
    //         name: "Product page",
    //         slider: {
    //             size: "medium",
    //             enable_zoom: true,
    //             thumbnail: {
    //                 position: "vertical",
    //                 show_items: 6
    //             }
    //         },
    //         product_detail: {
    //             show_quantity: true,
    //             show_vendor: true,
    //             show_share_buttons: true
    //         },
    //         recommend_products: {
    //             heading: "",
    //             item_per_row: 5
    //         }
    //     },
    //     product_list: {
    //         name: "Product list",
    //         heading: "",
    //         layout: "grid",
    //         product_per_row: 4,
    //         show_vendor: true,
    //         show_sale_tag: true
    //     },
    //     category_list: {
    //         name: "category list",
    //         heading: "",
    //         items_per_row: 3,
    //         show_list: [
    //             "all",
    //             "selected"
    //         ],
    //         sort: [
    //             "all",
    //             "alphabetically",
    //             "date",
    //             "price"
    //         ]
    //     }
    // },
}