import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Container, Paper, } from '@mui/material';
import _ from 'underscore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import './Fileupload.css';

export default function SingleFileupload({ onFileChange, preview, typeFile }) {
    return (
        <>
            <Box className="container">
                <Box className="fileUploader">
                    {/* {typeFile ? null : preview && preview !== "" &&
                                <Paper sx={{ mb: 2 }} className="filePreviewBox singlefilePreviewBox">
                                    <div className="filePreviewBox__img" >
                                        <img src={preview} />
                                    </div>
                                </Paper>
                            } */}
                    <Box className="customFileUpload">
                        <input
                            accept={`.jpg,.jpeg,.png,.webp,.svg ${typeFile ? ",.pdf" : null}`}
                            type='file' title="Choose image"
                            onChange={onFileChange}
                        />
                        <label>
                            {typeFile ? null : preview && preview !== "" ?
                                <div className="singlefilePreviewBox">
                                    <div className="filePreviewBox__img" >
                                        <img src={preview} />
                                        <span className='edit_image_icon'><CameraAltIcon /></span>
                                    </div>
                                </div>
                                :
                                <>
                                    <FileUploadIcon fontSize='small' sx={{ verticalAlign: "middle" }} />
                                    Add file
                                </>
                            }
                        </label>
                    </Box>
                </Box>
            </Box>
        </>
    )
}