import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSelector } from "react-redux";
import HeadingText from "./HeadingText";
import products from "../products"
import * as heading from "./Typography/Exports"

export default function ProductList() {
    const dataVal = useSelector((state) => state.dataValue.components.product_list)
    const slider = useRef(null)
    var settings = {
        "autoplay": false,
        "infinite": true,
        "dots": false,
        "arrows": true,
        "slidesToShow": dataVal.item_per_row,
        "slidesToScroll": 1
    }
    const prevslide = () => {
        slider.current.slickPrev()
    }
    const nextslide = () => {
        slider.current.slickNext()
    }
    return (
        <Box sx={{ py: 5 }} id="product_list">
            <Container maxWidth="lg">
                <HeadingText
                    data={dataVal && dataVal}
                />
                {dataVal && dataVal.is_slider ?
                    <Box className="product_slider_outer">
                        <Button className="slick-arrows slick-arrows-prev" onClick={prevslide}>
                            <ArrowBackIosNewIcon fontSize="large" />
                        </Button>
                        <Slider ref={slider} {...settings} className={`main_slider ${dataVal && dataVal.class}`}>
                            {products.length > 0 && products.slice(0, dataVal && dataVal.show_products).map((opt) => {
                                return (
                                    <Box key={opt.id.toString()} sx={{ px: 2 }}>
                                        <img src={opt.image} alt="" className="img-fluid" />
                                        <heading.HeadingH4
                                            ishtml={false}
                                            type="div"
                                            data={opt.title}
                                        />
                                        <heading.HeadingH5
                                            ishtml={false}
                                            type="div"
                                            data={`Rs. ${opt.price.toFixed(2)}`}
                                        />
                                    </Box>
                                )
                            })}
                        </Slider>
                        <Button className="slick-arrows slick-arrows-next" onClick={nextslide}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </Box>
                    :
                    <Grid container spacing={4}>
                        {products.length > 0 && products.slice(0, dataVal && dataVal.show_products).map((opt) => {
                            return (
                                <Grid key={opt.id.toString()} item sm={6} md={dataVal && dataVal.item_per_row == 4 ? 3 : dataVal.item_per_row == 3 ? 4 : 6} >
                                    <img src={opt.image} alt="" className="img-fluid" />
                                    <heading.HeadingH4
                                        ishtml={false}
                                        type="div"
                                        data={opt.title}
                                    />
                                    <heading.HeadingH5
                                        ishtml={false}
                                        type="div"
                                        data={`Rs. ${opt.price.toFixed(2)}`}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                }
            </Container>
        </Box>
    )
}