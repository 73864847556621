import React, { useEffect, useState } from "react";
import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ViewListIcon from '@mui/icons-material/ViewList';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TitleIcon from '@mui/icons-material/Title';
import EmailIcon from '@mui/icons-material/Email';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LinkIcon from '@mui/icons-material/Link';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';

export default function SectionIcons({ type }) {
    const [active, setActive] = useState("")
    useEffect(() => {
        if (type === "header") {
            return (
                setActive(<BorderTopIcon />)
            )
        }
        if (type === "footer") {
            return (
                setActive(<BorderBottomIcon />)
            )
        }
        if (type === "hero_slider") {
            return (
                setActive(<ViewCarouselIcon />)
            )
        }
        if (type === "content_block") {
            return (
                setActive(<VerticalSplitIcon />)
            )
        }
        if (type === "text_over_image") {
            return (
                setActive(<ChromeReaderModeIcon />)
            )
        }
        if (type === "gallery") {
            return (
                setActive(<DashboardIcon />)
            )
        }
        if (type === "text_column_with_image") {
            return (
                setActive(<LibraryBooksIcon />)
            )
        }
        if (type === "product_list") {
            return (
                setActive(<ViewListIcon />)
            )
        }
        if (type === "map") {
            return (
                setActive(<LocationOnIcon />)
            )
        }
        if (type === "brands_list") {
            return (
                setActive(<VerifiedUserIcon />)
            )
        }
        if (type === "heading_block") {
            return (
                setActive(<TitleIcon />)
            )
        }
        if (type === "testimonials") {
            return (
                setActive(<RateReviewIcon />)
            )
        }
        if (type === "blog_post") {
            return (
                setActive(<RssFeedIcon />)
            )
        }
        if (type === "newsletter") {
            return (
                setActive(<EmailIcon />)
            )
        }
        if (type === "video") {
            return (
                setActive(<OndemandVideoIcon />)
            )
        }
        if (type === "button") {
            return (
                setActive(<LinkIcon />)
            )
        }
        if (type === "counter") {
            return (
                setActive(<TimelapseIcon />)
            )
        }
        if (type === "list_style") {
            return (
                setActive(<FormatListBulletedIcon />)
            )
        }
        if (type === "accordion") {
            return (
                setActive(<StorageIcon />)
            )
        }
        if (type === "custom_html") {
            return (
                setActive(<CodeIcon />)
            )
        }

    }, [type])
    return (
        <React.Fragment>
            {active}
        </React.Fragment>
    )
}