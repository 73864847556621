export default {
    sections: [
        "hero_slider",
        "text_over_image",
        "product_list",
        "text_column_with_image",
        "gallery",
        "content_block",
        "brands_list",
        "map",
        "heading_block",
        "testimonials",
        "blog_post",
        "newsletter",
        "video",
        "button",
        "counter",
        "list_style",
        "accordion",
        "custom_html",
    ],
    theme: {
        typography: {
            name: "Typography",
        },
        colors: {
            name: "Colors",
        },
        share_button: {
            name: "Share buttons",
        },
        social_button: {
            name: "Social Buttons",
        },
        favicon: {
            name: "Favicon",
        }
    }
}